import { KafkaContext } from '@/domain/log/KafkaContext';
import { XxxxxxMenuLibraryLog } from '@/domain/log/__types__/xxxxxx-menu-library';
import { XxxxxxMenuStoreLog } from '@/domain/log/__types__/xxxxxx-menu-store';
import { XxxxxxMenuSvodLog } from '@/domain/log/__types__/xxxxxx-menu-svod';
import BadgeIcon from '@/shared/components/BadgeIcon';
import getLoginUrl from '@/shared/utils/getLoginUrl';
import classNames from 'classnames';
import React, { ReactNode, useContext } from 'react';
import { useIntl } from 'react-intl';
import BeemiLogo from '../../../../assets/icons/logo/Logo_BEEMI.svg';
import { globalMessages } from '../../../constants/messages';
import BeemiLink from '../../BeemiLink';
import Button from '../../Button/Button';
import Icon, { Icons } from '../../Icon';
import Footer from '../Footer';

interface Props {
  isLoggedIn: boolean;
  loading: boolean;
  showLogo: boolean;
  activeIndex: number;
  point: number;
  onLinkClicked?: () => void;
}

const NavigationDrawer: React.FC<Props> = ({
  isLoggedIn,
  loading,
  showLogo,
  activeIndex,
  point,
  onLinkClicked,
}) => {
  const intl = useIntl();
  const { client } = useContext(KafkaContext);
  return (
    <div className="flex flex-col h-screen width-drawer bg-purple/5">
      {showLogo && (
        <div className="flex justify-center py-10 border-b border-purple/10">
          <BeemiLink href="/store">
            <BeemiLogo className="w-32" />
          </BeemiLink>
        </div>
      )}

      <div
        className={classNames(
          'flex flex-col px-10 py-8 border-b align-center gap-y-4 border-purple/10',
          !showLogo && 'py-24'
        )}
      >
        {loading ? (
          <div className="flex flex-col flex-grow animate-pulse gap-y-4">
            <div className="h-10 rounded-md bg-purple/5" />
            <div className="h-10 rounded-md bg-purple/5" />
            <div className="h-10 rounded-md bg-purple/5" />
          </div>
        ) : isLoggedIn ? (
          <>
            <a
              target="_blank"
              href={`${process.env.NEXT_PUBLIC_MYACCOUNT_URL}/pointcharge`}
              data-cy="button-userPoints"
              className="flex items-center h-10 px-2.5 rounded-md bg-purple/10"
              rel="noreferrer"
            >
              <BadgeIcon name="POINT" size="BIGGER" className="text-pink" />
              <div className="flex items-baseline flex-grow ml-1 truncate">
                <span className="font-mono text-md mr-0.5 font-bold leading-4">
                  {intl.formatNumber(point)}
                </span>
                <span className="font-semibold text-label">
                  {intl.formatMessage({
                    id: 'navigationDrawer.point',
                    defaultMessage: 'ポイント',
                  })}
                </span>
              </div>
              <Icon name="NEW_TAB_ICON" className="text-purple/30" />
            </a>
            <DrawerButton
              href="/library"
              iconName="LIBRARY_ICON"
              isActive={activeIndex === 2}
              onClick={() => {
                onLinkClicked && onLinkClicked();
                client?.trackUser<XxxxxxMenuLibraryLog>(
                  'xxxxxx-menu-library',
                  'click',
                  'user_click_dimension_0_default',
                  {}
                );
              }}
            >
              {intl.formatMessage({
                id: 'navigationDrawer.library',
                defaultMessage: 'ライブラリ',
              })}
            </DrawerButton>
            <DrawerButton
              href="/mypage"
              iconName="USER_ICON"
              isActive={activeIndex === 3}
              onClick={() => {
                onLinkClicked && onLinkClicked();
              }}
            >
              {intl.formatMessage(globalMessages.myPage)}
            </DrawerButton>
          </>
        ) : (
          <>
            <Button
              text={intl.formatMessage(globalMessages.newMember)}
              component="a"
              href={process.env.NEXT_PUBLIC_REGISTER_WITH_MONTHLY_PLAN_URL}
            />
            <Button
              text={intl.formatMessage(globalMessages.login)}
              variant="SECONDARY"
              component="a"
              href={getLoginUrl()}
            />
          </>
        )}
      </div>
      <nav className="flex flex-col flex-grow px-10 pt-8 align-center gap-y-4">
        <DrawerButton
          href="/store"
          iconName="STORE_ICON"
          isActive={activeIndex === 0}
          onClick={() => {
            onLinkClicked && onLinkClicked();
            client?.trackUser<XxxxxxMenuStoreLog>(
              'xxxxxx-menu-store',
              'click',
              'user_click_dimension_0_default',
              {}
            );
          }}
        >
          {intl.formatMessage({
            id: 'navigationDrawer.store',
            defaultMessage: 'ストア',
          })}
        </DrawerButton>
        <DrawerButton
          href="/svod"
          iconName="SVOD_ICON"
          isActive={activeIndex === 1}
          onClick={() => {
            onLinkClicked && onLinkClicked();
            client?.trackUser<XxxxxxMenuSvodLog>(
              'xxxxxx-menu-svod',
              'click',
              'user_click_dimension_0_default',
              {}
            );
          }}
        >
          {intl.formatMessage({
            id: 'navigationDrawer.SVOD',
            defaultMessage: '見放題',
          })}
        </DrawerButton>
      </nav>
      <div className={classNames(!showLogo && 'mb-12')}>
        <Footer />
      </div>
    </div>
  );
};

interface DrawerButtonProps {
  iconName: Icons;
  children: ReactNode;
  isActive: boolean;
  onClick?: () => void;
  href?: string;
}

const DrawerButton: React.FC<DrawerButtonProps> = ({
  children,
  iconName,
  isActive,
  onClick,
  href,
}) => {
  return (
    <BeemiLink
      href={href || ''}
      className={classNames(
        'flex items-center h-10 px-4 py-2 text-sm leading-6 transition-colors rounded-md hover:bg-purple/5',
        isActive ? 'text-pink font-semibold' : ''
      )}
      onClick={onClick}
    >
      <Icon size="BIG" name={iconName} />
      <div className="ml-4">{children}</div>
    </BeemiLink>
  );
};

export default NavigationDrawer;
