import { InputKindIdTitle, LibuDomain } from '@/shared/generated';

export const PLANETS = ['video', 'amateur', 'anime', 'vr'] as const;

export type PLANET_TYPE = (typeof PLANETS)[number];

export const PLANET_ID = {
  video: 'AV_GENERAL' as const,
  amateur: 'AV_AMATEUR' as const,
  anime: 'AV_ANIME' as const,
  vr: 'AV_VR' as const,
};

const BEEMI_MYLIST = {
  PACKAGE: 'beemi-mylist-package' as const,
  CHAPTER: 'beemi-mylist-chapter' as const,
};

export const beemiMyListPackageInput = {
  kind: InputKindIdTitle.Title,
  domainTitle: {
    domain: LibuDomain.Beemi,
    title: BEEMI_MYLIST.PACKAGE,
  },
};

export const beemiMyListChaptersInput = {
  kind: InputKindIdTitle.Title,
  domainTitle: {
    domain: LibuDomain.Beemi,
    title: BEEMI_MYLIST.CHAPTER,
  },
};
