import { CHAPTER_SEQUENCE_ID_NAME } from '@/domain/chapter/useChapterSequence';
import { useBeemiSetFavorite } from '@/domain/library/mylist/useBeemiSetFavorite';
import {
  KafkaContext,
  KafkaPropsWithBaseSchema,
} from '@/domain/log/KafkaContext';
import type { ChapterPlayerRecommendTitleMyListLog } from '@/domain/log/__types__/chapterPlayer-recommendTitle-myList';
import type { ChapterPlayerRecommendTitleTitleCardLog } from '@/domain/log/__types__/chapterPlayer-recommendTitle-titleCard';
import type { SearchResultChapterBlockMyListLog } from '@/domain/log/__types__/searchResult-chapterBlock-myList';
import type { SearchResultChapterBlockTitleCardLog } from '@/domain/log/__types__/searchResult-chapterBlock-titleCard';
import type { SearchResultChapterTitleListTitleCardLog } from '@/domain/log/__types__/searchResultChapter-titleList-titleCard';
import type { SvodTitleListMyListLog } from '@/domain/log/__types__/svod-titleList-myList';
import type {
  CardSize,
  CommonMediaLightProps,
} from '@/shared/components/Card/types';
import VRBadge from '@/shared/components/Card/VRBadge';
import { useLoginState } from '@/shared/hooks/useLoginState';
import { getTimeString } from '@/shared/utils/timeHelper';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  FC,
  MouseEventHandler,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import Badge from '../../Badge';
import BeemiImage from '../../BeemiImage';
import MyListButton from '../../Button/MyListButton';
import OptionButton from '../../Button/OptionButton';
import CardTag from '../CardTag';
import EditCard from '../EditCard';
import Like from '../Like';
import OptionsSheet from '../OptionsSheet';

const CARD_SIZE_MAPS: Record<CardSize, string> = {
  FULL: 'w-full',
  BLOCK_SMALL: 'w-50 md:w-60',
  BLOCK_LARGE: 'w-84 md:w-66',
};

const TAG_THRESHOLD_LENGTH_MAPS: Record<CardSize, number> = {
  FULL: 9,
  BLOCK_SMALL: 9,
  BLOCK_LARGE: 20,
};

const MAX_NUMBER_OF_TAGS_MAP: Record<CardSize, number> = {
  FULL: 4,
  BLOCK_SMALL: 2,
  BLOCK_LARGE: 4,
};

const IMAGE_SIZE_MAPS: Record<CardSize, string> = {
  FULL: '200px',
  BLOCK_SMALL: '200px',
  BLOCK_LARGE: '335px',
};

type TitleCardLog =
  | SearchResultChapterBlockTitleCardLog
  | SearchResultChapterTitleListTitleCardLog
  | ChapterPlayerRecommendTitleTitleCardLog;

type MyListLog =
  | SvodTitleListMyListLog
  | SearchResultChapterBlockMyListLog
  | ChapterPlayerRecommendTitleMyListLog;

type OptionsSheetLog = MyListLog;

type ChapterCardLog = TitleCardLog | OptionsSheetLog;

export type ChapterCardProps = CommonMediaLightProps & {
  duration: number;
  cardSize?: CardSize;
  sequenceId?: number;
  kafkaProps?: KafkaPropsWithBaseSchema<ChapterCardLog>;
  featureBadges?: string[];
};

const ChapterCard: FC<ChapterCardProps> = ({
  id,
  sequenceId,
  description,
  imgSrc,
  imgAlt,
  duration,
  isNew,
  likes,
  tags = [],
  cardSize = 'FULL',
  edit,
  isFavorite = false,
  kafkaProps,
  featureBadges,
}) => {
  const router = useRouter();
  const [isOptionsShowing, setIsOptionsShowing] = useState(false);

  const { client } = useContext(KafkaContext);

  const { isLoggedIn } = useLoginState();

  const { setFavorite } = useBeemiSetFavorite({
    type: 'chapter',
    refetchQueries: ['getFavoriteList'],
  });

  const [isAddToMyListClicked, setIsAddToMyListClicked] = useState(false);

  const handleOnClickCard = useCallback(() => {
    if (kafkaProps) {
      const { targetPrefix, base_schema, ...rest } = kafkaProps;

      client?.trackUser(
        (targetPrefix + '-titleCard') as TitleCardLog['event']['target'],
        'click',
        base_schema,
        {
          chapter_id: rest.chapter_id,
          index: rest.index,
          search_kw: rest.search_kw,
          search_tag_list: rest.search_tag_list,
          source_package_id: rest.source_package_id,
          source_chapter_id: rest.source_chapter_id,
        }
      );
    }
  }, [client, kafkaProps]);

  const onClickMyListButton: MouseEventHandler = (e) => {
    // make the chapter link not work while clicking mylist button
    e.preventDefault();

    setFavorite({
      resourceId: id,
      isFavorite: !isFavorite,
    });

    setIsOptionsShowing(false);

    setIsAddToMyListClicked(true);
  };

  // Find max number of tags that will fit.
  const arrayFrom1ToMax = Array.from(
    { length: MAX_NUMBER_OF_TAGS_MAP[cardSize] },
    (_, i) => i + 1
  );
  const maxNumberOfTags = arrayFrom1ToMax
    .slice()
    .reverse()
    .find((n) => {
      // Check if n-1 words pass the string length threshold
      return (
        tags.slice(0, n - 1).join('').length <
        TAG_THRESHOLD_LENGTH_MAPS[cardSize]
      );
    });

  const slicedTags = tags.slice(0, maxNumberOfTags);

  const optionRef = useRef<HTMLDivElement>(null);

  const isVR = featureBadges?.includes('VR') ?? false;

  return (
    <article className={classNames(CARD_SIZE_MAPS[cardSize], 'relative')}>
      <div
        className={classNames(
          'overflow-hidden rounded-md shadow-sm aspect-w-25 aspect-h-14 group',
          !edit &&
            'transition-transform duration-100 hover:scale-105 will-change-transform'
        )}
      >
        {edit && <EditCard id={id} />}
        <Link
          href={{
            pathname: router.pathname,
            query: {
              ...router.query,
              cid: id,
              ...(sequenceId ? { [CHAPTER_SEQUENCE_ID_NAME]: sequenceId } : {}),
            },
          }}
          scroll={false}
          onClick={handleOnClickCard}
          data-testid="ChapterCard"
        >
          <BeemiImage
            className={`object-cover w-full h-full ${edit ? 'opacity-50' : ''}`}
            src={imgSrc}
            alt={imgAlt}
            sizeConfig={IMAGE_SIZE_MAPS[cardSize]}
          />
          {isVR && <VRBadge />}
          {isNew && (
            <div className="absolute bottom-2 left-2">
              <Badge badge="NEW" />
            </div>
          )}
          {!isFavorite && isLoggedIn && (
            <MyListButton
              className={classNames(
                'absolute z-10 transition opacity-0 pointer-events-none top-2 right-2',
                isAddToMyListClicked
                  ? 'group-hover:opacity-50'
                  : 'group-hover:pointer-events-auto group-hover:opacity-100 '
              )}
              onClick={onClickMyListButton}
              isAddToMyListClicked={isAddToMyListClicked}
            />
          )}
          <div className="absolute px-2 py-1 text-white rounded bottom-2 right-2 text-time bg-purple/60">
            {getTimeString(duration)}
          </div>
        </Link>
      </div>
      <div className="w-full mt-2">
        <Link
          className="block text-left line-clamp-1 md:line-clamp-2 text-caption text-purple/80 "
          href={{
            pathname: router.pathname,
            query: {
              ...router.query,
              cid: id,
              ...(sequenceId ? { [CHAPTER_SEQUENCE_ID_NAME]: sequenceId } : {}),
            },
          }}
          scroll={false}
          onClick={handleOnClickCard}
        >
          {description}
        </Link>
        <div className="flex items-center">
          <div className="flex flex-1 items-center h-4 -mx-0.5 overflow-hidden">
            {typeof likes === 'number' && (
              <div className="px-0.5">
                <Like likes={likes} />
              </div>
            )}
            <div className="flex px-0.5 overflow-hidden">
              <CardTag>{slicedTags.map((x) => `#${x}`).join(' ')}</CardTag>
            </div>
          </div>
          <div className="relative flex px-0.5" ref={optionRef}>
            <OptionButton
              onClick={() => {
                setIsOptionsShowing(true);
              }}
            />
            <OptionsSheet
              anchor={optionRef.current}
              id={id}
              title={description}
              isShowing={isOptionsShowing}
              isFavorite={isFavorite}
              showDetails={false}
              type="chapter"
              onClose={() => {
                setIsOptionsShowing(false);
              }}
              onCancel={() => {
                setIsOptionsShowing(false);
              }}
              onClickMyList={onClickMyListButton}
              kafkaProps={
                // because the parent props has got TitleCardLog which is not handled by OptionsSheet
                kafkaProps as KafkaPropsWithBaseSchema<OptionsSheetLog>
              }
              isAddToMyListClicked={isAddToMyListClicked}
              setIsAddToMyListAdded={setIsAddToMyListClicked}
            />
          </div>
        </div>
      </div>
    </article>
  );
};

export default ChapterCard;
