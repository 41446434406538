import { FC } from 'react';
import DataSpinner from '../../../../assets/icons/loader/loader.svg';

interface DataLoadingSpinnerProps {
  showAnimation?: boolean;
}

const DataLoadingSpinner: FC<DataLoadingSpinnerProps> = () => {
  return (
    <div className="flex items-center flex-grow mx-auto md:pt-30 md:items-start">
      <DataSpinner className={'w-12 h-12 text-purple/60'} />
    </div>
  );
};

export default DataLoadingSpinner;
