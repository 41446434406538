import classNames from 'classnames';
import React, { InputHTMLAttributes } from 'react';

const Radio: React.FC<InputHTMLAttributes<HTMLInputElement>> = (props) => {
  return (
    <input
      {...props}
      type="radio"
      className={classNames(
        'cursor-pointer h-5 w-5 border-2 rounded-full transition border-purple/60 focus:border-purple/60 checked:border-purple/60 hover:checked:border-purple/60 focus:checked:border-purple/60 text-pink focus:ring-0 focus:ring-offset-0',
        props.disabled ? 'border-purple/40' : 'border-purple/60'
      )}
    />
  );
};

export default Radio;
