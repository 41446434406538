import { TreasureDataContext } from '@/domain/log/TreasureDataContext';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

interface MediaLogInfo {
  media_id: string;
  media_name: string;
  consumable_id: string;
  consumable_type: string;
  play_time: number;
  product_public_code: string;
  sale_type_code: string;
}

interface MediaChapterLogInfo extends MediaLogInfo {
  media_chapter_id: string;
  media_chapter_start_position: number;
  media_chapter_end_position: number;
  media_chapter_number: number;
}

interface PlayLogInfo {
  download_flg: 0 | 1;
  player_session_id: string;
  player: string;
  movie_profile_type: string;
  drm_type: string;
}

interface PlayStartLogInfo {
  playback_start_time_raw: Date;
  play_start_position: number;
}

interface PlayEndLogInfo {
  playback_end_time_raw: Date;
  play_end_position: number;
}

const PLAYBACK_TIME_FORMAT = {
  year: 'numeric',
  month: 'numeric',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
} as const;

export interface PlayerTdLogger {
  tryToSend: (playEndLogInfo: PlayEndLogInfo) => void | undefined;
  setMediaLogInfo: (mediaLogInfo: MediaLogInfo | MediaChapterLogInfo) => void;
  setPlayLogInfo: (playLogInfo: PlayLogInfo) => void;
  setPlayStartLogInfo: (playStartLogInfo: PlayStartLogInfo) => void;
}

function usePlayerTdLogger(): PlayerTdLogger {
  const intl = useIntl();
  const { treasureDataClient } = useContext(TreasureDataContext);

  const mediaLogInfoRef = useRef<MediaLogInfo | null>(null);
  const playLogInfoRef = useRef<PlayLogInfo | null>(null);
  const playStartLogInfoRef = useRef<PlayStartLogInfo | null>(null);

  const setMediaLogInfo = useCallback((mediaLogInfo: MediaLogInfo) => {
    mediaLogInfoRef.current = mediaLogInfo;
  }, []);

  const setPlayLogInfo = useCallback((playLogInfo: PlayLogInfo) => {
    playLogInfoRef.current = playLogInfo;
  }, []);

  const setPlayStartLogInfo = useCallback(
    (playStartLogInfo: PlayStartLogInfo) => {
      playStartLogInfoRef.current = playStartLogInfo;
    },
    []
  );

  const tryToSend = useCallback(
    (playEndLogInfo: PlayEndLogInfo) => {
      if (playStartLogInfoRef.current) {
        const playStartLogInfo = playStartLogInfoRef.current;

        const playback_time = Math.floor(
          Math.abs(
            playEndLogInfo.playback_end_time_raw.getTime() -
              playStartLogInfo.playback_start_time_raw.getTime()
          ) / 1000
        );

        if (
          // we don't send TD logs if the end - start time is less than 1 second,
          // to prevent log spamming on button smashing
          playback_time >= 1
        ) {
          treasureDataClient?.send('beemi_movie_play_log', {
            ...mediaLogInfoRef.current,
            ...playLogInfoRef.current,
            play_start_position: playStartLogInfo.play_start_position,
            playback_start_time: intl
              .formatDate(
                playStartLogInfo.playback_start_time_raw,
                PLAYBACK_TIME_FORMAT
              )
              // YYYY/MM/DD -> YYYY-MM-DD
              .replaceAll('/', '-'),
            play_end_position: playEndLogInfo.play_end_position,
            playback_end_time: intl
              .formatDate(
                playEndLogInfo.playback_end_time_raw,
                PLAYBACK_TIME_FORMAT
              )
              // YYYY/MM/DD -> YYYY-MM-DD
              .replaceAll('/', '-'),
            playback_time,
          });
        }

        playStartLogInfoRef.current = null;
      }
    },
    [intl, treasureDataClient]
  );

  const returnValue = useMemo(
    () => ({
      tryToSend,
      setMediaLogInfo,
      setPlayLogInfo,
      setPlayStartLogInfo,
    }),
    [tryToSend, setMediaLogInfo, setPlayLogInfo, setPlayStartLogInfo]
  );

  return returnValue;
}

export default usePlayerTdLogger;
