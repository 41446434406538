/**
 * Returns [HH:]mm:ss string from value in second. The number after decimal point is rounded up.
 *
 * @param seconds duration, length of time
 * @param forceShowHour If true, show HH even though it is "00"
 */
export const getTimeString = (
  seconds: number,
  forceShowHour?: boolean
): string => {
  const showHour = forceShowHour || seconds >= 3600;
  const m = `${Math.floor((seconds / 60) % 60)}`.padStart(2, '0');
  const s = `${Math.ceil(seconds % 60)}`.padStart(2, '0');
  let text = `${m}:${s}`;
  if (showHour) {
    const h = `${Math.floor(seconds / 3600)}`.padStart(2, '0');
    text = `${h}:${text}`;
  }
  return text;
};

/**
 * Returns minutes string from value in second.
 *
 * @param seconds duration, length of time
 */
export const getMinutuesString = (seconds: number): string => {
  const m = seconds < 60 ? 1 : Math.floor(seconds / 60);
  return `${m}`;
};
