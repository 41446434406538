const getLoginUrl = (backUrl = ''): string => {
  const nonce = Math.floor(Math.random() * (2 ** 31 - 1));
  const encodedOAuth2LoginCallbackUrl = encodeURIComponent(
    `${process.env.NEXT_PUBLIC_PUBLIC_URL}/login_callback`
  );
  const encodedState = encodeURIComponent(`backUrl_${backUrl}`);
  return `${process.env.NEXT_PUBLIC_OAUTH_SERVER}/oauth2/auth?client_id=unext&redirect_uri=${encodedOAuth2LoginCallbackUrl}&response_type=code&scope=unext&state=${encodedState}&nonce=${nonce}`;
};

export default getLoginUrl;
