import type { PropsWithChildren } from 'react';

const CardTag = ({ children }: PropsWithChildren) => {
  return (
    <div className="inline-flex items-center min-w-0 text-label text-purple/60">
      <span className="truncate">{children}</span>
    </div>
  );
};

export default CardTag;
