import { TreasureDataClient } from '@/domain/log/TreasureDataClient';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';

const useTdTransitionLogger = (
  treasureDataClient: TreasureDataClient | undefined
) => {
  const router = useRouter();

  const sendTransitionLog = useCallback(() => {
    if (treasureDataClient) {
      treasureDataClient.send('beemi_page_transition_log', {
        td_title: document.title,
        td_url: document.location.href.split('#')[0],
        td_referrer: document.referrer,
        td_host: document.location.host,
        td_path: document.location.pathname,
      });
    }
  }, [treasureDataClient]);

  useEffect(() => {
    router.events.on('routeChangeComplete', sendTransitionLog);

    return () => {
      router.events.off('routeChangeComplete', sendTransitionLog);
    };
  }, [router, sendTransitionLog]);

  useEffect(() => {
    sendTransitionLog();
  }, [sendTransitionLog, treasureDataClient]);
};

export default useTdTransitionLogger;
