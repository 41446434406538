import { FC } from 'react';

export interface TagBannerProps {
  title: string;
  subTitle: string;
  imgSrc: string;
  imgAlt: string;
}

const TagBanner: FC<TagBannerProps> = ({ title, subTitle, imgSrc }) => {
  return (
    <div
      className={`w-full aspect-w-2 aspect-h-1 rounded-md shadow-sm bg-cover bg-top`}
      style={{ backgroundImage: `url(${imgSrc})` }}
    >
      <div className="flex flex-col items-center justify-end p-2 text-white/60">
        <div className="max-w-full space-x-0.5 text-xs md:text-sm truncate">
          <span className="">#</span>
          <span className="font-bold text-white">{title}</span>
        </div>
        <div className="max-w-full truncate text-label">{subTitle}</div>
      </div>
    </div>
  );
};

export default TagBanner;
