import { useRouter } from 'next/router';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

interface IsInitialRouteContextProps {
  isInitialRoute: boolean;
}

const IsInitialRouteContext = createContext<IsInitialRouteContextProps>({
  isInitialRoute: true,
});

export const IsInitialRouteProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const [isInitialRoute, setIsInitialRoute] = useState(true);

  const onRouteChange = useCallback(() => {
    setIsInitialRoute(false);
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', onRouteChange);

    return () => {
      router.events.off('routeChangeComplete', onRouteChange);
    };
  }, [router, onRouteChange]);

  return (
    <IsInitialRouteContext.Provider
      value={{
        isInitialRoute,
      }}
    >
      {children}
    </IsInitialRouteContext.Provider>
  );
};

export default IsInitialRouteContext;
