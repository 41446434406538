import { FC } from 'react';

const PlayerMetaLoading: FC = () => {
  return (
    <div className="flex flex-col items-center md:items-start md:pl-20">
      <div className="w-full mb-12 md:pr-80 md:pl-0 px-7">
        <div className="w-full h-4 mb-3 rounded bg-purple/10 md:h-5" />
        <div className="w-9/12 h-4 rounded bg-purple/10 md:h-5" />
      </div>

      <div className="flex flex-row gap-x-12 md:pl-6 md:gap-x-8">
        <div className="w-10 h-12 rounded bg-purple/10 md:w-12 md:h-14" />
        <div className="w-10 h-12 rounded bg-purple/10 md:w-12 md:h-14" />
        <div className="w-10 h-12 rounded bg-purple/10 md:w-12 md:h-14" />
      </div>
    </div>
  );
};

export default PlayerMetaLoading;
