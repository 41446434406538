import { KafkaContext } from '@/domain/log/KafkaContext';
import { TreasureDataContext } from '@/domain/log/TreasureDataContext';
import { PackageDetailLog } from '@/domain/log/__types__/packageDetail';
import MetaInfo from '@/domain/package/PackageDetail/MetaInfo';
import OtherPurchaseOptionDialog from '@/domain/package/PackageDetail/OtherPurchaseOptionDialog';
import PackageLoading from '@/domain/package/PackageDetail/PackageLoading';
import PlayAction from '@/domain/package/PackageDetail/PlayAction';
import Stage from '@/domain/package/PackageDetail/Stage';
import { useSubscribeActionPackageProps } from '@/domain/package/PackageDetail/useSubscribeActionPackageProps';
import PurchaseDialog from '@/domain/purchase';
import BeemiImage from '@/shared/components/BeemiImage';
import Gallery from '@/shared/components/Gallery';
import MetaTags from '@/shared/components/MetaTags';
import SignupPrime from '@/shared/components/Modality/Dialog/SignupPrime';
import SubscribeAction from '@/shared/components/SubscribeAction';
import UserButtonsGroup from '@/shared/components/UserButtonsGroup';
import VRAlert from '@/shared/components/VRAlert';
import { errorMessages } from '@/shared/constants/messages';
import { packageDetailMetaMessage } from '@/shared/constants/meta/package/packageDetail';
import {
  MediaFeatureBadge,
  useGetMediaQuery,
  VideoConsumableType,
} from '@/shared/generated';
import { useLoginState } from '@/shared/hooks/useLoginState';
import { useSnackBar } from '@/shared/SnackBarContext';
import { extractFirstGraphQLError } from '@/shared/utils/extractFirstGraphQLError';
import { ApolloError } from '@apollo/client';
import { useRouter } from 'next/router';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';

const messages = {
  hasRightUntil: defineMessage({
    id: 'package.hasRightUntil',
    defaultMessage: '{endDate}{endHour}時{endMinute}分まで視聴可',
  }),
};

interface Props {
  packageId: string;
}

const PackageDetail: FC<Props> = ({ packageId }) => {
  const router = useRouter();
  const intl = useIntl();
  const { tdClientId } = useContext(TreasureDataContext);

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const showGallery = () => {
    setIsGalleryOpen(true);
  };

  const {
    data: userInfoData,
    loading: loginStateLoading,
    isLoggedIn,
    isReady,
    canUpsell,
  } = useLoginState();

  const [
    isOtherPurchaseOptionDialogShowing,
    setIsOtherPurchaseOptionDialogShowing,
  ] = useState(false);

  const showOtherPurchaseOptionDialog = () => {
    setIsOtherPurchaseOptionDialogShowing(true);
  };

  const { showSnackBar } = useSnackBar();
  const onError = useCallback(
    (error: ApolloError) => {
      const notFoundError = extractFirstGraphQLError(error, ['NOT_FOUND']);
      if (notFoundError) {
        showSnackBar({
          message: intl.formatMessage(errorMessages.pageNotFound),
        });

        router.push('/store');

        return;
      }
      throw new ApolloError(error);
    },
    [intl, router, showSnackBar]
  );

  const { data, loading: getMediaQueryLoading } = useGetMediaQuery({
    variables: {
      mediaId: packageId,
      deviceId: tdClientId,
      isLoggedIn,
    },
    skip: !isReady,
    onError,
  });

  const { client } = useContext(KafkaContext);
  useEffect(() => {
    client?.trackUser<PackageDetailLog>(
      'packageDetail',
      'view',
      'user_view_page_default',
      {
        package_id: packageId,
      }
    );
  }, [client, packageId]);

  const handlePurchaseComplete = useCallback(() => {
    const newQuery = { ...router.query };
    delete newQuery['purchase'];
    newQuery['play'] = 'main';
    router.push({ ...router, query: newQuery }, undefined, { scroll: false });
  }, [router]);

  const metadata = data?.media?.metadata;

  const largeGalleryImages = metadata?.screenshots.map(({ w2 }) => ({
    src: w2,
    alt: w2,
  }));

  const hasRightUntil = data?.media?.playbackRight?.expiredDateTime;

  const [isSignupPrimeDialogShowing, setIsSignupPrimeDialogShowing] =
    useState<boolean>(false);

  const showSignUpDialog = () => {
    setIsSignupPrimeDialogShowing(true);
  };

  const hideSignUpDialog = () => {
    setIsSignupPrimeDialogShowing(false);
  };

  const loading = getMediaQueryLoading || loginStateLoading;

  const subscribeActionProps = useSubscribeActionPackageProps({
    productInfo: data?.media?.productInfo,
    isLoggedIn,
    canUpsell,
  });

  const videoDuration = data?.media?.metadata?.videoConsumables.find(
    (video) => video.type === VideoConsumableType.Main
  )?.durationSeconds;

  const hasSampleVideo = Boolean(
    data?.media?.metadata?.videoConsumables.find(
      (video) => video.type === VideoConsumableType.Sample
    )
  );

  const isVR = metadata?.featureBadges?.includes(MediaFeatureBadge.Vr);

  return (
    <>
      <MetaTags
        title={intl.formatMessage(packageDetailMetaMessage.title, {
          packageName: metadata?.nameJa,
        })}
        description={intl.formatMessage(packageDetailMetaMessage.description)}
        canonicalLink={`/package/${packageId}`}
      />
      {loading ? (
        <PackageLoading />
      ) : (
        <div
          className="pt-12 px-7 md:pt-16 md:px-20 pb-25 md:pb-10"
          data-testid="PackageDetail"
        >
          {/* Safari considers the Z axis position of an object that has been transformed when determining where to draw it instead of utilizing z-index. Translate helps to fix issue on Safari where Package title is cut off */}
          <div className="absolute top-0 left-0 w-full translate-x-0 translate-y-0 h-50 md:h-60 lg:rounded-t-2xl lg:overflow-hidden">
            <div
              className="absolute w-full h-full bg-white/80 backdrop-blur-xl"
              style={{
                background:
                  'linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, #FFFFFF 100%)',
              }}
            />
            <div
              className="w-full h-full bg-center bg-cover"
              style={{
                backgroundImage:
                  metadata?.thumbnails[0].t1 &&
                  `url(${metadata.thumbnails[0].t1})`,
              }}
            />
          </div>
          {metadata && (
            <div className="flex flex-col gap-10 md:gap-12">
              <section className="md:flex">
                <div className="relative w-40 h-56 mx-auto overflow-hidden border rounded-md shadow-sm border-purple/10 md:shrink-0 md:mr-12 md:w-50 md:h-70">
                  <button onClick={showGallery}>
                    <BeemiImage
                      src={metadata.thumbnails[0].t1}
                      alt={metadata.nameJa}
                    />
                  </button>
                </div>
                <div className="mt-6 md:flex-grow md:z-10 md:mt-2">
                  {data.media && (
                    <Stage
                      metadata={data.media?.metadata}
                      productInfo={data.media?.productInfo}
                    />
                  )}

                  <PlayAction
                    productInfo={data.media?.productInfo}
                    packageId={packageId}
                    showOtherPurchaseOptionDialog={
                      showOtherPurchaseOptionDialog
                    }
                    showSignUpDialog={showSignUpDialog}
                    videoDuration={videoDuration}
                    hasSampleVideo={hasSampleVideo}
                  />

                  {hasRightUntil && (
                    <p className="mt-4 text-center md:text-left text-pink/80 text-label">
                      {intl.formatMessage(messages.hasRightUntil, {
                        endDate: intl.formatDate(hasRightUntil, {
                          month: 'long',
                          day: 'numeric',
                        }),
                        endHour: new Date(hasRightUntil).getHours(),
                        endMinute: new Date(hasRightUntil).getMinutes(),
                      })}
                    </p>
                  )}

                  <section className="mt-6">
                    <UserButtonsGroup
                      packageId={packageId}
                      isLoggedIn={isLoggedIn}
                      isFavorite={Boolean(data?.media?.favorite?.id)}
                      rating={data?.media?.rating?.score}
                      title={data.media?.metadata.nameJa || ''}
                      showSignUpDialog={showSignUpDialog}
                      refetchQueries={['getMedia']}
                    />
                  </section>

                  {isVR && (
                    <section className="mt-6">
                      <VRAlert />
                    </section>
                  )}
                </div>
              </section>

              {subscribeActionProps && (
                <SubscribeAction
                  type="package"
                  textLine1={subscribeActionProps.textLine1}
                  textLine2={subscribeActionProps.textLine2}
                  buttonText={subscribeActionProps.buttonText}
                  buttonHref={subscribeActionProps.buttonHref || ''}
                />
              )}

              {data.media && (
                <MetaInfo
                  metadata={data.media?.metadata}
                  recommendations={data.media?.paginatedRecommendations.records}
                  showGallery={showGallery}
                  publicEndDateTime={data.media.productInfo?.publicEndDateTime}
                />
              )}
            </div>
          )}
        </div>
      )}
      {largeGalleryImages && (
        <Gallery
          images={largeGalleryImages}
          isGalleryOpen={isGalleryOpen}
          closeGallery={() => setIsGalleryOpen(false)}
        />
      )}
      {isSignupPrimeDialogShowing && (
        <SignupPrime
          resourceId={packageId}
          type="package"
          hideSignUpDialog={hideSignUpDialog}
        />
      )}
      <OtherPurchaseOptionDialog
        isShowing={isOtherPurchaseOptionDialogShowing}
        onClose={() => setIsOtherPurchaseOptionDialogShowing(false)}
      />
      <PurchaseDialog
        mediaId={packageId}
        userInfoData={userInfoData}
        onPurchaseComplete={handlePurchaseComplete}
      />
    </>
  );
};

export default PackageDetail;
