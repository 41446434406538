import DialogSignup from '@/shared/components/Modality/Dialog/Signup';
import PFUserSignUp from '@/shared/components/Modality/Dialog/Signup/PFUser';
import { useLoginState } from '@/shared/hooks/useLoginState';
import { BeemiResourceType } from '@/shared/types';
import getLoginUrl from '@/shared/utils/getLoginUrl';
import { FC } from 'react';

interface Props {
  resourceId: string;
  type: BeemiResourceType;
  hideSignUpDialog: () => void;
}

const SignupPrime: FC<Props> = ({ resourceId, type, hideSignUpDialog }) => {
  const { canUpsell } = useLoginState();
  return (
    <>
      {canUpsell ? (
        <PFUserSignUp
          isShowing={canUpsell}
          href={process.env.NEXT_PUBLIC_REGISTER_UPSELL_URL ?? ''}
          onCancel={hideSignUpDialog}
          onClickOutside={hideSignUpDialog}
        />
      ) : (
        <DialogSignup
          isShowing={true}
          registerHref={process.env.NEXT_PUBLIC_REGISTER_PF_URL ?? ''}
          loginHref={getLoginUrl(`/${type}/${resourceId}`)}
          onCancel={hideSignUpDialog}
          onClickOutside={hideSignUpDialog}
        />
      )}
    </>
  );
};

export default SignupPrime;
