import { SelectCardAction } from '@/shared/selectedCardsReducer';
import React, { createContext } from 'react';

interface ContextProps {
  selectedCards: Map<string, boolean>;
  updateSelectedCards: React.Dispatch<SelectCardAction>;
}
const SelectedCardsContext = createContext<ContextProps>({} as ContextProps);

export const useSelectedCards = (): ContextProps => {
  const context = React.useContext(SelectedCardsContext);
  if (!context) {
    throw new Error(
      'useSelectedCards must be rendered within the SelectedCardsContext.Provider'
    );
  }
  return context;
};

export default SelectedCardsContext;
