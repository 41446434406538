import SnackBar from '@/shared/components/Modality/SnackBar';
import {
  ComponentProps,
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

type SnackBarContextProps = ComponentProps<typeof SnackBar>;

interface ContextProps {
  showSnackBar: (props: Omit<SnackBarContextProps, 'isShowing'>) => void;
}

const SnackBarContext = createContext<ContextProps>({} as ContextProps);

const defaultSnackBarProps: SnackBarContextProps = {
  isShowing: false,
  message: '',
  action: undefined,
  onAction: undefined,
  onSwipe: undefined,
};

const SnackBarContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const resetTimer = useRef<number | null>(null);
  const [snackBarProps, setSnackBarProps] =
    useState<SnackBarContextProps>(defaultSnackBarProps);

  const turnOffSnackBar = useCallback(
    (props: Omit<SnackBarContextProps, 'isShowing'>) => {
      setSnackBarProps({
        ...props,
        isShowing: false,
      });
    },
    []
  );

  return (
    <SnackBarContext.Provider
      value={{
        showSnackBar: (params) => {
          // hide the previous shown snack bar before showing the upcoming one
          turnOffSnackBar(params);
          setTimeout(() => {
            if (resetTimer.current) {
              clearTimeout(resetTimer.current);
            }
            resetTimer.current = window.setTimeout(() => {
              turnOffSnackBar(params);
            }, 3000);
            setSnackBarProps({
              ...params,
              isShowing: true,
            });
          }, 300);
        },
      }}
    >
      <>{children}</>
      <SnackBar {...snackBarProps} />
    </SnackBarContext.Provider>
  );
};

const useSnackBar = (): {
  showSnackBar: ContextProps['showSnackBar'];
} => {
  const { showSnackBar } = useContext(SnackBarContext);

  return { showSnackBar };
};

export { SnackBarContextProvider, useSnackBar };
