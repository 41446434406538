import Icon from '@/shared/components/Icon';
import { FC, ReactNode } from 'react';

interface SimpleDialogTitleProps {
  title: ReactNode;
  onCancel?: () => void;
}

const SimpleDialogTitle: FC<SimpleDialogTitleProps> = ({ title, onCancel }) => {
  return (
    <div className="flex items-center gap-4">
      {onCancel && (
        <button
          className="absolute w-8 h-8 transition md:flex md:flex-grow-0 md:relative md:w-11 md:h-11 text-purple/80 hover:text-purple"
          onClick={onCancel}
          data-testid="simpleDialog-button-close"
        >
          <Icon name="CLOSE_ICON" size="FULL" />
        </button>
      )}
      <div className="flex items-center justify-center flex-grow text-purple md:justify-start text-headline">
        {title}
      </div>
    </div>
  );
};

export default SimpleDialogTitle;
