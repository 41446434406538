import {
  beemiMyListChaptersInput,
  beemiMyListPackageInput,
} from '@/domain/store/constants';
import {
  DeleteFavoriteItemsMutation,
  InputKindIdResource,
  ResourceType,
  useDeleteFavoriteItemsMutation,
} from '@/shared/generated';
import { BeemiResourceType } from '@/shared/types';
import { ApolloError, MutationResult } from '@apollo/client';

interface Props {
  refetchQueries?: string[];
  onError?: (error: ApolloError) => void;
  onCompleted?: (res: DeleteFavoriteItemsMutation) => void;
}

export const useBeemiDeleteFavoriteItems = ({
  refetchQueries,
  onError,
  onCompleted,
}: Props): MutationResult<DeleteFavoriteItemsMutation> & {
  deleteFavoriteItems: (params: {
    type: BeemiResourceType;
    resourceIds: string[];
  }) => Promise<void>;
} => {
  const [deleteFavoriteItemsMutation, res] = useDeleteFavoriteItemsMutation({
    refetchQueries,
    onError,
    onCompleted,
  });

  async function deleteFavoriteItems({
    type,
    resourceIds,
  }: {
    type: BeemiResourceType;
    resourceIds: string[];
  }) {
    const deleteItemPayload = (resourceId: string) => {
      return {
        favoriteList:
          type === 'package'
            ? beemiMyListPackageInput
            : beemiMyListChaptersInput,
        resource: {
          id: resourceId,
          type:
            type === 'package'
              ? ResourceType.MediaLight
              : ResourceType.MediaChapterLight,
        },
      };
    };

    await deleteFavoriteItemsMutation({
      variables: {
        input: {
          favoriteItems: resourceIds.map((id) => {
            return {
              kind: InputKindIdResource.Resource,
              favoriteListResource: deleteItemPayload(id),
            };
          }),
        },
      },
    });
  }

  return {
    ...res,
    deleteFavoriteItems,
  };
};
