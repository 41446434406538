import {
  KafkaContext,
  KafkaPropsWithBaseSchema,
} from '@/domain/log/KafkaContext';
import { SearchResultChapterBlockMyListLog } from '@/domain/log/__types__/searchResult-chapterBlock-myList';
import Icon from '@/shared/components/Icon';
import classNames from 'classnames';
import { ButtonHTMLAttributes, MouseEventHandler, useContext } from 'react';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  kafkaProps?: KafkaPropsWithBaseSchema<SearchResultChapterBlockMyListLog>;
  isAddToMyListClicked: boolean;
};

/**
 * Might want to adopt/extract cosmo MylistButton to coordinate the onClick animation effect in future
 */
const MyListButton = ({
  className,
  kafkaProps,
  onClick,
  isAddToMyListClicked,
}: Props) => {
  const { client } = useContext(KafkaContext);

  const onClickButton: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (kafkaProps) {
      const { targetPrefix, base_schema, ...rest } = kafkaProps;
      const target =
        `${targetPrefix}-myList` as SearchResultChapterBlockMyListLog['event']['target'];

      client?.trackUser<SearchResultChapterBlockMyListLog>(
        target,
        'click',
        base_schema,
        rest
      );
    }

    onClick?.(event);
  };

  return (
    <button
      onClick={onClickButton}
      className={classNames(
        `w-10 h-10 rounded transition text-purple/60 bg-white/80 hover:text-purple hover:bg-white`,
        className
      )}
    >
      <Icon
        name={isAddToMyListClicked ? 'ADDED_ICON' : 'PLUS_ICON'}
        size="FULL"
      />
    </button>
  );
};

export default MyListButton;
