import { createPopper, Instance as PopperjsInstance } from '@popperjs/core';
import { FC, ReactNode, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { MODAL_ROOT } from '../Modality/constants';

interface Props {
  anchor: HTMLElement | null;
  children?: ReactNode;
}

const PopupHelper: FC<Props> = ({ anchor, children }) => {
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let popperInstance: PopperjsInstance | undefined;
    if (anchor && popupRef.current) {
      popperInstance = createPopper(anchor, popupRef.current, {
        placement: 'bottom',
      });
    }

    return () => popperInstance?.destroy();
  }, [anchor]);

  return createPortal(
    <div
      className="z-100"
      onClick={(e) => {
        /**
         * Why `e.stopPropagation()`?
         *
         * If you click inside the PopupHelper div then the event will propagate up and reach `Dialog`
         * which will think it's a click outside and close the Dialog
         * (which is technically correct since the PopupHelper lives inside a portal a.k.a. modal-root div).
         * Using stopPropagation here will effectively contain any user action to the PopupHelper.
         *
         * FUTURE: maybe we need to apply useClickOutside on the PopupHelper, instead of the inner component?
         *
         * PR source: https://github.com/u-next/beemi-web/pull/237#discussion_r916522824
         */
        e.stopPropagation();
      }}
      role="presentation"
      ref={popupRef}
    >
      {children}
    </div>,
    document.getElementById(MODAL_ROOT) as Element
  );
};

export default PopupHelper;
