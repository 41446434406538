import { addRatingInput } from '@/domain/gql';
import { useBeemiSetFavorite } from '@/domain/library/mylist/useBeemiSetFavorite';
import { KafkaContext } from '@/domain/log/KafkaContext';
import { ChapterPlayerTitleActionMyListLog } from '@/domain/log/__types__/chapterPlayer-titleAction-myList';
import { ChapterPlayerTitleActionRateLog } from '@/domain/log/__types__/chapterPlayer-titleAction-rate';
import { PackageDetailTitleActionMyListLog } from '@/domain/log/__types__/packageDetail-titleAction-myList';
import { PackageDetailTitleActionRateLog } from '@/domain/log/__types__/packageDetail-titleAction-rate';
import TitleActionButton from '@/shared/components/Button/TitleActionButton';
import DialogShare from '@/shared/components/Modality/Share';
import { useAddRatingMutation } from '@/shared/generated';
import useFormatErrorMessage from '@/shared/hooks/useFormatErrorMessage';
import { useSnackBar } from '@/shared/SnackBarContext';
import UserAgentContext from '@/shared/UserAgentContext';
import { extractFirstGraphQLError } from '@/shared/utils/extractFirstGraphQLError';
import { FC, useContext, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';

const messages = {
  myList: defineMessage({
    id: 'userButtonsGroup.myList',
    defaultMessage: 'マイリスト',
  }),
  like: defineMessage({
    id: 'userButtonsGroup.like',
    defaultMessage: '評価',
  }),
  liked: defineMessage({
    id: 'userButtonsGroup.liked',
    defaultMessage: '評価済み',
  }),
  share: defineMessage({
    id: 'userButtonsGroup.share',
    defaultMessage: '共有',
  }),
};

interface Props {
  packageId: string;
  chapterId?: string;
  title: string;
  refetchQueries: string[];
  isFavorite?: boolean;
  rating?: number;
  isLoggedIn?: boolean;
  showSignUpDialog: () => void;
}

/**
 * Named it to be in sync with cosmo even though they dont share the same implemented.
 * Maybe one day we can put some effort to extract and consolidate them
 */
const UserButtonsGroup: FC<Props> = ({
  packageId,
  chapterId,
  refetchQueries,
  title,
  isFavorite,
  rating,
  isLoggedIn,
  showSignUpDialog,
}) => {
  const resourceId = chapterId || packageId;
  const type = chapterId ? 'chapter' : 'package';

  const intl = useIntl();
  const formatErrorMessage = useFormatErrorMessage();

  const { showSnackBar } = useSnackBar();

  const { setFavorite } = useBeemiSetFavorite({
    type,
    refetchQueries,
    showSnackBarOnSuccess: false,
  });

  const { client } = useContext(KafkaContext);

  const onClickMyListButton = () => {
    if (!isLoggedIn) {
      showSignUpDialog();
    } else {
      setFavorite({
        resourceId,
        isFavorite: !isFavorite,
      });

      if (type === 'package') {
        client?.trackUser<PackageDetailTitleActionMyListLog>(
          'packageDetail-titleAction-myList',
          'click',
          'user_click_dimension_0_default',
          {
            package_id: packageId,
            toggle_status: !isFavorite,
          }
        );
      } else if (type === 'chapter') {
        client?.trackUser<ChapterPlayerTitleActionMyListLog>(
          'chapterPlayer-titleAction-myList',
          'click',
          'user_click_dimension_0_default',
          {
            package_id: packageId,
            toggle_status: !isFavorite,
            chapter_id: chapterId,
          }
        );
      }
    }
  };

  const isThumbsUp = rating === 1;
  const [addRatingMutation] = useAddRatingMutation({
    refetchQueries,
    onError: (error) => {
      const { code, message } = extractFirstGraphQLError(error) || {};
      showSnackBar({ message: formatErrorMessage(code, message) });
    },
  });

  const onClickThumbsUp = () => {
    if (!isLoggedIn) {
      showSignUpDialog();
    } else {
      addRatingMutation({
        variables: {
          input: addRatingInput({
            type,
            resourceId,
            score: isThumbsUp ? 0 : 1,
          }),
        },
      });
      if (type === 'package') {
        client?.trackUser<PackageDetailTitleActionRateLog>(
          'packageDetail-titleAction-rate',
          'click',
          'user_click_dimension_0_default',
          {
            package_id: packageId,
            toggle_status: !isThumbsUp,
          }
        );
      } else if (type === 'chapter') {
        client?.trackUser<ChapterPlayerTitleActionRateLog>(
          'chapterPlayer-titleAction-rate',
          'click',
          'user_click_dimension_0_default',
          {
            package_id: packageId,
            toggle_status: !isThumbsUp,
            chapter_id: chapterId,
          }
        );
      }
    }
  };

  const [isShareDialogShowing, setIsShareDialogShowing] = useState(false);
  const onClickShareButton = () => {
    setIsShareDialogShowing(true);
  };

  const { userAgent } = useContext(UserAgentContext);
  const isIos = userAgent.match(/iPhone|iPad|iPod/i);

  return (
    <div data-testid="UserButtonsGroup" className="flex md:justify-start">
      <TitleActionButton
        icon={isFavorite ? 'ADDED_ICON' : 'PLUS_ICON'}
        text={intl.formatMessage(messages.myList)}
        checked={isFavorite}
        styleOverride="grow md:grow-0"
        onClick={onClickMyListButton}
      />
      <div className="border-l" />
      <TitleActionButton
        icon="THUMBS_UP"
        text={
          isThumbsUp
            ? intl.formatMessage(messages.liked)
            : intl.formatMessage(messages.like)
        }
        checked={isThumbsUp}
        styleOverride="grow md:grow-0"
        onClick={onClickThumbsUp}
      />
      <div className="border-l" />
      <TitleActionButton
        icon={isIos ? 'SHARE_ICON_IOS' : 'SHARE_ICON'}
        text={intl.formatMessage(messages.share)}
        styleOverride="grow md:grow-0"
        onClick={onClickShareButton}
      />

      {/* add this div so that the parent sits as the 4th item in the row */}
      <div>
        <div className="absolute">
          <DialogShare
            isShowing={isShareDialogShowing}
            embedCode={`<iframe width="560" height="315" src="${process.env.NEXT_PUBLIC_PUBLIC_URL}/embed/sample/${packageId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`}
            shareUrl={`${process.env.NEXT_PUBLIC_PUBLIC_URL}/package/${packageId}`}
            shareTitle={title}
            onCancel={() => {
              setIsShareDialogShowing(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserButtonsGroup;
