import * as Sentry from '@sentry/nextjs';
import Router from 'next/router';
import { Component, ReactNode } from 'react';
import Error from '../../../domain/error';

interface ExtendedError extends Error {
  customTitle?: string;
  customMessage?: string;
  errorCode?: string;
  withoutUI?: boolean;
}

export interface ErrorBoundaryState {
  error: ExtendedError | null;
}

interface Props {
  children: ReactNode;
}

const initialState = { error: null };

class ErrorBoundary extends Component<Props, ErrorBoundaryState> {
  constructor(props: Props) {
    super(props);

    this.state = initialState;
  }

  static getDerivedStateFromError(
    error: ExtendedError
  ): Partial<ErrorBoundaryState> {
    return { error };
  }

  componentDidCatch(error: ExtendedError, errorInfo: React.ErrorInfo): void {
    Sentry.configureScope((scope) => {
      if (errorInfo) {
        // The React.ErrorInfo type can be regarded as a Record<string, unknown> type,
        // but it can't pass the type check here even with type coersion
        // we can check this next time when we update typescript version
        // @ts-expect-error Because of the above comment
        scope.setExtras(errorInfo);
      }
      Sentry.captureException(error);
    });
  }

  componentDidMount(): void {
    Router.events.on('routeChangeComplete', this.onRouteChange);
  }

  componentWillUnmount(): void {
    Router.events.off('routeChangeComplete', this.onRouteChange);
  }

  onRouteChange = (): void => {
    this.setState(initialState);
  };

  render(): ReactNode {
    const { error } = this.state;
    if (error !== null && !error.withoutUI) {
      return <Error />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
