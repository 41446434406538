import { TreasureDataContext } from '@/domain/log/TreasureDataContext';
import { InitialLog } from '@/domain/log/__types__/initial';
import { useLoginState } from '@/shared/hooks/useLoginState';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { KafkaClient, _getKafkaClient } from './kafkaClient';

interface KafkaContextProps {
  client?: KafkaClient;
}

interface KafkaTarget {
  target: string;
  targetPrefix?: never;
}

interface KafkaTargetPrefix {
  target?: never;
  targetPrefix: string;
}

export type KafkaPropsWithTarget<
  T extends { event: { base_schema: unknown; detail: unknown } }
> = KafkaTarget & {
  base_schema?: T['event']['base_schema'];
} & T['event']['detail'];

export type KafkaPropsWithTargetPrefix<
  T extends { event: { base_schema: unknown; detail: unknown } }
> = KafkaTargetPrefix & {
  base_schema?: T['event']['base_schema'];
} & T['event']['detail'];

/**
 * some of the more generic UI component might get very different payload, including base_schema
 * in cases like this, you can use this type to make sure consumers will provide `base_schema`
 */
export type KafkaPropsWithBaseSchema<
  T extends { event: { base_schema: unknown; detail: unknown } }
> = (KafkaTarget | KafkaTargetPrefix) & {
  base_schema: T['event']['base_schema'];
} & T['event']['detail'];

export const KafkaContext = React.createContext<KafkaContextProps>({});

export const KafkaProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [client, setClient] = useState<KafkaClient>();
  const [isReady, setIsReady] = useState(false);
  const { data: userInfoData, loading } = useLoginState();
  const userInfo = userInfoData?.userInfo;
  const { tdClientId } = useContext(TreasureDataContext);

  useEffect(() => {
    // getKafkaClient is only needed at the client side
    setClient(_getKafkaClient());
  }, []);

  useEffect(() => {
    if (!client || !tdClientId) return;

    const deviceId = tdClientId;

    if (deviceId) {
      client.setDeviceId(deviceId);
    }

    if (userInfo) {
      client.setUserInfo({
        service_platform: (userInfo.userPlatformCode ||
          'unext') as InitialLog['key']['service_platform'],
        user_platform_id: userInfo.userPlatformId || '',
        user_multi_account_id: userInfo.multiAccountId || '',
        super_user_flg: userInfo.superUser ? 1 : 0,
      });
    }

    const isReady = !!deviceId && !!userInfo && !loading;
    client.setIsReady(isReady);
    setIsReady(isReady);
  }, [client, loading, tdClientId, userInfo]);

  useEffect(() => {
    if (!client || !isReady) return;
    client.trackInitial();
  }, [client, isReady]);

  return (
    <KafkaContext.Provider value={{ client }}>{children}</KafkaContext.Provider>
  );
};
