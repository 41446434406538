import Button from '@/shared/components/Button/Button';
import { globalMessages } from '@/shared/constants/messages';
import { FC } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import SimpleDialog from '../SimpleDialog';

const messages = {
  registerLogin: defineMessage({
    id: 'signup.registerLogin',
    defaultMessage: 'アカウント登録 / ログイン',
  }),
  accountRegister: defineMessage({
    id: 'signup.accountRegister',
    defaultMessage: 'アカウント登録',
  }),
  login: globalMessages.login,
  purchaseOnly: defineMessage({
    id: 'signup.purchaseOnly',
    defaultMessage: '登録せずに購入',
  }),
};

export interface DialogSignupProps {
  isShowing: boolean;
  registerHref: string;
  loginHref: string;
  onClickOutside: () => void;
  onCancel: () => void;
}

const DialogSignup: FC<DialogSignupProps> = ({
  isShowing,
  registerHref,
  loginHref,
  onClickOutside,
  onCancel,
}) => {
  const intl = useIntl();

  const renderButtons = () => {
    return (
      <>
        <Button
          variant="PRIMARY"
          text={intl.formatMessage(messages.accountRegister)}
          component="a"
          href={registerHref}
        />
        <Button
          variant="SECONDARY"
          text={intl.formatMessage(messages.login)}
          component="a"
          href={loginHref}
        />
      </>
    );
  };
  return (
    <SimpleDialog
      onClickOutside={onClickOutside}
      animationType="fade"
      onCancel={onCancel}
      isShowing={isShowing}
      title={intl.formatMessage(messages.registerLogin)}
      renderButtons={renderButtons}
    />
  );
};

export default DialogSignup;
