import { messages } from '@/shared/components/SubscribeAction/messages';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

/**
 * https://wiki.unext-info.jp/pages/viewpage.action?pageId=127805841
 */
export const useSubscribeActionChapterProps = ({
  isLoggedIn,
  canUpsell,
}: {
  isLoggedIn?: boolean;
  canUpsell?: boolean;
}) => {
  const intl = useIntl();

  return useMemo(() => {
    if (isLoggedIn) {
      if (canUpsell) {
        return {
          textLine1: intl.formatMessage(messages.upsellTrial),
          buttonText: intl.formatMessage(messages.watchTrial),
          buttonHref: process.env.NEXT_PUBLIC_REGISTER_UPSELL_URL,
        };
      }
    } else {
      return {
        textLine1: intl.formatMessage(messages.trial),
        buttonText: intl.formatMessage(messages.watchTrial),
        buttonHref: process.env.NEXT_PUBLIC_REGISTER_WITH_MONTHLY_PLAN_URL,
      };
    }
  }, [canUpsell, intl, isLoggedIn]);
};
