import {
  GetMediaProductInfoQuery,
  Resolution,
  VideoConsumableType,
} from '../generated';

export type VideoConsumables = NonNullable<
  GetMediaProductInfoQuery['media']
>['metadata']['videoConsumables'];

const hasResolution = (
  videoConsumables: VideoConsumables,
  resolution: keyof typeof Resolution
): boolean => {
  return videoConsumables
    .filter(
      (videoConsumable) => videoConsumable.type === VideoConsumableType.Main
    )
    .some((videoConsumable) =>
      videoConsumable.videoProfiles.some((videoProfile) =>
        videoProfile.resolution.includes(Resolution[resolution])
      )
    );
};

export default hasResolution;
