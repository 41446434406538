function mergeFetchMore<T extends Partial<Record<keyof T, unknown>>>(
  prev: T,
  {
    fetchMoreResult: next,
  }: {
    fetchMoreResult: T;
  }
): T {
  const result = { ...prev, ...next };

  let key: keyof T;

  for (key in result) {
    const prevValue = prev[key];
    const nextValue = next[key];
    if (Array.isArray(nextValue)) {
      if (Array.isArray(prevValue)) {
        // We always assume that object with same property name have the same type.
        result[key] = [...prevValue, ...nextValue] as T[keyof T];
      }
    } else if (nextValue instanceof Object) {
      if (prevValue instanceof Object) {
        result[key] = mergeFetchMore(prevValue, {
          fetchMoreResult: nextValue,
        });
      }
    }
  }

  return result;
}

export default mergeFetchMore;
