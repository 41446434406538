import { getCalendarDayDiffIncludingToday } from '@/lib/date';
import Icon from '@/shared/components/Icon';
import { parse } from 'date-fns';
import Link from 'next/link';
import type { ReactNode } from 'react';
import { defineMessage, useIntl } from 'react-intl';

/**
 * value copied from cosmo
 */
export const POINTBACK_MAX = 10000;

const messages = {
  pointbackText: defineMessage({
    id: 'purchase.pointback',
    defaultMessage:
      '<span>{date}日後に {percentage}%還元</span> <b>+{pointBack}</b>ポイント',
  }),
  noPointbackText: defineMessage({
    id: 'purchase.noPointback',
    defaultMessage: 'ポイントバックの対象外です。',
  }),
};

interface Props {
  pointBackTargetTotalPrice: number | undefined;
  currentPoints: number;
  percentage: number | undefined;
  scheduleDate: string | undefined;
}

/**
 * Modelled after cosmo's implementation
 * https://github.com/u-next/cosmo/blob/develop/app/domain/purchase/PointBack.tsx
 */
const PointBack = ({
  pointBackTargetTotalPrice,
  currentPoints,
  percentage,
  scheduleDate,
}: Props) => {
  const intl = useIntl();

  if (pointBackTargetTotalPrice === undefined || percentage === undefined) {
    return null;
  }

  const pointBackPoints = Math.min(
    Math.floor(
      (Math.max(pointBackTargetTotalPrice - currentPoints, 0) * percentage) /
        100
    ),
    POINTBACK_MAX
  );

  return (
    <Link
      href="/pointback"
      target="_blank"
      rel="noopener noreferrer"
      className="mt-3 flex items-center text-pink/80 gap-x-0.5 text-caption"
    >
      {percentage === 0 || scheduleDate === undefined ? (
        <span className="text-caption">
          {intl.formatMessage(messages.noPointbackText)}
        </span>
      ) : (
        <span className="flex items-baseline">
          {intl.formatMessage(messages.pointbackText, {
            date: getCalendarDayDiffIncludingToday(parse(scheduleDate)),
            percentage: percentage,
            pointBack: intl.formatNumber(pointBackPoints),
            b: (children: ReactNode) => (
              <b className="font-mono text-base font-bold leading-4 mr-0.5 text-pink lg:text-lg">
                {children}
              </b>
            ),
            span: (children: ReactNode) => (
              <span className="rounded bg-pink/20 py-0.5 px-1 mr-1 font-normal font-sans text-label">
                {children}
              </span>
            ),
          })}
        </span>
      )}
      <Icon name="INFO_HELP_BUTTON_ICON" />
    </Link>
  );
};

export default PointBack;
