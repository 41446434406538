import { messages } from '@/shared/components/SubscribeAction/messages';
import { CapyPaymentBadgeCode, GetMediaQuery } from '@/shared/generated';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

/**
 * https://wiki.unext-info.jp/pages/viewpage.action?pageId=127804353
 */
export const useSubscribeActionPackageProps = ({
  productInfo,
  isLoggedIn,
  canUpsell,
}: {
  productInfo?: NonNullable<GetMediaQuery['media']>['productInfo'];
  isLoggedIn?: boolean;
  canUpsell?: boolean;
}) => {
  const intl = useIntl();

  return useMemo(() => {
    const isSvod = productInfo?.paymentBadges?.includes(
      CapyPaymentBadgeCode.Svod
    );

    if (isLoggedIn) {
      if (!canUpsell) return;
      if (isSvod) {
        return {
          textLine1: intl.formatMessage(messages.upsellTrial),
          buttonText: intl.formatMessage(messages.watchTrial),
          buttonHref: process.env.NEXT_PUBLIC_REGISTER_UPSELL_URL,
        };
      }

      if (productInfo?.minPrice && productInfo?.minPrice <= 600) {
        return {
          textLine1: intl.formatMessage(messages.upsellPointsFirst),
          textLine2: intl.formatMessage(messages.pointsSecond),
          buttonText: intl.formatMessage(messages.watchNowFree),
          buttonHref: process.env.NEXT_PUBLIC_REGISTER_UPSELL_URL,
        };
      }

      return {
        textLine1: intl.formatMessage(messages.upsellPointsFirst),
        textLine2: intl.formatMessage(messages.pointsSecond),
        buttonText: intl.formatMessage(messages.watchNow),
        buttonHref: process.env.NEXT_PUBLIC_REGISTER_UPSELL_URL,
      };
    } else {
      if (isSvod) {
        return {
          textLine1: intl.formatMessage(messages.trial),
          buttonText: intl.formatMessage(messages.watchTrial),
          buttonHref: process.env.NEXT_PUBLIC_REGISTER_WITH_MONTHLY_PLAN_URL,
        };
      }

      if (productInfo?.minPrice && productInfo?.minPrice <= 600) {
        return {
          textLine1: intl.formatMessage(messages.pointsFirst),
          textLine2: intl.formatMessage(messages.pointsSecond),
          buttonText: intl.formatMessage(messages.watchNowFree),
          buttonHref: process.env.NEXT_PUBLIC_REGISTER_WITH_MONTHLY_PLAN_URL,
        };
      }

      return {
        textLine1: intl.formatMessage(messages.pointsFirst),
        textLine2: intl.formatMessage(messages.pointsSecond),
        buttonText: intl.formatMessage(messages.watchNow),
        buttonHref: process.env.NEXT_PUBLIC_REGISTER_WITH_MONTHLY_PLAN_URL,
      };
    }
  }, [
    canUpsell,
    intl,
    isLoggedIn,
    productInfo?.minPrice,
    productInfo?.paymentBadges,
  ]);
};
