import { NotFoundContext } from '@/shared/NotFoundContext';
import { isBot } from '@/shared/utils/isBot';
import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import { getDataFromTree } from '@apollo/client/react/ssr';
import { captureException } from '@sentry/nextjs';
import { NextPageContext } from 'next';
import NextApp, { AppContext, AppInitialProps, AppProps } from 'next/app';
import { ComponentType } from 'react';
import { initializeApollo } from './apolloClient';

export interface WithApolloProps {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  leanbackData: LeanbackGenre[];
}

type NextAppComponentType = Omit<typeof NextApp, 'origGetInitialProps'>;
type AppComponentType<P extends AppInitialProps & WithApolloProps> =
  ComponentType<P> & NextAppComponentType;

export interface WithApolloComponentProps {
  apolloState: NormalizedCacheObject;
  apolloClient: ApolloClient<NormalizedCacheObject>;
}

interface SearchMenu {
  id: string;
  name: string;
  type: string;
}

export interface LeanbackGenre {
  id: string;
  name: string;
  displayCode: string;
  searchMenu: SearchMenu;
}

export interface NextPageContextWithApollo extends NextPageContext {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  ssrResult?: {
    is404?: boolean;
  };
  isBot?: boolean;
  ctx: NextPageContextApp;
}

type NextPageContextApp = NextPageContextWithApollo & AppContext;

const withApollo = <P extends AppInitialProps & WithApolloProps>(
  App: AppComponentType<P>
): {
  (props: AppProps & WithApolloComponentProps): JSX.Element;
  getInitialProps(ctx: NextPageContextApp): Promise<{
    apolloState: NormalizedCacheObject;
    pageProps: Record<string, unknown>;
  }>;
} => {
  const Apollo = (props: AppProps & WithApolloComponentProps) => {
    // while rendering by getDataFromTree, we need to pass the apolloClient instance to help collecting the cache
    const apolloClient =
      props.apolloClient ?? initializeApollo(props.apolloState);

    // It's very difficult to make TypeScript happy here because although we
    // have `origGetInitialProps` in this HOC and the App component,
    // we don't actually build the App components `origGetInitialProps` ourselves
    // (even though we know, for certain, that it's set!)
    // So we will ignore this warning because it's not actually a problem
    return (
      <ApolloProvider client={apolloClient}>
        {/* @ts-expect-error See above comment */}
        <App apolloClient={apolloClient} {...props} />
      </ApolloProvider>
    );
  };

  Apollo.getInitialProps = async (appContext: NextPageContextApp) => {
    const { Component, router, AppTree, ctx: ctx } = appContext;

    const apollo = initializeApollo({}, ctx);

    let appProps: AppInitialProps = {
      pageProps: null,
    };

    const userAgent = ctx.req?.headers['user-agent'];

    ctx.apolloClient = apollo;
    ctx.isBot = Boolean(userAgent && isBot(userAgent));

    if (Component.name !== 'ErrorPage') {
      appProps = await App.getInitialProps(appContext);

      // If we are on the server, use getDataFromTree to make necessary API
      // requests for the SSR.
      if (typeof window === 'undefined' && ctx.req && ctx.isBot) {
        let notFound = false;
        try {
          await getDataFromTree(
            <NotFoundContext.Provider
              value={{
                setNotFound: () => {
                  notFound = true;
                },
              }}
            >
              <AppTree
                {...appProps}
                router={router}
                Component={Component}
                apolloClient={apollo}
              />
            </NotFoundContext.Provider>
          );
          ctx.ssrResult = {
            is404: notFound,
          };
        } catch (error: any) {
          // eslint-disable-next-line no-console
          console.error('Error while running `getDataFromTree`', error);
          captureException(error);
        }
      }
    }
    const apolloState = apollo.cache.extract();

    return {
      ...appProps,
      apolloState,
    };
  };

  return Apollo;
};

export default withApollo;
