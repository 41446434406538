import React, { ButtonHTMLAttributes } from 'react';
import Icon, { Icons } from '../../Icon';

export interface SearchButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  iconName: Icons;
}

const SearchButton: React.FC<SearchButtonProps> = ({
  text,
  iconName,
  onClick,
}) => {
  return (
    <button
      className="relative flex items-center h-8 px-3 text-sm font-semibold leading-snug transition-colors border border-pink hover:bg-pink/20 text-pink rounded-2xl"
      onClick={onClick}
    >
      <span className="flex items-center space-x-2">
        {iconName && (
          <span className="flex items-center w-6 h-6">
            <Icon name={iconName} size="FULL" />
          </span>
        )}
        <span className="flex items-center pr-2">{text}</span>
      </span>
    </button>
  );
};

export default SearchButton;
