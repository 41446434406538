import Badge from '@/shared/components/Badge';
import Like from '@/shared/components/Card/Like';
import {
  CapyPaymentBadgeCode,
  CapySaleBadgeCode,
  GetMediaQuery,
  MediaFeatureBadge,
  TitleLifetimeClass,
} from '@/shared/generated';
import hasResolution from '@/shared/utils/hasResolution';
import { isBefore } from 'date-fns';
import { defineMessage, useIntl } from 'react-intl';

const messages = {
  saleEnd: defineMessage({
    id: 'package.detail.stage.saleEnd',
    defaultMessage: '{saleEndDate}まで',
  }),
};

type StageProps = Pick<
  NonNullable<GetMediaQuery['media']>,
  'metadata' | 'productInfo'
>;

const Stage = ({ metadata, productInfo }: StageProps): JSX.Element => {
  const intl = useIntl();

  const isVR = metadata?.featureBadges?.includes(MediaFeatureBadge.Vr);

  const isSvod = productInfo?.paymentBadges?.includes(
    CapyPaymentBadgeCode.Svod
  );

  const isPurchased = productInfo?.products?.some(
    (product) => product.alreadyPurchased
  );

  const favoriteCount = metadata?.favoriteCount;

  const latestSaleEndDateTime = productInfo?.products?.reduce(
    (latestSaleEndDateTime, product) => {
      if (!product.saleEndDateTime) return latestSaleEndDateTime;

      if (latestSaleEndDateTime) {
        return isBefore(latestSaleEndDateTime, product.saleEndDateTime)
          ? latestSaleEndDateTime
          : product.saleEndDateTime;
      } else {
        return product.saleEndDateTime;
      }
    },
    undefined as Date | undefined
  );

  return (
    <>
      <h1 className="text-strong line-clamp-4 md:line-clamp-none">
        {isVR && (
          <span className="inline-flex items-center h-4 mr-1">
            <Badge badge="VR" />
          </span>
        )}
        {metadata.nameJa}
      </h1>
      <div className="flex flex-wrap items-center mt-4 gap-x-1 gap-y-2">
        {isSvod && <Badge badge="SVOD" />}
        {isPurchased && <Badge badge="PURCHASED" />}
        {typeof productInfo?.minPrice === 'number' && (
          <Badge
            badge="PRICE"
            price={productInfo.minPrice}
            multiPrice={productInfo.hasMultiplePrices}
          />
        )}
        {productInfo?.platformLifetimeClass.includes(
          TitleLifetimeClass.New
        ) && <Badge badge="NEW" />}
        {/* TODO WF-9900 preorder badge */}
        {metadata?.releaseLifetimeClass === TitleLifetimeClass.New && (
          <Badge badge="NEW_PRODUCT" />
        )}
        {metadata?.releaseLifetimeClass === TitleLifetimeClass.SemiNew && (
          <Badge badge="SEMINEW_PRODUCT" />
        )}
        {hasResolution(metadata.videoConsumables, 'Uhd4K') && (
          <Badge badge="4K" />
        )}
        {hasResolution(metadata.videoConsumables, 'Fhd') && (
          <Badge badge="FULLHD" />
        )}
        {favoriteCount !== 0 && (
          <Like likes={favoriteCount} color="DEEP_PURPLE" />
        )}
      </div>

      {productInfo?.saleBadges?.includes(CapySaleBadgeCode.Sale) && (
        <div className="flex items-center mt-2">
          <Badge badge="SALE" />
          <p className="ml-1 text-label text-purple/60">
            {intl.formatMessage(messages.saleEnd, {
              saleEndDate: intl
                .formatDate(latestSaleEndDateTime, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })
                // remove space between date and time
                .replace(' ', ''),
            })}
          </p>
        </div>
      )}
    </>
  );
};

export default Stage;
