import Icon from '@/shared/components/Icon';
import { useSelectedCards } from '@/shared/SelectedCardsContext';

interface Props {
  id: string;
}

const EditCard: React.FC<Props> = ({ id }) => {
  const { selectedCards, updateSelectedCards } = useSelectedCards();

  const selectCard = () => {
    updateSelectedCards({ type: 'SELECT', id });
  };

  const unselectCard = () => {
    updateSelectedCards({ type: 'UNSELECT', id });
  };

  return (
    <div className="z-10 flex items-center justify-center w-full h-full bg-purple/10">
      {!selectedCards.get(id) && (
        <div
          className="w-10 h-10 border-2 border-solid rounded cursor-pointer border-purple/60 bg-white/60"
          onClick={selectCard}
          onKeyDown={selectCard}
          role="button"
          tabIndex={0}
        />
      )}
      {selectedCards.get(id) && (
        <div
          className="flex items-center justify-center w-10 h-10 border-2 border-solid rounded cursor-pointer border-purple/60 bg-pink"
          onClick={unselectCard}
          onKeyDown={unselectCard}
          role="button"
          tabIndex={0}
        >
          <Icon name="ADDED_ICON" size="EXTRA_BIG" className="text-white" />
        </div>
      )}
    </div>
  );
};

export default EditCard;
