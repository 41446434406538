import BeemiLogo from '@/assets/icons/logo/Logo_BEEMI.svg';
import LogoBeemiSymbol from '@/assets/icons/logo/Logo_BEEMI_sym.svg';
import Footer from '@/shared/components/Layout/Footer';
import { errorMessages } from '@/shared/constants/messages';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import Buttons from './Buttons';

const Error: FC = () => {
  const intl = useIntl();
  return (
    <div className="flex flex-col w-full h-screen">
      <div className="flex flex-col items-center w-full h-full pt-8 md:pt-12 pb-14">
        <BeemiLogo className="h-8 md:h-10" />
        <div className="flex flex-col items-center px-5 pt-8 mt-20 space-y-8 md:px-12 md:mt-24">
          <LogoBeemiSymbol className="w-24 h-24 md:w-30 md:h-30" />
          <div className="flex flex-col items-center text-center text-purple/80">
            <h1 className="mt-8 text-headline">
              {intl.formatMessage(errorMessages.serverError)}
            </h1>
            <p className="mt-2 text-body">
              {intl.formatMessage(errorMessages.default, {
                p: (...chunks) => <p>{chunks}</p>,
                br: <br />,
              })}
            </p>
          </div>
          <Buttons />
        </div>
      </div>
      <div className="w-full md:hidden">
        <Footer />
      </div>
    </div>
  );
};

export default Error;
