import { KafkaContext } from '@/domain/log/KafkaContext';
import { XxxxxxHeaderSearchLog } from '@/domain/log/__types__/xxxxxx-header-search';
import { GetUserInfoQuery } from '@/shared/generated';
import useClickOutside from '@/shared/hooks/useClickOutside';
import { useLockBodyScroll } from '@/shared/hooks/useLockBodyScroll';
import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import { useSearchDialog } from '@/shared/SearchDialogContext';
import { animated, useTransition } from '@react-spring/web';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { globalMessages } from '../../constants/messages';
import { SearchButtonProps } from '../Button/SearchButton';
import Overlay from '../Modality/Overlay';
import Footer from './Footer';
import Header from './Header';
import NavigationDrawer from './NavigationDrawer';
import NavigationTabBar, {
  NavigationTabBarItemProps,
} from './NavigationTabBar';

export type HeaderTitle = 'STORE' | 'SVOD' | 'LIBRARY';

export interface LayoutProps {
  userInfoData?: GetUserInfoQuery;
  userInfoLoading?: boolean;
  hideMobileHeader?: boolean;
  children?: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({
  userInfoData,
  userInfoLoading,
  children,
  hideMobileHeader,
}) => {
  const intl = useIntl();
  const router = useRouter();
  const { client } = useContext(KafkaContext);
  const isLg = useTailwindBreakpoint('lg');
  const isMd = useTailwindBreakpoint('md');
  let activeNavIndex = -1;
  if (router.pathname === '/' || router.pathname.startsWith('/store')) {
    activeNavIndex = 0;
  } else if (router.pathname.startsWith('/svod')) {
    activeNavIndex = 1;
  } else if (router.pathname.startsWith('/library')) {
    activeNavIndex = 2;
  } else if (router.pathname.startsWith('/mypage')) {
    activeNavIndex = 3;
  }

  const userInfo = userInfoData?.userInfo;
  const hasUserInfo = !!(userInfo && userInfo.id);
  const userPoints = (userInfo && userInfo.points) ?? 0;

  const { showSearchDialog, updateSearchDialog } = useSearchDialog();

  const items: NavigationTabBarItemProps[] = [
    {
      icon: 'STORE_ICON',
      text: intl.formatMessage(globalMessages.STORE),
      kafkaSchema: 'xxxxxx-menu-store',
      href: '/store',
    },
    {
      icon: 'SVOD_ICON',
      text: intl.formatMessage(globalMessages.SVOD),
      kafkaSchema: 'xxxxxx-menu-svod',
      href: '/svod',
    },
    {
      icon: 'LIBRARY_ICON',
      text: intl.formatMessage(globalMessages.LIBRARY),
      kafkaSchema: 'xxxxxx-menu-library',
      href: '/library',
    },
  ];

  const searchButtonProps: SearchButtonProps =
    // No need to switch to a close icon on mobile
    showSearchDialog && isMd
      ? {
          text: '閉じる',
          iconName: 'CLOSE_ICON',
        }
      : {
          text: '探す',
          iconName: 'SEARCH_ICON',
        };

  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);

  const transitionsOnClick = useTransition(isHamburgerOpen, {
    from: { x: '-120%' },
    enter: { x: '0%' },
    leave: { x: '-120%' },
  });

  const containerRef = useRef<HTMLDivElement>(null);
  useLockBodyScroll(!!isHamburgerOpen, undefined, containerRef.current);

  const toggleSearchPage = () => {
    if (showSearchDialog && isMd) {
      updateSearchDialog(false);
    } else {
      client?.trackUser<XxxxxxHeaderSearchLog>(
        'xxxxxx-header-search',
        'click',
        'user_click_dimension_0_default',
        {}
      );
      updateSearchDialog(true);
    }
  };

  const handleHamburgerIconClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    setIsHamburgerOpen((previous) => !previous);
  };

  useClickOutside(containerRef, () => {
    if (isHamburgerOpen) {
      setIsHamburgerOpen(false);
    }
  });

  const onNavigationDrawerLinkClicked = useCallback(() => {
    setIsHamburgerOpen(false);
  }, []);

  useEffect(() => {
    if (isLg) {
      if (isHamburgerOpen) {
        setIsHamburgerOpen(false);
      }
    }
  }, [isLg, isHamburgerOpen]);

  return (
    <div className="flex flex-col h-full min-h-screen pb-14 md:pb-0">
      {isHamburgerOpen && (
        <div className="z-30 hidden md:block">
          <Overlay variant="DARK_PURPLE" />
        </div>
      )}
      {transitionsOnClick(
        (styles, item) =>
          item && (
            <animated.div
              style={styles}
              className="fixed bottom-0 left-0 z-40 hidden bg-white top-12 md:block"
            >
              <div ref={containerRef}>
                <NavigationDrawer
                  isLoggedIn={hasUserInfo}
                  loading={userInfoLoading || false}
                  point={userPoints}
                  showLogo={false}
                  activeIndex={activeNavIndex}
                  onLinkClicked={onNavigationDrawerLinkClicked}
                />
              </div>
            </animated.div>
          )
      )}
      <div className="fixed top-0 bottom-0 left-0 z-30 hidden bg-white lg:block">
        <NavigationDrawer
          isLoggedIn={hasUserInfo}
          loading={userInfoLoading || false}
          point={userPoints}
          showLogo
          activeIndex={activeNavIndex}
        />
      </div>
      <div className="relative flex flex-col flex-grow lg:ml-60 md:mb-24">
        <div
          className={classNames(
            'top-0 left-0 right-0 z-30 md:fixed md:border-b border-purple/10 lg:hidden',
            isHamburgerOpen && 'md:bg-purple/60 md:border-b-0'
          )}
        >
          <Header
            searchButtonProps={searchButtonProps}
            onHamburgerIconClick={handleHamburgerIconClick}
            isActive={isHamburgerOpen}
            onSearchIconClick={toggleSearchPage}
            userIconHref="/mypage"
            hideMobileHeader={hideMobileHeader}
            isLoggedIn={hasUserInfo}
          />
        </div>
        <div className="relative flex flex-col flex-grow">
          {children}
          <div className="w-full md:hidden">
            <Footer />
          </div>
        </div>
      </div>
      <nav className="box-content fixed bottom-0 z-10 w-full bg-white border-t border-purple/10 h-14 md:hidden phone-bottom-safe">
        <NavigationTabBar>
          {items.map(
            ({ href, ...props }: NavigationTabBarItemProps, idx: number) => (
              <NavigationTabBar.Item
                passHref
                key={idx}
                href={href}
                {...props}
                isActive={activeNavIndex === idx}
              />
            )
          )}
        </NavigationTabBar>
      </nav>
    </div>
  );
};

export default Layout;
