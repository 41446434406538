import { animated, useTransition } from '@react-spring/web';
import { FC, MouseEventHandler, TouchEventHandler, useState } from 'react';

type Props = {
  isShowing: boolean;
  message: string;
  action?: string;
  onSwipe?: () => void;
  onAction?: MouseEventHandler<HTMLButtonElement>;
};

const SnackBar: FC<Props> = ({
  isShowing,
  message,
  action,
  onAction,
  onSwipe,
}) => {
  const [touchStart, setTouchStart] = useState([0, 0]);
  const [touchEnd, setTouchEnd] = useState([0, 0]);

  const handleTouchStart: TouchEventHandler = (e) => {
    setTouchStart([e.targetTouches[0].clientX, e.targetTouches[0].clientY]);
  };

  const handleTouchMove: TouchEventHandler = (e) => {
    setTouchEnd([e.targetTouches[0].clientX, e.targetTouches[0].clientY]);
  };

  const handleTouchEnd: TouchEventHandler = () => {
    // On swipe left/right/down
    if (
      touchEnd[0] !== 0 &&
      touchEnd[1] !== 0 &&
      (Math.abs(touchStart[0] - touchEnd[0]) > 100 ||
        touchEnd[1] - touchStart[1] > 20)
    ) {
      onSwipe && onSwipe();
    }
  };
  const transitions = useTransition(isShowing, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions(
    (styles, item) =>
      item && (
        <animated.div
          style={styles}
          className="fixed left-0 md:left-10 bottom-3 md:bottom-10 w-full md:w-auto md:max-w[50vw] mr-10 px-2 z-100"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="relative flex items-center justify-between px-4 py-3 mt-1 rounded-md md:px-6 md:py-4 bg-purple shadow-mobile md:shadow-desktop">
            <span className="text-white text-body">{message}</span>
            {action && (
              <button
                className="ml-4 text-strong text-pink whitespace-nowrap"
                onClick={onAction}
              >
                {action}
              </button>
            )}
          </div>
        </animated.div>
      )
  );
};

export default SnackBar;
