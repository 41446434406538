import { defineMessage } from 'react-intl';

export const messages = {
  trial: defineMessage({
    id: 'subscribeAction.trial',
    defaultMessage: 'まずは、31日間無料体験',
  }),
  watchTrial: defineMessage({
    id: 'subscribeAction.watchTrial',
    defaultMessage: '無料トライアルで今すぐ観る',
  }),
  pointsFirst: defineMessage({
    id: 'subscribeAction.pointsFirst',
    defaultMessage: '無料トライアルで',
  }),
  pointsSecond: defineMessage({
    id: 'subscribeAction.pointsSecond',
    defaultMessage: '600円分のポイントをGET',
  }),
  watchNowFree: defineMessage({
    id: 'subscribeAction.watchNowFree',
    defaultMessage: '今すぐ無料で観る',
  }),
  watchNow: defineMessage({
    id: 'subscribeAction.watchFree',
    defaultMessage: '今すぐ観る',
  }),

  upsellTrial: defineMessage({
    id: 'subscribeAction.upsellTrial',
    defaultMessage: '初回は、31日間無料体験',
  }),
  upsellPointsFirst: defineMessage({
    id: 'subscribeAction.upsellWatchTrial',
    defaultMessage: '初回は、無料トライアルで',
  }),
};
