import useClickOutside from '@/shared/hooks/useClickOutside';
import { animated, useTransition } from '@react-spring/web';
import { FC, ReactNode, useRef } from 'react';

interface Props {
  onClickOutside: () => void;
  children?: ReactNode;
}

/* ActionSheet is only for Desktop */
const ActionSheet: FC<Props> = ({ children, onClickOutside }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  // Make sure onClick event runs at the beginning "capture" so we only add a capture event to document.body before the anchor button
  useClickOutside(
    containerRef,
    () => {
      onClickOutside();
    },
    { useCapture: true }
  );

  const transitions = useTransition(true, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return (
    <>
      {transitions(
        (styles, item) =>
          item && (
            <animated.div style={styles}>
              <div
                ref={containerRef}
                className="bg-white rounded-2xl shadow-desktop w-80"
              >
                <div className="relative flex flex-col items-center py-2 ">
                  <div className="w-full space-y-1.5 py-4 px-4">{children}</div>
                </div>
              </div>
            </animated.div>
          )
      )}
    </>
  );
};

export default ActionSheet;
