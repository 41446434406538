import Placeholder from '@/shared/components/BeemiImage/Placeholder';
import UserAgentContext from '@/shared/UserAgentContext';
import getKeys from '@/shared/utils/getKeys';
import { getTailwindConfigScreens } from '@/shared/utils/getTailwindConfig';
import { mergeQueryString } from '@/shared/utils/routeHelper';
import NextImage from 'next/legacy/image';
import React, { useContext, useState } from 'react';

const screens = getTailwindConfigScreens();

export interface SizeConfig {
  [key: string]: string;
}

interface ImageProps {
  src: string;
  alt: string;
  className?: string;
  sizeConfig?: SizeConfig | string;
}

/**
 * this function is 95% similar to mergeAkamaiParams
 * can consider to refactor this for anyone keen
 */
const customLoader =
  (isWebpSupported: boolean) =>
  ({
    src,
    width,
    quality,
  }: {
    src: string;
    width?: number;
    quality?: number;
  }) => {
    const [pathname, queryParams] = src.split('?');
    const urlSearchParams = new URLSearchParams(queryParams);
    const query = Object.fromEntries(urlSearchParams.entries());

    const { path } = mergeQueryString(
      {
        pathname: pathname,
        asPath: pathname,
        query,
      },
      {
        'output-format': isWebpSupported ? 'webp' : 'jpg',
        resize: `${width}:*`,
        'output-quality': `${quality || 70}`,
      }
    );

    return path;
  };

const BeemiImage: React.FC<ImageProps> = ({
  src,
  alt,
  className,
  sizeConfig,
}) => {
  let sizes: string | undefined = '';
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  if (typeof sizeConfig === 'string' || typeof sizeConfig === 'undefined') {
    sizes = sizeConfig;
  } else {
    sizes = getKeys(sizeConfig)
      .map((screenKey) => {
        if (screenKey === 'default') {
          return sizeConfig[screenKey];
        }
        if (screens && screens[screenKey]) {
          return `(min-width:${screens[screenKey]}) ${sizeConfig[screenKey]}`;
        }
      })
      .filter(Boolean)
      .join(', ');
  }

  const { isWebpSupported } = useContext(UserAgentContext);

  if (!src || src === '') return null;
  return (
    <>
      <NextImage
        src={src}
        alt={alt}
        onLoadingComplete={() => setShowPlaceholder(false)}
        className={className}
        loader={customLoader(isWebpSupported)}
        layout="fill"
        sizes={sizes}
      />
      {showPlaceholder && <Placeholder />}
    </>
  );
};

export default BeemiImage;
