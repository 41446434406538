import { KafkaContext } from '@/domain/log/KafkaContext';
import type { SearchLog } from '@/domain/log/__types__/search';
import type { BeemiLinkProps } from '@/shared/components/BeemiLink';
import SearchFormContainer, {
  SearchFormButton,
} from '@/shared/components/Control/SearchForm';
import DesktopHeader from '@/shared/components/Layout/DesktopHeader';
import DataLoadingSpinner from '@/shared/components/Loading/DataLoading';
import MetaTags from '@/shared/components/MetaTags';
import TagBanner from '@/shared/components/TagBanner';
import TagList from '@/shared/components/TagList';
import { contentTypeMap, globalMessages } from '@/shared/constants/messages';
import { searchTopMetaMessage } from '@/shared/constants/meta/search/searchTop';
import {
  ContentType,
  SearchSuggestionNode,
  useGetAllContentTagSuggestionsQuery,
  useGetAutoCompleteSearchLazyQuery,
  useGetPlanetsQuery,
  useGetSpotlightedSuggestionQuery,
} from '@/shared/generated';
import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import { useSearchDialog } from '@/shared/SearchDialogContext';
import { groupBy } from '@/shared/utils/groupBy';
import { ApolloError, gql } from '@apollo/client';
import classNames from 'classnames';
import debounce from 'just-debounce-it';
import { useRouter } from 'next/router';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { TAG_SEPARATOR } from './hooks/useSearchParams';

export const GET_AUTO_COMPLETE_SEARCH_RESULTS = gql`
  query getAutoCompleteSearch($query: String!) {
    beemiAutoCompleteSearch(query: $query, pagination: { limit: 20 }) {
      nodes {
        id
        contentType
        text
        score
        description
      }
    }
  }
`;

export const GET_PLANETS = gql`
  query getPlanets {
    planets {
      id
      description
      displayName
    }
  }
`;

export const GET_ALL_CONTENT_TAG_SUGGESTIONS = gql`
  query getAllContentTagSuggestions {
    allContentTagSuggestions {
      id
      nameJa
      contentType
      description
    }
  }
`;

const CATEGORY_BLOCK_INDEX = 1;
const OFFSET_BLOCK_INDEX = CATEGORY_BLOCK_INDEX + 1;

const SearchTopBase: FC = () => {
  const intl = useIntl();
  const isMd = useTailwindBreakpoint('md');
  const { updateSearchDialog } = useSearchDialog();

  const router = useRouter();

  const [isSearchFormContainerOpen, setIsSearchFormContainerOpen] =
    useState(true);
  const [searchValue, setSearchValue] = useState('');

  const [query, { data }] = useGetAutoCompleteSearchLazyQuery();

  const {
    data: contentTagsData,
    loading,
    error,
  } = useGetAllContentTagSuggestionsQuery();

  const { data: planetsData, loading: planetsLoading } = useGetPlanetsQuery();

  const { data: spotlightedSuggestion, loading: spotlightedSuggestionLoading } =
    useGetSpotlightedSuggestionQuery();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAutoCompleteSearch = useCallback(debounce(query, 500), []);

  useEffect(() => {
    if (searchValue) {
      getAutoCompleteSearch({ variables: { query: searchValue } });
    }
  }, [searchValue, getAutoCompleteSearch]);

  const suggestions: SearchSuggestionNode[] =
    data?.beemiAutoCompleteSearch?.nodes?.map((node) => {
      return {
        id: node?.id,
        text: node?.text,
        contentType: node?.contentType,
        score: node?.score,
        description: node?.description,
      };
    }) || [];

  const planets = planetsData?.planets;
  const planetTags = {
    nameJa: intl.formatMessage(contentTypeMap(ContentType.Planet)),
    contentTags:
      (planets?.length &&
        planets.map((planetTag, index) => ({
          id: planetTag.id,
          nameJa: planetTag.displayName || planetTag.id,
          contentType: ContentType.Planet,
          kafkaProps: {
            targetPrefix: 'search-tagSection',
            base_schema: 'user_click_dimension_2_default' as const,
            content_type: ContentType.Planet,
            row_index: CATEGORY_BLOCK_INDEX,
            column_index: index,
            tag_id: planetTag.id,
          },
        }))) ||
      [],
  };

  const contentTags = contentTagsData?.allContentTagSuggestions.filter(
    (item) => item.contentType === 'CONTENT_TAG'
  );
  const groupedTagLists = useMemo(() => {
    return contentTags
      ? groupBy(contentTags, (tag) => tag.description).map(
          ([description, tags], idx) => ({
            nameJa: description,
            contentTags: tags.map((tag, index) => ({
              ...tag,
              kafkaProps: {
                targetPrefix: 'search-tagSection',
                base_schema: 'user_click_dimension_2_default' as const,
                content_type: ContentType.Planet,
                row_index: OFFSET_BLOCK_INDEX + idx,
                column_index: index,
                tag_id: tag.id,
              },
            })),
          })
        )
      : undefined;
  }, [contentTags]);

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      updateSearchDialog(false);
    });
  }, [router.events, updateSearchDialog]);

  const generateHref = (
    id: string,
    contentType: ContentType
  ): BeemiLinkProps['href'] => {
    const searchParams = new URLSearchParams();
    searchParams.append('tag', id + TAG_SEPARATOR + contentType);
    return { pathname: '/search', query: searchParams.toString() };
  };

  const { client } = useContext(KafkaContext);

  useEffect(() => {
    client?.trackUser<SearchLog>(
      'search',
      'view',
      'user_view_page_default',
      {}
    );
  }, [client]);

  if (error) {
    throw new ApolloError(error);
  }

  return (
    <>
      <MetaTags
        title={intl.formatMessage(searchTopMetaMessage.title)}
        description={intl.formatMessage(searchTopMetaMessage.description)}
      />
      <DesktopHeader title={intl.formatMessage(globalMessages.search)} />
      <div className="absolute w-full h-14 md:h-auto md:mt-18 lg:max-w-160 md:mx-auto md:relative">
        {isMd && (
          <div
            className={classNames(isSearchFormContainerOpen && 'md:opacity-0')}
          >
            <SearchFormButton
              placeholder={intl.formatMessage(globalMessages.search)}
              value={searchValue}
              onClick={() => setIsSearchFormContainerOpen(true)}
            />
          </div>
        )}
        {(isSearchFormContainerOpen || !isMd) && (
          <div
            className={classNames(
              'fixed z-50 top-0 left-0 w-full md:absolute',
              searchValue && suggestions?.length > 0 && 'bg-white h-full'
            )}
          >
            <SearchFormContainer
              placeholder={intl.formatMessage(globalMessages.search)}
              value={searchValue}
              onChange={setSearchValue}
              onClose={() => {
                setIsSearchFormContainerOpen(false);
                if (!isMd) {
                  updateSearchDialog(false);
                }
              }}
              suggestions={searchValue ? suggestions : []}
              onSearch={(value = '', tags = []) => {
                const searchTags = tags.map(
                  (tag) => `${tag.id}${TAG_SEPARATOR}${tag.contentType}`
                );
                const searchParams = new URLSearchParams();
                if (value) searchParams.append('freeword', value);
                searchTags.forEach((s) => searchParams.append('tag', s));

                if (searchTags.length || value) {
                  router.push({
                    pathname: `/search`,
                    query: searchParams.toString(),
                  });
                }
              }}
            />
          </div>
        )}
      </div>
      {!searchValue && (
        <>
          {loading || planetsLoading || spotlightedSuggestionLoading ? (
            <DataLoadingSpinner />
          ) : (
            <>
              {spotlightedSuggestion &&
                spotlightedSuggestion?.spotlightedSuggestions.length > 0 && (
                  <div className="px-5 mt-20 md:mt-6 md:px-12">
                    <div className="flex items-center justify-between md:justify-start">
                      <span className="mr-4 text-strong">
                        {intl.formatMessage({
                          id: 'search.pickUpGenre',
                          defaultMessage: `ピックアップジャンル`,
                        })}
                      </span>
                    </div>
                    <div className="grid grid-cols-2 gap-3 mt-4 md:grid-cols-[repeat(4,_minmax(auto,_280px))]">
                      {spotlightedSuggestion?.spotlightedSuggestions.map(
                        (suggestion) => {
                          return (
                            <TagBanner
                              key={suggestion.id}
                              title={suggestion.text}
                              subTitle={suggestion.description}
                              imgSrc={suggestion.backgroundImage.w1}
                              imgAlt={suggestion.text}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                )}

              {planets && planets.length > 0 && (
                <div className="px-5 pt-8 md:px-12">
                  <TagList
                    tags={planetTags.contentTags}
                    title={planetTags.nameJa}
                    generateHref={generateHref}
                  />
                </div>
              )}
              {groupedTagLists?.map((tagList, idx) => (
                <div key={idx} className="px-5 pt-8 md:px-12">
                  <TagList
                    tags={tagList.contentTags}
                    title={tagList.nameJa}
                    generateHref={generateHref}
                  />
                </div>
              ))}
            </>
          )}
        </>
      )}
    </>
  );
};

export default SearchTopBase;
