import { BeemiLinkProps } from '@/shared/components/BeemiLink';
import { ContentType } from '@/shared/generated';
import classNames from 'classnames';
import { ComponentProps, ReactElement } from 'react';
import ShowMoreButton from '../Button/ShowMoreButton';
import TagButton from '../Button/TagButton';

export interface ContentTag<T = ContentType> {
  id: string;
  nameJa: string;
  contentType: T;
  count?: number;
  kafkaProps?: ComponentProps<typeof TagButton>['kafkaProps'];
  onClick?: () => void;
}

export interface Props<T = ContentType> {
  title?: string;
  tags: ContentTag<T>[];
  slicedTags?: number;
  generateHref: (id: string, contentType: T) => BeemiLinkProps['href'];
  onShowMoreClick?: () => void;
  dataTestId?: string;
}

export const isContentTag = (
  x: ContentTag
): x is ContentTag<ContentType.ContentTag> => {
  return x.contentType === ContentType.ContentTag;
};

function TagList<T = ContentType>({
  title,
  tags,
  slicedTags,
  generateHref = () => '/',
  onShowMoreClick,
  dataTestId,
}: Props<T>): ReactElement {
  return (
    <div>
      {title && (
        <div className="flex items-center justify-between md:justify-start">
          <span className="mr-4 text-strong">{title}</span>
          {slicedTags && tags.length > slicedTags && (
            <ShowMoreButton onClick={onShowMoreClick} />
          )}
        </div>
      )}
      <div
        className={classNames(
          'flex flex-wrap gap-1.5 md:gap-2',
          title && 'mt-3 md:mt-4'
        )}
      >
        {tags.slice(0, slicedTags).map((tag) => (
          <TagButton
            {...tag}
            key={tag.id}
            component="BeemiLink"
            href={generateHref(tag.id, tag.contentType)}
            text={tag.nameJa}
            size="SMALL"
            onClick={tag.onClick}
            dataTestId={dataTestId}
          />
        ))}
      </div>
    </div>
  );
}

export default TagList;
