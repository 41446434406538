import {
  createDeviceInformation,
  DeviceInformation,
} from '@/domain/log/DeviceInformation';
import type { InitialLog } from '@/domain/log/__types__/initial';
import type { LogBody } from '@/domain/log/__types__/logBody';
import type { SystemCdnPerfLog } from '@/domain/log/__types__/system_cdn_perf';
import type { Nullable } from '@/shared/utils/Nullable';
import fetch from 'isomorphic-unfetch';

const KAFKA_LOG_VERSION = process.env
  .NEXT_PUBLIC_KAFKA_LOG_VERSION as InitialLog['version'];
interface KafkaUserInfo {
  service_platform: InitialLog['key']['service_platform'];
  user_platform_id: string;
  user_multi_account_id: string;
  super_user_flg: 0 | 1;
}

type DataType = LogBody | InitialLog | SystemCdnPerfLog;
type GetDataFn = () => DataType;

type SystemCdnPerfLogDetail = Partial<SystemCdnPerfLog['event']['detail']>;

function sendLogRequest<T>(data: T): Promise<Response> {
  return fetch(
    `${process.env.NEXT_PUBLIC_KAFKA_SERVER}/${process.env.NEXT_PUBLIC_KAFKA_LOG_TOPIC}`,
    {
      method: 'POST',
      headers: {
        'x-ka-api-key': process.env.NEXT_PUBLIC_KAFKA_API_KEY || '',
      },
      body: JSON.stringify(data),
    }
  );
}

export class KafkaClient {
  private _sendQueue: GetDataFn[];
  private _device: DeviceInformation;
  private _userInfo: Nullable<KafkaUserInfo>;
  /**
   * The rationale for _isReady is:
   * 1. UserInfo & device id are retrieved asynchronously
   * 2. Kafka log timing may happen earlier than when userInfo or device id is available,
   *   as result it'd be necessary to queue the logs before the userInfo & device id are ready
   * 3. Checking the validity of userInfo to judge if logs are ready to be sent can be error prone
   *   cuz only the code consuming the kafkaClient knows if the userInfo is valid or not
   */
  private _isReady: boolean;

  constructor() {
    this._sendQueue = [];
    const device = createDeviceInformation(window.navigator.userAgent);
    this._device = device;
    this._userInfo = null;
    this._isReady = false;
  }

  setDeviceId(id: string): void {
    this._device.setId(id);
  }

  setUserInfo(userInfo: KafkaUserInfo): void {
    this._userInfo = userInfo;
  }

  private _getKey(): InitialLog['key'] {
    return {
      service_platform: this._userInfo?.service_platform || 'unext',
      client: 'web',
      user_multi_account_id: this._userInfo?.user_multi_account_id || '',
      device_id: this._device.id,
    };
  }

  setIsReady(isReady: boolean): void {
    this._isReady = isReady;

    if (isReady) {
      this._fireQueue();
    }
  }

  private _fireQueue(): void {
    const sendQueue = this._sendQueue;
    if (sendQueue.length && this._isReady) {
      while (sendQueue.length) {
        const getter = sendQueue.shift();
        if (typeof getter !== 'function') {
          // eslint-disable-next-line no-console
          console.error(
            `This sendCallback must be function but not. Some part to enqueue functions for this may has bug`
          );
          continue;
        }

        const data: DataType = getter();
        // We will not catch in the caller if this promise is rejected.
        // So we should catch at here.
        // eslint-disable-next-line no-console
        sendLogRequest(data).catch(console.error);
      }
    }
  }

  /**
   * https://wiki.unext-info.jp/pages/viewpage.action?pageId=35425828
   */
  private async _send(getData: GetDataFn): Promise<void> {
    // userInfo や device id がまだ取得できていない場合、キューに入れる
    if (!this._isReady) {
      this._sendQueue.push(getData);
      return;
    }

    const data: DataType = getData();
    // We will not catch in the caller if this promise is rejected.
    // So we should catch at here.
    try {
      await sendLogRequest(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  trackInitial(): void {
    const getInitialData: () => InitialLog = () => {
      // We assume this line would not be null because we only enter here after setting userInfo properly.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const userInfo = this._userInfo!;

      const device = this._device;

      const r: InitialLog = {
        key: this._getKey(),
        session: {
          user_platform_id: userInfo.user_platform_id,
          super_user_flg: userInfo.super_user_flg ?? 0,
        },
        device: {
          app_version:
            process.env.NEXT_PUBLIC_COMMIT_TAG ||
            process.env.NEXT_PUBLIC_APP_ENV ||
            'local',
          os: device.os,
          os_version: device.os_version,
          user_agent: device.user_agent,
          viewport_h: window.innerHeight,
          viewport_w: window.innerWidth,
          model_name: '',
          browser_name: device.browser_name,
          browser_version: device.browser_version,
        },
        version: KAFKA_LOG_VERSION,
      };
      return r;
    };

    this._send(getInitialData);
  }

  async trackSystemCdnPerfLog(detail: SystemCdnPerfLogDetail): Promise<void> {
    const getSystemCdnPerfLog = (): SystemCdnPerfLog => {
      return {
        key: this._getKey(),
        event: {
          name: 'system_cdn_perf',
          base_schema: 'system_cdn_perf',
          /**
           * NOTE: override type from orbit
           * since not all values are needed
           */
          detail: detail as SystemCdnPerfLog['event']['detail'],
        },
        version: KAFKA_LOG_VERSION,
      };
    };

    await this._send(getSystemCdnPerfLog);
  }

  async trackUser<T extends LogBody>(
    target: T['event']['target'],
    action: T['event']['action'],
    baseSchema: T['event']['base_schema'],
    detail: T['event']['detail']
  ): Promise<void> {
    const getDimension0Data = () => {
      return {
        key: this._getKey() as T['key'],
        event: {
          action,
          detail,
          target,
          base_schema: baseSchema,
        },
        version: KAFKA_LOG_VERSION,
      } as T;
    };

    await this._send(getDimension0Data);
  }
}

let client: Nullable<KafkaClient> = null;

export function _getKafkaClient(): KafkaClient {
  if (!client) {
    client = new KafkaClient();
  }
  return client;
}

export function destroyKafkaClient(): void {
  client = null;
}
