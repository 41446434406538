import { useEffect } from 'react';
import { lock, unlock } from 'tua-body-scroll-lock';

/**
 * Hook to lock the body scroll whenever a component is mounted or
 * whenever isLocked is set to true.
 *
 * You can pass in true always to cause a lock on mount/dismount of the component
 * using this hook.
 *
 * @param isLocked Toggle the scroll lock
 * @param disabled Disables the entire hook (allows conditional skipping of the lock)
 */
export const useLockBodyScroll = (
  isLocked: boolean,
  disabled?: boolean,
  targetElement?: HTMLDivElement | null
): void => {
  useEffect(() => {
    if (isLocked && !disabled) {
      lock(targetElement);
      return () => {
        unlock(targetElement);
      };
    }
  }, [isLocked, disabled, targetElement]);
};
