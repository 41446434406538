import Link, { LinkProps } from 'next/link';
import React, { AnchorHTMLAttributes } from 'react';

export type BeemiLinkProps = LinkProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & {
    'data-testid'?: string;
  };

const BeemiLink: React.FC<BeemiLinkProps> = ({
  className,
  children,
  onClick,
  onKeyDown,
  ...props
}) => {
  return (
    <Link
      {...props}
      passHref
      onClick={onClick}
      onKeyDown={onKeyDown}
      className={className}
      role={onClick ? 'button' : ''}
      data-testid={props['data-testid']}
    >
      {children}
    </Link>
  );
};

export default BeemiLink;
