import classNames from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import { globalMessages } from '../../../constants/messages';
import Badge from '../../Badge';
import Icon, { Icons } from '../../Icon';
import Radio from '../Radio';

interface SelectCellBaseProps {
  text: string;
  subText?: string;
  onChange?: InputHTMLAttributes<HTMLInputElement>['onChange'];
  checked?: InputHTMLAttributes<HTMLInputElement>['checked'];
  disabled?: boolean;
  name?: string;
  id?: string;
  readOnly?: boolean;
  onSale?: boolean;
}

interface SelectCellSimpleProps extends SelectCellBaseProps {
  price?: never;
  originalPrice?: never;
  iconName?: never;
}
export interface SelectCellPriceProps extends SelectCellBaseProps {
  price: number;
  originalPrice?: number;
  iconName?: never;
}

interface SelectCellIconProps extends SelectCellBaseProps {
  iconName: Icons;
  price?: never;
  originalPrice?: never;
}

export type SelectCellProps =
  | SelectCellSimpleProps
  | SelectCellPriceProps
  | SelectCellIconProps;

const isPriceProps = (
  props: SelectCellProps
): props is SelectCellPriceProps => {
  if (typeof props.price === 'number') {
    return true;
  }
  return false;
};

const isIconProps = (props: SelectCellProps): props is SelectCellIconProps => {
  if (props.iconName) {
    return true;
  }
  return false;
};

const SelectCell: React.FC<SelectCellProps> = (props) => {
  const {
    text,
    subText,
    originalPrice,
    onSale,
    checked,
    onChange,
    disabled,
    id,
    name,
    readOnly,
    ...labelProps
  } = props;
  delete labelProps.iconName;
  const intl = useIntl();
  const isBig = !!subText || isPriceProps(props);
  return (
    <label
      {...labelProps}
      htmlFor={id}
      className={classNames(
        'flex items-center px-3 text-sm md:text-md transition-colors rounded-md justify-content border border-purple/10 overflow-hidden',
        !disabled && 'hover:bg-purple/10',
        isBig ? 'h-16 md:h-24' : 'h-12 md:h-16'
      )}
    >
      <Radio
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        name={name}
        id={id}
        readOnly={readOnly}
      />
      <div className="ml-4 space-y-1 truncate">
        {text ? (
          <div
            className={classNames(
              'leading-none transition-none text-strong truncate',
              checked && 'font-semibold',
              disabled && 'text-purple/50'
            )}
          >
            {text}
          </div>
        ) : null}
        {subText ? (
          <div className="truncate text-label text-purple/60">{subText}</div>
        ) : null}
      </div>
      {(isPriceProps(props) || isIconProps(props)) && (
        <div className="ml-auto text whitespace-nowrap">
          {isPriceProps(props) && (
            <div className="flex flex-col items-end space-y-1">
              <div
                className={classNames(
                  'text-xs font-semibold',
                  onSale ? 'text-pink' : 'text-purple/80',
                  disabled && 'opacity-50'
                )}
              >
                {intl.formatMessage(globalMessages.priceWithTax, {
                  price: intl.formatNumber(props.price),
                })}
              </div>
              {originalPrice && onSale ? (
                <div className="flex items-center space-x-1">
                  <Badge badge="SALE" />
                  <span className="font-normal line-through text-3xs md:text-2xs text-purple/80 ">
                    {intl.formatMessage(globalMessages.priceWithTax, {
                      price: intl.formatNumber(originalPrice),
                    })}
                  </span>
                </div>
              ) : null}
            </div>
          )}
          {isIconProps(props) && (
            <div className="text-purple/60">
              <Icon name={props.iconName} size="EXTRA_BIG" />
            </div>
          )}
        </div>
      )}
    </label>
  );
};

export default SelectCell;
