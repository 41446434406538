import { NextRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
interface MergedQueryString {
  pathname: string;
  path: string;
}

/**
 * Takes a query paramter object and returns a new pathname and path
 * with the new values appended.
 *
 * - If the value already exists, it is updated.
 * - If a key with the value of null is passed, it will be removed from
 *   the current query paramters
 *
 * ## Example usage:
 *
 * If the current URL is `/foo?bar=test` and you want to add a new query parameter, you
 * can do the following:
 *
 * ```
 * const newRoute = mergeQueryString(router, { newParam: 'value' });
 * ```
 * NewRoute will become
 *
 * ```
 * {
 *  pathName: '/foo?bar=test&newParam=value',
 *   path: '/foo?bar=test&newParam=value'
 * }
 * ```
 *
 * @param router Nextjs router instance
 * @param query Key/value pair object containing query paramters
 */
export const mergeQueryString = (
  {
    pathname,
    query,
    asPath,
  }: Pick<NextRouter, 'pathname' | 'asPath'> &
    Partial<Pick<NextRouter, 'query'>>,
  newQuery: ParsedUrlQuery
): MergedQueryString => {
  const mergedQuery = Object.assign({}, query, newQuery);

  const queryArray: string[] = [];

  Object.keys(mergedQuery).map((key) => {
    if (mergedQuery[key]) {
      queryArray.push(`${key}=${mergedQuery[key]}`);
    }
  });

  // (Normally pathname should not include a query string)
  // If the pathname already contains a query string, we need to remove it to avoid double query strings
  const newPathname = `${pathname.split('?')[0]}${
    queryArray.length > 0 ? `?${queryArray.join('&')}` : ''
  }`;
  const path = `${asPath.split('?')[0]}${
    queryArray.length > 0 ? `?${queryArray.join('&')}` : ''
  }`;

  return { pathname: newPathname, path };
};
