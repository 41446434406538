import { KafkaContext } from '@/domain/log/KafkaContext';
import { PackageDetailButtonsSampleLog } from '@/domain/log/__types__/packageDetail-buttons-sample';
import Button from '@/shared/components/Button/Button';
import {
  CapyPaymentBadgeCode,
  CapySaleTypeCode,
  GetMediaQuery,
  VideoConsumableType,
} from '@/shared/generated';
import { LOGIN_STATE, useLoginState } from '@/shared/hooks/useLoginState';
import { mergeQueryString } from '@/shared/utils/routeHelper';
import { getMinutuesString } from '@/shared/utils/timeHelper';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { defineMessage, useIntl } from 'react-intl';

const messages = {
  play: defineMessage({
    id: 'package.detail.playAction.play',
    defaultMessage: '再生',
  }),
  samplePlay: defineMessage({
    id: 'package.detail.playAction.samplePlay',
    defaultMessage: 'サンプルを視聴',
  }),
  otherPurchaseOption: defineMessage({
    id: 'package.detail.playAction.otherPurchaseOption',
    defaultMessage: 'その他の購入オプション',
  }),
  videoDuration: defineMessage({
    id: 'package.detail.playAction.videoDuration',
    defaultMessage: '{videoDuration}分',
  }),
};

type PlayActionProps = Pick<
  NonNullable<GetMediaQuery['media']>,
  'productInfo'
> & {
  packageId: string;
  showOtherPurchaseOptionDialog: () => void;
  showSignUpDialog: () => void;
  videoDuration: number | undefined;
  hasSampleVideo: boolean;
};

const PlayAction = ({
  productInfo,
  packageId,
  showOtherPurchaseOptionDialog,
  showSignUpDialog,
  videoDuration,
  hasSampleVideo,
}: PlayActionProps): JSX.Element => {
  const intl = useIntl();
  const router = useRouter();

  const { data: loginData, state: loginState } = useLoginState();

  const { client } = useContext(KafkaContext);

  const hasVideoSubscription = loginData?.userInfo?.hasVideoSubscription;

  const isTvodOrEst = productInfo?.products?.some(
    (product) =>
      product.saleTypeCode === CapySaleTypeCode.Tod ||
      product.saleTypeCode === CapySaleTypeCode.Est
  );

  const isSvod = productInfo?.paymentBadges?.includes(
    CapyPaymentBadgeCode.Svod
  );

  const shouldShowOtherPurchaseOptionButton =
    hasVideoSubscription && isTvodOrEst && isSvod;

  return (
    <div className="mt-10 md:mt-8 md:flex md:gap-x-2">
      <div className="flex gap-x-2 md:flex-grow">
        <Link
          href={{
            pathname: router.pathname,
            query: {
              ...router.query,
              play: VideoConsumableType.Main,
            },
          }}
          scroll={false}
          legacyBehavior
        >
          <Button
            component="a"
            text={intl.formatMessage(messages.play)}
            data-testid="packageDetail-buttons-play"
            subText={
              videoDuration
                ? intl.formatMessage(messages.videoDuration, {
                    videoDuration: getMinutuesString(videoDuration),
                  })
                : undefined
            }
            leftIconName="PLAY_ICON"
            appendClassName={classNames(
              `cursor-pointer`,
              // which means we only display play button on this line
              !hasSampleVideo && !shouldShowOtherPurchaseOptionButton
                ? 'md:max-w-120'
                : 'md:max-w-60'
            )}
          />
        </Link>
        {hasSampleVideo && !shouldShowOtherPurchaseOptionButton && (
          <Button
            text={intl.formatMessage(messages.samplePlay)}
            variant="SECONDARY"
            onClick={() => {
              client?.trackUser<PackageDetailButtonsSampleLog>(
                'packageDetail-buttons-sample',
                'click',
                'user_click_dimension_0_default',
                { package_id: packageId }
              );
              const { path: packageSamplePlayPath } = mergeQueryString(router, {
                play: VideoConsumableType.Sample,
              });
              router.push(packageSamplePlayPath, undefined, { scroll: false });
            }}
            component="a"
            appendClassName="md:max-w-60"
            data-testid="packageDetail-buttons-sample"
          />
        )}
      </div>
      {shouldShowOtherPurchaseOptionButton && (
        <div className="mt-3 md:flex-grow">
          <Button
            text={intl.formatMessage(messages.otherPurchaseOption)}
            variant="THIN_SECONDARY"
            leftIconName="POINT_ICON"
            onClick={() => {
              if (loginState === LOGIN_STATE.LOGGED_IN) {
                showOtherPurchaseOptionDialog();
              } else {
                showSignUpDialog();
              }
            }}
            appendClassName="md:max-w-60"
          />
        </div>
      )}
    </div>
  );
};

export default PlayAction;
