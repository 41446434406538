import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import classNames from 'classnames';
import { FC } from 'react';
import ChapterCard, { ChapterCardProps } from '../ChapterCard';
import PackageCard, { PackageCardProps } from '../PackageCard';

type ChapterGridType = 'CHAPTER_BLOCK_LARGE' | 'CHAPTER_BLOCK_NORMAL';

type PackageGridType =
  | 'PACKAGE_BLOCK_SMALL'
  | 'PACKAGE_COLLECTION'
  | 'PACKAGE_BLOCK_LARGE';

export type GridType = ChapterGridType | PackageGridType;

const GAPS_MAPS: Record<GridType, string> = {
  PACKAGE_BLOCK_SMALL: 'gap-x-3 gap-y-6 md:gap-y-7',
  PACKAGE_COLLECTION: 'gap-x-3 gap-y-6 md:gap-y-7',
  PACKAGE_BLOCK_LARGE: 'gap-x-3 gap-y-6 md:gap-y-7',
  CHAPTER_BLOCK_NORMAL: 'gap-x-3 gap-y-5 md:gap-y-7',
  CHAPTER_BLOCK_LARGE: 'gap-x-3 gap-y-5 md:gap-y-7',
};

export const GRID_MAPS: Record<GridType, string> = {
  PACKAGE_BLOCK_SMALL: 'grid grid-cols-[repeat(auto-fill,minmax(9rem,1fr))]',
  PACKAGE_COLLECTION:
    'grid grid-cols-[repeat(auto-fill,minmax(10rem,1fr))] md:grid-cols-[repeat(auto-fill,minmax(11rem,1fr))]',
  PACKAGE_BLOCK_LARGE:
    'grid grid-cols-[repeat(auto-fill,minmax(13rem,1fr))] md:grid-cols-[repeat(auto-fill,minmax(11rem,1fr))]',
  CHAPTER_BLOCK_NORMAL: 'grid grid-cols-[repeat(auto-fill,minmax(15rem,1fr))]',
  CHAPTER_BLOCK_LARGE: 'grid grid-cols-[repeat(auto-fill,minmax(16.5rem,1fr))]',
};

export interface GridChapterProps {
  type: ChapterGridType;
  cards?: ChapterCardProps[];
  edit?: boolean;
}
export interface GridPackageProps {
  type: PackageGridType;
  cards?: PackageCardProps[];
  edit?: boolean;
}

export type GridProps = (GridChapterProps | GridPackageProps) & {
  appendClassName?: string;
};

const isChapterCards = (
  type: GridChapterProps['type'] | GridPackageProps['type'],
  cards: GridChapterProps['cards'] | GridPackageProps['cards']
): cards is GridChapterProps['cards'] => {
  if (type === 'CHAPTER_BLOCK_LARGE' || type === 'CHAPTER_BLOCK_NORMAL')
    return true;
  return false;
};

const chapterCardSize = (type: ChapterGridType, isMd: boolean) => {
  if (type === 'CHAPTER_BLOCK_LARGE') {
    return 'FULL';
  } else {
    return isMd ? 'BLOCK_SMALL' : 'FULL';
  }
};

const CardGrid: FC<GridProps> = ({
  type,
  cards = [],
  edit,
  appendClassName,
}) => {
  const isChapter = isChapterCards(type, cards);
  const isMd = useTailwindBreakpoint('md');

  return (
    <div
      className={classNames(
        'w-full justify-center',
        GAPS_MAPS[type],
        GRID_MAPS[type],
        appendClassName
      )}
    >
      {isChapter
        ? cards.map((card) => (
            <ChapterCard
              {...card}
              key={card.id}
              edit={edit}
              cardSize={chapterCardSize(type as ChapterGridType, isMd)} //type is typeCasted to ChapterGridType due to isChapterCards handeling both GridChapter & GridPackage
            />
          ))
        : cards.map((card) => (
            <PackageCard {...card} key={card.id} edit={edit} />
          ))}
    </div>
  );
};

export default CardGrid;
