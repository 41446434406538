import ScrollContainer from '@/shared/components/ScrollContainer';
import { animated, useTransition } from '@react-spring/web';
import { FC } from 'react';
import { createPortal } from 'react-dom';
import { useIntl } from 'react-intl';
import { globalMessages } from '../../constants/messages';
import BeemiImage from '../BeemiImage';
import Button from '../Button/Button';
import { MODAL_ROOT } from '../Modality/constants';

const COLUMNS = { MOBILE: 4, DESKTOP: 8 };
const countPlaceholdersToFillRow = (columnNum: number, itemNum: number) =>
  itemNum % columnNum > 0 ? columnNum - (itemNum % columnNum) : 0;

type ImageSet = {
  src: string;
  alt: string;
};

export const GalleryThumbnails: FC<{
  images: ImageSet[];
  onClick: () => void;
}> = ({ images, onClick }) => {
  const mobilePlaceholderNum = countPlaceholdersToFillRow(
    COLUMNS.MOBILE,
    images.length
  );
  const desktopPlaceholderNum = countPlaceholdersToFillRow(
    COLUMNS.DESKTOP,
    images.length
  );
  return (
    <div className="grid grid-cols-4 sm:grid-cols-8 gap-0.5">
      {images.map((image, idx) => {
        return (
          <button
            key={idx}
            className="relative object-cover aspect-1"
            onClick={onClick}
          >
            <BeemiImage
              src={image.src}
              alt={image.alt}
              className="object-cover w-full h-full"
            />
          </button>
        );
      })}
      {new Array(mobilePlaceholderNum).fill('').map((_, index) => (
        <div key={index} className="bg-purple/60 opacity-10" />
      ))}
      {new Array(desktopPlaceholderNum - mobilePlaceholderNum)
        .fill('')
        .map((_, index) => (
          <div
            key={index}
            className="hidden bg-purple/60 opacity-10 sm:block"
          />
        ))}
    </div>
  );
};

const Gallery: FC<{
  images: ImageSet[];
  isGalleryOpen: boolean;
  closeGallery: () => void;
}> = ({ images, isGalleryOpen, closeGallery }) => {
  const intl = useIntl();
  const transitions = useTransition(isGalleryOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions(
    (styles, item) =>
      item &&
      createPortal(
        <ScrollContainer
          as={animated.div}
          style={styles}
          className="fixed top-0 bottom-0 left-0 right-0 overflow-y-scroll bg-purple/60 z-100"
        >
          <div className="flex flex-col items-center gap-6 mt-10 mb-42">
            {images.map((image, idx) => (
              <img
                key={idx}
                src={image.src}
                alt={image.alt}
                className="w-full h-auto lg:max-w-3xl 2xl:max-w-5xl"
              />
            ))}
          </div>
          <div className="fixed left-0 right-0 w-40 mx-auto bottom-28">
            <Button
              text={intl.formatMessage(globalMessages.close)}
              onClick={closeGallery}
              leftIconName="CLOSE_ICON"
            />
          </div>
        </ScrollContainer>,
        document.getElementById(MODAL_ROOT) as Element
      )
  );
};

export default Gallery;
