import { useBeemiDeleteFavoriteItems } from '@/domain/library/mylist/useBeemiDeleteFavoriteItems';
import {
  beemiMyListChaptersInput,
  beemiMyListPackageInput,
} from '@/domain/store/constants';
import {
  AddFavoriteItemMutation,
  DeleteFavoriteItemsMutation,
  InputAddConflictPolicy,
  LibuMutationStatus,
  ResourceType,
  useAddFavoriteItemMutation,
} from '@/shared/generated';
import useFormatErrorMessage from '@/shared/hooks/useFormatErrorMessage';
import { useSnackBar } from '@/shared/SnackBarContext';
import { BeemiResourceType } from '@/shared/types';
import { extractFirstGraphQLError } from '@/shared/utils/extractFirstGraphQLError';
import { ApolloError } from '@apollo/client';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

type Params = {
  type: BeemiResourceType;
  refetchQueries?: string[];
  showSnackBarOnSuccess?: boolean;
};

type SetFavoriteParams = {
  resourceId: string;
  isFavorite: boolean;
};

const messages = {
  added: {
    id: 'mylist.added',
    defaultMessage: 'マイリストに追加しました',
  },
  alreadyAdded: {
    id: 'mylist.alreadyAdded',
    defaultMessage: 'マイリストに追加済みです',
  },
  removed: {
    id: 'mylist.removed',
    defaultMessage: 'マイリストから削除しました',
  },
  alreadyRemoved: {
    id: 'mylist.alreadyRemoved',
    defaultMessage: 'マイリストから削除済みです',
  },
};

/**
 * xz: im starting to think that "setFavorite" does not describe the feature correctly
 * it should be useBeemiSetInMyList()
 */
export const useBeemiSetFavorite = ({
  type,
  refetchQueries,
  showSnackBarOnSuccess = true,
}: Params): {
  setFavorite: (params: SetFavoriteParams) => Promise<void>;
} => {
  const intl = useIntl();
  const formatErrorMessage = useFormatErrorMessage();
  const { showSnackBar } = useSnackBar();

  const handleAddFavoriteCompleted = useCallback(
    (res: AddFavoriteItemMutation) => {
      if (showSnackBarOnSuccess) {
        const message =
          res.addFavoriteItem?.status === LibuMutationStatus.Ignored
            ? messages.alreadyAdded
            : messages.added;

        showSnackBar({
          message: intl.formatMessage(message),
        });
      }
    },
    [intl, showSnackBar, showSnackBarOnSuccess]
  );

  const handleRemoveFavoriteCompleted = useCallback(
    (res: DeleteFavoriteItemsMutation) => {
      if (showSnackBarOnSuccess) {
        const message =
          res.deleteFavoriteItems === null
            ? messages.alreadyRemoved
            : messages.removed;

        showSnackBar({
          message: intl.formatMessage(message),
        });
      }
    },
    [intl, showSnackBar, showSnackBarOnSuccess]
  );

  const handleError = useCallback(
    (error: ApolloError) => {
      const { code, message } = extractFirstGraphQLError(error) || {};
      showSnackBar({ message: formatErrorMessage(code, message) });
    },
    [formatErrorMessage, showSnackBar]
  );

  const [addFavoriteItemMutation] = useAddFavoriteItemMutation({
    refetchQueries,
    onCompleted: handleAddFavoriteCompleted,
    onError: handleError,
  });

  const { deleteFavoriteItems } = useBeemiDeleteFavoriteItems({
    refetchQueries,
    onCompleted: handleRemoveFavoriteCompleted,
    onError: handleError,
  });

  async function setFavorite({ resourceId, isFavorite }: SetFavoriteParams) {
    const favoriteItemPayload = {
      favoriteList:
        type === 'package' ? beemiMyListPackageInput : beemiMyListChaptersInput,
      resource: {
        id: resourceId,
        type:
          type === 'package'
            ? ResourceType.MediaLight
            : ResourceType.MediaChapterLight,
      },
      options: {
        conflict: InputAddConflictPolicy.Ignore,
      },
    };

    if (isFavorite) {
      await addFavoriteItemMutation({
        variables: {
          input: favoriteItemPayload,
        },
      });
    } else {
      await deleteFavoriteItems({
        type,
        resourceIds: [resourceId],
      });
    }
  }

  return {
    setFavorite,
  };
};
