import { useGetPlatformInfoQuery } from '@/shared/generated';
import { useLoginState } from '@/shared/hooks/useLoginState';
import * as Sentry from '@sentry/nextjs';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { type TreasureDataClient } from './TreasureDataClient';

async function importTreasureDataClient() {
  return await import('./TreasureDataClient');
}

interface TreasureDataContextProps {
  treasureDataClient?: TreasureDataClient;
  tdClientId: string;
}

export const TreasureDataContext =
  React.createContext<TreasureDataContextProps>({ tdClientId: '' });

export const TreasureDataContextProvider: React.FC<{
  treasureDataClient: TreasureDataClient | undefined;
  setTreasureDataClient: React.Dispatch<
    React.SetStateAction<TreasureDataClient | undefined>
  >;
  children?: ReactNode;
}> = ({ children, treasureDataClient, setTreasureDataClient }) => {
  const { data: userInfoData } = useLoginState();
  const { data: platformInfoData } = useGetPlatformInfoQuery();
  const userInfo = userInfoData?.userInfo;
  const platformInfo = platformInfoData?.platformInfo;

  useEffect(() => {
    importTreasureDataClient().then(({ TreasureDataClient }) => {
      if (userInfo && platformInfo) {
        setTreasureDataClient(new TreasureDataClient(userInfo, platformInfo));
      }
    });
  }, [userInfo, platformInfo, setTreasureDataClient]);

  const tdClientId = useMemo(() => {
    if (treasureDataClient && !treasureDataClient?.tdClientId) {
      Sentry.captureMessage(`TreasureDataClient is empty!`);
    }
    return treasureDataClient?.tdClientId ?? '';
  }, [treasureDataClient]);

  return (
    <TreasureDataContext.Provider value={{ treasureDataClient, tdClientId }}>
      {children}
    </TreasureDataContext.Provider>
  );
};
