import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

const icons = require.context(
  '!@svgr/webpack!../../../assets/icons/badge',
  true,
  /\.svg$/
);

export type Icons = 'COIN' | 'POINT' | 'CHECK';

type Sizes = 'DEFAULT' | 'BIG' | 'BIGGER' | 'FULL';

const SIZE_MAPS: Record<Sizes, string> = {
  DEFAULT: 'w-3 h-3',
  BIG: 'w-3.5 h-3.5',
  BIGGER: 'w-4 h-4',
  FULL: 'w-full h-full',
};

export const ICONS_MAPS: Record<Icons, string> = {
  COIN: 'coin_badge.svg',
  POINT: 'point_badge.svg',
  CHECK: 'check_badge.svg',
};

export interface BadgeIconProps extends HTMLAttributes<HTMLDivElement> {
  name: Icons;
  size?: Sizes;
}

const BadgeIcon: React.FC<BadgeIconProps> = ({
  name,
  className,
  size = 'DEFAULT',
  ...props
}) => {
  const LoadedIcon = icons(`./${ICONS_MAPS[name]}`).default;
  return (
    <div
      className={classNames(
        `inline-flex text-center`,
        SIZE_MAPS[size],
        className
      )}
      {...props}
    >
      <LoadedIcon className={`w-full h-full fill-current`} />
    </div>
  );
};

export default BadgeIcon;
