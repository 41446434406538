import BadgeIcon from '@/shared/components/BadgeIcon';
import Button from '@/shared/components/Button/Button';
import SimpleDialog from '@/shared/components/Modality/Dialog/SimpleDialog';
import { FC, ReactNode } from 'react';
import { defineMessage, useIntl } from 'react-intl';

const messages = {
  title: defineMessage({
    id: 'signupMonthly.title',
    defaultMessage: '月額プランに登録しませんか？',
  }),
  subTitle: defineMessage({
    id: 'signupMonthly.subTitle',
    defaultMessage: '登録すると、',
  }),
  btnRegister: defineMessage({
    id: 'signupMonthly.btnRegister',
    defaultMessage: '登録',
  }),
  btnWithoutRegister: defineMessage({
    id: 'signupMonthly.btnWithoutRegister',
    defaultMessage: '登録せずに購入',
  }),
  point1: defineMessage({
    id: 'signupMonthly.point1',
    defaultMessage:
      'この作品を含む<pinkText>{svodCount}以上の見放題対象作品</pinkText>が楽しめます。',
  }),
  point2: defineMessage({
    id: 'signupMonthly.point2',
    defaultMessage:
      '<pinkText>U-NEXTの月額プランサービス</pinkText>も楽しめます。',
  }),
  point3: defineMessage({
    id: 'signupMonthly.point3',
    defaultMessage: '<pinkText>毎月1,200円分のポイント</pinkText>をプレゼント!',
  }),
};

const PRPoint: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div className="flex items-start">
      <span className="flex flex-shrink-0 mt-1 mr-3 text-pink">
        <BadgeIcon name="CHECK" size="BIGGER" />
      </span>
      <p className="flex-wrap text-md">{children}</p>
    </div>
  );
};

interface Props {
  isShowing: boolean;
  onClickOutside: () => void;
  onCancel: () => void;
}

const SignupMonthly: FC<Props> = ({ isShowing, onClickOutside, onCancel }) => {
  const intl = useIntl();
  const renderButtons = () => {
    return (
      <>
        <Button
          text={intl.formatMessage(messages.btnRegister)}
          href={process.env.NEXT_PUBLIC_REGISTER_WITH_MONTHLY_PLAN_URL}
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          variant="PRIMARY"
        />
        <Button
          text={intl.formatMessage(messages.btnWithoutRegister)}
          href={process.env.NEXT_PUBLIC_REGISTER_PF_URL}
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          variant="SECONDARY"
        />
      </>
    );
  };

  const point1 = intl.formatMessage(messages.point1, {
    svodCount: '5万本',
    pinkText: (str: ReactNode) => <span className="text-pink">{str}</span>,
  });
  const point2 = intl.formatMessage(messages.point2, {
    pinkText: (str: ReactNode) => <span className="text-pink">{str}</span>,
  });
  const point3 = intl.formatMessage(messages.point3, {
    pinkText: (str: ReactNode) => <span className="text-pink">{str}</span>,
  });

  return (
    <SimpleDialog
      isShowing={isShowing}
      title={intl.formatMessage(messages.title)}
      onClickOutside={onClickOutside}
      onCancel={onCancel}
      renderButtons={renderButtons}
    >
      <div className="flex flex-col items-center">
        <p className="mt-6 text-caption">
          {intl.formatMessage(messages.subTitle)}
        </p>
        <div className="pl-3 pr-1 mt-4 space-y-4">
          <PRPoint>{point1}</PRPoint>
          <PRPoint>{point2}</PRPoint>
          <PRPoint>{point3}</PRPoint>
        </div>
      </div>
    </SimpleDialog>
  );
};

export default SignupMonthly;
