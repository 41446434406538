import { errorMessages } from '@/shared/constants/messages';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

type FormatErrorMessage = (errorCode?: string, errorMessage?: string) => string;

function useFormatErrorMessage(): FormatErrorMessage {
  const intl = useIntl();
  const defaultErrorMessage = intl.formatMessage(
    errorMessages.defaultErrorText
  );
  const formatErrorMessage = useCallback(
    (errorCode?: string, errorMessage?: string) => {
      return `${errorMessage || defaultErrorMessage}${
        errorCode ? `(${errorCode})` : ''
      }`;
    },
    [defaultErrorMessage]
  );
  return formatErrorMessage;
}

export default useFormatErrorMessage;
