import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

type ReturnError = {
  code: KnownErrorCodes;
  message: string;
  graphQLError: GraphQLError | null;
};

/**
 * Known Error codes from backend
 *
 * NOTE: Please continue to fill in codes here
 */
type KnownErrorCodes =
  | 'AUTHORISATION_ERROR'
  | 'UNAUTHENTICATED'
  | 'NOT_FOUND'
  | 'FORBIDDEN';

export const extractFirstGraphQLError = (
  apolloError: ApolloError | undefined,
  targetCodes?: KnownErrorCodes[]
): ReturnError | undefined => {
  if (!apolloError) {
    return undefined;
  }

  const targetError =
    targetCodes && targetCodes?.length > 0
      ? apolloError?.graphQLErrors.find((graphQLError) => {
          const code = graphQLError.extensions['code'];
          return targetCodes?.some((targetCode) => targetCode === code);
        })
      : apolloError?.graphQLErrors[0];

  if (!targetError) {
    return undefined;
  }

  const message =
    targetError.extensions['errorMessage'] ||
    targetError.extensions['message'] ||
    targetError.message ||
    apolloError.message;

  const code = targetError.extensions['code'];

  return {
    code,
    message,
    graphQLError: targetError,
  };
};
