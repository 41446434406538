import Button from '@/shared/components/Button/Button';
import { FC } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import SimpleDialog from '../SimpleDialog';

type PlayerErrorCode =
  /**
   * 復帰不可のエラー、最初からやり直し
   */
  | 'MISSING_PARAMETER'
  /**
   * 復帰不可のエラー、最初からやり直し
   */
  | 'INVALID_TOKEN'
  /**
   * （起きないはずだが、起きても無視していいです）
   */
  | 'ALREADY_ACTIVATED'
  /**
   * 復帰不可のエラー、最初からやり直し
   */
  | 'CLIENT_MISMATCH'
  /**
   * 同時再生エラー他のクライアントで使用中
   * 他 UA
   */
  | 'IN_USE_BY_ANOTHER_CLIENT'
  /**
   * 同時再生エラー他のデバイスで使用中
   * 他 device_id
   */
  | 'IN_USE_BY_ANOTHER_DEVICE'
  /**
   * 後勝ち同時再生エラー、切り替え上限に到達
   */
  | 'TOO_EARLY_TO_OVERWRITE';

const messages = {
  playerErrorButtonBack: defineMessage({
    id: 'playerError.buttonBack',
    defaultMessage: '戻る',
  }),
  playerErrorButtonYes: defineMessage({
    id: 'playerError.buttonYes',
    defaultMessage: 'はい',
  }),
  playerErrorButtonCancel: defineMessage({
    id: 'playerError.buttonCancel',
    defaultMessage: 'キャンセル',
  }),
  playerErrorMissingParameterTitle: defineMessage({
    id: 'playerError.missingParameter.title',
    defaultMessage: '再生できません',
  }),
  playerErrorMissingParameterMessage: defineMessage({
    id: 'playerError.missingParameter.message',
    defaultMessage:
      '予期せぬエラーが発生しました。お手数ですが最初からお試しください。',
  }),
  playerErrorInuseByAnotherClientTitle: defineMessage({
    id: 'playerError.inuseByAnotherClient.title',
    defaultMessage: '別の端末で再生中です',
  }),
  playerErrorInuseByAnotherClientMessage: defineMessage({
    id: 'playerError.inuseByAnotherClient.message',
    defaultMessage:
      '同時に複数の端末では再生できません。{br}このデバイスで再生を行いますか？',
  }),
  playerErrorTooEarlyToOverwiteTitle: defineMessage({
    id: 'playerError.tooEarlyToOverwite.title',
    defaultMessage:
      '一定回数以上、別端末での再生を検知したため、再生できません。{br}しばらく経ってからお試しください。',
  }),
  playerErrorTooEarlyToOverwiteMessage: defineMessage({
    id: 'playerError.tooEarlyToOverwite.message',
    defaultMessage:
      '一定回数以上、別端末での再生を検知したため、再生できません。{br}しばらく経ってからお試しください。',
  }),
  playerErrorUnexpectedTitle: defineMessage({
    id: 'playerError.unexpected.title',
    defaultMessage: '再生できません',
  }),
  playerErrorUnexpectedMessage: defineMessage({
    id: 'playerError.unexpected.message',
    defaultMessage:
      'サーバへのアクセスが混み合っているか、メンテナンス中の可能性があります。{br}しばらく時間をおいてからもう一度アクセスしてください。',
  }),
};

function getPlayerErrorText(code: PlayerErrorCode) {
  switch (code) {
    case 'MISSING_PARAMETER':
    case 'INVALID_TOKEN':
    case 'ALREADY_ACTIVATED':
    case 'CLIENT_MISMATCH':
      return {
        title: messages.playerErrorMissingParameterTitle,
        message: messages.playerErrorMissingParameterMessage,
      };
    case 'IN_USE_BY_ANOTHER_DEVICE':
    case 'IN_USE_BY_ANOTHER_CLIENT':
      return {
        title: messages.playerErrorInuseByAnotherClientTitle,
        message: messages.playerErrorInuseByAnotherClientMessage,
      };
    case 'TOO_EARLY_TO_OVERWRITE':
      return {
        title: messages.playerErrorTooEarlyToOverwiteTitle,
        message: messages.playerErrorTooEarlyToOverwiteMessage,
      };

    default: // (予期せぬエラー)
      return {
        title: messages.playerErrorUnexpectedTitle,
        message: messages.playerErrorUnexpectedMessage,
      };
  }
}

interface Props {
  playerErrorCode: string;
  onBackClick: () => void;
  onTakeOverSessionClick: () => void;
}

const PlayErrorDialog: FC<Props> = ({
  playerErrorCode,
  onBackClick,
  onTakeOverSessionClick,
}) => {
  const intl = useIntl();

  const code = playerErrorCode as PlayerErrorCode;
  const playerErrorText = getPlayerErrorText(code);

  return (
    <SimpleDialog
      isShowing
      title={intl.formatMessage(playerErrorText.title, { br: <br /> })}
      renderButtons={() => (
        <>
          {code === 'IN_USE_BY_ANOTHER_CLIENT' ||
          code === 'IN_USE_BY_ANOTHER_DEVICE' ? (
            <>
              <Button
                variant="PRIMARY"
                text={intl.formatMessage(messages.playerErrorButtonYes)}
                onClick={onTakeOverSessionClick}
              />
              <Button
                variant="PRIMARY"
                text={intl.formatMessage(messages.playerErrorButtonCancel)}
                onClick={onBackClick}
              />
            </>
          ) : (
            <Button
              variant="PRIMARY"
              text={intl.formatMessage(messages.playerErrorButtonBack)}
              onClick={onBackClick}
            />
          )}
        </>
      )}
    >
      <div className="mt-2 md:mt-4 text-caption">
        <p>{intl.formatMessage(playerErrorText.message, { br: <br /> })}</p>
        {playerErrorCode && <p>（{playerErrorCode}）</p>}
      </div>
    </SimpleDialog>
  );
};

export default PlayErrorDialog;
