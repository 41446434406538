import { ContentType } from '@/shared/generated';
import { defineMessage, MessageDescriptor } from 'react-intl';

/**
 * Global i18n messages
 *
 * Any messages that will be used often or cross-component should be
 * placed in this file.
 */
export const globalMessages = {
  priceWithTax: defineMessage({
    id: 'price.withTax',
    defaultMessage: '{price}円 (税込)',
  }),
  showMore: defineMessage({
    id: 'showMore',
    defaultMessage: 'すべて表示',
  }),
  close: defineMessage({
    id: 'close',
    defaultMessage: '閉じる',
  }),
  ok: defineMessage({
    id: 'ok',
    defaultMessage: 'OK',
  }),
  cancel: defineMessage({
    id: 'cancel',
    defaultMessage: 'キャンセル',
  }),
  buy: defineMessage({
    id: 'purchase',
    defaultMessage: '購入',
  }),
  noDateLimit: defineMessage({
    id: 'noDateLimit',
    defaultMessage: '無期限',
  }),
  pointsAtHand: defineMessage({
    id: 'pointsAtHand',
    defaultMessage: '所持ポイント',
  }),
  pointsToUse: defineMessage({
    id: 'pointsToUse',
    defaultMessage: '利用ポイント',
  }),
  playTestVideo: defineMessage({
    id: 'playTestVideo',
    defaultMessage: 'テスト動画を再生',
  }),
  STORE: defineMessage({
    id: 'store',
    defaultMessage: 'ストア',
  }),
  SVOD: defineMessage({
    id: 'svod',
    defaultMessage: '見放題',
  }),
  LIBRARY: defineMessage({
    id: 'library',
    defaultMessage: 'ライブラリ',
  }),
  numberOfResults: defineMessage({
    id: 'numberOfResults',
    defaultMessage: '{count}件',
  }),
  filter: defineMessage({
    id: 'filter',
    defaultMessage: '絞り込み',
  }),
  noResults: defineMessage({
    id: 'noResults',
    defaultMessage: '該当する検索結果がありません',
  }),
  myPage: defineMessage({
    id: 'mypage',
    defaultMessage: 'マイページ',
  }),
  helpCenter: defineMessage({
    id: 'helpCenter',
    defaultMessage: 'ヘルプセンター',
  }),
  backToTop: defineMessage({
    id: 'backToTop',
    defaultMessage: 'トップへ戻る',
  }),
  yes: defineMessage({
    id: 'yes',
    defaultMessage: 'はい',
  }),
  no: defineMessage({
    id: 'no',
    defaultMessage: 'いいえ',
  }),
  newMember: defineMessage({
    id: 'newMember',
    defaultMessage: '新規会員登録',
  }),
  login: defineMessage({
    id: 'login',
    defaultMessage: 'ログイン',
  }),
  logout: defineMessage({
    id: 'logout',
    defaultMessage: 'ログアウト',
  }),
  point: defineMessage({
    id: 'point',
    defaultMessage: 'ポイント',
  }),
  search: defineMessage({
    id: 'search',
    defaultMessage: '検索',
  }),
  cast: defineMessage({
    id: 'cast',
    defaultMessage: '女優・監督との一致',
  }),
  purchased: defineMessage({
    id: 'purchased',
    defaultMessage: '購入済み',
  }),
  edit: defineMessage({
    id: 'edit',
    defaultMessage: '編集',
  }),
  delete: defineMessage({
    id: 'delete',
    defaultMessage: '削除',
  }),
  deleteAll: defineMessage({
    id: 'deleteAll',
    defaultMessage: 'すべて削除',
  }),
  deleteCase: defineMessage({
    id: 'deleteCase',
    defaultMessage: '件を削除',
  }),
};

export const errorMessages = {
  404: defineMessage({
    id: 'error.404',
    defaultMessage:
      '<p>入力したアドレスが間違っているか、ページが移動した可能性があります。{br}(WF00404)</p>',
  }),
  default: defineMessage({
    id: 'error.default',
    defaultMessage:
      '<p>サーバへのアクセスが混み合っているか、メンテナンス中の可能性があります。{br}しばらく時間をおいてからもう一度アクセスしてください。{br}(FWE010100001)</p>',
  }),
  pageNotFound: defineMessage({
    id: 'pageNotFound',
    defaultMessage: 'お探しのページが見つかりません',
  }),
  serverError: defineMessage({
    id: 'serverError',
    defaultMessage: 'エラーが発生しました',
  }),
  defaultErrorText: defineMessage({
    id: 'error.defaultErrorText',
    defaultMessage:
      '予期せぬエラーが発生しました。しばらく経ってからお試し下さい。',
  }),
};

export const contentTypeMap = (
  contentType: ContentType = ContentType.Unknown
): MessageDescriptor => {
  switch (contentType) {
    case ContentType.Alias: {
      return defineMessage({
        id: 'autocomplete.alias',
        defaultMessage: '女優・監督',
      });
    }
    case ContentType.BrandGroup: {
      return defineMessage({
        id: 'autocomplete.brandGroup',
        defaultMessage: 'シリーズ・メーカー',
      });
    }
    case ContentType.Media: {
      return defineMessage({
        id: 'autocomplete.media',
        defaultMessage: 'タイトル',
      });
    }
    case ContentType.Planet: {
      return defineMessage({
        id: 'autocomplete.planet',
        defaultMessage: 'カテゴリ',
      });
    }
  }

  // All other ContentTypes can be ignored on Autocomplete.
  return defineMessage({
    id: 'autocomplete.unknown',
    defaultMessage: 'unknown',
  });
};
