import {
  SaleDateTypes,
  SaleTypes,
  VideoDurationTypes,
  VideoQualityTypes,
  VideoType,
} from '@/shared/components/Modality/Dialog/SearchFilter';
import { ContentType, NodeFilter } from '@/shared/generated';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { SortTypes } from '../SearchResult/components/SearchControl';

export const TAG_SEPARATOR = '-';

interface SearchParams {
  pathname: string;
  query: ParsedUrlQuery;
  freeword: string;
  nodeFilter: NodeFilter[];
  minPrice?: number;
  maxPrice?: number;
  resolution: VideoQualityTypes[];
  duration: VideoDurationTypes;
  sale: SaleTypes[];
  date: SaleDateTypes[];
  videoType: VideoType[];
  page: number;
  sort?: SortTypes;
  isActive: boolean;
  asPath?: string;
}

/**
 * useSearchParams hook is used the get the query params used in SearchResult pages
 */
export const useSearchParams = (): SearchParams => {
  const router = useRouter();
  const pathname = router.pathname;
  const query = router.query;
  const asPath = router.asPath;

  const freeword = query.freeword
    ? Array.isArray(query.freeword)
      ? query.freeword[0]
      : query.freeword
    : '';
  const tags = query.tag
    ? Array.isArray(query.tag)
      ? query.tag
      : [query.tag]
    : [];
  const nodeFilter = tags.map((tag) => {
    const lastIndex = tag.lastIndexOf(TAG_SEPARATOR);
    return {
      id: tag.slice(0, lastIndex),
      contentType: tag.slice(lastIndex + 1) as ContentType,
    };
  });

  let minPrice: number | undefined =
    parseInt(
      query.minPrice
        ? Array.isArray(query.minPrice)
          ? ''
          : query.minPrice
        : ''
    ) ?? undefined;
  minPrice = isNaN(minPrice) ? undefined : minPrice;
  let maxPrice: number | undefined =
    parseInt(
      query.maxPrice
        ? Array.isArray(query.maxPrice)
          ? ''
          : query.maxPrice
        : ''
    ) ?? undefined;
  maxPrice = isNaN(maxPrice) ? undefined : maxPrice;
  const resolution = (
    query.resolution
      ? Array.isArray(query.resolution)
        ? query.resolution
        : [query.resolution]
      : []
  ) as VideoQualityTypes[];
  const duration = (
    query.duration
      ? Array.isArray(query.duration)
        ? query.duration[0]
        : query.duration
      : 'all'
  ) as VideoDurationTypes;
  const videoType = (
    query.videoType
      ? Array.isArray(query.videoType)
        ? query.videoType
        : [query.videoType]
      : []
  ) as VideoType[];
  const sale = (
    query.sale ? (Array.isArray(query.sale) ? query.sale : [query.sale]) : []
  ) as SaleTypes[];
  const date = (
    query.date ? (Array.isArray(query.date) ? query.date : [query.date]) : []
  ) as SaleDateTypes[];

  const page = parseInt(
    Array.isArray(query.page) ? query.page[0] : query.page || '1'
  );

  const sort = query.sort as SortTypes | undefined;

  const isActive =
    !!minPrice ||
    !!maxPrice ||
    resolution.length > 0 ||
    !!query.duration ||
    sale.length > 0 ||
    date.length > 0;

  return {
    pathname,
    query,
    freeword,
    nodeFilter,
    minPrice,
    maxPrice,
    resolution,
    duration,
    videoType,
    sale,
    date,
    page,
    sort,
    isActive,
    asPath,
  };
};
