import Synopsis from '@/domain/package/PackageDetail/Synopsis';
import { TAG_SEPARATOR } from '@/domain/search/hooks/useSearchParams';
import { mapMediaLightToPackageCard } from '@/domain/store/util';
import CardGrid from '@/shared/components/Card/CardGrid';
import { PackageCardProps } from '@/shared/components/Card/PackageCard';
import { GalleryThumbnails } from '@/shared/components/Gallery';
import Icon from '@/shared/components/Icon';
import TagList, { ContentTag, isContentTag } from '@/shared/components/TagList';
import {
  AliasLight,
  BrandGroupLight,
  ContentType,
  GetMediaQuery,
} from '@/shared/generated';
import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import { differenceInCalendarDays } from 'date-fns';
import { ComponentProps } from 'react';
import { defineMessage, useIntl } from 'react-intl';

const messages = {
  actorsAndDirector: defineMessage({
    id: 'package.detail.metaInfo.actorsAndDirector',
    defaultMessage: '女優・監督',
  }),
  seriesAndManufacturer: defineMessage({
    id: 'package.detail.metaInfo.seriesAndManufacturer',
    defaultMessage: 'シリーズ・メーカー',
  }),
  recommended: defineMessage({
    id: 'package.detail.metaInfo.recommended',
    defaultMessage: 'おすすめ作品',
  }),
  publicEndDate: defineMessage({
    id: 'package.detail.metaInfo.publicEndDate',
    defaultMessage: '{endDate}まで配信',
  }),
  publicEndDateNotSet: defineMessage({
    id: 'package.detail.metaInfo.publicEndDateNotSet',
    defaultMessage: '90日以内に配信終了の予定はありません',
  }),
};

type AliasLightPartial = Pick<AliasLight, 'id' | 'nameJa'>;

function mapAliasLightToContentTag(aliasLight: AliasLightPartial) {
  return {
    id: aliasLight.id,
    nameJa: aliasLight.nameJa,
    contentType: ContentType.Alias,
  };
}

function mapBrandGroupLightToContentTag(brandGroup: BrandGroupLight) {
  return {
    id: brandGroup.id,
    nameJa: brandGroup.nameJa,
    contentType: ContentType.BrandGroup,
  };
}

type Media = NonNullable<GetMediaQuery['media']>;

type MetaInfoProps = {
  metadata: Media['metadata'];
  recommendations: Media['paginatedRecommendations']['records'];
  showGallery: () => void;
  publicEndDateTime: Date | undefined;
};

const MetaInfo = ({
  metadata,
  recommendations,
  showGallery,
  publicEndDateTime,
}: MetaInfoProps) => {
  const intl = useIntl();

  const isMd = useTailwindBreakpoint('md');

  const synopsis = metadata?.synopsis;
  const contentTags: ContentTag<ContentType.ContentTag>[] =
    metadata?.contentTags.filter(isContentTag).map((tag, index) => ({
      ...tag,
      kafkaProps: {
        targetPrefix: 'packageDetail-contentTags',
        content_type: ContentType.ContentTag,
        index,
        tag_id: tag.id,
        package_id: metadata.id,
      },
    })) || [];

  const recommendationCards: PackageCardProps[] =
    recommendations?.map(mapMediaLightToPackageCard).map((card, index) => ({
      ...card,
      kafkaProps: {
        targetPrefix: 'packageDetail-recommendTitle',
        base_schema: 'user_click_dimension_1_default' as const,
        package_id: card.id,
        index,
        source_package_id: metadata.id,
      },
    })) || [];

  const performers: ComponentProps<typeof TagList>['tags'] =
    metadata?.performances.map((x) => mapAliasLightToContentTag(x.alias)) || [];

  const director =
    metadata?.credits.map((x) => mapAliasLightToContentTag(x.alias)) || [];

  const castList = [...performers, ...director];

  const seriesAndManufacturer =
    metadata?.brandGroups.map(mapBrandGroupLightToContentTag) || [];

  const saleStartDate = metadata?.originalReleaseDate;

  const galleryImages = metadata?.screenshots.map(({ w1 }) => ({
    src: w1,
    alt: w1,
  }));

  return (
    <>
      {galleryImages && (
        <GalleryThumbnails images={galleryImages} onClick={showGallery} />
      )}
      {contentTags && (
        <section>
          <TagList
            tags={contentTags}
            generateHref={(id, contentType) => ({
              pathname: '/search',
              query: 'tag=' + id + TAG_SEPARATOR + contentType,
            })}
          />
        </section>
      )}
      {synopsis && (
        <Synopsis
          synopsis={synopsis}
          saleStartDate={saleStartDate}
          adultProductCode={metadata.adultProductCode}
        />
      )}
      {castList.length > 0 && seriesAndManufacturer.length > 0 && (
        <div className="flex flex-col md:flex-row">
          {castList.length > 0 && (
            <section className="flex flex-1">
              <TagList
                tags={castList}
                title={intl.formatMessage(messages.actorsAndDirector)}
                generateHref={(id, contentType) => ({
                  pathname: '/search',
                  query: 'tag=' + id + TAG_SEPARATOR + contentType,
                })}
              />
            </section>
          )}
          {seriesAndManufacturer.length > 0 && (
            <section
              className={`md:max-w-[50%] ${
                castList.length > 0 ? 'mt-10' : ''
              } md:mt-0`}
            >
              <TagList
                tags={seriesAndManufacturer}
                title={intl.formatMessage(messages.seriesAndManufacturer)}
                generateHref={(id, contentType) => ({
                  pathname: '/search',
                  query: 'tag=' + id + TAG_SEPARATOR + contentType,
                })}
              />
            </section>
          )}
        </div>
      )}
      {recommendations && (
        <section className="flex flex-col flex-1">
          <h2 className="mb-3 text-strong md:mb-4">
            {intl.formatMessage(messages.recommended)}
          </h2>
          <CardGrid type="PACKAGE_BLOCK_SMALL" cards={recommendationCards} />
        </section>
      )}
      <section className="px-4 py-5 border rounded-md md:py-6 md:px-5 border-purple/30">
        <h2 className="flex items-center text-strong gap-0.5">
          <Icon name="CAUTION_ICON" size={isMd ? 'BIGGER' : 'BIG'} />
          対象デバイス
        </h2>
        <p className="mt-1 text-caption text-purple/80">
          スマートフォン / タブレット（U-NEXTアプリ）・パソコン（Google Chrome /
          Firefox / Microsoft Edge / Safari）・テレビ（Android TV / Amazon
          FireTV / FireTV Stick / Chromecast / Chromecast with Google TV /
          U-NEXT TV / AirPlay）
        </p>
      </section>
      <div className="flex flex-col gap-2 text-caption text-purple/80">
        <span>
          {publicEndDateTime &&
          differenceInCalendarDays(publicEndDateTime, new Date()) <= 90
            ? intl.formatMessage(messages.publicEndDate, {
                endDate: intl.formatDate(publicEndDateTime, {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }),
              })
            : intl.formatMessage(messages.publicEndDateNotSet)}
        </span>
        {metadata.copyright && <span>{metadata.copyright}</span>}
      </div>
    </>
  );
};

export default MetaInfo;
