import BadgeIcon from '@/shared/components/BadgeIcon';
import Button from '@/shared/components/Button/Button';
import { FC, ReactNode } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import SimpleDialog from '../../SimpleDialog';

const messages = {
  title: defineMessage({
    id: 'prusersignup.title',
    defaultMessage: '月額プランに登録しませんか？',
  }),
  subTitle: defineMessage({
    id: 'prusersignup.subTitle',
    defaultMessage: '登録すると、',
  }),
  register: defineMessage({
    id: 'prusersignup.register',
    defaultMessage: '登録',
  }),
  viewing: defineMessage({
    id: 'prusersignup.viewing',
    defaultMessage:
      'この作品を含む<pinkText>{svodCount}以上の見放題対象作品</pinkText>が楽しめます。',
  }),
  plan: defineMessage({
    id: 'prusersignup.plan',
    defaultMessage:
      '<pinkText>U-NEXTの月額プランサービス</pinkText>も楽しめます。',
  }),
  pointsOffer: defineMessage({
    id: 'prusersignup.pointsOffer',
    defaultMessage: '<pinkText>毎月1,200円分のポイント</pinkText>をプレゼント!',
  }),
};

const PRPoint: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div className="flex items-start">
      <span className="flex flex-shrink-0 mt-1 mr-3 text-pink">
        <BadgeIcon name="CHECK" size="BIGGER" />
      </span>
      <p className="flex-wrap text-md">{children}</p>
    </div>
  );
};

interface Props {
  isShowing: boolean;
  href: string;
  onClickOutside: () => void;
  onCancel: () => void;
}

const PFUserSignUp: React.FC<Props> = ({
  isShowing,
  href,
  onClickOutside,
  onCancel,
}) => {
  const intl = useIntl();
  const renderButtons = () => {
    return (
      <Button
        variant="PRIMARY"
        text={intl.formatMessage(messages.register)}
        component="a"
        href={href}
      />
    );
  };

  return (
    <SimpleDialog
      onClickOutside={onClickOutside}
      onCancel={onCancel}
      isShowing={isShowing}
      title={intl.formatMessage(messages.title)}
      renderButtons={renderButtons}
      animationType="fade"
    >
      <div className="flex items-center justify-center mt-6 text-caption text-purple">
        {intl.formatMessage(messages.subTitle)}
      </div>

      <div className="pl-3 pr-1 mt-4 space-y-4">
        <PRPoint>
          {intl.formatMessage(messages.viewing, {
            svodCount: '5万本',
            pinkText: (text: ReactNode) => (
              <span className="text-pink">{text}</span>
            ),
          })}
        </PRPoint>
        <PRPoint>
          {intl.formatMessage(messages.plan, {
            pinkText: (text: ReactNode) => (
              <span className="text-pink">{text}</span>
            ),
          })}
        </PRPoint>
        <PRPoint>
          {intl.formatMessage(messages.pointsOffer, {
            pinkText: (text: ReactNode) => (
              <span className="text-pink">{text}</span>
            ),
          })}
        </PRPoint>
      </div>
    </SimpleDialog>
  );
};

export default PFUserSignUp;
