import LogoBeemiSymbol from '@/assets/icons/logo/Logo_BEEMI_WHITE.svg';
import Button from '@/shared/components/Button/Button';
import { BeemiResourceType } from '@/shared/types';

interface ISubscribeActionProps {
  type: BeemiResourceType;
  textLine1: string;
  textLine2?: string;
  buttonText: string;
  buttonHref: string;
}

const SubscribeAction = ({
  type,
  textLine1,
  textLine2,
  buttonText,
  buttonHref,
}: ISubscribeActionProps) => {
  if (type === 'chapter') {
    return (
      <section
        data-testid="SubscribeAction"
        className="flex flex-col items-center px-3 pt-4 pb-3 rounded-lg bg-pink/80 md:pb-4 md:justify-center lg:px-6 2xl:flex-row 2xl:pt-6"
      >
        <div className="flex flex-col items-center 2xl:mr-10">
          <div className="scale-75 2xl:scale-100">
            <LogoBeemiSymbol className="md:flex 2xl:mb-2.5" />
          </div>
          <p className="flex flex-col items-center justify-center flex-1 text-white 2xl:text-lg Hiragino Sans">
            <span>{textLine1}</span>
            {Boolean(textLine2) && <span>{textLine2}</span>}
          </p>
        </div>
        <Button
          className="flex w-full mt-3 bg-white rounded-md md:w-60 h-11 text-pink max-w-84"
          component="a"
          href={buttonHref}
          text={buttonText}
          variant="TEXT"
          color="text-pink"
        />
      </section>
    );
  }

  return (
    <section
      data-testid="SubscribeAction"
      className="flex flex-col items-center px-3 pt-4 pb-3 rounded-lg bg-pink/80 md:flex-row md:justify-center md:pb-4 md:pt-6 lg:px-6 "
    >
      <div className="flex flex-col items-center md:mr-10">
        <div className="scale-75 md:scale-100">
          <LogoBeemiSymbol className="md:flex md:mb-2.5" />
        </div>
        <p className="flex flex-col items-center justify-center flex-1 text-white 2xl:flex-row lg:text-lg Hiragino Sans">
          <span>{textLine1}</span>
          {Boolean(textLine2) && <span>{textLine2}</span>}
        </p>
      </div>
      <div className="flex justify-center w-full mt-3 md:w-auto">
        <Button
          className="flex w-full bg-white rounded-md md:w-60 h-11 text-pink max-w-84"
          component="a"
          href={buttonHref}
          text={buttonText}
          variant="TEXT"
          color="text-pink"
        />
      </div>
    </section>
  );
};

export default SubscribeAction;
