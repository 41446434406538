import { useBeemiSetFavorite } from '@/domain/library/mylist/useBeemiSetFavorite';
import {
  KafkaContext,
  KafkaPropsWithBaseSchema,
} from '@/domain/log/KafkaContext';
import type { SearchResultChapterTitleListTitleCardLog } from '@/domain/log/__types__/searchResultChapter-titleList-titleCard';
import type { SearchResultPackageTitleListTitleCardLog } from '@/domain/log/__types__/searchResultPackage-titleList-titleCard';
import type { StoreNewListTitleCardLog } from '@/domain/log/__types__/store-newList-titleCard';
import type { StoreRankingListTitleCardLog } from '@/domain/log/__types__/store-rankingList-titleCard';
import type { StoreTitleListTitleCardLog } from '@/domain/log/__types__/store-titleList-titleCard';
import type { StoreTitleListDetailTitleListTitleCardLog } from '@/domain/log/__types__/storeTitleListDetail-titleList-titleCard';
import EditCard from '@/shared/components/Card/EditCard';
import {
  CardSize,
  CommonMediaLightProps,
} from '@/shared/components/Card/types';
import VRBadge from '@/shared/components/Card/VRBadge';
import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  ComponentProps,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import Badge from '../../Badge';
import BeemiImage from '../../BeemiImage';
import OptionButton from '../../Button/OptionButton';
import CardTag from '../CardTag';
import Like from '../Like';
import OptionsSheet from '../OptionsSheet';

type PackageCardSize = CardSize | 'COLLECTION';

const CARD_SIZE_MAPS: Record<PackageCardSize, string> = {
  FULL: 'w-full',
  BLOCK_SMALL: 'w-35',
  BLOCK_LARGE: 'w-50 md:w-45',
  COLLECTION: 'w-40 md:w-44',
};

const FIRST_TAG_THRESHOLD_LENGTH_MAPS: Record<PackageCardSize, number> = {
  FULL: 4,
  BLOCK_SMALL: 4,
  COLLECTION: 5,
  BLOCK_LARGE: 7,
};

const IMAGE_SIZE_MAPS: Record<PackageCardSize, string> = {
  FULL: '144px',
  BLOCK_SMALL: '144px',
  COLLECTION: '176px',
  BLOCK_LARGE: '208px',
};

type TitleCardLog =
  | StoreNewListTitleCardLog
  | StoreTitleListTitleCardLog
  | StoreRankingListTitleCardLog
  | StoreTitleListDetailTitleListTitleCardLog
  | SearchResultPackageTitleListTitleCardLog
  | SearchResultChapterTitleListTitleCardLog;

export type PackageCardProps = CommonMediaLightProps & {
  isSvod?: boolean;
  price: number;
  multiPrice?: boolean;
  ranking?: number;
  cardSize?: PackageCardSize;
  kafkaProps?:
    | KafkaPropsWithBaseSchema<TitleCardLog>
    | ComponentProps<typeof OptionsSheet>['kafkaProps'];
  featureBadges?: string[];
};

const PackageCard: React.FC<PackageCardProps> = ({
  id,
  description,
  imgSrc,
  imgAlt,
  isSvod,
  price,
  multiPrice,
  isNew,
  ranking,
  likes,
  tags = [],
  cardSize = 'FULL',
  edit,
  isFavorite = false,
  kafkaProps,
  featureBadges,
}) => {
  const router = useRouter();
  const [isOptionsShowing, setIsOptionsShowing] = useState(false);
  const [isAddToMyListClicked, setIsAddToMyListClicked] = useState(false);

  const { client } = useContext(KafkaContext);

  const { setFavorite } = useBeemiSetFavorite({
    type: 'package',
    refetchQueries: ['getFavoriteList'],
  });

  const handleOnClickCard = useCallback(() => {
    if (kafkaProps) {
      const { targetPrefix, base_schema, ...rest } = kafkaProps;

      /**
       * xz: we are loose with the typing here because this is kinda a mega action
       * but as of 2022/10/04, i've checked that the base_schema is always consistent down the line
       * e.g. if one uses `user_click_dimension_2_default`. then both `-titleCard` and `OptionsSheet` does as well
       * well except for `showAll` log that uses `user_click_dimension_0_default` and `user_click_dimension_1_default`
       * ¯\_(ツ)_/¯
       * but its handled, so its fine.
       * fyi its here if you want to see it packages/beemi-web/src/shared/components/Block/index.tsx
       */
      client?.trackUser(
        (targetPrefix + '-titleCard') as TitleCardLog['event']['target'],
        'click',
        base_schema,
        {
          ...rest,
        }
      );
    }
  }, [client, kafkaProps]);

  const onClickMyListButton = () => {
    setFavorite({
      resourceId: id,
      isFavorite: !isFavorite,
    });
    setIsOptionsShowing(false);
  };

  const slicedTags =
    tags.length > 0 &&
    tags[0].length >= FIRST_TAG_THRESHOLD_LENGTH_MAPS[cardSize]
      ? tags.slice(0, 1)
      : tags.slice(0, 2);

  const optionRef = useRef<HTMLDivElement>(null);

  const RANKING_WIDTH: Record<number, string> = {
    1: 'w-11',
    2: 'w-17',
    3: 'w-24',
  };

  const isVR = featureBadges?.includes('VR') ?? false;

  return (
    <article className={classNames(CARD_SIZE_MAPS[cardSize], 'flex flex-col')}>
      <div
        className={classNames(
          'overflow-hidden rounded-md shadow-sm aspect-w-10 aspect-h-14',
          !edit &&
            'transition-transform duration-100 hover:scale-105 will-change-transform'
        )}
      >
        {edit && <EditCard id={id} />}
        <Link
          href={{
            pathname: router.pathname,
            query: {
              ...router.query,
              pid: id,
            },
          }}
          scroll={false}
          onClick={handleOnClickCard}
          data-testid="PackageCard"
        >
          <BeemiImage
            className="object-cover w-full h-full"
            src={imgSrc}
            alt={imgAlt}
            sizeConfig={IMAGE_SIZE_MAPS[cardSize]}
          />
          {isVR && <VRBadge />}
          {ranking && (
            <div className="w-full h-full">
              <div
                className={classNames(
                  'absolute bottom-0 right-0 flex items-center justify-center h-12 rounded-tl-md rounded-br-md bg-purple/10 backdrop-filter backdrop-blur-sm',
                  RANKING_WIDTH[ranking.toString().length]
                )}
              >
                <div className="font-mono font-semibold text-white text-7xl ">
                  {ranking}
                </div>
              </div>
            </div>
          )}
        </Link>
      </div>
      <div className="flex flex-col flex-grow w-full mt-3">
        <div className="flex-grow">
          <Link
            className="block text-left text-caption text-purple/80 line-clamp-2"
            href={{
              pathname: router.pathname,
              query: {
                ...router.query,
                pid: id,
              },
            }}
            scroll={false}
            onClick={handleOnClickCard}
          >
            {description}
          </Link>
          {isSvod && (
            <div className="flex mt-1">
              <Badge badge="SVOD" />
            </div>
          )}
        </div>
        <div className="flex mt-1 space-x-1">
          {typeof price === 'number' && (
            <Badge badge="PRICE" price={price} multiPrice={multiPrice} />
          )}
          {isNew && <Badge badge="NEW" />}
        </div>
        <div className="flex items-center">
          <div className="flex flex-1 items-center h-4 -mx-0.5 overflow-hidden">
            {typeof likes === 'number' && (
              <div className="px-0.5">
                <Like likes={likes} />
              </div>
            )}
            <div className="flex px-0.5 overflow-hidden">
              <CardTag>{slicedTags.map((x) => `#${x}`).join(' ')}</CardTag>
            </div>
          </div>
          <div className="relative flex px-0.5" ref={optionRef}>
            <OptionButton
              onClick={() => {
                setIsOptionsShowing(true);
              }}
            />
            <OptionsSheet
              id={id}
              type="package"
              title={description}
              isShowing={isOptionsShowing}
              isFavorite={isFavorite}
              isSample
              anchor={optionRef.current}
              onClose={() => {
                setIsOptionsShowing(false);
              }}
              onCancel={() => {
                setIsOptionsShowing(false);
              }}
              onClickMyList={onClickMyListButton}
              kafkaProps={kafkaProps}
              isAddToMyListClicked={isAddToMyListClicked}
              setIsAddToMyListAdded={setIsAddToMyListClicked}
            />
          </div>
        </div>
      </div>
    </article>
  );
};

export default PackageCard;
