import { SearchResultLog } from '@/domain/log/__types__/searchResult';
import { NodeFilter } from '@/shared/generated';

type SearchTagList = {
  tag_id: string;
  content_type: SearchResultLog['event']['detail']['search_tag_list'][0]['content_type'];
};

/**
 * helper function to map tags to data science kafka log xxx_tag_list schema
 */
export function mapTagsToKafkaTagList(tags: NodeFilter[]): SearchTagList[] {
  return tags.map((tag) => ({
    tag_id: tag.id,
    content_type: tag.contentType,
  }));
}
