/* eslint-disable @typescript-eslint/no-var-requires */
const defaultTheme = require('tailwindcss/defaultTheme');

/** @type {import('tailwindcss/types').Config} */
module.exports = {
  content: [
    './public/**/*.html',
    './static-app/{pages,components}/**/*.{js,jsx,ts,tsx}',
    './src/{pages,shared,domain}/**/*.{js,jsx,ts,tsx}',
    './.storybook/**/*.{js,jsx,ts,tsx}',
  ],
  theme: {
    fontSize: {
      '7xl': '3rem',
      '6xl': '2.5rem',
      '5xl': '2rem',
      '4xl': '1.75rem',
      '3xl': '1.69rem',
      '2xl': '1.5rem',
      xl: '1.313rem',
      lg: '1.125rem',
      md: '1rem',
      sm: '.875rem',
      xs: '.75rem',
      '2xs': '.688rem',
      '3xs': '.625rem',
      'xl-number': '1.25rem',
    },
    fontFamily: {
      sans: [
        '"Hiragino Sans"',
        '"Hiragino Kaku Gothic ProN"',
        '"Noto Sans JP"',
        '"Noto Sans CJK JP"',
        '"BIZ UDPGothic"',
        '"Meiryo"',
        '-apple-system',
        'BlinkMacSystemFont',
        'sans-serif',
        ...defaultTheme.fontFamily.sans,
      ],
      mono: [
        '"SF Pro Text"',
        '"Helvetica Neue"',
        '"Robot"',
        '"Segoe UI"',
        '"Arial"',
        'BlinkMacSystemFont',
        '-apple-system',
        'sans-serif',
        ...defaultTheme.fontFamily.mono,
      ],
    },
    extend: {
      screens: {
        /**
         * This will override Tailwind's default of 1536px
         */
        '2xl': '1440px',
      },
      spacing: {
        4.5: '1.125rem',
        12.5: '3.125rem',
        13: '3.25rem',
        17: '4.5rem',
        18: '4.5rem',
        19.5: '4.875rem',
        25: '6.25rem',
        30: '7.5rem',
        35: '8.75rem',
        38: '9.5rem',
        40: '10rem',
        42: '10.5rem',
        44: '11rem',
        45: '11.25rem',
        48: '12rem',
        50: '12.5rem',
        52: '13rem',
        60: '15rem',
        66: '16.5rem',
        70: '17.5rem',
        75: '18.75rem',
        84: '21rem',
        86: '21.5rem',
        90: '22.5rem',
        200: '50rem',
      },
      padding: {
        0.75: '0.1875rem',
        1.25: '0.3125rem',
      },
      aspectRatio: {
        7: '7',
        18: '18',
        20: '20',
        25: '25',
      },
      boxShadow: {
        mobile: '0px 2px 24px -4px rgba(43, 38, 71, 0.2)',
        desktop: '0px 4px 32px -8px rgba(43, 38, 71, 0.2)',
        xs: '0px 1px 8px rgba(43, 38, 71, 0.1)',
        sm: '0px 4px 16px -4px rgba(43, 38, 71, 0.2)',
        showAll: '-18px 0 7px -1px #ffffff',
      },
      backdropBlur: {
        xs: '2px',
      },
      lineHeight: {
        snug: '1.4',
      },
      width: {
        '1/8': '12.5%',
      },
      maxWidth: {
        '3xl': '50rem',
        60: '15rem',
        84: '21rem',
        120: '30rem',
        140: '35rem',
        144: '36rem',
        160: '40rem',
      },
      maxHeight: {
        76: '19rem',
        77: '19.25rem',
      },
      colors: {
        pink: '#FF5963',
        purple: '#2B2647',
        error: '#C61801',
        babystar_base: '#22222C',
      },
      inset: {
        4.5: '18px',
        13: '52px',
      },
      transitionDuration: {
        DEFAULT: '200ms',
      },
      transitionTimingFunction: {
        DEFAULT: 'ease-out',
      },
      zIndex: {
        75: 75,
        100: 100,
      },
      transitionProperty: {
        height: 'height',
      },
      letterSpacing: {
        2: '0.02em',
      },
      animation: {
        'spin-fast': 'spin 500ms linear infinite',
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/forms'),
    require('tailwind-scrollbar-hide'),
    require('@tailwindcss/line-clamp'),
  ],
};
