import { useEffect } from 'react';

/**
 * useClickOutside
 *
 * Simple hook to add an event listener to the body and allow a callback to
 * be triggered when clicking outside of the target ref
 *
 * @param ref Any HTML Element ref
 * @param callback Callback triggered when clicking outside of ref element
 */

interface Options {
  useEscKey?: boolean;
  useCapture?: boolean;
}

const useClickOutside = (
  ref: React.MutableRefObject<HTMLElement | HTMLDivElement | null>,
  callback: (e: MouseEvent | KeyboardEvent) => void,
  options?: Options
): void => {
  useEffect(() => {
    const handleBodyClick = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        callback(e);
      }
    };

    document.body.addEventListener(
      'click',
      handleBodyClick,
      options?.useCapture ?? false
    );

    return () => {
      document.body.removeEventListener(
        'click',
        handleBodyClick,
        options?.useCapture ?? false
      );
    };
  }, [callback, options?.useCapture, ref]);

  useEffect(() => {
    const handleEscPress = (e: KeyboardEvent) => {
      if (e.key == 'Escape') {
        callback(e);
      }
    };

    if (options?.useEscKey) {
      addEventListener('keydown', handleEscPress);
    }

    return () => removeEventListener('keydown', handleEscPress);
  }, [callback, options?.useEscKey]);
};

export default useClickOutside;
