import { LibuDomain, ResourceType } from '@/shared/generated';
import { BeemiResourceType } from '@/shared/types';
import { gql } from '@apollo/client';

export function addRatingInput({
  type,
  resourceId,
  score,
}: {
  type: BeemiResourceType;
  resourceId: string;
  score: 0 | 1; // xz: we can also provide -1 for thumbs down if it really gets implemented
}) {
  return {
    rating: {
      score,
      resource: {
        type:
          type === 'package'
            ? ResourceType.MediaLight
            : ResourceType.MediaChapterLight,
        id: resourceId,
      },
    },
    domain: LibuDomain.Beemi,
  };
}

export const ADD_RATING = gql`
  mutation addRating($input: AddLibuRatingInput!) {
    addRating(input: $input) {
      id
    }
  }
`;
