import { MutableRefObject, useEffect, useRef } from 'react';

const CODEC_SAMPLE_4K_LIST = [
  `video/mp4; codecs="hev1.1.6.L153.B0"`,
  `video/mp4; codecs="hvc1.1.6.L153.B0"`,
];

function useIsHevcSupportRef(): MutableRefObject<boolean> {
  const isHevcSupportRef = useRef(false);

  useEffect(() => {
    if (
      'MediaSource' in window &&
      CODEC_SAMPLE_4K_LIST.every((codecSample) =>
        MediaSource.isTypeSupported(codecSample)
      )
    ) {
      isHevcSupportRef.current = true;
    }
  }, []);

  return isHevcSupportRef;
}

export default useIsHevcSupportRef;
