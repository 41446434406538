const VRBadge = (): JSX.Element => {
  return (
    <div className="absolute flex justify-center w-16 lg:h-5 h-[18px] -rotate-45 top-1.5 -left-4 bg-purple items-center">
      <p className="font-mono font-black uppercase lg:text-lg text-white/80">
        vr
      </p>
    </div>
  );
};

export default VRBadge;
