import getCookieDomain from '@/shared/utils/getCookieDomain';
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ApolloLinks, ITokenHolder } from '@u-next/unextjs-oauth';
import merge from 'just-merge';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { parseCookies } from 'nookies';

let apolloClient: ApolloClient<NormalizedCacheObject>;

class TokenHolder implements ITokenHolder {
  private tokenValue: string | undefined;
  set(newToken: string): void {
    this.tokenValue = newToken;
  }
  get(): string | undefined {
    return this.tokenValue;
  }
}

function createApolloClient(ctx?: GetServerSidePropsContext | NextPageContext) {
  const isBrowser = typeof window !== 'undefined';
  const cookies =
    !isBrowser && ctx?.req ? parseCookies({ req: ctx.req }) : undefined;

  const accessTokenHolder = new TokenHolder();
  if (cookies?._at) {
    accessTokenHolder.set(cookies._at);
  }

  const apiEndpoint = isBrowser
    ? process.env.NEXT_PUBLIC_ENTERPRISE_SERVER
    : process.env.ENTERPRISE_SERVER;

  const cache = isBrowser
    ? new InMemoryCache({
        typePolicies: {
          // Customize the cache id to avoid cache conflicts.
          MediaChapterLightMeta: {
            keyFields: ['id', 'thumbnails'],
          },
          MediaLightMeta: {
            keyFields: ['id', 'thumbnails'],
          },
          LibuFavoriteList: {
            keyFields: ['id', 'title'],
          },
          RevenueRankingBlock: {
            keyFields: false,
          },
        },
      })
    : // disable cache normalization on SSR, so that SSR won't fails(rendering page with no data)
      // even if cache confliction happens
      new InMemoryCache({
        dataIdFromObject: () => false,
      });

  return new ApolloClient({
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser,
    cache,
    link: from([
      ApolloLinks.createRetryLink({
        delay: {
          initial: 300,
          max: Infinity,
          jitter: true,
        },
        attempts: {
          max: 5,
          retryIf: (error, _operation) => {
            if (
              !!error &&
              !_operation.query.definitions.some(
                (node) =>
                  node.kind === 'OperationDefinition' &&
                  node.operation === 'mutation'
              )
            ) {
              return true;
            }
            return false;
          },
        },
      }),
      onError(
        ApolloLinks.createErrorLinkWithOAuthRefresh(
          isBrowser,
          accessTokenHolder,
          !isBrowser
            ? {
                baseUrl: process.env.NEXT_PUBLIC_OAUTH_SERVER || '',
                clientId: 'unext',
                clientSecret: process.env.OAUTH_CLIENT_SECRET ?? '',
                cookieDomain: getCookieDomain(
                  new URL(process.env.NEXT_PUBLIC_PUBLIC_URL ?? '').hostname
                ),
                cookiePath: '/',
                cookieTTLSeconds: Number(
                  process.env.NEXT_PUBLIC_OAUTH_COOKIE_TTL
                ),
                refreshToken: cookies?._rt,
              }
            : undefined,
          ctx?.res
        )
      ),
      ApolloLinks.createAuthLink(isBrowser, accessTokenHolder),
      new HttpLink({
        uri: apiEndpoint,
        headers: ctx?.req
          ? {
              'user-agent': ctx?.req.headers['user-agent'],
            }
          : {},
      }),
    ]),
  });
}

export function initializeApollo(
  initialState?: NormalizedCacheObject,
  ctx?: GetServerSidePropsContext | NextPageContext
): ApolloClient<NormalizedCacheObject> {
  const _apolloClient = apolloClient ?? createApolloClient(ctx);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache) as typeof initialState &
      typeof existingCache;

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}
