import React, { ButtonHTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import { globalMessages } from '../../../constants/messages';
import BeemiLink, { BeemiLinkProps } from '../../BeemiLink';
import Icon from '../../Icon';

interface ButtonVariantProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: never;
}

interface BeemiLinkVariantProps extends BeemiLinkProps {
  href: BeemiLinkProps['href'];
}

const isBeemiLink = (
  props: ShowMoreButtonProps
): props is BeemiLinkVariantProps => !!props.href;

export type ShowMoreButtonProps = ButtonVariantProps | BeemiLinkVariantProps;

const ShowMoreButton: React.FC<ShowMoreButtonProps> = (props) => {
  const intl = useIntl();

  return isBeemiLink(props) ? (
    <BeemiLink
      {...props}
      className="flex items-center text-caption md:text-label text-purple/60"
    >
      <span>{intl.formatMessage(globalMessages.showMore)}</span>
      <span className="w-5 h-5 md:w-6 md:h-6">
        <Icon name="HALF_ARROW_RIGHT" size="FULL" />
      </span>
    </BeemiLink>
  ) : (
    <button
      {...props}
      className="flex items-center text-caption md:text-label text-purple/60"
    >
      <span>{intl.formatMessage(globalMessages.showMore)}</span>
      <span className="w-5 h-5 md:w-6 md:h-6">
        <Icon name="HALF_ARROW_RIGHT" size="FULL" />
      </span>
    </button>
  );
};

export default ShowMoreButton;
