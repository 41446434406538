import { createContext } from 'react';

interface ContextProps {
  setNotFound: () => void;
}

export const NotFoundContext = createContext<ContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNotFound: () => {},
});
