import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import UserAgentContext from '@/shared/UserAgentContext';
import { useContext } from 'react';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
};

export enum AccountTypeCode {
  Main = 'MAIN',
  Sub = 'SUB',
}

export type AddLibuFavoriteListInput = {
  domain: LibuDomain;
  favoriteList: LibuFavoriteListInput;
};

export type AddLibuPlaylistInput = {
  domain: LibuDomain;
  playlist: LibuPlaylistInput;
};

export type AddLibuPurchaseHistoryItem = {
  amount: Scalars['Int'];
  amountIncludingTax: Scalars['Int'];
  billedAmount: Scalars['Int'];
  detail?: InputMaybe<PurchaseHistoryDetail>;
  purchaseDatetime: Scalars['DateTime'];
  resource: LibuResourceInput;
  supplement?: InputMaybe<Scalars['String']>;
  usedPartnerPoint?: InputMaybe<Scalars['Int']>;
  usedUCoin?: InputMaybe<Scalars['Int']>;
  usedUNextPoint?: InputMaybe<Scalars['Int']>;
};

export type AddLibuPurchaseHistoryItemInput = {
  domain: LibuDomain;
  item: AddLibuPurchaseHistoryItem;
};

export type AddLibuRatingInput = {
  domain: LibuDomain;
  rating: LibuRatingInput;
};

/** Input to be registered in the viewing history. */
export type AddLibuViewHistoryInput = {
  domain: LibuDomain;
  item: LibuViewHistoryInput;
};

export type AddResult = {
  __typename?: 'AddResult';
  createTime: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type Alias = {
  __typename?: 'Alias';
  cmsBookAliasXid?: Maybe<Scalars['String']>;
  cmsVideoAliasXid?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nameJa: Scalars['String'];
  nameKana: Scalars['String'];
  nameKanaNormalized: Scalars['String'];
  nameNormalizedJa: Scalars['String'];
  paginatedChapterCredits: PaginatedAliasChapterCredit;
  paginatedChapterPerformances: PaginatedAliasChapterPerformance;
  paginatedMediaCredits: PaginatedAliasCredit;
  paginatedMediaPerformances: PaginatedAliasPerformance;
};

export type AliasPaginatedChapterCreditsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type AliasPaginatedChapterPerformancesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type AliasPaginatedMediaCreditsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type AliasPaginatedMediaPerformancesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type AliasChapterCredit = {
  __typename?: 'AliasChapterCredit';
  chapter: MediaChapterLight;
  type?: Maybe<Scalars['String']>;
};

export type AliasChapterPerformance = {
  __typename?: 'AliasChapterPerformance';
  chapter: MediaChapterLight;
  characterName?: Maybe<Scalars['String']>;
};

export type AliasCredit = {
  __typename?: 'AliasCredit';
  media: MediaLight;
  type?: Maybe<Scalars['String']>;
};

export type AliasLight = {
  __typename?: 'AliasLight';
  id: Scalars['ID'];
  nameJa: Scalars['String'];
  nameKana: Scalars['String'];
  nameKanaNormalized: Scalars['String'];
  nameNormalizedJa: Scalars['String'];
};

export type AliasPerformance = {
  __typename?: 'AliasPerformance';
  characterName?: Maybe<Scalars['String']>;
  media: MediaLight;
};

export type AliasSearchHits = SearchHits & {
  __typename?: 'AliasSearchHits';
  hasMore: Scalars['Boolean'];
  hits: Array<AliasLight>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export enum AudioChannel {
  A2_1 = 'A2_1',
  A5_1 = 'A5_1',
  DolbyAtmos = 'DOLBY_ATMOS',
  Unspecified = 'UNSPECIFIED',
}

export enum AudioCodec {
  Aaclc = 'AACLC',
  Eac3 = 'EAC3',
  Eac3Joc = 'EAC3JOC',
  Flac = 'FLAC',
  Heaacv2 = 'HEAACV2',
  Unspecified = 'UNSPECIFIED',
}

export enum AudioCodecType {
  Aac = 'AAC',
  DolbyDigital = 'DOLBY_DIGITAL',
  DolbyDigitalAtmos = 'DOLBY_DIGITAL_ATMOS',
  DolbyDigitalPlus = 'DOLBY_DIGITAL_PLUS',
}

/** AudioProfile is a audio format set for video */
export type AudioProfile = {
  __typename?: 'AudioProfile';
  codec: AudioCodec;
};

export type AudioType = {
  __typename?: 'AudioType';
  audioType?: Maybe<Scalars['String']>;
};

export type AutoCompleteResponse = {
  __typename?: 'AutoCompleteResponse';
  nodes?: Maybe<Array<SearchSuggestionNode>>;
};

export type BatchAddResult = {
  __typename?: 'BatchAddResult';
  added: Scalars['Int'];
};

export type BeemiInfoListItem = {
  __typename?: 'BeemiInfoListItem';
  title: Scalars['String'];
  ttl: Scalars['String'];
  type?: Maybe<BeemiInfoListItemType>;
  url: Scalars['String'];
};

export enum BeemiInfoListItemType {
  Info = 'INFO',
  Warning = 'WARNING',
}

/** Profile input for BEEMI. */
export type BeemiProfileInput = {
  /** whether show view history or not. */
  showViewHistory?: InputMaybe<Scalars['Boolean']>;
};

/** User profile for "BEEMI" */
export type BeemiUserProfile = {
  __typename?: 'BeemiUserProfile';
  /**
   * use this to indicate which domain this profile belongs to.
   * it is necessary when resolving the union type of LibuUserProfile.
   */
  domain: LibuDomain;
  showViewHistory?: Maybe<Scalars['Boolean']>;
};

export type BirthdayUpdateResponse = {
  __typename?: 'BirthdayUpdateResponse';
  updated: Scalars['Boolean'];
};

export type BlockInfo = {
  __typename?: 'BlockInfo';
  isBlocked?: Maybe<Scalars['Boolean']>;
  /** Indicates the blocking level of this account which is blocked (isBlocked : true) */
  score?: Maybe<Scalars['Int']>;
};

export enum BlockType {
  BFeature = 'B_FEATURE',
  BFree = 'B_FREE',
  BHistory = 'B_HISTORY',
  BLeanback = 'B_LEANBACK',
  BNew = 'B_NEW',
  BPreorder = 'B_PREORDER',
  BSptlight = 'B_SPTLIGHT',
  BUnextpub = 'B_UNEXTPUB',
  FTemplate = 'F_TEMPLATE',
  Magazine = 'MAGAZINE',
  OrigComic = 'ORIG_COMIC',
  Ranking = 'RANKING',
  RecUsrB = 'REC_USR_B',
  SearchCat = 'SEARCH_CAT',
}

export type BonusMaterial = {
  __typename?: 'BonusMaterial';
  bonusMaterialNoDisplayName?: Maybe<Scalars['String']>;
  cmsXid: Scalars['String'];
  id: Scalars['ID'];
  introduction: Scalars['String'];
  nameJa: Scalars['String'];
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for BonusMaterial.
   */
  thumbnails: Array<ThumbnailSet>;
};

export type Book = {
  __typename?: 'Book';
  blacklist: Array<Platform>;
  bookContent?: Maybe<BookContent>;
  bookMagazine?: Maybe<BookMagazine>;
  bookNo: Scalars['Int'];
  code: Scalars['ID'];
  credits: Array<BookCredit>;
  hasRights?: Maybe<Scalars['Boolean']>;
  includedBook?: Maybe<IncludedBook>;
  introduction?: Maybe<Scalars['String']>;
  isFree?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  isPreorderable?: Maybe<Scalars['Boolean']>;
  isPreordered?: Maybe<Scalars['Boolean']>;
  isPurchased?: Maybe<Scalars['Boolean']>;
  isSale?: Maybe<Scalars['Boolean']>;
  mediaType: BookMediaType;
  name: Scalars['String'];
  /** The date when the book started to be sold */
  originalSaleDate?: Maybe<Scalars['DateTime']>;
  paymentBadge?: Maybe<PaymentBadge>;
  publicEndDateTime?: Maybe<Scalars['DateTime']>;
  /** The time when the book was published on U-NEXT */
  publicStartDateTime?: Maybe<Scalars['DateTime']>;
  publishedIn: Array<Magazine>;
  publisher?: Maybe<Publisher>;
  ratingCode: Scalars['String'];
  rightsExpirationDatetime?: Maybe<Scalars['DateTime']>;
  saleEndDateTime?: Maybe<Scalars['DateTime']>;
  saleStartDateTime?: Maybe<Scalars['DateTime']>;
  saleText?: Maybe<Scalars['String']>;
  thumbnail: Thumbnail;
};

export type BookBlock = {
  __typename?: 'BookBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID'];
  results: Scalars['Int'];
  type: BlockType;
};

export type BookCategoryListResponse = {
  __typename?: 'BookCategoryListResponse';
  books: Array<BookSakuhin>;
  menuCode: Scalars['String'];
  pageInfo: PortalPageInfo;
};

export type BookCategoryResponse = {
  __typename?: 'BookCategoryResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
};

export type BookContent = {
  __typename?: 'BookContent';
  mainBookFile?: Maybe<BookFile>;
  productList: Array<BookProduct>;
  sampleBookFile?: Maybe<BookFile>;
};

export type BookCredit = {
  __typename?: 'BookCredit';
  bookAuthorType?: Maybe<Scalars['String']>;
  penName?: Maybe<Scalars['String']>;
  penNameCode?: Maybe<Scalars['String']>;
  penNameKana?: Maybe<Scalars['String']>;
  personCode?: Maybe<Scalars['String']>;
  unextPublishingDetail?: Maybe<UnextPublishingDetail>;
};

export type BookDeviceRegistrationInfo = {
  __typename?: 'BookDeviceRegistrationInfo';
  device: Scalars['String'];
  displayName: Scalars['String'];
};

export type BookFeature = {
  __typename?: 'BookFeature';
  books: Array<BookSakuhin>;
  code: Scalars['ID'];
  comment: Scalars['String'];
  displayEnd: Scalars['DateTime'];
  name: Scalars['String'];
  results: Scalars['Int'];
  typeCode: Scalars['String'];
};

export type BookFeatureBlock = {
  __typename?: 'BookFeatureBlock';
  code: Scalars['ID'];
  feature: BookFeature;
  type: BlockType;
};

export type BookFeatureItem = {
  __typename?: 'BookFeatureItem';
  books: Array<BookSakuhin>;
  code: Scalars['ID'];
  comment: Scalars['String'];
  displayEnd: Scalars['DateTime'];
  name: Scalars['String'];
  typeCode: BookFeatureTypeCode;
};

export type BookFeatureResponse = {
  __typename?: 'BookFeatureResponse';
  feature: BookFeatureItem;
  pageInfo: PortalPageInfo;
};

export enum BookFeatureTypeCode {
  Campaign = 'CAMPAIGN',
  Feature = 'FEATURE',
}

export type BookFile = {
  __typename?: 'BookFile';
  code: Scalars['ID'];
  completeFlg: Scalars['Boolean'];
  licenseExpiredDateTime?: Maybe<Scalars['DateTime']>;
  resumePoint: Scalars['Float'];
  updateDateTime: Scalars['String'];
};

export type BookFreeResponse = {
  __typename?: 'BookFreeResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
};

export type BookFreewordSearchResponse = {
  __typename?: 'BookFreewordSearchResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
};

export type BookHistoryRemovalResponse = {
  __typename?: 'BookHistoryRemovalResponse';
  bookSakuhinCodes: Array<Scalars['ID']>;
  removedAll: Scalars['Boolean'];
};

export type BookHistoryResponse = {
  __typename?: 'BookHistoryResponse';
  books: Array<BookSakuhin>;
  pageInfoHash: PageInfoHash;
};

export type BookLabel = {
  __typename?: 'BookLabel';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type BookLeanbackBlock = {
  __typename?: 'BookLeanbackBlock';
  code: Scalars['ID'];
  leanbackList: Array<LeanbackGenre>;
  type: BlockType;
};

export type BookMagazine = {
  __typename?: 'BookMagazine';
  bookMagazineCode: Scalars['ID'];
  bookMagazineName?: Maybe<Scalars['String']>;
  magazineGenreCode?: Maybe<Scalars['String']>;
};

export type BookMediaType = {
  __typename?: 'BookMediaType';
  code: BookMediaTypeCode;
  name: Scalars['String'];
};

export enum BookMediaTypeCode {
  Book = 'BOOK',
  Comic = 'COMIC',
  Magazine = 'MAGAZINE',
  PhotoBook = 'PHOTO_BOOK',
  Picbook = 'PICBOOK',
  Ranobe = 'RANOBE',
}

export type BookMenu = {
  __typename?: 'BookMenu';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type BookMetaSearchResponse = {
  __typename?: 'BookMetaSearchResponse';
  books: Array<BookSakuhin>;
  name: Scalars['String'];
  pageInfo: PortalPageInfo;
};

export enum BookMetaType {
  Label = 'LABEL',
  PublishedIn = 'PUBLISHED_IN',
  Publisher = 'PUBLISHER',
}

export type BookNewArrivalsResponse = {
  __typename?: 'BookNewArrivalsResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
};

export type BookOriginalBlock = {
  __typename?: 'BookOriginalBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID'];
  lead: Scalars['String'];
  leadTitle: Scalars['String'];
  results: Scalars['Int'];
  type: BlockType;
};

export type BookOriginalTitlesResponse = {
  __typename?: 'BookOriginalTitlesResponse';
  books: Array<BookSakuhin>;
  leadText: Scalars['String'];
  leadTitle: Scalars['String'];
  pageInfo: PortalPageInfo;
};

export type BookPersonListResponse = {
  __typename?: 'BookPersonListResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
  personCode: Scalars['String'];
};

export type BookPersonResponse = {
  __typename?: 'BookPersonResponse';
  books: Array<BookSakuhin>;
  /** Returns the the person's name if personNameCode, which personNameCode and penNameCode can be set, is provided. */
  name?: Maybe<Scalars['String']>;
  pageInfo: PortalPageInfo;
};

export type BookPlaylistUrl = {
  __typename?: 'BookPlaylistUrl';
  epub?: Maybe<Epub>;
  ubooks: Array<UBook>;
  version?: Maybe<Scalars['Int']>;
};

export type BookPlaylistUrlResponse = {
  __typename?: 'BookPlaylistUrlResponse';
  bookFileCode: Scalars['String'];
  bookFileTypeCode: Scalars['String'];
  completeFlg: Scalars['Boolean'];
  dictionaryWordNameList: Array<Scalars['String']>;
  kanaSyllabaryText: Scalars['String'];
  licenseUrl: Scalars['String'];
  penNameKana: Scalars['String'];
  playToken: Scalars['String'];
  playTokenHash: Scalars['String'];
  playlistBaseUrl: Scalars['String'];
  playlistUrl: BookPlaylistUrl;
  productCode: Scalars['String'];
  resumePoint: Scalars['Float'];
  rightsExpirationDatetime?: Maybe<Scalars['DateTime']>;
  saleTypeCode: Scalars['String'];
  webtoonFlg: Scalars['Boolean'];
};

export type BookPostPlayEmptyResponse = {
  __typename?: 'BookPostPlayEmptyResponse';
  type: BookPostPlayResultType;
};

export type BookPostPlayNextBookResult = {
  __typename?: 'BookPostPlayNextBookResult';
  nextBook: BookSakuhin;
  type: BookPostPlayResultType;
};

export type BookPostPlayRecommendResponse = {
  __typename?: 'BookPostPlayRecommendResponse';
  recommend: Array<BookSakuhin>;
  type: BookPostPlayResultType;
};

export type BookPostPlayResult =
  | BookPostPlayEmptyResponse
  | BookPostPlayNextBookResult
  | BookPostPlayRecommendResponse;

export enum BookPostPlayResultType {
  NextBook = 'NEXT_BOOK',
  None = 'NONE',
  Recommend = 'RECOMMEND',
}

export type BookPreorderResponse = {
  __typename?: 'BookPreorderResponse';
  set: Scalars['Boolean'];
};

export type BookPreorderableResponse = {
  __typename?: 'BookPreorderableResponse';
  books: Array<BookSakuhin>;
  pageInfo: PortalPageInfo;
};

export type BookProduct = {
  __typename?: 'BookProduct';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  salePeriod?: Maybe<SalePeriod>;
  saleTypeCode?: Maybe<Scalars['String']>;
};

export type BookProductItem = {
  __typename?: 'BookProductItem';
  bookCode: Scalars['ID'];
  bookCodeList: Array<Scalars['ID']>;
  code: Scalars['ID'];
  discountRate: Scalars['String'];
  isPoint: Scalars['Boolean'];
  isSale: Scalars['Boolean'];
  licensedDeviceCodeList: Array<Scalars['String']>;
  name: Scalars['String'];
  productTypeCode: Scalars['String'];
  recentLicenseExpiredDateTime?: Maybe<Scalars['DateTime']>;
  saleEndDateTime: Scalars['DateTime'];
  saleStartDateTime: Scalars['DateTime'];
  saleTypeCode: Scalars['String'];
  sellingPrice: Scalars['Int'];
  sourcePrice: Scalars['Int'];
};

export type BookProductList = {
  __typename?: 'BookProductList';
  hasReadingHistory: Scalars['Boolean'];
  isWaitingForRecoveryTicket: Scalars['Boolean'];
  products: Array<BookProductItem>;
};

export type BookProductPurchaseResponse = {
  __typename?: 'BookProductPurchaseResponse';
  product?: Maybe<BookPurchasedProductItem>;
};

export type BookPublishingDetailThumbnail = {
  __typename?: 'BookPublishingDetailThumbnail';
  icon?: Maybe<Scalars['String']>;
  standard?: Maybe<Scalars['String']>;
};

export type BookPurchaseRemoveResponse = {
  __typename?: 'BookPurchaseRemoveResponse';
  bookSakuhinCodes: Array<Scalars['ID']>;
};

export type BookPurchaseWithTicketResponseModel = {
  __typename?: 'BookPurchaseWithTicketResponseModel';
  bookTicketCode: Scalars['String'];
  bookTicketTypeCode: Scalars['String'];
  bookTicketTypeName: Scalars['String'];
  useTicketCount: Scalars['Int'];
};

export type BookPurchasedProductItem = {
  __typename?: 'BookPurchasedProductItem';
  code: Scalars['ID'];
  discountRate?: Maybe<Scalars['String']>;
  isSale: Scalars['Boolean'];
  name: Scalars['String'];
  originalPrice: Scalars['Int'];
  price: Scalars['Int'];
  publicEndDate: Scalars['DateTime'];
  publicStartDate: Scalars['DateTime'];
  segment: Scalars['String'];
};

export type BookRanking = {
  __typename?: 'BookRanking';
  bookCode: Scalars['ID'];
  isNewRanking: Scalars['Boolean'];
  rankDiff: Scalars['Int'];
};

export type BookRankingBlock = {
  __typename?: 'BookRankingBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID'];
  comment: Scalars['String'];
  heading: Scalars['String'];
  rankings: Array<BookRanking>;
  results: Scalars['Int'];
  targetCode: Scalars['String'];
  type: BlockType;
};

export type BookRankingResponse = {
  __typename?: 'BookRankingResponse';
  books: Array<BookRankingSakuhin>;
  pageInfo: PortalPageInfo;
  rankingComment: Scalars['String'];
  rankingHeading: Scalars['String'];
  rankingTargetCode: BookRankingTargetCode;
};

export type BookRankingSakuhin = {
  __typename?: 'BookRankingSakuhin';
  bookSakuhin: BookSakuhin;
  isNewRanking: Scalars['Boolean'];
  rankDiff: Scalars['Int'];
};

export enum BookRankingTargetCode {
  DBook = 'D_BOOK',
  DComic = 'D_COMIC',
  DRanobe = 'D_RANOBE',
}

export type BookRecommendBlock =
  | BookBlock
  | BookFeatureBlock
  | BookLeanbackBlock
  | BookOriginalBlock
  | BookRankingBlock
  | BookSearchCategoryBlock
  | BookSpotlightBlock
  | BookTemplateBlock
  | BookUserRecommendedBlock
  | MagazineBlock
  | OriginalComicBlock;

export type BookRecommendBlockResponse = {
  __typename?: 'BookRecommendBlockResponse';
  pageInfo: PortalPageInfo;
  recommendBlocks: Array<BookRecommendBlock>;
};

export type BookResumePointUpdateResponse = {
  __typename?: 'BookResumePointUpdateResponse';
  bookFileCode: Scalars['String'];
  resumePoint: Scalars['Float'];
};

export type BookSakuhin = {
  __typename?: 'BookSakuhin';
  blacklist: Array<Platform>;
  book?: Maybe<Book>;
  bookLabel?: Maybe<BookLabel>;
  bookNumForTicket: Scalars['Int'];
  bookTickets?: Maybe<BookTickets>;
  bookViewCode: Scalars['String'];
  books?: Maybe<BooksResponse>;
  code: Scalars['ID'];
  detail: BookSakuhinDetail;
  dictionaryWordNameList: Array<Scalars['String']>;
  featurePieceCode?: Maybe<Scalars['String']>;
  freeBookNum?: Maybe<Scalars['Int']>;
  fromBookNum?: Maybe<Scalars['Int']>;
  hasChapter: Scalars['Boolean'];
  hasMultiplePrices?: Maybe<Scalars['Boolean']>;
  isBlacklist?: Maybe<Scalars['Boolean']>;
  isBookReserved?: Maybe<Scalars['Boolean']>;
  isCompleted: Scalars['Boolean'];
  isFavorite?: Maybe<Scalars['Boolean']>;
  isNew?: Maybe<Scalars['Boolean']>;
  isPointAvailable?: Maybe<Scalars['Boolean']>;
  isRecentSale?: Maybe<Scalars['Boolean']>;
  isSale?: Maybe<Scalars['Boolean']>;
  isUnextOriginal?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use isUnextOriginal */
  isUnextPublishing?: Maybe<Scalars['Boolean']>;
  kanaSyllabaryText?: Maybe<Scalars['String']>;
  menuList: Array<BookMenu>;
  minPrice?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  nextReleaseDatetime?: Maybe<Scalars['DateTime']>;
  otherOriginalBooks?: Maybe<BookOriginalTitlesResponse>;
  paymentBadgeList: Array<PaymentBadge>;
  purchaseBookNum?: Maybe<Scalars['Int']>;
  /** Will only be returned by the webfront_purchasedBooks query */
  purchasedBooks: Array<PurchasedBook>;
  rate: Scalars['Float'];
  recentLicenseExpiredDatetime?: Maybe<Scalars['DateTime']>;
  recentPublicEndDateTime?: Maybe<Scalars['DateTime']>;
  relatedBooks?: Maybe<RelatedBooksResponse>;
  relatedTitles?: Maybe<RelatedVideosResponse>;
  subgenreTagList: Array<SubgenreTag>;
  thumbnail: BookSakuhinThumbnails;
  ticketEndDatetime?: Maybe<Scalars['DateTime']>;
  toBookNum?: Maybe<Scalars['Int']>;
  totalBookNum: Scalars['Int'];
  totalBookNumIncludingPreorders?: Maybe<Scalars['Int']>;
  userRate?: Maybe<Scalars['Float']>;
};

export type BookSakuhinBooksArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type BookSakuhinRelatedBooksArgs = {
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type BookSakuhinRelatedTitlesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type BookSakuhinDetail = {
  __typename?: 'BookSakuhinDetail';
  catchSentence: Scalars['String'];
  introduction: Scalars['String'];
};

export type BookSakuhinThumbnails = {
  __typename?: 'BookSakuhinThumbnails';
  secondary: Scalars['String'];
  standard: Scalars['String'];
};

/** FREE corresponds to FOD_OR_POD in portal response */
export enum BookSaleType {
  Free = 'FREE',
}

export type BookSearchCategory = {
  __typename?: 'BookSearchCategory';
  code: Scalars['ID'];
  defaultSortOrder: PortalSortOrder;
  name: Scalars['String'];
  typeCode: Scalars['String'];
};

export type BookSearchCategoryBlock = {
  __typename?: 'BookSearchCategoryBlock';
  code: Scalars['ID'];
  menuList: Array<BookSearchCategory>;
  type: BlockType;
};

export type BookSpotLight = {
  __typename?: 'BookSpotLight';
  code: Scalars['ID'];
  feature: BookSpotLightFeature;
  featureTemplate: BookSpotLightFeatureTemplate;
  linkTypeCode: Scalars['String'];
  name: Scalars['String'];
  publicEndDateTime?: Maybe<Scalars['DateTime']>;
  publicStartDateTime?: Maybe<Scalars['DateTime']>;
  sakuhin: BookSpotLightSakuhin;
  thumbnail: Thumbnail;
};

export type BookSpotLightFeature = {
  __typename?: 'BookSpotLightFeature';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type BookSpotLightFeatureTemplate = {
  __typename?: 'BookSpotLightFeatureTemplate';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type BookSpotLightSakuhin = {
  __typename?: 'BookSpotLightSakuhin';
  code: Scalars['ID'];
  name: Scalars['String'];
};

export type BookSpotlightBlock = {
  __typename?: 'BookSpotlightBlock';
  code: Scalars['ID'];
  spotlightList: Array<BookSpotLight>;
  type: BlockType;
};

export type BookTemplateBlock = {
  __typename?: 'BookTemplateBlock';
  code: Scalars['ID'];
  template: FeatureTemplate;
  type: BlockType;
};

export type BookTicket = {
  __typename?: 'BookTicket';
  availableCount: Scalars['Int'];
  code: Scalars['ID'];
  nextDeliveryCount?: Maybe<Scalars['Int']>;
  nextDeliveryDatetime?: Maybe<Scalars['DateTime']>;
  receivedCount: Scalars['Int'];
  typeCode: Scalars['String'];
  typeName: Scalars['String'];
};

export type BookTickets = {
  __typename?: 'BookTickets';
  plusTicket?: Maybe<BookTicket>;
  sakuhinTicket?: Maybe<BookTicket>;
};

export type BookTopBlock =
  | BookRankingBlock
  | BookTopRecommendBlock
  | BookUserRecommendedBlock;

export type BookTopBlockResponse = {
  __typename?: 'BookTopBlockResponse';
  blocks?: Maybe<Array<Maybe<BookTopBlock>>>;
  results: Scalars['Int'];
};

export type BookTopLeanback = {
  __typename?: 'BookTopLeanback';
  displayCode: Scalars['String'];
  menuName: Scalars['String'];
  searchMenuCode: Scalars['String'];
  searchMenuName: Scalars['String'];
  searchMenuType: Scalars['String'];
  tagCode: Scalars['String'];
  thumbnailUrl: Scalars['String'];
};

export type BookTopLeanbackResponse = {
  __typename?: 'BookTopLeanbackResponse';
  list?: Maybe<Array<Maybe<BookTopLeanback>>>;
  results: Scalars['Int'];
};

export type BookTopRecommendBlock = {
  __typename?: 'BookTopRecommendBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID'];
  results: Scalars['Int'];
  type: BlockType;
};

export type BookUserRecommendedBlock = {
  __typename?: 'BookUserRecommendedBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID'];
  comment: Scalars['String'];
  heading: Scalars['String'];
  results: Scalars['Int'];
  type: BlockType;
};

export type BookUserRecommendedResponse = {
  __typename?: 'BookUserRecommendedResponse';
  books: Array<BookSakuhin>;
  comment: Scalars['String'];
  heading: Scalars['String'];
  results?: Maybe<Scalars['Int']>;
};

export type BooksRecommendResponse = {
  __typename?: 'BooksRecommendResponse';
  books: Array<BookSakuhin>;
  results: Scalars['Int'];
};

export type BooksResponse = {
  __typename?: 'BooksResponse';
  books: Array<Book>;
  pageInfo?: Maybe<PortalPageInfo>;
};

export type BrandGroupLight = {
  __typename?: 'BrandGroupLight';
  id: Scalars['ID'];
  nameJa: Scalars['String'];
};

export type BulkBookProductPurchaseResponse = {
  __typename?: 'BulkBookProductPurchaseResponse';
  products: Array<BookPurchasedProductItem>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export enum CapyPaymentBadgeCode {
  Buy = 'BUY',
  Point = 'POINT',
  Rental = 'RENTAL',
  Sell = 'SELL',
  Svod = 'SVOD',
}

export type CapyRecommendationBlock = MediaBlock & {
  __typename?: 'CapyRecommendationBlock';
  description?: Maybe<Scalars['String']>;
  displayEndDate: Scalars['DateTime'];
  displayStartDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  paginatedMedia: PaginatedMediaLight;
};

export enum CapySaleBadgeCode {
  Sale = 'SALE',
}

export enum CapySaleTypeCode {
  Est = 'EST',
  Fod = 'FOD',
  Pod = 'POD',
  Sod = 'SOD',
  Tod = 'TOD',
  Unspecified = 'UNSPECIFIED',
}

export enum CapyVideoCodec {
  Dvhe = 'DVHE',
  H264 = 'H264',
  H265 = 'H265',
  Unspecified = 'UNSPECIFIED',
}

export type Channel = {
  __typename?: 'Channel';
  alias: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  movieProfileList: Array<MovieProfile>;
  name: Scalars['String'];
  paymentBadgeList: Array<PaymentBadge>;
  thumbnail: Thumbnail;
};

export type ChapterList = {
  __typename?: 'ChapterList';
  chapters: Array<MediaChapterLight>;
};

export type Collection = {
  __typename?: 'Collection';
  collectionType: Scalars['String'];
  id: Scalars['ID'];
  nameJa: Scalars['String'];
  paginatedMedia: PaginatedMediaLight;
};

export type CollectionPaginatedMediaArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type CollectionLight = {
  __typename?: 'CollectionLight';
  collectionType: Scalars['String'];
  id: Scalars['ID'];
  nameJa: Scalars['String'];
};

export type ContentBlock =
  | CapyRecommendationBlock
  | MediaSpotlightBlock
  | NewMediaRankingBlock
  | PopularityRankingBlock
  | RevenueRankingBlock
  | SalesBlock;

export enum ContentBlockQueryMode {
  All = 'ALL',
  SvodOnly = 'SVOD_ONLY',
  TvodOnly = 'TVOD_ONLY',
}

export enum ContentBlockType {
  ContentBlockTypeCustom = 'CONTENT_BLOCK_TYPE_CUSTOM',
  ContentBlockTypeCustomCondition = 'CONTENT_BLOCK_TYPE_CUSTOM_CONDITION',
  ContentBlockTypeMediaSpotlight = 'CONTENT_BLOCK_TYPE_MEDIA_SPOTLIGHT',
  ContentBlockTypeNewMedia = 'CONTENT_BLOCK_TYPE_NEW_MEDIA',
  ContentBlockTypePopularity = 'CONTENT_BLOCK_TYPE_POPULARITY',
  ContentBlockTypePopularContentTags = 'CONTENT_BLOCK_TYPE_POPULAR_CONTENT_TAGS',
  ContentBlockTypeRecommendation = 'CONTENT_BLOCK_TYPE_RECOMMENDATION',
  ContentBlockTypeRevenueRanking = 'CONTENT_BLOCK_TYPE_REVENUE_RANKING',
  ContentBlockTypeSales = 'CONTENT_BLOCK_TYPE_SALES',
  ContentBlockTypeUniversalNewMediaChapter = 'CONTENT_BLOCK_TYPE_UNIVERSAL_NEW_MEDIA_CHAPTER',
  ContentBlockTypeUniversalPopularityChapter = 'CONTENT_BLOCK_TYPE_UNIVERSAL_POPULARITY_CHAPTER',
  ContentBlockTypeUniversalRevenueRanking = 'CONTENT_BLOCK_TYPE_UNIVERSAL_REVENUE_RANKING',
  ContentBlockTypeUniversalSvodNewMedia = 'CONTENT_BLOCK_TYPE_UNIVERSAL_SVOD_NEW_MEDIA',
  ContentBlockTypeUniversalSvodPopularity = 'CONTENT_BLOCK_TYPE_UNIVERSAL_SVOD_POPULARITY',
  ContentBlockTypeUniversalTvodNewMedia = 'CONTENT_BLOCK_TYPE_UNIVERSAL_TVOD_NEW_MEDIA',
  ContentBlockTypeUniversalTvodPopularity = 'CONTENT_BLOCK_TYPE_UNIVERSAL_TVOD_POPULARITY',
  ContentBlockTypeUnspecified = 'CONTENT_BLOCK_TYPE_UNSPECIFIED',
}

export type ContentTag = {
  __typename?: 'ContentTag';
  contentType: ContentType;
  id: Scalars['ID'];
  nameJa: Scalars['String'];
};

export type ContentTagGroup = {
  __typename?: 'ContentTagGroup';
  contentTags: Array<ContentTag>;
  nameJa: Scalars['String'];
};

export enum ContentType {
  Alias = 'ALIAS',
  BrandGroup = 'BRAND_GROUP',
  Collection = 'COLLECTION',
  ContentTag = 'CONTENT_TAG',
  Media = 'MEDIA',
  MediaChapter = 'MEDIA_CHAPTER',
  Planet = 'PLANET',
  ProductionCompany = 'PRODUCTION_COMPANY',
  PublishedMedia = 'PUBLISHED_MEDIA',
  Studio = 'STUDIO',
  Unknown = 'UNKNOWN',
}

export type ContractProduct = {
  __typename?: 'ContractProduct';
  /**
   * Denotes whether this product has already been purchased/is currently active
   * Always returns false for unauthenticated users
   */
  alreadyPurchased: Scalars['Boolean'];
  /**
   * expiredDateTime is the available date time for viewing period if user do a rental in case of TOD, which is used to calculate and display 視聴可能期限
   * expiredDateTime is empty for EST, and unused for SOD
   */
  expiredDateTime?: Maybe<Scalars['DateTime']>;
  onSale: Scalars['Boolean'];
  originalPrice?: Maybe<Scalars['Int']>;
  originalPriceInTax?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  priceInTax?: Maybe<Scalars['Int']>;
  productCode: Scalars['String'];
  productName: Scalars['String'];
  saleEndDateTime: Scalars['DateTime'];
  saleStartDateTime: Scalars['DateTime'];
  saleTypeCode: CapySaleTypeCode;
};

export type ContractProductSummary = {
  __typename?: 'ContractProductSummary';
  copyright?: Maybe<Scalars['String']>;
  hasMultiplePrices: Scalars['Boolean'];
  id: Scalars['ID'];
  minPrice?: Maybe<Scalars['Int']>;
  paymentBadges?: Maybe<Array<CapyPaymentBadgeCode>>;
  platformLifetimeClass: TitleLifetimeClass;
  products?: Maybe<Array<ContractProduct>>;
  publicEndDateTime: Scalars['DateTime'];
  publicStartDateTime: Scalars['DateTime'];
  saleBadges?: Maybe<Array<CapySaleBadgeCode>>;
};

export enum CooperationPointsPolicy {
  UseCooperationCardOnly = 'USE_COOPERATION_CARD_ONLY',
  UseCooperationCardPriority = 'USE_COOPERATION_CARD_PRIORITY',
  UseCooperationPointOnly = 'USE_COOPERATION_POINT_ONLY',
}

export type Credit = {
  __typename?: 'Credit';
  castTypeName?: Maybe<Scalars['String']>;
  characterName?: Maybe<Scalars['String']>;
  personCode?: Maybe<Scalars['String']>;
  personName?: Maybe<Scalars['String']>;
  personNameCode?: Maybe<Scalars['String']>;
};

export type DeleteHistoryItemResponse = {
  __typename?: 'DeleteHistoryItemResponse';
  count: Scalars['Int'];
};

/** Input to remove from favorites */
export type DeleteLibuFavoriteItemsInput = {
  favoriteItems: Array<LibuFavoriteItemKey>;
};

export type DeleteLibuFavoriteListInput = {
  id: Scalars['ID'];
};

export type DeleteLibuPlaylistInput = {
  id: Scalars['ID'];
};

/** Delete Item from playlist's input. */
export type DeleteLibuPlaylistItemsInput = {
  items: Array<Scalars['ID']>;
  playlistId: Scalars['ID'];
};

export type DeleteLibuPurchaseHistoryItemsInput = {
  domain: LibuDomain;
  items: Array<LibuPurchaseHistoryItemKey>;
};

export type DeleteLibuRatingInput = {
  domain: LibuDomain;
  resource: LibuResourceInput;
};

/**
 * delete viewing history's input.
 *
 * when <inverse> is "false" or not specified, delete the specified items(<resources>).
 * otherwise(inverse is true), delete all items except <resources> from the viewing history,
 * if you don't specify <resources>, all items in the viewing history will be deleted.
 */
export type DeleteLibuViewHistoryInput = {
  domain: LibuDomain;
  inverse?: InputMaybe<Scalars['Boolean']>;
  resources: Array<LibuResourceInput>;
};

export type DeleteResult = {
  __typename?: 'DeleteResult';
  count: Scalars['Int'];
  items?: Maybe<Array<Scalars['ID']>>;
  success: Scalars['Boolean'];
};

export type DownloadTitleMeta = {
  __typename?: 'DownloadTitleMeta';
  keywords: Array<Scalars['String']>;
  titleInKatakana: Scalars['String'];
};

export enum DrmType {
  Fairplay = 'FAIRPLAY',
  Playready = 'PLAYREADY',
  Widevine = 'WIDEVINE',
}

export enum DubSubType {
  All = 'ALL',
  Dub = 'DUB',
  Sub = 'SUB',
}

export enum DynamicRange {
  Dv = 'DV',
  Hdr10 = 'HDR10',
  Hdr10Plus = 'HDR10PLUS',
  Sdr = 'SDR',
  Unspecified = 'UNSPECIFIED',
}

export type EndpointUrl = {
  __typename?: 'EndpointUrl';
  licenseUrlList: Array<LicenseUrl>;
  playlistUrl?: Maybe<Scalars['String']>;
  type?: Maybe<StreamingType>;
};

export type EndpointUrlV2 = {
  __typename?: 'EndpointUrlV2';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  image: Scalars['String'];
  playables: Array<Playable>;
  previews?: Maybe<Array<Preview>>;
  sceneSearchList?: Maybe<Array<SceneSearch>>;
};

export type Episode = {
  __typename?: 'Episode';
  chromecastFlag?: Maybe<Scalars['Boolean']>;
  completeFlag?: Maybe<Scalars['Boolean']>;
  displayDurationText?: Maybe<Scalars['String']>;
  displayNo?: Maybe<Scalars['String']>;
  downloadFlag?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Int']>;
  endDateText?: Maybe<Scalars['String']>;
  endrollPosition?: Maybe<Scalars['Int']>;
  episodeName?: Maybe<Scalars['String']>;
  episodeNotices?: Maybe<Array<Scalars['String']>>;
  /** for getWatchingEpisode */
  episodeTitleInfo?: Maybe<EpisodeTitleInfo>;
  episodelistButtonName?: Maybe<Scalars['String']>;
  existsRelatedEpisode?: Maybe<Scalars['Boolean']>;
  hasDub?: Maybe<Scalars['Boolean']>;
  hasSubtitle?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  interruption?: Maybe<Scalars['Int']>;
  /** @deprecated no need. use interruption instead */
  interruptionPercent?: Maybe<Scalars['Int']>;
  introduction?: Maybe<Scalars['String']>;
  isNew?: Maybe<Scalars['Boolean']>;
  /** returns purchased status if logged-in, else false */
  isPurchased?: Maybe<Scalars['Boolean']>;
  maxResolutionCode?: Maybe<Scalars['String']>;
  movieTypeCode?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['String']>;
  nodCatchupFlag?: Maybe<Scalars['Boolean']>;
  nodCatchupPlanCode?: Maybe<Scalars['String']>;
  nodSpecialFlag?: Maybe<Scalars['Boolean']>;
  nodSpecialPlanCode?: Maybe<Scalars['String']>;
  /** @deprecated replaced by paymentBadgeList */
  paymentBadge?: Maybe<PaymentBadge>;
  paymentBadgeList: Array<PaymentBadge>;
  playButtonName?: Maybe<Scalars['String']>;
  productLineupCodeList: Array<Scalars['String']>;
  publicEndDate?: Maybe<Scalars['DateTime']>;
  publicStartDate?: Maybe<Scalars['DateTime']>;
  publishStyleCode?: Maybe<Scalars['String']>;
  purchaseEpisodeLimitday?: Maybe<Scalars['String']>;
  saleText?: Maybe<Scalars['String']>;
  saleTypeCode?: Maybe<Scalars['String']>;
  /** @deprecated replaced by episodeNotices */
  supplementText1?: Maybe<Scalars['String']>;
  /** @deprecated replaced by episodeNotices */
  supplementText2?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Thumbnail>;
};

export type EpisodeCredit = {
  __typename?: 'EpisodeCredit';
  episodeDisplayNumber?: Maybe<Scalars['String']>;
  episodeName?: Maybe<Scalars['String']>;
  episodeNumber?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  people: Array<Credit>;
};

export type EpisodeCreditResponse = {
  __typename?: 'EpisodeCreditResponse';
  episodeCredits: Array<EpisodeCredit>;
  pageInfo: PortalPageInfo;
};

export type EpisodeTitleInfo = {
  __typename?: 'EpisodeTitleInfo';
  hasMultiprice: Scalars['Boolean'];
  id: Scalars['ID'];
  lastEpisode: Scalars['String'];
  minimumPrice: Scalars['Int'];
  name: Scalars['String'];
  nfreeBadge: Scalars['String'];
  productLineupCodeList: Array<Scalars['String']>;
  rate: Scalars['Int'];
  updateOfWeek: Scalars['Int'];
};

export type Epub = {
  __typename?: 'Epub';
  content?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  isEncrypted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export enum EvaluationTypes {
  Rate_0 = 'RATE_0',
  Rate_1 = 'RATE_1',
  Rate_2 = 'RATE_2',
  Rate_3 = 'RATE_3',
  Rate_4 = 'RATE_4',
  Rate_5 = 'RATE_5',
}

export enum ExclusiveBadgeCode {
  Excl = 'EXCL',
  ExclSvod = 'EXCL_SVOD',
}

export type ExclusiveInfo = {
  __typename?: 'ExclusiveInfo';
  isOnlyOn: Scalars['Boolean'];
  typeCode?: Maybe<ExclusiveBadgeCode>;
};

export type FavoriteBooksResponse = {
  __typename?: 'FavoriteBooksResponse';
  books: Array<BookSakuhin>;
  pageInfoHash: PageInfoHash;
};

export type FavoriteTitlesResponse = {
  __typename?: 'FavoriteTitlesResponse';
  pageInfo: PortalPageInfo;
  titles: Array<Title>;
};

export enum FavoriteTypes {
  Add = 'ADD',
  Remove = 'REMOVE',
}

export type Feature = {
  __typename?: 'Feature';
  /** @deprecated No need to care at client side */
  commentDisplayFlag?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  leadText?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pageInfo?: Maybe<PortalPageInfo>;
  titleComments: Array<FeatureTitleComment>;
  titles: Array<Title>;
  typeCode?: Maybe<Scalars['String']>;
};

export type FeatureArtwork = {
  __typename?: 'FeatureArtwork';
  movies?: Maybe<FeatureMovies>;
  thumbnail: Thumbnail;
  typeCode: Scalars['String'];
};

export type FeatureMovies = {
  __typename?: 'FeatureMovies';
  large?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  small?: Maybe<Scalars['String']>;
};

export type FeatureTemplate = {
  __typename?: 'FeatureTemplate';
  code: Scalars['ID'];
  displayEnd: Scalars['DateTime'];
  featureArtwork: FeatureArtwork;
  heading?: Maybe<Scalars['String']>;
  lead?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  typeCode: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type FeatureTemplateResponse = {
  __typename?: 'FeatureTemplateResponse';
  books: Array<BookSakuhin>;
  template: FeatureTemplate;
  titles: Array<Title>;
  videoFirst: Scalars['Boolean'];
};

export type FeatureTitleComment = {
  __typename?: 'FeatureTitleComment';
  comment?: Maybe<Scalars['String']>;
  featureCode: Scalars['ID'];
  /** id: featureCode_titleCode, done for facilitating-caching by client */
  id: Scalars['ID'];
  titleCode: Scalars['ID'];
};

export type ForceLowBitRateFlgs = {
  __typename?: 'ForceLowBitRateFlgs';
  cellular?: Maybe<Scalars['Boolean']>;
  wifi?: Maybe<Scalars['Boolean']>;
};

/** Geometry includes vr formats of a vr video. */
export type Geometry = {
  __typename?: 'Geometry';
  geometryType: GeometryType;
  horizontalAngleDegree: Scalars['Int'];
  projectionType: ProjectionType;
  stereoLayout: StereoLayout;
  stereoVideo: Scalars['Boolean'];
};

export enum GeometryType {
  Regular = 'REGULAR',
  Unspecified = 'UNSPECIFIED',
  Vr = 'VR',
}

export type GetLibuRatingInput = {
  domain: LibuDomain;
  resource: LibuResourceInput;
};

export type Guest = {
  __typename?: 'Guest';
  debug?: Maybe<Scalars['Boolean']>;
};

export type HistoryTitlesResponse = {
  __typename?: 'HistoryTitlesResponse';
  pageInfo: PortalPageInfo;
  titles: Array<Title>;
};

export type IconThumbnail = {
  __typename?: 'IconThumbnail';
  icon: Scalars['String'];
};

export type IdNamePair = {
  __typename?: 'IdNamePair';
  id: Scalars['ID'];
  nameJa: Scalars['String'];
};

export type IncludedBook = {
  __typename?: 'IncludedBook';
  code: Scalars['String'];
  name: Scalars['String'];
  thumbnail: Thumbnail;
};

export type InfoListItem = {
  __typename?: 'InfoListItem';
  title: Scalars['String'];
  ttl: Scalars['DateTime'];
  type?: Maybe<InfoListItemType>;
  url: Scalars['String'];
};

export enum InfoListItemType {
  Info = 'INFO',
  Warning = 'WARNING',
}

/**
 * InputAddConflictPolicy is used to specify the behavior when adding a resource that already exists.
 *
 * This is only available for a few endpoints.
 * Please confirm wehther it is supported in which endpoint before using it.
 */
export enum InputAddConflictPolicy {
  /** DEFAULT is to update the resource if it already exists. */
  Default = 'DEFAULT',
  /**
   * NOT IMPLEMENTED YET
   * An error will be returned if the same item exists.
   */
  Fail = 'FAIL',
  /** It will be ignored if the same item exists. */
  Ignore = 'IGNORE',
  /**
   * NOT IMPLEMENTED YET
   * It will be replaced with the new one if the same item exists.
   * Because this is not the desired behavior in most cases, it is not recommended.
   *
   *
   * Notic that the ID of the existing item will be changed,
   * you may need to update the ID in your local.
   */
  Replace = 'REPLACE',
  /**
   * UPDATE: Update the existing resource.
   * Because in almost all cases, this is the desired behavior,
   * so we left it as the default behavior.
   */
  Update = 'UPDATE',
}

export enum InputKindDomainOrFavoriteLists {
  Domain = 'DOMAIN',
  FavoriteLists = 'FAVORITE_LISTS',
}

export enum InputKindIdResource {
  Id = 'ID',
  Resource = 'RESOURCE',
}

export enum InputKindIdTitle {
  Id = 'ID',
  Title = 'TITLE',
}

export type IntQueryOperator = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
};

export type LeadTitle = {
  __typename?: 'LeadTitle';
  leadCode: Scalars['ID'];
  text: Scalars['String'];
  thumbnail: LeadTitleThumbnail;
  titleCode: Scalars['ID'];
};

export type LeadTitleThumbnail = {
  __typename?: 'LeadTitleThumbnail';
  large: Scalars['String'];
  medium: Scalars['String'];
  small: Scalars['String'];
};

export type LeanbackGenre = {
  __typename?: 'LeanbackGenre';
  displayCode: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  searchMenu: SearchMenu;
  thumbnail: IconThumbnail;
};

export type LeanbackGenreGroup = {
  __typename?: 'LeanbackGenreGroup';
  displayCode: Scalars['String'];
  id: Scalars['ID'];
  leanbackGenres: Array<LeanbackGenre>;
  name: Scalars['String'];
};

export type LibuAddOptions = {
  conflict?: InputMaybe<InputAddConflictPolicy>;
};

/** Manage data separately for each service in the domain. */
export enum LibuDomain {
  Beemi = 'BEEMI',
  Book = 'BOOK',
  /** Error, do NOT use this */
  Default = 'DEFAULT',
  H2U = 'H2U',
  Music = 'MUSIC',
}

/**
 * When getting the favoriteItem list, "Do you want to search from all FavoriteLists in that Domain?",
 *
 * Or used when specifying "Search from some Favorite list".
 *
 * If kind is set to Domain, the Favorite list cannot be narrowed down to Domain and everyone will search,
 *
 * If kind is FavoriteLists, search in the specified FavoriteList.
 */
export type LibuDomainOrFavoriteListsFilter = {
  domain?: InputMaybe<LibuDomain>;
  favoriteLists?: InputMaybe<Array<LibuFavoriteListKey>>;
  kind: InputKindDomainOrFavoriteLists;
};

/** Favorite information */
export type LibuFavoriteItem = {
  __typename?: 'LibuFavoriteItem';
  /** Date and time when you entered your favorites */
  createTime: Scalars['DateTime'];
  /** Favorite list containing this favorite information */
  favoriteList: LibuFavoriteListLight;
  id: Scalars['ID'];
  /** the reason why you added this to your favorites */
  reason?: Maybe<Scalars['String']>;
  /** Resources in your favorites */
  resource?: Maybe<Resource>;
  /**
   * status of this favorite item (Output only).
   * Only returned when mutation is performed.
   */
  status?: Maybe<LibuMutationStatus>;
};

/**
 * Filter to get favorite Item and Playlist together (MUSIC only).
 *
 * You can specify the time of creation and the type (multiple) of what you put in.
 */
export type LibuFavoriteItemAndPlaylistFilter = {
  createTime?: InputMaybe<LibuPeriod>;
  favoriteResourceType?: InputMaybe<Array<ResourceType>>;
};

/** Sort order for acquisition of favoriteItem and Playlist (MUSIC only). */
export type LibuFavoriteItemAndPlaylistSortCondition = {
  field: LibuFavoriteItemPlaylistSortField;
  order?: InputMaybe<LibuSortOrder>;
};

/**
 * Filter for getting a list of favoriteItems,
 *
 * You can specify the creation period and the type (multiple) of what you put in.
 */
export type LibuFavoriteItemFilter = {
  createTime?: InputMaybe<LibuPeriod>;
  /**
   * The text string on which to search.
   * IMPORTANT: This maybe costly, so use it with caution.
   */
  kw?: InputMaybe<Scalars['String']>;
  resourceTypes?: InputMaybe<Array<ResourceType>>;
};

export type LibuFavoriteItemHits = {
  __typename?: 'LibuFavoriteItemHits';
  items?: Maybe<Array<LibuFavoriteItem>>;
  pageResult: LibuPageResult;
};

/**
 * Input to favorite,
 * You need to specify the Favorite list to put in and what to put in it.
 *
 * Even with Reason, it's something you can put in.
 *
 * There are two ways to specify the Favorite list:
 * 1. Specify by ID. It is necessary to obtain the ID of FavoriteList in advance.
 *
 * 2. Specify by Domain and Title.
 *
 * If there is no favoriteList with the same title in the domain, a new favoriteList will be automatically created with this title.
 */
export type LibuFavoriteItemInput = {
  favoriteList: LibuFavoriteListKey;
  /**
   * If you do NOT want to change anything if the same resource is already exists,
   * just set ConflictPolicy to IGNORE in the options.
   *
   * If this is not set, the default is to overwrite the existing one(UPDATE) and
   * the fields below will be updated.
   *
   * 1. reason
   *
   * 2. updateTime
   */
  options?: InputMaybe<LibuAddOptions>;
  reason?: InputMaybe<Scalars['String']>;
  resource: LibuResourceInput;
};

/**
 * Input for identifying favorite Item
 *
 * Decide whether to use ID or Resource with kind
 *
 * If kind is set as ID, FavoriteItem will be searched by id.
 *
 * If kind is Resource, look for favoriteItem by Resource in the specified FavoriteList
 */
export type LibuFavoriteItemKey = {
  /**
   * The resource included in the favoriteItem and the corresponding FavoriteList.
   *
   * Required if kind is Resource, will use this to see if it's in the favorites list
   */
  favoriteListResource?: InputMaybe<LibuFavoriteItemResourceKey>;
  /**
   * favoriteItem ID,Required if kind is ID.
   *
   * It is necessary to get the ID of FavoriteItem in advance.
   */
  id?: InputMaybe<Scalars['ID']>;
  kind: InputKindIdResource;
};

export type LibuFavoriteItemOrPlaylist = LibuFavoriteItem | LibuPlaylistLight;

export type LibuFavoriteItemOrPlaylistHits = {
  __typename?: 'LibuFavoriteItemOrPlaylistHits';
  items?: Maybe<Array<LibuFavoriteItemOrPlaylist>>;
  pageResult: LibuPageResult;
};

/** Sort order that can be used to get a list of favorites and playlists (MUSIC only). */
export enum LibuFavoriteItemPlaylistSortField {
  CreateTime = 'CREATE_TIME',
  Default = 'DEFAULT',
  UpdateTime = 'UPDATE_TIME',
}

/**
 * Input for specifying favoriteItem in favoriteList and resource.
 *
 * Input when deciding whether to put something in that Favorite list.
 */
export type LibuFavoriteItemResourceKey = {
  favoriteList: LibuFavoriteListKey;
  resource?: InputMaybe<LibuResourceInput>;
};

/** Sort order of favoriteItem. */
export type LibuFavoriteItemSortCondition = {
  field: LibuFavoriteItemSortField;
  order?: InputMaybe<LibuSortOrder>;
};

/** Sort order that can be used when acquiring the favorite list. */
export enum LibuFavoriteItemSortField {
  /** Sort by the time of the item being added to the favorite list. */
  CreateTime = 'CREATE_TIME',
  /**
   * For the default sort order, there is no guarantee that
   * items always being sorted in the same order.
   */
  Default = 'DEFAULT',
  /**
   * Do NOT use this for it is not implemented yet.
   * @deprecated not implemented yet
   */
  Group = 'GROUP',
  /**
   * Sort by the resource,
   * this is will force the result to be sorted by the <resource_type + resource_id>.
   * Only use this when you query the favorite list of a specific resource(all items are
   * of the same resource type), otherwise the result will be meaningless.
   */
  Resource = 'RESOURCE',
  /**
   * Do NOT use this for it is not implemented yet.
   * @deprecated not implemented yet
   */
  ResourceType = 'RESOURCE_TYPE',
  /**
   * Sort by the title of the resource.
   * Because this may be cause performance issue, use this only when necessary.
   */
  Title = 'TITLE',
  /** Sort by the time of the item was last updated. */
  UpdateTime = 'UPDATE_TIME',
}

export type LibuFavoriteList = {
  __typename?: 'LibuFavoriteList';
  createTime: Scalars['DateTime'];
  domain: LibuDomain;
  id: Scalars['ID'];
  itemList?: Maybe<LibuFavoriteItemHits>;
  title: Scalars['String'];
  totalItems: Scalars['Int'];
  updateTime?: Maybe<Scalars['DateTime']>;
  user: LibuUser;
};

export type LibuFavoriteListItemListArgs = {
  filter?: InputMaybe<LibuFavoriteItemFilter>;
  orderBy?: InputMaybe<Array<LibuFavoriteItemSortCondition>>;
  pagination?: InputMaybe<LibuPagination>;
};

export type LibuFavoriteListFilter = {
  createTime?: InputMaybe<LibuPeriod>;
  items?: InputMaybe<LibuNumberRange>;
  updateTime?: InputMaybe<LibuPeriod>;
};

export type LibuFavoriteListHits = {
  __typename?: 'LibuFavoriteListHits';
  items?: Maybe<Array<LibuFavoriteList>>;
  pageResult: LibuPageResult;
};

/** favorite list mutation input. */
export type LibuFavoriteListInput = {
  title: Scalars['String'];
};

/**
 * Input for identifying favorite list,
 *
 * Decide whether to use ID or Title with kind.
 *
 * If kind is set as ID, the Favorite list will be searched by id.
 *
 * If kind is Title, look for FavoriteList in title.
 */
export type LibuFavoriteListKey = {
  /**
   * favoriteList title and domain,
   *
   * Domain and title must be specified
   */
  domainTitle?: InputMaybe<LibuFavoriteListTitleKey>;
  /** favoriteList ID */
  id?: InputMaybe<Scalars['ID']>;
  kind: InputKindIdTitle;
};

/** It's the same as LibuFavoriteList, but it doesn't get itemList. */
export type LibuFavoriteListLight = {
  __typename?: 'LibuFavoriteListLight';
  createTime: Scalars['DateTime'];
  domain: LibuDomain;
  id: Scalars['ID'];
  title: Scalars['String'];
  totalItems: Scalars['Int'];
  updateTime?: Maybe<Scalars['DateTime']>;
  user: LibuUser;
};

export type LibuFavoriteListSortCondition = {
  field: LibuFavoriteListSortField;
  order?: InputMaybe<LibuSortOrder>;
};

export enum LibuFavoriteListSortField {
  CreateTime = 'CREATE_TIME',
  Default = 'DEFAULT',
  Items = 'ITEMS',
  UpdateTime = 'UPDATE_TIME',
}

/** Used to specify the Favorite list by Title,Domain and title must be specified */
export type LibuFavoriteListTitleKey = {
  domain: LibuDomain;
  title: Scalars['String'];
};

export enum LibuMutationStatus {
  /**
   * ADDED means the item was added.
   * When adding an existing item, it will be ADDED even the policy is UPDATE,
   * in other words, it means always ADDED when adding an item even if it already exists
   * and was updated.
   */
  Added = 'ADDED',
  /** DELETED means the item was deleted. */
  Deleted = 'DELETED',
  /**
   * IGNORED means the mutation was ignored.
   * This is different from NULL, for NULL means nothing happened(no mutation),
   * but IGNORED means it was ignored.
   */
  Ignored = 'IGNORED',
  /** NULL means nothing happened. It always be NULL for query. */
  Null = 'NULL',
  /**
   * REPLACED means the item was replaced.
   *
   * This will change the whole item(even the ID) with the new one,
   * not just a part of it.
   */
  Replaced = 'REPLACED',
  /** UPDATED means the item was updated. */
  Updated = 'UPDATED',
}

export type LibuNumberRange = {
  max?: InputMaybe<Scalars['Int']>;
  min?: InputMaybe<Scalars['Int']>;
};

export type LibuPageResult = {
  __typename?: 'LibuPageResult';
  hasNextPage: Scalars['Boolean'];
  hasPrevPage: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type LibuPagination = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type LibuPeriod = {
  since?: InputMaybe<Scalars['DateTime']>;
  until?: InputMaybe<Scalars['DateTime']>;
};

export type LibuPlaylist = {
  __typename?: 'LibuPlaylist';
  createTime: Scalars['DateTime'];
  deleteTime?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  favorite?: Maybe<LibuFavoriteItem>;
  id: Scalars['ID'];
  itemList?: Maybe<LibuPlaylistItemHits>;
  rating?: Maybe<LibuRating>;
  resourceFavorites: LibuFavoriteItemHits;
  resourceRatings: LibuRatingHits;
  title: Scalars['String'];
  totalItems: Scalars['Int'];
  updateTime?: Maybe<Scalars['DateTime']>;
  user: LibuUser;
  viewHistory?: Maybe<LibuViewHistoryItem>;
};

export type LibuPlaylistFavoriteArgs = {
  favoriteList: LibuFavoriteListKey;
};

export type LibuPlaylistItemListArgs = {
  filter?: InputMaybe<LibuPlaylistItemFilter>;
  orderBy?: InputMaybe<Array<LibuPlaylistItemSortCondition>>;
  pagination?: InputMaybe<LibuPagination>;
};

export type LibuPlaylistRatingArgs = {
  domain: LibuDomain;
};

export type LibuPlaylistResourceFavoritesArgs = {
  domainOrFavoriteLists: LibuDomainOrFavoriteListsFilter;
  limit?: InputMaybe<Scalars['Int']>;
};

export type LibuPlaylistResourceRatingsArgs = {
  domain: LibuDomain;
  limit?: InputMaybe<Scalars['Int']>;
};

export type LibuPlaylistViewHistoryArgs = {
  domain: LibuDomain;
};

export type LibuPlaylistFilter = {
  createTime?: InputMaybe<LibuPeriod>;
  items?: InputMaybe<LibuNumberRange>;
};

export type LibuPlaylistHits = {
  __typename?: 'LibuPlaylistHits';
  items?: Maybe<Array<LibuPlaylist>>;
  pageResult: LibuPageResult;
};

export type LibuPlaylistInput = {
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type LibuPlaylistItem = {
  __typename?: 'LibuPlaylistItem';
  beginPosition?: Maybe<Scalars['Int']>;
  createTime: Scalars['DateTime'];
  endPosition?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  index: Scalars['Int'];
  resource: Resource;
  updateTime?: Maybe<Scalars['DateTime']>;
};

/**
 * Filter for getting Items from playlist,
 * The index (order) and time to add to the playlist can be specified.
 */
export type LibuPlaylistItemFilter = {
  /** DEPRECATED: only "updateTime" is supported. */
  createTime?: InputMaybe<LibuPeriod>;
  index?: InputMaybe<LibuNumberRange>;
  updateTime?: InputMaybe<LibuPeriod>;
};

export type LibuPlaylistItemHits = {
  __typename?: 'LibuPlaylistItemHits';
  items?: Maybe<Array<LibuPlaylistItem>>;
  pageResult?: Maybe<LibuPageResult>;
};

/** Item information of playlist. */
export type LibuPlaylistItemInput = {
  /**
   * resource start position,
   *
   * It is assumed that the meaning will change depending on the resource type.
   *
   * Libu doesn't limit it, it's just data.
   */
  beginPosition?: InputMaybe<Scalars['Int']>;
  /**
   * End position of resource,
   *
   * It is assumed that the meaning will change depending on the resource type.
   *
   * Libu doesn't limit it, it's just data.
   */
  endPosition?: InputMaybe<Scalars['Int']>;
  resource: LibuResourceInput;
};

/** Sort order when getting Items from playlist. */
export type LibuPlaylistItemSortCondition = {
  field: LibuPlaylistItemSortField;
  order?: InputMaybe<LibuSortOrder>;
};

/** Sort order that can be used to get the list of Items in Playlist. */
export enum LibuPlaylistItemSortField {
  /** Sort DEFAULT by "INDEX" */
  Default = 'DEFAULT',
  Index = 'INDEX',
}

/** Add Item (s) to playlist Input. */
export type LibuPlaylistItemsInput = {
  /**
   * Additional position. Count from 1,
   *
   * Specify with 1 when adding to the beginning. If you add it to the end, specify it with 0.
   */
  insertPosition?: InputMaybe<Scalars['Int']>;
  items: Array<LibuPlaylistItemInput>;
  playlistId: Scalars['ID'];
};

/** It's the same as LibuPlaylist, but it doesn't get itemList. */
export type LibuPlaylistLight = {
  __typename?: 'LibuPlaylistLight';
  createTime: Scalars['DateTime'];
  deleteTime?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title: Scalars['String'];
  totalItems: Scalars['Int'];
  updateTime?: Maybe<Scalars['DateTime']>;
  user: LibuUser;
};

export type LibuPlaylistSortCondition = {
  field: LibuPlaylistSortField;
  order?: InputMaybe<LibuSortOrder>;
};

export enum LibuPlaylistSortField {
  CreateTime = 'CREATE_TIME',
  Default = 'DEFAULT',
  Items = 'ITEMS',
  UpdateTime = 'UPDATE_TIME',
}

export type LibuPreferenceInput = {
  domain: LibuDomain;
  optionIds: Array<Scalars['ID']>;
};

export type LibuPreferenceItem = {
  __typename?: 'LibuPreferenceItem';
  createTime: Scalars['DateTime'];
  id: Scalars['ID'];
  option: LibuPreferenceOption;
};

export type LibuPreferenceItems = {
  __typename?: 'LibuPreferenceItems';
  domain: Scalars['String'];
  items?: Maybe<Array<LibuPreferenceItem>>;
};

export type LibuPreferenceOption = {
  __typename?: 'LibuPreferenceOption';
  createTime: Scalars['DateTime'];
  group: Scalars['String'];
  id: Scalars['ID'];
  resource?: Maybe<LibuPreferenceResource>;
  title: Scalars['String'];
};

export type LibuPreferenceResource = {
  __typename?: 'LibuPreferenceResource';
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type LibuPurchaseHistoryFilter = {
  purchaseTime?: InputMaybe<LibuPeriod>;
  resourceTypes?: InputMaybe<Array<ResourceType>>;
};

export type LibuPurchaseHistoryItem = {
  __typename?: 'LibuPurchaseHistoryItem';
  amount: Scalars['Int'];
  amountIncludingTax: Scalars['Int'];
  billedAmount: Scalars['Int'];
  createdTime: Scalars['DateTime'];
  familyUser?: Maybe<LibuUser>;
  id: Scalars['ID'];
  purchaseDatetime: Scalars['DateTime'];
  resource: Resource;
  supplement?: Maybe<Scalars['String']>;
  updatedTime?: Maybe<Scalars['DateTime']>;
  usedPartnerPoint?: Maybe<Scalars['Int']>;
  usedUCoin?: Maybe<Scalars['Int']>;
  usedUNextPoint?: Maybe<Scalars['Int']>;
  user: LibuUser;
};

export type LibuPurchaseHistoryItemHits = {
  __typename?: 'LibuPurchaseHistoryItemHits';
  items?: Maybe<Array<LibuPurchaseHistoryItem>>;
  pageResult: LibuPageResult;
};

export type LibuPurchaseHistoryItemKey = {
  /**
   * purchase history item ID,Required if kind is ID.
   *
   * It is necessary to get the ID of purchase history item in advance.
   */
  id?: InputMaybe<Scalars['ID']>;
  kind: InputKindIdResource;
  /**
   * The resource included in the purchase history.
   *
   * Required if kind is Resource, will use this to see if it's in the purchase history(maybe more than 1 item)
   */
  resource?: InputMaybe<LibuResourceInput>;
};

export type LibuPurchaseHistorySortCondition = {
  field: LibuPurchaseHistorySortField;
  order?: InputMaybe<LibuSortOrder>;
};

/** Sort order that can be used when acquiring the purchase history list. */
export enum LibuPurchaseHistorySortField {
  Default = 'DEFAULT',
  PurchaseTime = 'PURCHASE_TIME',
}

export type LibuRating = {
  __typename?: 'LibuRating';
  comment?: Maybe<Scalars['String']>;
  createTime: Scalars['DateTime'];
  id: Scalars['ID'];
  resource?: Maybe<Resource>;
  score: Scalars['Int'];
  updateTime?: Maybe<Scalars['DateTime']>;
  user: LibuUser;
};

export type LibuRatingFilter = {
  resourceTypes?: InputMaybe<Array<ResourceType>>;
  score?: InputMaybe<LibuNumberRange>;
  updateTime?: InputMaybe<LibuPeriod>;
};

export type LibuRatingHits = {
  __typename?: 'LibuRatingHits';
  items?: Maybe<Array<LibuRating>>;
  pageResult: LibuPageResult;
};

export type LibuRatingInput = {
  comment?: InputMaybe<Scalars['String']>;
  resource: LibuResourceInput;
  /** MUST be between -32768 and 32767 */
  score: Scalars['Int'];
};

export type LibuRatingSortCondition = {
  field: LibuRatingSortField;
  order?: InputMaybe<LibuSortOrder>;
};

/** Sort order that can be used when acquiring the evaluation list. */
export enum LibuRatingSortField {
  Default = 'DEFAULT',
  Score = 'SCORE',
}

export type LibuResourceInput = {
  id: Scalars['ID'];
  type: ResourceType;
};

export type LibuResourceRatingFilter = {
  score?: InputMaybe<LibuNumberRange>;
  updateTime?: InputMaybe<LibuPeriod>;
};

export enum LibuSortOrder {
  Asc = 'ASC',
  Default = 'DEFAULT',
  Desc = 'DESC',
}

export type LibuUser = Guest | NapiUser | UnknownUser;

/**
 * LibuUserProfile is an union type and has only one type now(BeemiUserProfile).
 * We should add other user profile types in the future.
 */
export type LibuUserProfile = BeemiUserProfile;

/**
 * Filter for viewing history acquisition,
 *
 * You can specify the latest playback start time, the number of playbacks, and the content type.
 *
 * If you specify multiple conditions, connect them all with "AND".
 *
 * Full-text search is also possible, but it maybe cause performance issues,
 * only use it when necessary.
 */
export type LibuViewHistoryFilter = {
  /**
   * The text string on which to search.
   * IMPORTANT: This maybe costly, so use it with caution.
   */
  kw?: InputMaybe<Scalars['String']>;
  resourceTypes?: InputMaybe<Array<ResourceType>>;
  startTime?: InputMaybe<LibuPeriod>;
  times?: InputMaybe<LibuNumberRange>;
};

/** Viewing history information. */
export type LibuViewHistoryInput = {
  deviceCode?: InputMaybe<Scalars['String']>;
  resource: LibuResourceInput;
  /** How long have you watched? (Updated when playback is stopped). */
  resumePoint?: InputMaybe<Scalars['Int']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
};

export type LibuViewHistoryItem = {
  __typename?: 'LibuViewHistoryItem';
  createTime: Scalars['DateTime'];
  deviceCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  resource: Resource;
  resumePoint?: Maybe<Scalars['Int']>;
  startTime: Scalars['DateTime'];
  times: Scalars['Int'];
  updateTime?: Maybe<Scalars['DateTime']>;
};

export type LibuViewHistoryItemHits = {
  __typename?: 'LibuViewHistoryItemHits';
  items?: Maybe<Array<LibuViewHistoryItem>>;
  pageResult: LibuPageResult;
};

/** Viewing history with order. */
export type LibuViewHistorySortCondition = {
  field: LibuViewHistorySortField;
  order?: InputMaybe<LibuSortOrder>;
};

/** Sort order that can be used when acquiring the viewing history list. */
export enum LibuViewHistorySortField {
  Default = 'DEFAULT',
  StartTime = 'START_TIME',
  Times = 'TIMES',
  /**
   * Sort by the title of the resource.
   * Because this may be cause performance issue, use this only when necessary.
   */
  Title = 'TITLE',
}

export type LicenseUrl = {
  __typename?: 'LicenseUrl';
  licenseUrl?: Maybe<Scalars['String']>;
  type?: Maybe<DrmType>;
};

/**
 * In case of portal-error, errorCode can be found under gql "errors" array with following message:
 *
 * "relatedLives" resolver:   "LIVE_RELATED_LIVES_ERROR",
 * "relatedVideos" resolver: "LIVE_RELATED_VIDEOS_ERROR"
 */
export type Live = {
  __typename?: 'Live';
  allowsTimeshiftedPlayback: Scalars['Boolean'];
  attractions?: Maybe<Scalars['String']>;
  castSummary?: Maybe<Scalars['String']>;
  copyright?: Maybe<Scalars['String']>;
  credits: Array<Credit>;
  deliveryEndDateTime: Scalars['DateTime'];
  deliveryStartDateTime: Scalars['DateTime'];
  description: Scalars['String'];
  displayLiveMinute: Scalars['Int'];
  displaySaleEndDateTime?: Maybe<Scalars['DateTime']>;
  displayStartDateTime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isOnlyOn: Scalars['Boolean'];
  location?: Maybe<Scalars['String']>;
  musicCrossAidText?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  notices: Array<LiveNotice>;
  paymentBadgeList: Array<PaymentBadge>;
  performanceStartDateTime: Scalars['DateTime'];
  productLineupCodeList: Array<Scalars['String']>;
  purchased: Scalars['Boolean'];
  relatedLives?: Maybe<Array<Live>>;
  /** @deprecated Use relatedVideos */
  relatedTitles: Array<TitleRelationGroup>;
  relatedVideos?: Maybe<Array<Title>>;
  saleTypeCode: Scalars['String'];
  seriesCode?: Maybe<Scalars['ID']>;
  subContentList?: Maybe<Array<LiveSubContent>>;
  testLiveId: Scalars['ID'];
  tickets: Array<LiveTicket>;
  timeshiftedPlaybackAllowedUntil?: Maybe<Scalars['DateTime']>;
  timeshiftedPlaybackIntactUntil?: Maybe<Scalars['DateTime']>;
  venue?: Maybe<Scalars['String']>;
  viewableDeviceText: Scalars['String'];
};

/**
 * If structureType is FLAT then
 * list will only have 1 item in the array
 * otherwise if structureType is GROUP then
 * list can have multiple items
 */
export type LiveBlock = {
  __typename?: 'LiveBlock';
  code: Scalars['String'];
  list: Array<LiveBlockLiveGroupResponse>;
  name: Scalars['String'];
  structureType: LiveBlockStructureType;
};

export type LiveBlockDetailResponse = {
  __typename?: 'LiveBlockDetailResponse';
  code: Scalars['String'];
  liveList: Array<Live>;
  name: Scalars['String'];
  pageInfo: PortalPageInfo;
  structureType: LiveBlockStructureType;
};

/**
 * If structureType is FLAT then
 * only liveList and total will have data
 * otherwise if structureType is GROUP then
 * all properties will have data
 */
export type LiveBlockLiveGroupResponse = {
  __typename?: 'LiveBlockLiveGroupResponse';
  /**
   * LiveGroupCode
   *
   * e.g. LGP0000000001
   */
  code?: Maybe<Scalars['String']>;
  liveList: Array<Live>;
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<LiveGroupThumbnail>;
  total: Scalars['Int'];
};

export enum LiveBlockStructureType {
  Flat = 'FLAT',
  Group = 'GROUP',
}

export type LiveBlocksResponse = {
  __typename?: 'LiveBlocksResponse';
  blockList: Array<LiveBlock>;
  pageInfo: PortalPageInfo;
};

export type LiveGroupResponse = {
  __typename?: 'LiveGroupResponse';
  /**
   * LiveGroupCode
   *
   * e.g. LGP0000000001
   */
  code: Scalars['String'];
  liveList: Array<Live>;
  name: Scalars['String'];
  pageInfo: PortalPageInfo;
  thumbnail: LiveGroupThumbnail;
  /**
   * total_live_num
   * @deprecated Use pageInfo
   */
  total: Scalars['Int'];
};

export type LiveGroupThumbnail = {
  __typename?: 'LiveGroupThumbnail';
  secondary: Scalars['String'];
  standard: Scalars['String'];
};

export type LiveInfo = {
  __typename?: 'LiveInfo';
  allowsTimeShiftedPlayback: Scalars['Boolean'];
  deliveryEndEpoch: Scalars['Float'];
  deliveryStartEpoch: Scalars['Float'];
  performanceStartEpoch: Scalars['Float'];
};

export type LiveNotice = {
  __typename?: 'LiveNotice';
  movies: LiveNoticeMovie;
  name: Scalars['String'];
  publicStartDateTime: Scalars['DateTime'];
  thumbnail: Thumbnail;
  typeCode: Scalars['String'];
};

export type LiveNoticeMovie = {
  __typename?: 'LiveNoticeMovie';
  large: Scalars['String'];
  medium: Scalars['String'];
  small: Scalars['String'];
};

export type LivePagingResponse = {
  __typename?: 'LivePagingResponse';
  liveList: Array<Live>;
  pageInfo: PortalPageInfo;
};

export type LivePlaylistUrl = {
  __typename?: 'LivePlaylistUrl';
  beaconSpan?: Maybe<Scalars['Int']>;
  endpointUrls: Array<EndpointUrl>;
  playToken?: Maybe<Scalars['String']>;
  playTokenHash?: Maybe<Scalars['String']>;
  result?: Maybe<Result>;
  resultStatus?: Maybe<Scalars['Int']>;
};

export type LivePlaylistUrlResult = {
  __typename?: 'LivePlaylistUrlResult';
  beaconSpan: Scalars['Int'];
  endpointUrls: Array<EndpointUrlV2>;
  endpointVersion?: Maybe<Scalars['Int']>;
  live?: Maybe<LiveInfo>;
  playToken: Scalars['String'];
  playTokenHash: Scalars['String'];
  playlistTypeCode: Scalars['String'];
  result: Result;
  resultStatus: Scalars['Int'];
  subContentCode: Scalars['String'];
  userLiveTicketCode: Scalars['String'];
};

export type LivePlaylistUrlV2 = {
  __typename?: 'LivePlaylistUrlV2';
  beaconSpan: Scalars['Int'];
  endpointUrls: Array<EndpointUrlV2>;
  endpointVersion?: Maybe<Scalars['Int']>;
  live?: Maybe<LiveInfo>;
  playToken: Scalars['String'];
  playTokenHash: Scalars['String'];
  playlistTypeCode: Scalars['String'];
  result: Result;
  resultStatus: Scalars['Int'];
  subContentCode: Scalars['String'];
  userLiveTicketCode: Scalars['String'];
};

export type LivePlaylistValidationResult = {
  __typename?: 'LivePlaylistValidationResult';
  result: Result;
  resultStatus: Scalars['Int'];
};

export type LiveProductList = {
  __typename?: 'LiveProductList';
  contractProducts: Array<VideoAssociatedProductItem>;
  liveTickets: Array<PurchaseLiveTicket>;
};

export type LiveProductListContractProductsArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type LiveSubContent = {
  __typename?: 'LiveSubContent';
  code: Scalars['String'];
  isFixedStartDateTime: Scalars['Boolean'];
  name: Scalars['String'];
  publicEndDateTime: Scalars['DateTime'];
  publicStartDateTime?: Maybe<Scalars['DateTime']>;
  thumbnail?: Maybe<LiveSubContentThumbnail>;
  typeCode: Scalars['String'];
};

export type LiveSubContentThumbnail = {
  __typename?: 'LiveSubContentThumbnail';
  standard?: Maybe<Scalars['String']>;
};

export type LiveTicket = {
  __typename?: 'LiveTicket';
  id: Scalars['ID'];
  isSelling: Scalars['Boolean'];
  name: Scalars['String'];
  price: Scalars['Int'];
  productCode: Scalars['String'];
  saleEndDateTime: Scalars['DateTime'];
  saleStartDateTime: Scalars['DateTime'];
  typeCode: Scalars['String'];
};

export enum Location {
  /** supposed to open the link from web browser. */
  ExternalBrowser = 'EXTERNAL_BROWSER',
  /** supposed to open the link from browser inside app(like webview). */
  InappBrowser = 'INAPP_BROWSER',
  /** supposed to use this feature from native app, so ignore it. */
  NativeApp = 'NATIVE_APP',
}

export type Magazine = {
  __typename?: 'Magazine';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type MagazineBlock = {
  __typename?: 'MagazineBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID'];
  magazineGenreCode: Scalars['ID'];
  name: Scalars['String'];
  results: Scalars['Int'];
  type: BlockType;
};

export type MagazinesResponse = {
  __typename?: 'MagazinesResponse';
  books: Array<BookSakuhin>;
  name: Scalars['String'];
  pageInfo: PortalPageInfo;
};

export enum MainGenreCodeType {
  Adrama = 'ADRAMA',
  Anime = 'ANIME',
  Document = 'DOCUMENT',
  Drama = 'DRAMA',
  Fdrama = 'FDRAMA',
  Houga = 'HOUGA',
  Kids = 'KIDS',
  MusicIdol = 'MUSIC_IDOL',
  News = 'NEWS',
  Semiadult = 'SEMIADULT',
  Sport = 'SPORT',
  StagePlay = 'STAGE_PLAY',
  Variety = 'VARIETY',
  Youga = 'YOUGA',
}

export type Media = {
  __typename?: 'Media';
  /** Favorite information of this Media. */
  favorite?: Maybe<LibuFavoriteItem>;
  id: Scalars['ID'];
  metadata: MediaMeta;
  paginatedRecommendations: PaginatedMediaLight;
  playbackRight?: Maybe<PlaybackRight>;
  productInfo?: Maybe<ContractProductSummary>;
  /**
   * User in purchase history of this Media.
   * @deprecated use papy field instead
   */
  purchaseHistory?: Maybe<Array<LibuPurchaseHistoryItem>>;
  /** User in use-evaluation information for this Media. */
  rating?: Maybe<LibuRating>;
  /**
   * Favorite list that contains this Media,
   * only get information such as the total number of cases. Because favorite is not open to others.
   * the total number of people recording, but can't identify who is recording it.
   * @deprecated use capy field instead
   */
  resourceFavorites: LibuFavoriteItemHits;
  /**
   * All ratings for this Media. You don't have to log in。
   * because of you can't see the data of other users, you can't get any items (items are always empty), but you can get the total number of people who have commented on the current media through pagination.
   * @deprecated use capy field instead
   */
  resourceRatings: LibuRatingHits;
  /** User in use-Viewing history of this Media. */
  viewHistory?: Maybe<LibuViewHistoryItem>;
};

export type MediaFavoriteArgs = {
  favoriteList: LibuFavoriteListKey;
};

export type MediaPaginatedRecommendationsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type MediaProductInfoArgs = {
  deviceId: Scalars['String'];
};

export type MediaPurchaseHistoryArgs = {
  domain: LibuDomain;
};

export type MediaRatingArgs = {
  domain: LibuDomain;
};

export type MediaResourceFavoritesArgs = {
  domainOrFavoriteLists: LibuDomainOrFavoriteListsFilter;
  limit?: InputMaybe<Scalars['Int']>;
};

export type MediaResourceRatingsArgs = {
  domain: LibuDomain;
  limit?: InputMaybe<Scalars['Int']>;
};

export type MediaViewHistoryArgs = {
  domain: LibuDomain;
};

export type MediaBlock = {
  description?: Maybe<Scalars['String']>;
  displayEndDate: Scalars['DateTime'];
  displayStartDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type MediaChapter = {
  __typename?: 'MediaChapter';
  favorite?: Maybe<LibuFavoriteItem>;
  id: Scalars['ID'];
  /** The parent package product info. */
  mediaProductInfo?: Maybe<MediaLightProductSummary>;
  metadata: MediaChapterMeta;
  paginatedRecommendations: PaginatedMediaChapterLight;
  /**
   * The chapter product info may be quite different from it's parent package product info.
   * For Beemi, chapter use only platformLifetimeClass field in MediaChapterLightProductSummary.
   */
  productInfo?: Maybe<MediaChapterLightProductSummary>;
  /**
   * User in purchase history of this Media.
   * @deprecated use papy field instead
   */
  purchaseHistory?: Maybe<Array<LibuPurchaseHistoryItem>>;
  rating?: Maybe<LibuRating>;
  /** @deprecated use capy field instead */
  resourceFavorites: LibuFavoriteItemHits;
  /** @deprecated use capy field instead */
  resourceRatings: LibuRatingHits;
  viewHistory?: Maybe<LibuViewHistoryItem>;
};

export type MediaChapterFavoriteArgs = {
  favoriteList: LibuFavoriteListKey;
};

export type MediaChapterPaginatedRecommendationsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type MediaChapterPurchaseHistoryArgs = {
  domain: LibuDomain;
};

export type MediaChapterRatingArgs = {
  domain: LibuDomain;
};

export type MediaChapterResourceFavoritesArgs = {
  domainOrFavoriteLists: LibuDomainOrFavoriteListsFilter;
  limit?: InputMaybe<Scalars['Int']>;
};

export type MediaChapterResourceRatingsArgs = {
  domain: LibuDomain;
  limit?: InputMaybe<Scalars['Int']>;
};

export type MediaChapterViewHistoryArgs = {
  domain: LibuDomain;
};

export type MediaChapterLight = {
  __typename?: 'MediaChapterLight';
  /** @deprecated use this in MediaChapter type */
  favorite?: Maybe<LibuFavoriteItem>;
  id: Scalars['ID'];
  /** The parent package product info. */
  mediaProductInfo?: Maybe<MediaLightProductSummary>;
  metadata: MediaChapterLightMeta;
  /**
   * The chapter product info may be quite different from it's parent package product info.
   * For Beemi, chapter use only platformLifetimeClass field in MediaChapterLightProductSummary.
   */
  productInfo?: Maybe<MediaChapterLightProductSummary>;
  /**
   * User in purchase history of this Media.
   * @deprecated use papy field instead
   */
  purchaseHistory?: Maybe<Array<LibuPurchaseHistoryItem>>;
  /** @deprecated use this in MediaChapter type */
  rating?: Maybe<LibuRating>;
  /** @deprecated use capy field instead */
  resourceFavorites: LibuFavoriteItemHits;
  /** @deprecated use capy field instead */
  resourceRatings: LibuRatingHits;
  /** @deprecated use this in MediaChapter type */
  viewHistory?: Maybe<LibuViewHistoryItem>;
};

export type MediaChapterLightFavoriteArgs = {
  favoriteList: LibuFavoriteListKey;
};

export type MediaChapterLightPurchaseHistoryArgs = {
  domain: LibuDomain;
};

export type MediaChapterLightRatingArgs = {
  domain: LibuDomain;
};

export type MediaChapterLightResourceFavoritesArgs = {
  domainOrFavoriteLists: LibuDomainOrFavoriteListsFilter;
  limit?: InputMaybe<Scalars['Int']>;
};

export type MediaChapterLightResourceRatingsArgs = {
  domain: LibuDomain;
  limit?: InputMaybe<Scalars['Int']>;
};

export type MediaChapterLightViewHistoryArgs = {
  domain: LibuDomain;
};

export type MediaChapterLightMeta = {
  __typename?: 'MediaChapterLightMeta';
  chapterNo: Scalars['Int'];
  contentTags: Array<ContentTag>;
  durationSeconds: Scalars['Float'];
  endTimeSeconds: Scalars['Float'];
  favoriteCount: Scalars['Int'];
  featureBadges: Array<MediaFeatureBadge>;
  id: Scalars['ID'];
  nameJa: Scalars['String'];
  startTimeSeconds: Scalars['Float'];
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for MediaChapter.
   */
  thumbnails: Array<ThumbnailSet>;
};

/**
 * The chapter product info may be quite different from it's parent package product info.
 * For Beemi, MediaChapterLightProductSummary has only platformLifetimeClass field.
 */
export type MediaChapterLightProductSummary = {
  __typename?: 'MediaChapterLightProductSummary';
  id: Scalars['ID'];
  minPrice?: Maybe<Scalars['Int']>;
  platformLifetimeClass: TitleLifetimeClass;
};

export type MediaChapterMeta = {
  __typename?: 'MediaChapterMeta';
  brandGroups: Array<BrandGroupLight>;
  chapterNo: Scalars['Int'];
  contentTags: Array<ContentTag>;
  credits: Array<MediaCredit>;
  durationSeconds: Scalars['Float'];
  endTimeSeconds: Scalars['Float'];
  favoriteCount: Scalars['Int'];
  featureBadges: Array<MediaFeatureBadge>;
  id: Scalars['ID'];
  nameJa: Scalars['String'];
  nameKana: Scalars['String'];
  paginatedAllChapters: PaginatedMediaChapterLight;
  parent: MediaLight;
  performances: Array<MediaPerformance>;
  ratingCode: RatingCode;
  startTimeSeconds: Scalars['Float'];
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for MediaChapter.
   */
  thumbnails: Array<ThumbnailSet>;
  /**
   * The same videoConsumables as the ones media package has.
   * please use consumable id with chapter startTimeSeconds/endTimeSeconds
   * to play a video chapter.
   */
  videoConsumables: Array<VideoConsumable>;
};

export type MediaChapterMetaPaginatedAllChaptersArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type MediaChaptersSearchHits = SearchHits & {
  __typename?: 'MediaChaptersSearchHits';
  hasMore: Scalars['Boolean'];
  hits: Array<MediaChapterLight>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type MediaContent = Media | MediaChapter;

export type MediaCredit = {
  __typename?: 'MediaCredit';
  alias: AliasLight;
  creditType?: Maybe<Scalars['String']>;
};

export enum MediaFeatureBadge {
  Atmos = 'ATMOS',
  Dv = 'DV',
  Fhd = 'FHD',
  Hdr = 'HDR',
  Hdr10Plus = 'HDR10PLUS',
  Sd = 'SD',
  TwoD = 'TWO_D',
  Uhd4K = 'UHD4K',
  Uhd8K = 'UHD8K',
  Unspecified = 'UNSPECIFIED',
  Vr = 'VR',
}

export type MediaLight = {
  __typename?: 'MediaLight';
  /** @deprecated use this in Media type */
  favorite?: Maybe<LibuFavoriteItem>;
  id: Scalars['ID'];
  metadata: MediaLightMeta;
  productInfo?: Maybe<MediaLightProductSummary>;
  /**
   * User in purchase history of this Media.
   * @deprecated use papy field instead
   */
  purchaseHistory?: Maybe<Array<LibuPurchaseHistoryItem>>;
  /** @deprecated use this in Media type */
  rating?: Maybe<LibuRating>;
  /** @deprecated use capy field instead */
  resourceFavorites: LibuFavoriteItemHits;
  /** @deprecated use capy field instead */
  resourceRatings: LibuRatingHits;
  /** @deprecated use this in Media type */
  viewHistory?: Maybe<LibuViewHistoryItem>;
};

export type MediaLightFavoriteArgs = {
  favoriteList: LibuFavoriteListKey;
};

export type MediaLightPurchaseHistoryArgs = {
  domain: LibuDomain;
};

export type MediaLightRatingArgs = {
  domain: LibuDomain;
};

export type MediaLightResourceFavoritesArgs = {
  domainOrFavoriteLists: LibuDomainOrFavoriteListsFilter;
  limit?: InputMaybe<Scalars['Int']>;
};

export type MediaLightResourceRatingsArgs = {
  domain: LibuDomain;
  limit?: InputMaybe<Scalars['Int']>;
};

export type MediaLightViewHistoryArgs = {
  domain: LibuDomain;
};

export type MediaLightMeta = {
  __typename?: 'MediaLightMeta';
  contentTags: Array<ContentTag>;
  favoriteCount: Scalars['Int'];
  featureBadges: Array<MediaFeatureBadge>;
  id: Scalars['ID'];
  mainDurationSeconds?: Maybe<Scalars['Float']>;
  nameJa: Scalars['String'];
  originalReleaseDate?: Maybe<Scalars['DateTime']>;
  promoLine?: Maybe<Scalars['String']>;
  releaseLifetimeClass: TitleLifetimeClass;
  synopsis?: Maybe<Scalars['String']>;
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for Media.
   */
  thumbnails: Array<ThumbnailSet>;
};

export type MediaLightProductSummary = {
  __typename?: 'MediaLightProductSummary';
  hasMultiplePrices: Scalars['Boolean'];
  id: Scalars['ID'];
  minPrice?: Maybe<Scalars['Int']>;
  paymentBadges?: Maybe<Array<CapyPaymentBadgeCode>>;
  platformLifetimeClass: TitleLifetimeClass;
  publicEndDateTime: Scalars['DateTime'];
  publicStartDateTime: Scalars['DateTime'];
  saleBadges?: Maybe<Array<CapySaleBadgeCode>>;
};

export type MediaMeta = {
  __typename?: 'MediaMeta';
  /** The product number used to catalog JAV, e.g. SCUTE-378. Obviously only populated for adult titles */
  adultProductCode?: Maybe<Scalars['String']>;
  brandGroups: Array<BrandGroupLight>;
  collections: Array<CollectionLight>;
  commentarySynopsis?: Maybe<Scalars['String']>;
  contentTags: Array<ContentTag>;
  copyright?: Maybe<Scalars['String']>;
  credits: Array<MediaCredit>;
  favoriteCount: Scalars['Int'];
  featureBadges: Array<MediaFeatureBadge>;
  highlights?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logLine?: Maybe<Scalars['String']>;
  nameJa: Scalars['String'];
  nameKana: Scalars['String'];
  originalPrice: Scalars['Int'];
  originalReleaseDate?: Maybe<Scalars['DateTime']>;
  paginatedBonusMaterials: PaginatedBonusMaterials;
  paginatedChapters: PaginatedMediaChapterLight;
  paginatedTrailers: PaginatedTrailers;
  performances: Array<MediaPerformance>;
  productionCompany?: Maybe<ProductionCompanyLight>;
  promoLine?: Maybe<Scalars['String']>;
  ratingCode: RatingCode;
  releaseLifetimeClass: TitleLifetimeClass;
  /** Gets an array of ThumbnailSet, each ThumbnailSet in results is a screenshot. */
  screenshots: Array<ThumbnailSet>;
  studio?: Maybe<StudioLight>;
  synonyms?: Maybe<Array<Scalars['String']>>;
  synopsis?: Maybe<Scalars['String']>;
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for Media.
   */
  thumbnails: Array<ThumbnailSet>;
  /**
   * The following kinds of video consumable types would be provided for a media:
   * MAIN: main video
   * SAMPLE: sample video
   * DUBS: dubbed main video
   * SUBS: subbed main video
   *
   * Please get the video length from the MAIN type video consumable.
   *
   * To get available formats for playing video(codec, resolution, etc),
   * please check videoProfiles under a VideoConsumable.
   */
  videoConsumables: Array<VideoConsumable>;
};

export type MediaMetaPaginatedBonusMaterialsArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type MediaMetaPaginatedChaptersArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type MediaMetaPaginatedTrailersArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type MediaPerformance = {
  __typename?: 'MediaPerformance';
  alias: AliasLight;
  characterName?: Maybe<Scalars['String']>;
};

export type MediaSearchHits = SearchHits & {
  __typename?: 'MediaSearchHits';
  hasMore: Scalars['Boolean'];
  hits: Array<MediaLight>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

/** Block containing a singular featured title */
export type MediaSpotlightBlock = MediaBlock & {
  __typename?: 'MediaSpotlightBlock';
  description?: Maybe<Scalars['String']>;
  displayEndDate: Scalars['DateTime'];
  displayStartDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  spotlight: MediaLight;
};

export type MovieProfile = {
  __typename?: 'MovieProfile';
  typeCode: Scalars['String'];
};

export type MultiAccountUserInfo = {
  __typename?: 'MultiAccountUserInfo';
  accountTypeCode: AccountTypeCode;
  id: Scalars['ID'];
  loginId: Scalars['String'];
  mailRegistrationFlg: Scalars['Boolean'];
  multiAccountId: Scalars['String'];
  securityToken?: Maybe<Scalars['String']>;
  userEmail: Scalars['String'];
  userPurchaseAuth: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Register your favorite information.
   *
   * Register the resource in the favorites list, will change the 'reason' and 'created_at' if <resource> already exists in favoriteList.
   * There are two ways to specify the favoriteList:
   *
   * 1. Specify the ID of the favoriteList. If "Non-existent favoriteList" or "Non-own favoriteList" is specified, an error will occur.
   *
   * 2. Specify the title and domain of your favoriteList.If the favoriteList title and domain do not exist, the favoriteList is automatically created in that domain.
   *
   * 3. automatically created favoriteList is not allowed to be modified (the system does not have this restriction now, but please do not modify it, and the restriction against modification will be added in subsequent versions).
   */
  addFavoriteItem?: Maybe<LibuFavoriteItem>;
  /**
   * Create a favorites list, Create a new favorites list with <Title> in the specified <Domain>.
   *
   * Titles are unique within the same <Domain>,It cannot be duplicated with other favorites lists.
   *
   * Favorites list created this way will be displayed from the list of favorites list.
   *
   * Only ID can be used to get the information of this list in 'favoriteList' method,
   *
   * You cannot make inquiries using Domain and Title.
   */
  addFavoriteList?: Maybe<LibuFavoriteListLight>;
  addPlaylist?: Maybe<LibuPlaylistLight>;
  /**
   * Add an Item to the Playlist. You can add multiple Items at once.
   *
   * If you specify a playlist that does not exist, an error will occur.
   *
   * Returns the successfully added Item.
   */
  addPlaylistItems: Array<LibuPlaylistItem>;
  /**
   * add purchase history item
   *
   * Returns the successfully added Item.
   */
  addPurchaseHistoryItem?: Maybe<LibuPurchaseHistoryItem>;
  /**
   * Delete the Item from the Playlist. You can delete multiple Items at once. Register the rating.
   *
   * Only points and comments can be registered.
   *
   * If you rate the same thing again, overwrite the existing rating without writing a new rating.
   *
   * The so-called evaluation is always registered in this way regardless of whether it has been evaluated or not.
   */
  addRating?: Maybe<LibuRating>;
  /**
   * Register the viewing history,Register the specified resource as a viewing history in the specified domain.
   *
   * If it already exists in the viewing history, perform the following four actions.
   *
   * 1. Set "Last viewed timing" (resume position) to 0.
   *
   * 2. Add 1 to "Number of views".
   *
   * 3. Update the playback time.
   *
   * 4. Update the information specified by other Clients such as Device Code.
   */
  addViewHistory?: Maybe<LibuViewHistoryItem>;
  bulkRemoveBookTitleFavorite: Array<Scalars['ID']>;
  /**
   * Remove a resource from your favorites list.
   *
   * If you specify more than one, it is essential that all of them exist.
   *
   * even if one is not found in it, an error will occur.
   */
  deleteFavoriteItems?: Maybe<Array<LibuFavoriteItem>>;
  /**
   * Delete a favorites list
   *
   * When a favorites list is deleted, all items in the list are also deleted.
   * If the favorite list is not found, it will return null.(Never throws "Not found").
   */
  deleteFavoriteList?: Maybe<LibuFavoriteListLight>;
  deletePlaylist?: Maybe<LibuPlaylistLight>;
  /** Delete the Item from the Playlist. You can delete multiple Items at once. */
  deletePlaylistItems: Array<LibuPlaylistItem>;
  /**
   * batch delete purchase history items
   *
   * you can use the ID or resource ID to delete the item
   *
   * returns the successfully deleted items
   */
  deletePurchaseHistoryItems?: Maybe<DeleteHistoryItemResponse>;
  /** Delete purchased media of the given ids */
  deletePurchasedMedia: Scalars['Boolean'];
  /**
   * Deletes the previously given rating for a resource.
   *
   * If it is not evaluated, an error will occur.
   */
  deleteRating?: Maybe<LibuRating>;
  /**
   * Delete the viewing history.
   *
   * You can delete multiple Items at once, but if you cannot find even one of the specified Items, an error will occur.
   *
   * Only deleted items will be returned. Items not found will not be returned.
   */
  deleteViewHistory?: Maybe<Array<LibuViewHistoryItem>>;
  /** purchase beemi video */
  purchaseBeemiVideo?: Maybe<Scalars['Boolean']>;
  purchaseBookWithTicket: BookPurchaseWithTicketResponseModel;
  removeAllBookHistory: BookHistoryRemovalResponse;
  removeAllVideoHistory: VideoHistoryRemovalResponse;
  removeBookHistory: BookHistoryRemovalResponse;
  removeBookPurchase: BookPurchaseRemoveResponse;
  removeVideoHistory: VideoHistoryRemovalResponse;
  removeVideoHistoryTitles: VideoHistoryRemovalResponse;
  setPreference: BatchAddResult;
  /** set user profile */
  setUserProfile: SetUserProfileResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'SWITCH_ACCOUNT_ERROR' */
  switchAccount: MultiAccountUserInfo;
  /** Registers the download for the license control logic. */
  syncDownloadLicense: SyncLicenseResult;
  /**
   * In case of portal-error, errorCode can be found under gql-error with message 'UPDATE_BIRTHDAY_ERROR'.
   *
   * The month parameter should be an integer between 1 - 12, and day should be between 1 - 31.
   */
  updateBirthday: BirthdayUpdateResponse;
  updateBookTitleEvaluation: BookSakuhin;
  updateBookTitleFavorite: BookSakuhin;
  /** update a favorites list */
  updateFavoriteList?: Maybe<LibuFavoriteListLight>;
  updatePlaylist?: Maybe<LibuPlaylistLight>;
  /**
   * Change the order of the Items in the Playlist.
   *
   * You can freely rearrange the Items in the Playlist.
   *
   * When you get the list, it will be put out in the decided order (including the reverse order).
   */
  updatePlaylistItems: Array<LibuPlaylistItem>;
  /** @deprecated Renamed to webfront_updateTitleEvaluation */
  updateTitleEvaluation?: Maybe<Title>;
  /** @deprecated Renamed to webfront_updateTitleFavorite */
  updateTitleFavorite?: Maybe<Title>;
  updateVideoResumePoint: ResumePointUpdateResponse;
  /**
   * Update the viewing history.
   *
   * Update the Device Code and resume position.
   *
   * If the specified source does not exist in the viewing history, an error will occur.
   */
  updateViewHistory?: Maybe<LibuViewHistoryItem>;
  /** In case of portal-error, errorCode can be found under gql-error with message 'BULK_BOOK_PRODUCT_PURCHASE_ERROR' */
  webfront_bulkPurchaseBookProduct: BulkBookProductPurchaseResponse;
  webfront_cancelBookPreorder: Array<Scalars['ID']>;
  webfront_checkUserSecurityCode: UserSecurityCodeCheckResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'BOOK_PRODUCT_PURCHASE_ERROR' */
  webfront_purchaseBookProduct: BookProductPurchaseResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'VIDEO_PRODUCT_PURCHASE_ERROR' */
  webfront_purchaseVideoProduct: VideoProductPurchaseResponse;
  webfront_setBookPreorder: BookPreorderResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'UPDATE_BOOK_RESUME_POINT_ERROR' */
  webfront_updateBookResumePoint: BookResumePointUpdateResponse;
  /** @deprecated Changed to webfront_updateTitleStageEvaluation */
  webfront_updateTitleEvaluation?: Maybe<Title>;
  /** @deprecated Changed to webfront_updateTitleStageFavorite */
  webfront_updateTitleFavorite?: Maybe<Title>;
  webfront_updateTitleStageEvaluation?: Maybe<TitleStage>;
  webfront_updateTitleStageFavorite?: Maybe<TitleStage>;
  /** In case of portal-error, errorCode can be found under gql-error with message 'USER_SECURITY_CODE_ERROR' */
  webfront_updateUserSecurityCode: UserSecurityCodeUpdateResponse;
};

export type MutationAddFavoriteItemArgs = {
  input: LibuFavoriteItemInput;
};

export type MutationAddFavoriteListArgs = {
  input: AddLibuFavoriteListInput;
};

export type MutationAddPlaylistArgs = {
  input: AddLibuPlaylistInput;
};

export type MutationAddPlaylistItemsArgs = {
  input: LibuPlaylistItemsInput;
};

export type MutationAddPurchaseHistoryItemArgs = {
  input: AddLibuPurchaseHistoryItemInput;
};

export type MutationAddRatingArgs = {
  input: AddLibuRatingInput;
};

export type MutationAddViewHistoryArgs = {
  input: AddLibuViewHistoryInput;
};

export type MutationBulkRemoveBookTitleFavoriteArgs = {
  bookSakuhinIds: Array<Scalars['ID']>;
};

export type MutationDeleteFavoriteItemsArgs = {
  input: DeleteLibuFavoriteItemsInput;
};

export type MutationDeleteFavoriteListArgs = {
  input: DeleteLibuFavoriteListInput;
};

export type MutationDeletePlaylistArgs = {
  input: DeleteLibuPlaylistInput;
};

export type MutationDeletePlaylistItemsArgs = {
  input?: InputMaybe<DeleteLibuPlaylistItemsInput>;
};

export type MutationDeletePurchaseHistoryItemsArgs = {
  input: DeleteLibuPurchaseHistoryItemsInput;
};

export type MutationDeletePurchasedMediaArgs = {
  ids: Array<Scalars['String']>;
};

export type MutationDeleteRatingArgs = {
  input: DeleteLibuRatingInput;
};

export type MutationDeleteViewHistoryArgs = {
  input: DeleteLibuViewHistoryInput;
};

export type MutationPurchaseBeemiVideoArgs = {
  capyId: Scalars['String'];
  deviceCode: Scalars['String'];
  productCode: Scalars['String'];
  purchaseAgent?: InputMaybe<Scalars['String']>;
};

export type MutationPurchaseBookWithTicketArgs = {
  productCode: Scalars['ID'];
};

export type MutationRemoveBookHistoryArgs = {
  bookSakuhinCodes: Array<Scalars['ID']>;
};

export type MutationRemoveBookPurchaseArgs = {
  bookSakuhinCodes: Array<Scalars['ID']>;
};

export type MutationRemoveVideoHistoryArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveVideoHistoryTitlesArgs = {
  ids: Array<Scalars['ID']>;
};

export type MutationSetPreferenceArgs = {
  input: LibuPreferenceInput;
};

export type MutationSetUserProfileArgs = {
  input: SetUserProfileInput;
};

export type MutationSwitchAccountArgs = {
  functionFlag: Scalars['String'];
  multiAccountId: Scalars['String'];
  securityCode?: InputMaybe<Scalars['String']>;
};

export type MutationSyncDownloadLicenseArgs = {
  codes: Array<Scalars['String']>;
};

export type MutationUpdateBirthdayArgs = {
  day: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type MutationUpdateBookTitleEvaluationArgs = {
  bookSakuhinId: Scalars['ID'];
  type: EvaluationTypes;
};

export type MutationUpdateBookTitleFavoriteArgs = {
  bookSakuhinId: Scalars['ID'];
  type: FavoriteTypes;
};

export type MutationUpdateFavoriteListArgs = {
  input: UpdateLibuFavoriteListInput;
};

export type MutationUpdatePlaylistArgs = {
  input: UpdateLibuPlaylistInput;
};

export type MutationUpdatePlaylistItemsArgs = {
  input: UpdateLibuPlaylistItemsInput;
};

export type MutationUpdateTitleEvaluationArgs = {
  id: Scalars['ID'];
  type: EvaluationTypes;
};

export type MutationUpdateTitleFavoriteArgs = {
  id: Scalars['ID'];
  type: FavoriteTypes;
};

export type MutationUpdateVideoResumePointArgs = {
  completeFlag: Scalars['Boolean'];
  episodeCode: Scalars['ID'];
  pauseTimestamp: Scalars['String'];
  playMode: PlayMode;
  resumePoint: Scalars['Int'];
};

export type MutationUpdateViewHistoryArgs = {
  input: UpdateLibuViewHistoryInput;
};

export type MutationWebfront_BulkPurchaseBookProductArgs = {
  productCodes: Array<Scalars['ID']>;
  useCooperationPoints?: InputMaybe<CooperationPointsPolicy>;
};

export type MutationWebfront_CancelBookPreorderArgs = {
  bookCodeList: Array<Scalars['ID']>;
};

export type MutationWebfront_CheckUserSecurityCodeArgs = {
  securityCode: Scalars['String'];
};

export type MutationWebfront_PurchaseBookProductArgs = {
  productCode: Scalars['ID'];
  useCooperationPoints?: InputMaybe<CooperationPointsPolicy>;
};

export type MutationWebfront_PurchaseVideoProductArgs = {
  liveTicketCode?: InputMaybe<Scalars['ID']>;
  productCode?: InputMaybe<Scalars['ID']>;
  purchasingAgent?: InputMaybe<PurchasingAgent>;
  useCooperationPoints?: InputMaybe<CooperationPointsPolicy>;
};

export type MutationWebfront_SetBookPreorderArgs = {
  bookCode: Scalars['ID'];
};

export type MutationWebfront_UpdateBookResumePointArgs = {
  bookFileCode: Scalars['String'];
  completeFlg?: InputMaybe<Scalars['Boolean']>;
  maxResumePoint?: InputMaybe<Scalars['Float']>;
  pauseTimestamp?: InputMaybe<Scalars['DateTime']>;
  resumePoint: Scalars['Float'];
};

export type MutationWebfront_UpdateTitleEvaluationArgs = {
  id: Scalars['ID'];
  type: EvaluationTypes;
};

export type MutationWebfront_UpdateTitleFavoriteArgs = {
  id: Scalars['ID'];
  type: FavoriteTypes;
};

export type MutationWebfront_UpdateTitleStageEvaluationArgs = {
  id: Scalars['ID'];
  type: EvaluationTypes;
};

export type MutationWebfront_UpdateTitleStageFavoriteArgs = {
  id: Scalars['ID'];
  type: FavoriteTypes;
};

export type MutationWebfront_UpdateUserSecurityCodeArgs = {
  newSecurityCode: Scalars['String'];
  oldSecurityCode: Scalars['String'];
};

export type NapiUser = {
  __typename?: 'NAPIUser';
  multiAccId: Scalars['String'];
  platformCode: Scalars['String'];
  platformId: Scalars['String'];
};

/** Block containing the Top 100 newest titles as of the current server time for a planet or in the universe. */
export type NewMediaRankingBlock = MediaBlock & {
  __typename?: 'NewMediaRankingBlock';
  description?: Maybe<Scalars['String']>;
  displayEndDate: Scalars['DateTime'];
  displayStartDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  paginatedMedia: PaginatedMediaLight;
};

export type Node = {
  __typename?: 'Node';
  contentType: ContentType;
  id: Scalars['ID'];
  nameJa: Scalars['String'];
};

export type NodeFilter = {
  contentType: ContentType;
  id: Scalars['ID'];
};

export type OneTimeTokenResponse = {
  __typename?: 'OneTimeTokenResponse';
  token: Scalars['String'];
};

export type OriginalComicBlock = {
  __typename?: 'OriginalComicBlock';
  books: Array<BookSakuhin>;
  code: Scalars['ID'];
  lead: Scalars['String'];
  leadTitle: Scalars['String'];
  results: Scalars['Int'];
  type: BlockType;
};

/** The page information of one paginated response */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Current requested offset */
  currentOffset: Scalars['Int'];
  /** Whether there are additional records after the final record on this page */
  hasNext: Scalars['Boolean'];
  /** Whether there are additional records prior to the first record on this page */
  hasPrevious: Scalars['Boolean'];
  /** Number of records on one page */
  pageSize: Scalars['Int'];
  /** Total number of records in the unpaginated query result */
  totalRecordCount: Scalars['Int'];
};

export type PageInfoHash = {
  __typename?: 'PageInfoHash';
  nextPageHash: Scalars['ID'];
  pageSize: Scalars['Int'];
};

export type PaginatedAliasChapterCredit = PaginatedResponse & {
  __typename?: 'PaginatedAliasChapterCredit';
  pageInfo: PageInfo;
  records: Array<AliasChapterCredit>;
};

export type PaginatedAliasChapterPerformance = PaginatedResponse & {
  __typename?: 'PaginatedAliasChapterPerformance';
  pageInfo: PageInfo;
  records: Array<AliasChapterPerformance>;
};

export type PaginatedAliasCredit = PaginatedResponse & {
  __typename?: 'PaginatedAliasCredit';
  pageInfo: PageInfo;
  records: Array<AliasCredit>;
};

export type PaginatedAliasPerformance = PaginatedResponse & {
  __typename?: 'PaginatedAliasPerformance';
  pageInfo: PageInfo;
  records: Array<AliasPerformance>;
};

export type PaginatedBonusMaterials = PaginatedResponse & {
  __typename?: 'PaginatedBonusMaterials';
  pageInfo: PageInfo;
  records: Array<BonusMaterial>;
};

export type PaginatedContentBlocks = PaginatedResponse & {
  __typename?: 'PaginatedContentBlocks';
  pageInfo: PageInfo;
  records: Array<ContentBlock>;
};

export type PaginatedContentTags = PaginatedResponse & {
  __typename?: 'PaginatedContentTags';
  pageInfo: PageInfo;
  records: Array<ContentTag>;
};

export type PaginatedMediaChapterLight = PaginatedResponse & {
  __typename?: 'PaginatedMediaChapterLight';
  pageInfo: PageInfo;
  records: Array<MediaChapterLight>;
};

export type PaginatedMediaLight = PaginatedResponse & {
  __typename?: 'PaginatedMediaLight';
  pageInfo: PageInfo;
  records: Array<MediaLight>;
};

/** Represents a paginated response */
export type PaginatedResponse = {
  pageInfo: PageInfo;
};

export type PaginatedSalesBlocks = PaginatedResponse & {
  __typename?: 'PaginatedSalesBlocks';
  pageInfo: PageInfo;
  records: Array<SalesBlock>;
};

export type PaginatedTrailers = PaginatedResponse & {
  __typename?: 'PaginatedTrailers';
  pageInfo: PageInfo;
  records: Array<Trailer>;
};

export type Pagination = {
  limit: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
};

/** Known as SaleTypeBadge for Books CMS API */
export type PaymentBadge = {
  __typename?: 'PaymentBadge';
  code: PaymentBadgeCode;
  name: Scalars['String'];
};

export enum PaymentBadgeCode {
  Buy = 'BUY',
  Point = 'POINT',
  Rental = 'RENTAL',
  Sale = 'SALE',
  Sell = 'SELL',
  Svod = 'SVOD',
}

export type PersonFreewordSearchResponse = {
  __typename?: 'PersonFreewordSearchResponse';
  pageInfo: PortalPageInfo;
  people: Array<Credit>;
};

export type PersonWebfront = {
  __typename?: 'PersonWebfront';
  penNameCode?: Maybe<Scalars['ID']>;
  personCode: Scalars['ID'];
  personName: Scalars['String'];
  personNameCode?: Maybe<Scalars['ID']>;
};

export type PingReply = {
  __typename?: 'PingReply';
  calls: Scalars['Int'];
  startedAt: Scalars['String'];
  status: Scalars['String'];
};

export type Planet = {
  __typename?: 'Planet';
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export enum Platform {
  Android = 'ANDROID',
  AndroidTv = 'ANDROID_TV',
  Ios = 'IOS',
  Web = 'WEB',
}

export type PlatformInfo = {
  __typename?: 'PlatformInfo';
  deviceName: Scalars['String'];
  deviceType: Scalars['Int'];
  screenType?: Maybe<ScreenType>;
  userAgent: Scalars['String'];
};

export enum PlayMode {
  Download = 'DOWNLOAD',
  Streaming = 'STREAMING',
}

export enum PlayType {
  Download = 'DOWNLOAD',
  Streaming = 'STREAMING',
}

export type Playable = {
  __typename?: 'Playable';
  cdns: Array<PlayableCdn>;
  type: StreamingType;
};

export type PlayableCdn = {
  __typename?: 'PlayableCdn';
  cdnId: Scalars['ID'];
  licenseUrlList: Array<LicenseUrl>;
  playlistUrl: Scalars['String'];
  timeshiftPlaylistUrl?: Maybe<Scalars['String']>;
  weight: Scalars['Float'];
};

export type PlaybackRight = {
  __typename?: 'PlaybackRight';
  createdAt: Scalars['DateTime'];
  expiredDateTime?: Maybe<Scalars['DateTime']>;
  productCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PlaybackTokenResponse = {
  __typename?: 'PlaybackTokenResponse';
  /** Token to control simultaneous playback */
  isemToken: Scalars['String'];
  /** Token for viewing rights */
  playbackToken: Scalars['String'];
  productCode?: Maybe<Scalars['String']>;
  saleTypeCode?: Maybe<CapySaleTypeCode>;
};

export type PlaylistMovieProfile = {
  __typename?: 'PlaylistMovieProfile';
  cdnId?: Maybe<Scalars['String']>;
  licenseUrlList: Array<LicenseUrl>;
  movieAudioList: Array<AudioType>;
  playlistUrl?: Maybe<Scalars['String']>;
  type?: Maybe<StreamingType>;
};

export type PlaylistUrl = {
  __typename?: 'PlaylistUrl';
  beaconSpan?: Maybe<Scalars['Int']>;
  downloadTitleMeta?: Maybe<DownloadTitleMeta>;
  licenseExpireDate?: Maybe<Scalars['String']>;
  playToken?: Maybe<Scalars['String']>;
  playTokenHash?: Maybe<Scalars['String']>;
  result?: Maybe<Result>;
  resultStatus?: Maybe<Scalars['Int']>;
  subTitle?: Maybe<Scalars['String']>;
  urlInfo: Array<UrlInfo>;
};

export enum PointBackProduct {
  Charge = 'CHARGE',
  Est = 'EST',
  Live = 'LIVE',
  Ppdkara = 'PPDKARA',
  Tod = 'TOD',
}

export type PointBackResponse = {
  __typename?: 'PointBackResponse';
  /** video_cont_flg: ビデオ見放題明細を持っているかフラグ */
  hasVideoSubscription: Scalars['Boolean'];
  isAnnouncedIos: Scalars['Boolean'];
  point: Scalars['Int'];
  setting?: Maybe<PointBackSetting>;
  settlementPlatformCode: Scalars['String'];
};

export type PointBackSetting = {
  __typename?: 'PointBackSetting';
  /** point_back_increase_percentage_flg: ポイントバック率を上げる余地があるフラグ */
  canIncreasePercentage: Scalars['Boolean'];
  /** restricted_to_point_flg: ポイントまたは U コインしか購入することができないフラグ */
  isRestrictedToPoint: Scalars['Boolean'];
  maxPercentage: Scalars['Int'];
  percentage: Scalars['Int'];
  productList: Array<PointBackProduct>;
  scheduleDate: Scalars['String'];
};

/**
 * ポイント利用・失効履歴
 * Portal API docs: https://wiki.unext-info.jp/pages/viewpage.action?pageId=98498593
 */
export type PointHistoryModel = {
  __typename?: 'PointHistoryModel';
  /**
   * ポイント失効予定日時の表示テキスト（「まで有効」も含む）
   * 表示しなければいけない場合だけテキストが入っている
   *
   * e.g. "2022年9月17日 23:59まで有効"
   */
  itemDetailInfo: Scalars['String'];
  /** 詳細 の項目「4. 詳細」に相当する (https://wiki.unext-info.jp/pages/viewpage.action?pageId=85289617) */
  itemName: Scalars['String'];
  /**
   * 詳細 の項目「3. 項目」に相当する (https://wiki.unext-info.jp/pages/viewpage.action?pageId=85289617)
   * 表示しなければいけない場合だけテキストが入っている
   *
   * e.g. "キャンペーン／ギフトコード適用"
   */
  itemType: Scalars['String'];
  /**
   * ポイント操作日
   * e.g. "2022年6月20日"
   */
  operationDate: Scalars['String'];
  /**
   * ポイント操作日時（データとして使う際に）
   * e.g. "20220620170513"
   */
  operationDatetime: Scalars['String'];
  /**
   * ポイント操作時間
   * e.g. "17:05"
   */
  operationTime: Scalars['String'];
  /**
   * ポイント操作対象区分
   *
   * API docs that lists codes: https://wiki.unext-info.jp/pages/viewpage.action?pageId=98498593
   */
  operationTypeCode: Scalars['String'];
  /**
   * 操作ポイント数
   * 付与時に付記する "+" はクライアントで付けた方がよいかと
   */
  point: Scalars['Int'];
  /**
   * ポイント操作の区分
   * ポイント数文字列に色付けする際の基準
   */
  pointHistoryType: PointHistoryType;
};

export enum PointHistoryType {
  Added = 'ADDED',
  Expired = 'EXPIRED',
  Used = 'USED',
}

export type PointbackSchedule = {
  __typename?: 'PointbackSchedule';
  /**
   * ポイントバックするポイント数
   *
   * e.g. 302
   */
  pointBackAmount: Scalars['Int'];
  /**
   * ポイントバックする日
   *
   * e.g. "20181229"
   */
  pointBackDate: Scalars['String'];
};

/** Block containing the Top 100 most popular titles as of the current server time for a planet or in the universe. */
export type PopularityRankingBlock = MediaBlock & {
  __typename?: 'PopularityRankingBlock';
  description?: Maybe<Scalars['String']>;
  displayEndDate: Scalars['DateTime'];
  displayStartDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  paginatedMedia: PaginatedMediaLight;
};

export type PortalPageInfo = {
  __typename?: 'PortalPageInfo';
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  pages: Scalars['Int'];
  results: Scalars['Int'];
};

export enum PortalPointHistoryRequestType {
  Ios = 'IOS',
  Normal = 'NORMAL',
}

export enum PortalSortOrder {
  AlphaAsc = 'ALPHA_ASC',
  AlphaDesc = 'ALPHA_DESC',
  LastUpdateDesc = 'LAST_UPDATE_DESC',
  NewArrival = 'NEW_ARRIVAL',
  Popular = 'POPULAR',
  ProductionYearAsc = 'PRODUCTION_YEAR_ASC',
  ProductionYearDesc = 'PRODUCTION_YEAR_DESC',
  PublicEndAsc = 'PUBLIC_END_ASC',
  PublicStartDesc = 'PUBLIC_START_DESC',
  Recommend = 'RECOMMEND',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UpdateDateTime = 'UPDATE_DATE_TIME',
}

export type PostPlayItem = {
  __typename?: 'PostPlayItem';
  catchphrase: Scalars['String'];
  chromecastFlag?: Maybe<Scalars['Boolean']>;
  country: Scalars['String'];
  displayNo: Scalars['String'];
  duration: Scalars['Int'];
  episodeCode: Scalars['String'];
  episodeName: Scalars['String'];
  exclusive: ExclusiveInfo;
  exclusiveBadgeCode?: Maybe<ExclusiveBadgeCode>;
  hasDub: Scalars['Boolean'];
  hasMultiprice: Scalars['Boolean'];
  hasSubtitle: Scalars['Boolean'];
  introduction: Scalars['String'];
  isNew: Scalars['Boolean'];
  isOriginal: Scalars['Boolean'];
  lastEpisode: Scalars['String'];
  mainCast: Array<Credit>;
  minimumPrice: Scalars['Int'];
  nextUpdateDateTime?: Maybe<Scalars['DateTime']>;
  nfreeBadge: Scalars['String'];
  paymentBadgeList: Array<PaymentBadge>;
  productLineupCodeList: Array<Scalars['String']>;
  productionYear: Scalars['String'];
  publishStyleCode: Scalars['String'];
  rate: Scalars['Int'];
  saleText: Scalars['String'];
  thumbnail: Thumbnail;
  titleCode: Scalars['String'];
  titleName: Scalars['String'];
  updateOfWeek: Scalars['Int'];
};

export type PostPlayResult = {
  __typename?: 'PostPlayResult';
  nextEpisode?: Maybe<PostPlayItem>;
  recommendations?: Maybe<Array<PostPlayItem>>;
  resultType: PostPlayResultType;
};

export enum PostPlayResultType {
  NextEpisode = 'NEXT_EPISODE',
  Recommendations = 'RECOMMENDATIONS',
}

export type Preview = {
  __typename?: 'Preview';
  cdns: Array<PreviewCdn>;
  type: PreviewType;
};

export type PreviewCdn = {
  __typename?: 'PreviewCdn';
  cdnId: Scalars['ID'];
  height: Scalars['Int'];
  playlistUrl: Scalars['String'];
  timeshiftPlaylistUrl?: Maybe<Scalars['String']>;
  updateIntervalMs: Scalars['Int'];
  weight: Scalars['Float'];
  width: Scalars['Int'];
};

export enum PreviewType {
  Jpg = 'JPG',
}

export type Product = {
  __typename?: 'Product';
  chargePoint: Scalars['Int'];
  expire: Scalars['Int'];
  id: Scalars['ID'];
  isPointChargable: Scalars['Boolean'];
  nsboSaleTypeCode: Scalars['String'];
  nsboTypeCode: Scalars['String'];
  planCode: Scalars['String'];
  price: Scalars['Int'];
  productName: Scalars['String'];
  ruleImageUrl: Scalars['String'];
  ruleText: Scalars['String'];
  ruleTextHeading: Scalars['String'];
  ruleTitle: Scalars['String'];
  typeCode: Scalars['String'];
};

export enum ProductTypeCode {
  Charge = 'CHARGE',
  Ftabcharge = 'FTABCHARGE',
  Googcharge = 'GOOGCHARGE',
  Ppdkara = 'PPDKARA',
  Ppdnod = 'PPDNOD',
  Ucoin = 'UCOIN',
}

export type ProductionCompany = {
  __typename?: 'ProductionCompany';
  id: Scalars['ID'];
  media: Array<MediaLight>;
  nameJa: Scalars['String'];
  studios: Array<Studio>;
};

export type ProductionCompanyLight = {
  __typename?: 'ProductionCompanyLight';
  id: Scalars['ID'];
  nameJa: Scalars['String'];
};

export enum ProjectionType {
  Cubemap = 'CUBEMAP',
  Custom = 'CUSTOM',
  Equirectangular = 'EQUIRECTANGULAR',
  Fisheye = 'FISHEYE',
  Unspecified = 'UNSPECIFIED',
}

export type Publisher = {
  __typename?: 'Publisher';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PurchaseHistoryDetail = {
  data: Scalars['String'];
  format: Scalars['String'];
  ver: Scalars['String'];
};

export type PurchaseLiveTicket = {
  __typename?: 'PurchaseLiveTicket';
  id: Scalars['ID'];
  isSelling: Scalars['Boolean'];
  lives: Array<Maybe<PurchaseLiveTicketLiveDescription>>;
  name: Scalars['String'];
  price: Scalars['Int'];
  productCode: Scalars['String'];
  saleEndDateTime: Scalars['DateTime'];
  saleStartDateTime: Scalars['DateTime'];
  typeCode: Scalars['String'];
};

export type PurchaseLiveTicketLiveDescription = {
  __typename?: 'PurchaseLiveTicketLiveDescription';
  copyright?: Maybe<Scalars['String']>;
  deliveryEndDateTime: Scalars['DateTime'];
  deliveryStartDateTime: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  performanceStartDateTime: Scalars['DateTime'];
  saleTypeCode: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
};

export type PurchasedBook = {
  __typename?: 'PurchasedBook';
  bookCode: Scalars['ID'];
  bookNo: Scalars['Int'];
};

export type PurchasedBooksResponse = {
  __typename?: 'PurchasedBooksResponse';
  books: Array<BookSakuhin>;
  pageInfoHash: PageInfoHash;
};

export enum PurchasedMediaSortMode {
  CreatedAt = 'CREATED_AT',
  ExpiredAt = 'EXPIRED_AT',
}

export type PurchasedMediaSorter = {
  order: SortOrder;
  sortMode: PurchasedMediaSortMode;
};

export type PurchasedTitlesResponse = {
  __typename?: 'PurchasedTitlesResponse';
  pageInfo: PortalPageInfo;
  titles: Array<Title>;
};

export enum PurchasingAgent {
  Amazon = 'AMAZON',
  Apple = 'APPLE',
  Default = 'DEFAULT',
  Google = 'GOOGLE',
  Huawei = 'HUAWEI',
}

export type Query = {
  __typename?: 'Query';
  /** Get all Active Sale Content blocks for a planet, with paginated media. */
  activeSaleBlocksV2: PaginatedSalesBlocks;
  /** Gets the full alias object */
  alias?: Maybe<Alias>;
  /** Gets all content tags suggestions grouping by category for search top page */
  allContentTagSuggestions: Array<Suggestion>;
  autoSuggest: Array<Scalars['String']>;
  /** Auto-complete suggestions */
  beemiAutoCompleteSearch?: Maybe<AutoCompleteResponse>;
  beemiInfoList: Array<BeemiInfoListItem>;
  /** Full search */
  beemiSearch?: Maybe<TextSearchResponse>;
  bookCategoryList: Array<BookCategoryListResponse>;
  bookHistory?: Maybe<BookHistoryResponse>;
  bookPersonList: Array<BookPersonListResponse>;
  /** In case of portal-error, errorCode can be found under gql-error with message 'BOOK_TITLE_ERROR' */
  bookTitle: BookSakuhin;
  bookTitle_books?: Maybe<BooksResponse>;
  bookTitle_postPlay: BookPostPlayResult;
  bookTitle_relatedBooks?: Maybe<RelatedBooksResponse>;
  bookTitle_relatedTitles?: Maybe<RelatedVideosResponse>;
  booksRecommend: BooksRecommendResponse;
  /** Gets the full Collection/Series object */
  collection?: Maybe<Collection>;
  /** Gets the full content block with pagination */
  contentBlockFull?: Maybe<ContentBlock>;
  /** Get favorite information. */
  favorite?: Maybe<LibuFavoriteItem>;
  favoriteList?: Maybe<LibuFavoriteList>;
  favoriteLists?: Maybe<LibuFavoriteListHits>;
  /**
   * Get a list of favorites,
   * Specify the acquisition range with Domain Or FavoriteLists.
   */
  favorites?: Maybe<LibuFavoriteItemHits>;
  /**
   * Not implemented yet.!!!(only for music)
   * Get a list of favorites and playlists (Music only),
   * Combine all Playlists of this user in a certain Domain with the Item list of FavoriteList specified.
   * Narrow down based on Filter and sort by OrderBy.
   * @deprecated Not implemented yet.!!!
   */
  favoritesAndPlaylists?: Maybe<LibuFavoriteItemOrPlaylistHits>;
  /** @deprecated Renamed to bookHistory */
  getBookHistory?: Maybe<BookHistoryResponse>;
  getBookTopBlocks?: Maybe<BookTopBlockResponse>;
  getBookTopLeanback?: Maybe<BookTopLeanbackResponse>;
  /** @deprecated Use getSakuhinGroupBlock or getSakuhinGroup instead */
  getCharacterBlock: Array<SakuhinGroup>;
  getCommonProducts: Array<Product>;
  /** @deprecated Renamed to webfront_episodeCredits */
  getEpisodeCredits?: Maybe<EpisodeCreditResponse>;
  /** @deprecated Renamed to webfront_favoriteTitles */
  getFavoriteTitles?: Maybe<FavoriteTitlesResponse>;
  /** @deprecated Renamed to webfront_feature */
  getFeature?: Maybe<Feature>;
  /** @deprecated Renamed to webfront_leanbackGenres */
  getLeanbackGenres: Array<LeanbackGenreGroup>;
  /** @deprecated Renamed to webfront_linearChannels */
  getLinearChannels: Array<Channel>;
  /** @deprecated Renamed to webfront_liveList */
  getLiveList: Array<Live>;
  /** @deprecated Renamed to platformInfo */
  getPlatformInfo: PlatformInfo;
  /** @deprecated Renamed to webfront_recommendBlocks */
  getRecommendBlocks?: Maybe<RecommendBlockResponse>;
  getSakuhinGroup?: Maybe<SakuhinGroup>;
  getSakuhinGroupBlock: Array<SakuhinGroup>;
  /** @deprecated Renamed to webfront_searchGenres */
  getSearchGenres: Array<SearchGenreGroup>;
  getSettingMenu: SettingMenuResponse;
  /** @deprecated Renamed to spotlight */
  getSpotlight: Array<Spotlight>;
  /**
   * In case of portal-error, errorCode can be found under gql-error with message 'VIDEO_TITLE_ERROR'
   * @deprecated Renamed to webfront_title
   */
  getTitle?: Maybe<Title>;
  getUpsellDisplayState: UpsellDisplayState;
  getUpsellGuide: UpsellGuide;
  /** @deprecated Renamed to userInfo */
  getUserInfo?: Maybe<UserInfo>;
  /** @deprecated Renamed to webfront_userRecommendFeature */
  getUserRecommendFeature?: Maybe<Feature>;
  /**
   * episodeSize: number of episodes to be requested
   * @deprecated Renamed to webfront_watchingEpisodes
   */
  getWatchingEpisodes?: Maybe<Array<Episode>>;
  /** @deprecated Insufficient for paging. Use liveBlocks */
  liveBlock: Array<LiveBlock>;
  liveBlockDetail: LiveBlockDetailResponse;
  liveBlocks: LiveBlocksResponse;
  liveCategorySearch: LivePagingResponse;
  liveFreewordSearch: LivePagingResponse;
  liveGroup?: Maybe<LiveGroupResponse>;
  /**
   * Live Playlist URL query for clients which support time-shift playback.
   * For clients which do not support time-shift, use webfront_livePlaylistUrlV2 instead.
   */
  livePlaylistUrl: LivePlaylistUrlResult;
  liveProductList: LiveProductList;
  liveRelatedLives: LivePagingResponse;
  liveRelatedVideos: RelatedVideosResponse;
  /** Gets the full media object */
  media?: Maybe<Media>;
  /** Gets the full media chapter object */
  mediaChapter?: Maybe<MediaChapter>;
  multiAccountUsersInfo: Array<MultiAccountUserInfo>;
  /** Gets Chapters added to the users' My List */
  myListChapters: Array<MediaChapterLight>;
  /** Gets packages added to the user's My List */
  myListPackages: Array<MediaLight>;
  /** Gets a paginated list of the top 100 newest chapters */
  newChaptersV2: PaginatedMediaChapterLight;
  /**
   * newMediaRankingBlock returns Top 100 media in the planet with pagination ranked by
   * platform release date DESCENDING.
   */
  newMediaRankingBlock?: Maybe<NewMediaRankingBlock>;
  originalBooks: BookOriginalTitlesResponse;
  originalComic: BookOriginalTitlesResponse;
  personLives: LivePagingResponse;
  /** Get paginated Pickup Content blocks for a planet */
  pickupBlockV2: PaginatedContentBlocks;
  pingLibu?: Maybe<PingReply>;
  /** Gets all content planets currently available */
  planets: Array<Planet>;
  platformInfo: PlatformInfo;
  /** Acquire the required playback tokens required to start playback */
  playBackTokens?: Maybe<PlaybackTokenResponse>;
  playlist?: Maybe<LibuPlaylist>;
  /**
   * Get a list of items in a playlist,
   * An error will occur if you specify a PlaylistId that does not exist or a PlaylistId of another person.
   */
  playlistItems?: Maybe<LibuPlaylistItemHits>;
  playlists?: Maybe<LibuPlaylistHits>;
  /** Gets a paginated list of the top 100 popular chapters */
  popularChaptersV2: PaginatedMediaChapterLight;
  /** Get popular content tags */
  popularContentTagsV2: PaginatedContentTags;
  /** popularityRankingBlock return Top 100 media in the planet with pagination ranked by popularity DESCENDING. */
  popularityRankingBlock?: Maybe<PopularityRankingBlock>;
  /** items List of user preferences. */
  preferenceItems: Array<LibuPreferenceItem>;
  preferenceOptions?: Maybe<Array<LibuPreferenceOption>>;
  /**
   * Get a purchaseItems of user.
   *
   * you can use the ID or resource ID to query the item
   */
  purchaseHistoryItem?: Maybe<Array<LibuPurchaseHistoryItem>>;
  /**
   * Get a list of items in purchase history items.
   *
   * you can specify a domain to get the purchase history items in that domain.
   *
   * pagination is optional.
   */
  purchaseHistoryItems?: Maybe<LibuPurchaseHistoryItemHits>;
  purchasedLives: LivePagingResponse;
  /** Get the list of purchased media */
  purchasedMedia: PaginatedMediaLight;
  queryRegisteredDevices: Array<BookDeviceRegistrationInfo>;
  /** Acquisition of evaluation list issued by this user. */
  ratings?: Maybe<LibuRatingHits>;
  /**
   * Gets the first N items of each block recommended by the data science recommendation system (starship).
   * See type for more details. Use getContentBlockFull for paginated block.
   */
  recommendedBlocksV2: PaginatedContentBlocks;
  /** Gets a paginated list of the top 100 recommended chapters for the given user */
  recommendedChaptersV2: PaginatedMediaChapterLight;
  registerDevice: BookDeviceRegistrationInfo;
  /** revenueRankingBlock return Top 100 media in the planet with pagination ranked by revenue DESCENDING */
  revenueRankingBlock?: Maybe<RevenueRankingBlock>;
  /** Get basic information by node Xid, returns: Xid, Node name in Japanese and ContentType */
  simpleNodeLookup?: Maybe<SimpleNodeLookupResponse>;
  spotlight: Array<Spotlight>;
  /** Gets 4 spotlighted suggestions for search top page */
  spotlightedSuggestions: Array<SpotlightedSuggestion>;
  subscriptionProductList: SubscriptionProductList;
  unRegisterDevice: Array<BookDeviceRegistrationInfo>;
  /**
   * universalNewMediaRankingBlock returns Top 100 media in the universe with pagination ranked by
   * platform release date DESCENDING.
   */
  universalNewMediaRankingBlock?: Maybe<NewMediaRankingBlock>;
  /**
   * universalPopularityRankingBlock return Top 100 media in the universe with pagination ranked by
   * popularity DESCENDING.
   */
  universalPopularityRankingBlock?: Maybe<PopularityRankingBlock>;
  /** universalRevenueRankingBlock return Top 100 media in the universe with pagination ranked by revenue DESCENDING */
  universalRevenueRankingBlock?: Maybe<RevenueRankingBlock>;
  userCard: UserCard;
  userInfo?: Maybe<UserInfo>;
  userPointHistory: Array<Maybe<PointHistoryModel>>;
  userPointbackSchedule: Array<PointbackSchedule>;
  /** Get a profile of user. */
  userProfile: UserProfileResponse;
  /**
   * Get a list of viewing history,
   * The viewing history related to a certain resource is acquired by viewHistory in that resource.
   * See the documentation for that resource for more information.
   */
  viewHistoryItems?: Maybe<LibuViewHistoryItemHits>;
  webfront_bookCategory: BookCategoryResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'BOOK_FEATURES_ERROR' */
  webfront_bookFeatures: BookFeatureResponse;
  webfront_bookFreewordSearch: BookFreewordSearchResponse;
  webfront_bookMetaSearch?: Maybe<BookMetaSearchResponse>;
  webfront_bookPerson: BookPersonResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'BOOK_PLAYLIST_URL_ERROR' */
  webfront_bookPlaylistUrl: BookPlaylistUrlResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'BOOK_PRODUCT_LIST_ERROR' */
  webfront_bookProductList: BookProductList;
  webfront_bookRanking: BookRankingResponse;
  webfront_bookRecommendBlocks: BookRecommendBlockResponse;
  webfront_bookUserRecommended?: Maybe<BookUserRecommendedResponse>;
  webfront_episodeCredits?: Maybe<EpisodeCreditResponse>;
  webfront_favoriteBooks: FavoriteBooksResponse;
  webfront_favoriteTitles?: Maybe<FavoriteTitlesResponse>;
  webfront_feature?: Maybe<Feature>;
  webfront_featureTemplate: FeatureTemplateResponse;
  webfront_freeBooks: BookFreeResponse;
  webfront_getLinearChannel: Channel;
  /** In case of portal-error, errorCode can be found under gql-error with message 'LIVE_DETAIL_ERROR'. */
  webfront_getLive: Live;
  /** @deprecated use liveRelatedLives with paging info included. */
  webfront_getLive_relatedLives?: Maybe<Array<Live>>;
  /** @deprecated use liveRelatedVideos with paging info included. */
  webfront_getLive_relatedVideos?: Maybe<Array<Title>>;
  /**
   * menuCode must be a genre menu code. Sub menu codes are not accepted and will return
   * nothing.
   * @deprecated Please use webfront_getSingleSearchGenre for querying details of one single searchGenre. This query is not very friendly for caching so we decide to deprecate it.
   */
  webfront_getSearchGenre: SearchGenreGroup;
  webfront_getSingleSearchGenre: SearchGenre;
  webfront_historyTitles: HistoryTitlesResponse;
  webfront_infoList: Array<InfoListItem>;
  webfront_leadTitle: LeadTitle;
  webfront_leanbackGenres: Array<LeanbackGenreGroup>;
  webfront_linearChannels: Array<Channel>;
  webfront_linearPlaylistUrl?: Maybe<LivePlaylistUrl>;
  /** @deprecated use liveCategorySearch */
  webfront_liveCategorySearch: Array<Live>;
  /** @deprecated use liveFreewordSearch */
  webfront_liveFreewordSearch: Array<Live>;
  webfront_liveList: Array<Live>;
  /**
   * Live Playlist URL query for clients which do NOT support time-shift playback.
   * After live delivery ends, this returns 1101 error and therefore time-shift URLs can not be retrieved.
   * For clients which support time-shift, use livePlaylistUrl instead.
   */
  webfront_livePlaylistUrlV2?: Maybe<LivePlaylistUrlV2>;
  webfront_livePlaylistValidation: LivePlaylistValidationResult;
  /** @deprecated Use liveProductList instead */
  webfront_liveTicketList: Array<PurchaseLiveTicket>;
  webfront_magazines: MagazinesResponse;
  webfront_newBooks: BookNewArrivalsResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'ONE_TIME_TOKEN_ERROR' */
  webfront_oneTimeToken?: Maybe<OneTimeTokenResponse>;
  /** @deprecated Use originalBooks */
  webfront_originalBooks: BookOriginalTitlesResponse;
  webfront_person?: Maybe<PersonWebfront>;
  webfront_personFreewordSearch: PersonFreewordSearchResponse;
  /** @deprecated use personLives */
  webfront_personLiveList: Array<Live>;
  webfront_playlistUrl?: Maybe<PlaylistUrl>;
  webfront_pointBack: PointBackResponse;
  webfront_postPlay?: Maybe<PostPlayResult>;
  webfront_preorderableBooks: BookPreorderableResponse;
  webfront_purchasedBooks: PurchasedBooksResponse;
  /** @deprecated use purchasedLives */
  webfront_purchasedLiveList: Array<Live>;
  webfront_purchasedTitles: PurchasedTitlesResponse;
  webfront_recommendBlocks?: Maybe<RecommendBlockResponse>;
  webfront_searchGenres: Array<SearchGenreGroup>;
  webfront_searchVideo: VideoSearchResponse;
  webfront_seriesRelatedTitles: Array<TitleRelationGroup>;
  webfront_targetDevice?: Maybe<TargetDeviceResponse>;
  /** @deprecated Changed to webfront_title_stage */
  webfront_title?: Maybe<Title>;
  webfront_title_credits?: Maybe<TitleCreditResponse>;
  /** @deprecated Changed to webfront_title_titleEpisodes */
  webfront_title_episodes: Array<Episode>;
  webfront_title_recommendedTitles?: Maybe<RecommendedTitlesResponse>;
  webfront_title_relatedBooks?: Maybe<RelatedBooksResponse>;
  webfront_title_relatedTitles: Array<TitleRelationGroup>;
  webfront_title_stage?: Maybe<TitleStage>;
  webfront_title_titleEpisodes?: Maybe<TitleEpisodes>;
  webfront_upsell: Upsell;
  /** Returned [Feature] will not return typeCode, commentDisplayFlag and titleComments */
  webfront_userRecommendFeature?: Maybe<Feature>;
  webfront_videoFreewordSearch: VideoFreewordSearchResponse;
  /** In case of portal-error, errorCode can be found under gql-error with message 'VIDEO_PRODUCT_LIST_ERROR' */
  webfront_videoProductList?: Maybe<VideoProductList>;
  webfront_videoRanking: VideoRankingResponse;
  webfront_watchingEpisodes?: Maybe<Array<Episode>>;
};

export type QueryActiveSaleBlocksV2Args = {
  itemsPerBlock?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<Pagination>;
  planetId: Scalars['ID'];
};

export type QueryAliasArgs = {
  aliasId: Scalars['ID'];
};

export type QueryAutoSuggestArgs = {
  pageSize?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
  suggesterType: SuggesterType;
};

export type QueryBeemiAutoCompleteSearchArgs = {
  pagination?: InputMaybe<Pagination>;
  query: Scalars['String'];
};

export type QueryBeemiInfoListArgs = {
  isCheck?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBeemiSearchArgs = {
  nodeFilter?: InputMaybe<Array<NodeFilter>>;
  pagination?: InputMaybe<Pagination>;
  query: Scalars['String'];
  ratingCode: RatingCode;
  searchFilters?: InputMaybe<SearchFilter>;
  searchSorters?: InputMaybe<Array<SearchSorter>>;
  searchType: SearchType;
};

export type QueryBookCategoryListArgs = {
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']>;
  menuCodeList: Array<Scalars['ID']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  sortOrder: PortalSortOrder;
};

export type QueryBookHistoryArgs = {
  nextPageHash?: InputMaybe<Scalars['ID']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchWord?: InputMaybe<Scalars['String']>;
};

export type QueryBookPersonListArgs = {
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']>;
  onlyChapter?: InputMaybe<Scalars['Boolean']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  personCodeList: Array<Scalars['ID']>;
  sortOrder: PortalSortOrder;
};

export type QueryBookTitleArgs = {
  bookCode?: InputMaybe<Scalars['ID']>;
  bookSakuhinCode: Scalars['ID'];
  featurePieceCode?: InputMaybe<Scalars['String']>;
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  viewBookCode?: InputMaybe<Scalars['String']>;
};

export type QueryBookTitle_BooksArgs = {
  code: Scalars['ID'];
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryBookTitle_PostPlayArgs = {
  bookCode?: InputMaybe<Scalars['ID']>;
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  includePreorderableFlg?: InputMaybe<Scalars['Boolean']>;
};

export type QueryBookTitle_RelatedBooksArgs = {
  code: Scalars['ID'];
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryBookTitle_RelatedTitlesArgs = {
  code: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryBooksRecommendArgs = {
  bookCode: Scalars['ID'];
  pageNumber?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryCollectionArgs = {
  collectionId: Scalars['ID'];
};

export type QueryContentBlockFullArgs = {
  blockId: Scalars['ID'];
  pagination?: InputMaybe<Pagination>;
  planetId?: InputMaybe<Scalars['ID']>;
};

export type QueryFavoriteArgs = {
  favoriteItem: LibuFavoriteItemKey;
};

export type QueryFavoriteListArgs = {
  favoriteList: LibuFavoriteListKey;
};

export type QueryFavoriteListsArgs = {
  domain: LibuDomain;
  filter?: InputMaybe<LibuFavoriteListFilter>;
  orderBy?: InputMaybe<Array<LibuFavoriteListSortCondition>>;
  pagination?: InputMaybe<LibuPagination>;
};

export type QueryFavoritesArgs = {
  domainOrFavoriteLists: LibuDomainOrFavoriteListsFilter;
  filter?: InputMaybe<LibuFavoriteItemFilter>;
  orderBy?: InputMaybe<Array<LibuFavoriteItemSortCondition>>;
  pagination?: InputMaybe<LibuPagination>;
};

export type QueryFavoritesAndPlaylistsArgs = {
  favoriteLists: Array<LibuFavoriteListKey>;
  filter?: InputMaybe<LibuFavoriteItemAndPlaylistFilter>;
  orderBy?: InputMaybe<Array<LibuFavoriteItemAndPlaylistSortCondition>>;
  pagination?: InputMaybe<LibuPagination>;
  playlistDomain: LibuDomain;
};

export type QueryGetBookHistoryArgs = {
  nextPageHash?: InputMaybe<Scalars['ID']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchWord?: InputMaybe<Scalars['String']>;
};

export type QueryGetBookTopBlocksArgs = {
  bookNumber?: InputMaybe<Scalars['Int']>;
  includePreoderFlag?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryGetCharacterBlockArgs = {
  genreCode: Scalars['String'];
  sakuhinGroupCode?: InputMaybe<Scalars['String']>;
};

export type QueryGetCommonProductsArgs = {
  planCode?: InputMaybe<Scalars['String']>;
  productTypeCode: ProductTypeCode;
};

export type QueryGetEpisodeCreditsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  titleId: Scalars['ID'];
};

export type QueryGetFavoriteTitlesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortOrder: PortalSortOrder;
};

export type QueryGetFeatureArgs = {
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryGetLinearChannelsArgs = {
  genreId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetLiveListArgs = {
  genreId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetRecommendBlocksArgs = {
  extensions?: InputMaybe<Scalars['String']>;
  genreId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sakuhinsPerFeature?: InputMaybe<Scalars['Int']>;
};

export type QueryGetSakuhinGroupArgs = {
  sakuhinGroupCode: Scalars['ID'];
};

export type QueryGetSakuhinGroupBlockArgs = {
  genreCode: Scalars['ID'];
};

export type QueryGetSettingMenuArgs = {
  deviceCode?: InputMaybe<Scalars['Int']>;
};

export type QueryGetSpotlightArgs = {
  genreId: Scalars['ID'];
};

export type QueryGetTitleArgs = {
  id: Scalars['ID'];
};

export type QueryGetUpsellGuideArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type QueryGetUserRecommendFeatureArgs = {
  additionalDataFlag?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  topPickTypeNumber: Scalars['Int'];
};

export type QueryGetWatchingEpisodesArgs = {
  episodeSize?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type QueryLiveBlockArgs = {
  genreCode?: InputMaybe<Scalars['ID']>;
  numPerBlock: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryLiveBlockDetailArgs = {
  genreCode?: InputMaybe<Scalars['ID']>;
  liveBlockCode: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryLiveBlocksArgs = {
  genreCode?: InputMaybe<Scalars['ID']>;
  numPerBlock: Scalars['Int'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryLiveCategorySearchArgs = {
  menuCode: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryLiveFreewordSearchArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type QueryLiveGroupArgs = {
  genreCode: Scalars['ID'];
  liveGroupCode: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryLivePlaylistUrlArgs = {
  liveCode?: InputMaybe<Scalars['String']>;
};

export type QueryLiveProductListArgs = {
  liveCode: Scalars['ID'];
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type QueryLiveRelatedLivesArgs = {
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryLiveRelatedVideosArgs = {
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryMediaArgs = {
  mediaId: Scalars['ID'];
};

export type QueryMediaChapterArgs = {
  mediaChapterId: Scalars['ID'];
};

export type QueryMultiAccountUsersInfoArgs = {
  isAll?: InputMaybe<Scalars['Boolean']>;
};

export type QueryMyListChaptersArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryMyListPackagesArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryNewChaptersV2Args = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryNewMediaRankingBlockArgs = {
  pagination?: InputMaybe<Pagination>;
  planetId: Scalars['ID'];
};

export type QueryOriginalBooksArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  personCode?: InputMaybe<Scalars['String']>;
};

export type QueryOriginalComicArgs = {
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  personCode?: InputMaybe<Scalars['String']>;
};

export type QueryPersonLivesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  personCode: Scalars['ID'];
};

export type QueryPickupBlockV2Args = {
  itemsPerBlock?: InputMaybe<Scalars['Int']>;
  pagination?: InputMaybe<Pagination>;
  planetId: Scalars['ID'];
};

export type QueryPlayBackTokensArgs = {
  bitRateLowerLimit?: InputMaybe<Scalars['Int']>;
  bitRateUpperLimit?: InputMaybe<Scalars['Int']>;
  consumableId: Scalars['ID'];
  contentId: Scalars['ID'];
  deviceId: Scalars['String'];
  isDownload?: Scalars['Boolean'];
  resolutionLowerLimit?: InputMaybe<Scalars['String']>;
  resolutionUpperLimit?: InputMaybe<Scalars['String']>;
};

export type QueryPlaylistArgs = {
  id: Scalars['ID'];
};

export type QueryPlaylistItemsArgs = {
  filter?: InputMaybe<LibuPlaylistItemFilter>;
  orderBy?: InputMaybe<Array<LibuPlaylistItemSortCondition>>;
  pagination?: InputMaybe<LibuPagination>;
  playlistId: Scalars['ID'];
};

export type QueryPlaylistsArgs = {
  domain: LibuDomain;
  filter?: InputMaybe<LibuPlaylistFilter>;
  orderBy?: InputMaybe<Array<LibuPlaylistSortCondition>>;
  pagination?: InputMaybe<LibuPagination>;
};

export type QueryPopularChaptersV2Args = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryPopularContentTagsV2Args = {
  pagination?: InputMaybe<Pagination>;
  planetId?: InputMaybe<Scalars['ID']>;
};

export type QueryPopularityRankingBlockArgs = {
  pagination?: InputMaybe<Pagination>;
  planetId: Scalars['ID'];
};

export type QueryPreferenceItemsArgs = {
  domain: LibuDomain;
};

export type QueryPreferenceOptionsArgs = {
  group: Scalars['String'];
};

export type QueryPurchaseHistoryItemArgs = {
  domain: LibuDomain;
  item: LibuPurchaseHistoryItemKey;
};

export type QueryPurchaseHistoryItemsArgs = {
  domain: LibuDomain;
  filter?: InputMaybe<LibuPurchaseHistoryFilter>;
  orderBy?: InputMaybe<Array<LibuPurchaseHistorySortCondition>>;
  pagination?: InputMaybe<LibuPagination>;
};

export type QueryPurchasedLivesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryPurchasedMediaArgs = {
  pagination?: InputMaybe<Pagination>;
  sorter?: InputMaybe<PurchasedMediaSorter>;
};

export type QueryRatingsArgs = {
  domain: LibuDomain;
  filter?: InputMaybe<LibuRatingFilter>;
  orderBy?: InputMaybe<Array<LibuRatingSortCondition>>;
  pagination?: InputMaybe<LibuPagination>;
};

export type QueryRecommendedBlocksV2Args = {
  blockTypeFilter?: InputMaybe<Array<InputMaybe<ContentBlockType>>>;
  itemsPerBlock?: Scalars['Int'];
  pagination?: InputMaybe<Pagination>;
  planetId?: InputMaybe<Scalars['ID']>;
};

export type QueryRecommendedChaptersV2Args = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryRegisterDeviceArgs = {
  deviceId: Scalars['String'];
  displayName: Scalars['String'];
};

export type QueryRevenueRankingBlockArgs = {
  pagination?: InputMaybe<Pagination>;
  planetId: Scalars['ID'];
};

export type QuerySimpleNodeLookupArgs = {
  nodes?: InputMaybe<SimpleNodeLookupFilter>;
};

export type QuerySpotlightArgs = {
  genreId: Scalars['ID'];
};

export type QueryUnRegisterDeviceArgs = {
  deviceIds: Array<Scalars['String']>;
};

export type QueryUniversalNewMediaRankingBlockArgs = {
  mode: ContentBlockQueryMode;
  pagination?: InputMaybe<Pagination>;
};

export type QueryUniversalPopularityRankingBlockArgs = {
  mode: ContentBlockQueryMode;
  pagination?: InputMaybe<Pagination>;
};

export type QueryUniversalRevenueRankingBlockArgs = {
  pagination?: InputMaybe<Pagination>;
};

export type QueryUserPointHistoryArgs = {
  type: PortalPointHistoryRequestType;
};

export type QueryUserProfileArgs = {
  domain: LibuDomain;
};

export type QueryViewHistoryItemsArgs = {
  domain: LibuDomain;
  filter?: InputMaybe<LibuViewHistoryFilter>;
  orderBy?: InputMaybe<Array<LibuViewHistorySortCondition>>;
  pagination?: InputMaybe<LibuPagination>;
};

export type QueryWebfront_BookCategoryArgs = {
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']>;
  filterSaleType?: InputMaybe<BookSaleType>;
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  menuCode: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortOrder: PortalSortOrder;
};

export type QueryWebfront_BookFeaturesArgs = {
  bookFeatureCode: Scalars['ID'];
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryWebfront_BookFreewordSearchArgs = {
  filterSaleType?: InputMaybe<BookSaleType>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
  sortOrder: PortalSortOrder;
};

export type QueryWebfront_BookMetaSearchArgs = {
  code: Scalars['ID'];
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']>;
  filterSaleType?: InputMaybe<BookSaleType>;
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  metaType: BookMetaType;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sortOrder: PortalSortOrder;
};

export type QueryWebfront_BookPersonArgs = {
  excludeBookSakuhinCode?: InputMaybe<Scalars['String']>;
  filterSaleType?: InputMaybe<BookSaleType>;
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  personCode: Scalars['ID'];
  personNameCode?: InputMaybe<Scalars['ID']>;
  sortOrder: PortalSortOrder;
};

export type QueryWebfront_BookPlaylistUrlArgs = {
  bookFileCode: Scalars['String'];
  includeChapter?: InputMaybe<Scalars['Boolean']>;
};

export type QueryWebfront_BookProductListArgs = {
  bookCode?: InputMaybe<Scalars['ID']>;
  bookSakuhinCode?: InputMaybe<Scalars['ID']>;
};

export type QueryWebfront_BookRankingArgs = {
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  rankingTargetCode: BookRankingTargetCode;
};

export type QueryWebfront_BookRecommendBlocksArgs = {
  booksPerBlock?: InputMaybe<Scalars['Int']>;
  genreId: Scalars['ID'];
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryWebfront_BookUserRecommendedArgs = {
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  tagCode?: InputMaybe<Scalars['ID']>;
};

export type QueryWebfront_EpisodeCreditsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  titleId: Scalars['ID'];
};

export type QueryWebfront_FavoriteBooksArgs = {
  nextPageHash?: InputMaybe<Scalars['ID']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortOrder: PortalSortOrder;
};

export type QueryWebfront_FavoriteTitlesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortOrder: PortalSortOrder;
};

export type QueryWebfront_FeatureArgs = {
  genreId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryWebfront_FeatureTemplateArgs = {
  id: Scalars['ID'];
  includeChapter?: InputMaybe<Scalars['Boolean']>;
};

export type QueryWebfront_FreeBooksArgs = {
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  tagCode: Scalars['ID'];
};

export type QueryWebfront_GetLinearChannelArgs = {
  id: Scalars['ID'];
};

export type QueryWebfront_GetLiveArgs = {
  id: Scalars['ID'];
};

export type QueryWebfront_GetLive_RelatedLivesArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryWebfront_GetLive_RelatedVideosArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryWebfront_GetSearchGenreArgs = {
  menuCode: Scalars['ID'];
};

export type QueryWebfront_GetSingleSearchGenreArgs = {
  menuCode: Scalars['ID'];
};

export type QueryWebfront_HistoryTitlesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryWebfront_InfoListArgs = {
  isCheck?: InputMaybe<Scalars['Boolean']>;
};

export type QueryWebfront_LinearChannelsArgs = {
  genreId?: InputMaybe<Scalars['ID']>;
};

export type QueryWebfront_LinearPlaylistUrlArgs = {
  channelCode?: InputMaybe<Scalars['String']>;
};

export type QueryWebfront_LiveCategorySearchArgs = {
  menuCode: Scalars['ID'];
};

export type QueryWebfront_LiveFreewordSearchArgs = {
  query: Scalars['String'];
};

export type QueryWebfront_LiveListArgs = {
  genreId?: InputMaybe<Scalars['ID']>;
};

export type QueryWebfront_LivePlaylistUrlV2Args = {
  liveCode?: InputMaybe<Scalars['String']>;
};

export type QueryWebfront_LivePlaylistValidationArgs = {
  liveCode?: InputMaybe<Scalars['String']>;
};

export type QueryWebfront_LiveTicketListArgs = {
  id: Scalars['ID'];
};

export type QueryWebfront_MagazinesArgs = {
  magazineGenreCode: Scalars['ID'];
};

export type QueryWebfront_NewBooksArgs = {
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  tagCode: Scalars['ID'];
};

export type QueryWebfront_OriginalBooksArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  personCode?: InputMaybe<Scalars['String']>;
  tagCode: Scalars['ID'];
};

export type QueryWebfront_PersonArgs = {
  code: Scalars['ID'];
};

export type QueryWebfront_PersonFreewordSearchArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type QueryWebfront_PersonLiveListArgs = {
  personCode?: InputMaybe<Scalars['ID']>;
};

export type QueryWebfront_PlaylistUrlArgs = {
  audioTypeList?: InputMaybe<Array<AudioCodecType>>;
  bitrateHigh?: InputMaybe<Scalars['Int']>;
  bitrateLow?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  codec?: InputMaybe<Array<VideoCodec>>;
  dynamicRangeList?: InputMaybe<Array<VideoDynamicRangeType>>;
  httpsSegments?: InputMaybe<Scalars['Boolean']>;
  keyOnly?: InputMaybe<Scalars['Boolean']>;
  playMode?: InputMaybe<Scalars['String']>;
  playType?: InputMaybe<PlayType>;
  singleBitrateOnly?: InputMaybe<Scalars['Boolean']>;
  validationOnly?: InputMaybe<Scalars['Boolean']>;
};

export type QueryWebfront_PointBackArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type QueryWebfront_PostPlayArgs = {
  code?: InputMaybe<Scalars['String']>;
};

export type QueryWebfront_PreorderableBooksArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  tagCode: Scalars['ID'];
};

export type QueryWebfront_PurchasedBooksArgs = {
  nextPageHash?: InputMaybe<Scalars['ID']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  sortOrder: PortalSortOrder;
};

export type QueryWebfront_PurchasedTitlesArgs = {
  searchQuery?: InputMaybe<Scalars['String']>;
  sortOrder: PortalSortOrder;
};

export type QueryWebfront_RecommendBlocksArgs = {
  extensions?: InputMaybe<Scalars['String']>;
  genreId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sakuhinsPerFeature?: InputMaybe<Scalars['Int']>;
};

export type QueryWebfront_SearchVideoArgs = {
  categoryCode?: InputMaybe<Scalars['ID']>;
  dubSubFilter?: InputMaybe<DubSubType>;
  filterSaleType?: InputMaybe<SaleType>;
  genreCode?: InputMaybe<Scalars['ID']>;
  mainGenreCode?: InputMaybe<MainGenreCodeType>;
  menuCode?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  personCode?: InputMaybe<Scalars['ID']>;
  personGroupCode?: InputMaybe<Scalars['ID']>;
  personNameCode?: InputMaybe<Scalars['ID']>;
  sakuhinGroupCode?: InputMaybe<Scalars['ID']>;
  sortOrder: PortalSortOrder;
  tagCode?: InputMaybe<Scalars['ID']>;
};

export type QueryWebfront_SeriesRelatedTitlesArgs = {
  seriesCode?: InputMaybe<Scalars['ID']>;
};

export type QueryWebfront_TargetDeviceArgs = {
  titleCode?: InputMaybe<Scalars['String']>;
};

export type QueryWebfront_TitleArgs = {
  id: Scalars['ID'];
};

export type QueryWebfront_Title_CreditsArgs = {
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryWebfront_Title_EpisodesArgs = {
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryWebfront_Title_RecommendedTitlesArgs = {
  id: Scalars['ID'];
};

export type QueryWebfront_Title_RelatedBooksArgs = {
  id: Scalars['ID'];
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryWebfront_Title_RelatedTitlesArgs = {
  id: Scalars['ID'];
};

export type QueryWebfront_Title_StageArgs = {
  featureCode?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type QueryWebfront_Title_TitleEpisodesArgs = {
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type QueryWebfront_UpsellArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type QueryWebfront_UserRecommendFeatureArgs = {
  additionalDataFlag?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  topPickTypeNumber: Scalars['Int'];
};

export type QueryWebfront_VideoFreewordSearchArgs = {
  filterSaleType?: InputMaybe<SaleType>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
  sortOrder: PortalSortOrder;
};

export type QueryWebfront_VideoProductListArgs = {
  episodeCode: Scalars['ID'];
  purchasingAgent?: InputMaybe<PurchasingAgent>;
  titleCode: Scalars['ID'];
};

export type QueryWebfront_VideoRankingArgs = {
  isCheck?: InputMaybe<Scalars['Boolean']>;
  rankingType: VideoRankingType;
};

export type QueryWebfront_WatchingEpisodesArgs = {
  episodeSize?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export enum RatingCode {
  G = 'G',
  Pg12 = 'PG12',
  R15 = 'R15',
  R18 = 'R18',
  R20 = 'R20',
  Unknown = 'UNKNOWN',
}

export type RecommendBlock = {
  __typename?: 'RecommendBlock';
  /** @deprecated No need to care at client side */
  commentDisplayFlag?: Maybe<Scalars['Boolean']>;
  featureName?: Maybe<Scalars['String']>;
  featurePickupFlag?: Maybe<Scalars['Boolean']>;
  featureTypeCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  leadText?: Maybe<Scalars['String']>;
  mediaTypeCode?: Maybe<Scalars['String']>;
  mediaTypeGroupCode?: Maybe<Scalars['String']>;
  pages?: Maybe<Scalars['Int']>;
  titleComments: Array<FeatureTitleComment>;
  titleResults?: Maybe<Scalars['Int']>;
  titles: Array<Title>;
};

export type RecommendBlockResponse = {
  __typename?: 'RecommendBlockResponse';
  pageInfo: PortalPageInfo;
  recommendBlocks: Array<RecommendBlock>;
};

export type RecommendationBlock = {
  __typename?: 'RecommendationBlock';
  /** @deprecated No need to care at client side */
  commentDisplayFlag?: Maybe<Scalars['Boolean']>;
  featureName?: Maybe<Scalars['String']>;
  featurePickupFlag?: Maybe<Scalars['Boolean']>;
  featureTypeCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  leadText?: Maybe<Scalars['String']>;
  mediaTypeCode?: Maybe<Scalars['String']>;
  mediaTypeGroupCode?: Maybe<Scalars['String']>;
  pages?: Maybe<Scalars['Int']>;
  titleResults?: Maybe<Scalars['Int']>;
  titles: Array<Title>;
};

export type RecommendationBlockResponse = {
  __typename?: 'RecommendationBlockResponse';
  pageInfo: PortalPageInfo;
  recommendationBlocks: Array<RecommendationBlock>;
};

export type RecommendedTitlesResponse = {
  __typename?: 'RecommendedTitlesResponse';
  titles: Array<Title>;
};

export type RelatedBooksResponse = {
  __typename?: 'RelatedBooksResponse';
  books: Array<BookSakuhin>;
  pageInfo?: Maybe<PortalPageInfo>;
};

export type RelatedVideosResponse = {
  __typename?: 'RelatedVideosResponse';
  pageInfo: PortalPageInfo;
  titles: Array<Title>;
};

export enum Resolution {
  Fhd = 'FHD',
  Hhd = 'HHD',
  Sd = 'SD',
  Uhd4K = 'UHD4K',
  Uhd8K = 'UHD8K',
  Unspecified = 'UNSPECIFIED',
}

export type Resource = LibuPlaylistLight | MediaChapterLight | MediaLight;

/** Resource type, ONLY the following types can be used in libu. */
export enum ResourceType {
  /** LIBU Entity */
  LibuPlaylistLight = 'LIBU_PLAYLIST_LIGHT',
  /** CAPY Entity */
  MediaChapterLight = 'MEDIA_CHAPTER_LIGHT',
  /** CAPY Entity */
  MediaLight = 'MEDIA_LIGHT',
  Unknown = 'UNKNOWN',
}

export type Result = {
  __typename?: 'Result';
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type ResumePointUpdateResponse = {
  __typename?: 'ResumePointUpdateResponse';
  updated: Scalars['Boolean'];
};

/** Block containing the Top 100 media titles ranked by sales revenue for a planet or in the universe. */
export type RevenueRankingBlock = MediaBlock & {
  __typename?: 'RevenueRankingBlock';
  description?: Maybe<Scalars['String']>;
  displayEndDate: Scalars['DateTime'];
  displayStartDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  paginatedMedia: PaginatedMediaLight;
};

export type SakuhinGroup = {
  __typename?: 'SakuhinGroup';
  code: Scalars['ID'];
  name: Scalars['String'];
  targetAge: Scalars['String'];
  thumbnail: SakuhinGroupThumbnail;
  topSakuhinList: Array<TopSakuhin>;
  totalSakuhinNum: Scalars['Int'];
};

export type SakuhinGroupThumbnail = {
  __typename?: 'SakuhinGroupThumbnail';
  circle: Scalars['String'];
  square: Scalars['String'];
};

export type SalePeriod = {
  __typename?: 'SalePeriod';
  endDateTime?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  startDateTime?: Maybe<Scalars['String']>;
};

export enum SaleType {
  Mihoudai = 'MIHOUDAI',
  Point = 'POINT',
}

/** Block containing all of the sales that are in progress for a planet */
export type SalesBlock = MediaBlock & {
  __typename?: 'SalesBlock';
  description?: Maybe<Scalars['String']>;
  displayEndDate: Scalars['DateTime'];
  displayStartDate: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  paginatedMedia: PaginatedMediaLight;
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for Trailer.
   */
  thumbnails: Array<ThumbnailSet>;
};

export type SceneSearch = {
  __typename?: 'SceneSearch';
  cdns: Array<SceneSearchCdn>;
  type: SceneSearchType;
};

export type SceneSearchCdn = {
  __typename?: 'SceneSearchCdn';
  cdnId: Scalars['ID'];
  height: Scalars['Int'];
  playlistUrl: Scalars['String'];
  weight: Scalars['Float'];
  width: Scalars['Int'];
};

export type SceneSearchList = {
  __typename?: 'SceneSearchList';
  IMS_AD1?: Maybe<Scalars['String']>;
  IMS_L?: Maybe<Scalars['String']>;
  IMS_M?: Maybe<Scalars['String']>;
  IMS_S?: Maybe<Scalars['String']>;
};

export enum SceneSearchType {
  ImsL = 'IMS_L',
  ImsM = 'IMS_M',
  ImsS = 'IMS_S',
}

export enum ScreenType {
  Pc = 'PC',
  Phone = 'Phone',
  Tv = 'TV',
  Tablet = 'Tablet',
}

export type SearchCategory = {
  __typename?: 'SearchCategory';
  captionDubFlag: Scalars['Boolean'];
  count: Scalars['Int'];
  /** @deprecated Use defaultSortOrder */
  defaultOrder: Scalars['String'];
  defaultSortOrder: PortalSortOrder;
  id: Scalars['ID'];
  link: Scalars['String'];
  menuTypeCode: Scalars['String'];
  name: Scalars['String'];
  thumbnail: IconThumbnail;
};

export type SearchFilter = {
  duration?: InputMaybe<IntQueryOperator>;
  featureBadges1?: InputMaybe<Array<MediaFeatureBadge>>;
  featureBadges2?: InputMaybe<Array<MediaFeatureBadge>>;
  hasSvod?: InputMaybe<Scalars['Boolean']>;
  onSale?: InputMaybe<Scalars['Boolean']>;
  platformLifetimeClass?: InputMaybe<Array<TitleLifetimeClass>>;
  price?: InputMaybe<IntQueryOperator>;
  releaseLifetimeClass?: InputMaybe<Array<TitleLifetimeClass>>;
};

export type SearchGenre = {
  __typename?: 'SearchGenre';
  captionDubFlag: Scalars['Boolean'];
  /** @deprecated Use defaultSortOrder */
  defaultOrder: Scalars['String'];
  defaultSortOrder: PortalSortOrder;
  displayCode: Scalars['String'];
  id: Scalars['ID'];
  menuTypeCode: Scalars['String'];
  name: Scalars['String'];
  searchCategories: Array<SearchCategory>;
};

export type SearchGenreGroup = {
  __typename?: 'SearchGenreGroup';
  displayCode: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  searchGenres: Array<SearchGenre>;
};

export type SearchHits = {
  hasMore: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type SearchMenu = {
  __typename?: 'SearchMenu';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export enum SearchSortMode {
  FavoriteCount = 'FAVORITE_COUNT',
  Length = 'LENGTH',
  PlatformReleaseDate = 'PLATFORM_RELEASE_DATE',
  Plays = 'PLAYS',
  Price = 'PRICE',
  ReleaseDate = 'RELEASE_DATE',
  Relevance = 'RELEVANCE',
  Sales = 'SALES',
  UserEvaluation = 'USER_EVALUATION',
}

export enum SearchSortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SearchSorter = {
  order: SearchSortOrder;
  sortMode: SearchSortMode;
};

export type SearchSuggestionNode = {
  __typename?: 'SearchSuggestionNode';
  contentType: ContentType;
  /**
   * The description field provides additional information for enriching frontend displays in some needs,
   * e.g. category name for ContentTag suggestions.
   */
  description: Scalars['String'];
  id: Scalars['ID'];
  score: Scalars['Float'];
  text: Scalars['String'];
};

export enum SearchType {
  Alias = 'ALIAS',
  All = 'ALL',
  MediaChapter = 'MEDIA_CHAPTER',
  PublishedMedia = 'PUBLISHED_MEDIA',
  VideoMedia = 'VIDEO_MEDIA',
}

export enum ServicePlatformCode {
  Ana = 'ANA',
  Ccc = 'CCC',
  Gelatoni = 'GELATONI',
  Ldh = 'LDH',
  Rakuten = 'RAKUTEN',
  Unext = 'UNEXT',
  Unknown = 'UNKNOWN',
}

/**
 * input for modifying user profile.
 * domain: to which domain the profile belongs.
 * beemiProfile: server should pick this up if domain is "BEEMI".
 */
export type SetUserProfileInput = {
  beemiProfile?: InputMaybe<BeemiProfileInput>;
  domain: LibuDomain;
};

export type SetUserProfileResponse = {
  __typename?: 'SetUserProfileResponse';
  profile: LibuUserProfile;
};

export type SettingMenuItem = {
  __typename?: 'SettingMenuItem';
  location: Location;
  serviceControlCode: Scalars['String'];
  serviceControlName: Scalars['String'];
  url: Scalars['String'];
};

export type SettingMenuResponse = {
  __typename?: 'SettingMenuResponse';
  menu: Array<SettingMenuItem>;
};

export type SimpleNodeLookupFilter = {
  forceCacheRefresh?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type SimpleNodeLookupResponse = {
  __typename?: 'SimpleNodeLookupResponse';
  nodes?: Maybe<Array<Node>>;
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Spotlight = {
  __typename?: 'Spotlight';
  id: Scalars['ID'];
  image?: Maybe<SpotlightImage>;
  linkCode?: Maybe<Scalars['String']>;
  linkType?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
};

export type SpotlightImage = {
  __typename?: 'SpotlightImage';
  landscape?: Maybe<Scalars['String']>;
  standard?: Maybe<Scalars['String']>;
};

export type SpotlightedSuggestion = {
  __typename?: 'SpotlightedSuggestion';
  backgroundImage: ThumbnailSet;
  contentType: ContentType;
  /**
   * The description field provides additional information for enriching frontend displays in some needs,
   * e.g. category name for ContentTag suggestions.
   */
  description: Scalars['String'];
  id: Scalars['ID'];
  text: Scalars['String'];
};

export enum StereoLayout {
  BottomTop = 'BOTTOM_TOP',
  Custom = 'CUSTOM',
  LeftRight = 'LEFT_RIGHT',
  RightLeft = 'RIGHT_LEFT',
  TopBottom = 'TOP_BOTTOM',
  Unspecified = 'UNSPECIFIED',
}

export enum StreamingType {
  Dash = 'DASH',
  DashDolbyvision = 'DASH_DOLBYVISION',
  HlsAes = 'HLS_AES',
  HlsAesVr = 'HLS_AES_VR',
  HlsCmaf = 'HLS_CMAF',
  HlsFp = 'HLS_FP',
  HlsIs = 'HLS_IS',
  HlsSAes = 'HLS_S_AES',
  Marlin = 'MARLIN',
  Smooth = 'SMOOTH',
}

export type Studio = {
  __typename?: 'Studio';
  id: Scalars['ID'];
  media: Array<Media>;
  nameJa: Scalars['String'];
};

export type StudioLight = {
  __typename?: 'StudioLight';
  id: Scalars['ID'];
  nameJa: Scalars['String'];
};

export type SubgenreTag = {
  __typename?: 'SubgenreTag';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type SubscriptionProductList = {
  __typename?: 'SubscriptionProductList';
  productList: Array<SubscriptionProduct>;
};

export enum SuggesterType {
  /** TV以外の場合 */
  General = 'GENERAL',
  /** TVの場合 */
  Tv = 'TV',
}

export type Suggestion = {
  __typename?: 'Suggestion';
  contentType: ContentType;
  /**
   * The description field provides additional information for enriching frontend displays in some needs,
   * e.g. category name for ContentTag suggestions.
   */
  description: Scalars['String'];
  id: Scalars['ID'];
  incidence: Scalars['Int'];
  nameJa: Scalars['String'];
};

export type SyncLicenseResult = {
  __typename?: 'SyncLicenseResult';
  deletedFileCodes: Array<Scalars['String']>;
};

export type TargetDevice = {
  __typename?: 'TargetDevice';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type TargetDeviceResponse = {
  __typename?: 'TargetDeviceResponse';
  isLicensedPC: Scalars['Boolean'];
  isLicensedTV: Scalars['Boolean'];
};

export type TextSearchResponse = {
  __typename?: 'TextSearchResponse';
  aliases: AliasSearchHits;
  media: MediaSearchHits;
  mediaChapters: MediaChaptersSearchHits;
  suggestions: Array<Suggestion>;
};

export type Thumbnail = {
  __typename?: 'Thumbnail';
  /** Only set for videos */
  other?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  /** Only set for Channel */
  secondaryHD?: Maybe<Scalars['String']>;
  standard?: Maybe<Scalars['String']>;
  /** Only set for LiveNotice */
  wide: Array<Scalars['String']>;
};

/**
 * A ThumbnailSet represent the same image but in different variants(aspect ratio/resolution).
 * spec: https://wiki.unext-info.jp/pages/viewpage.action?pageId=106375214
 */
export type ThumbnailSet = {
  __typename?: 'ThumbnailSet';
  /** Square image 1(3:4 < aspect ratio < 4:3, < 580K pixels.) Example: 640x640 */
  s1: Scalars['String'];
  /** Square image 2(3:4 < aspect ratio < 4:3, between 580K and 3M pixels.) Example: 1440x1440 */
  s2: Scalars['String'];
  /** Square image 3(3:4 < aspect ratio < 4:3, between 3M and 16M pixels.) Example: 2880x2880 */
  s3: Scalars['String'];
  /** Tight image 1(aspect ratio <= 3:4, < 580K pixels.) Example: 320x640 */
  t1: Scalars['String'];
  /** Tight image 2(aspect ratio <= 3:4, between 580K and 3M pixels.) Example: 1080x1920 */
  t2: Scalars['String'];
  /** Tight image 3(aspect ratio <= 3:4, between 3M and 16M pixels.) Example: 2160x3840 */
  t3: Scalars['String'];
  /** Ultra-wide image 1(extreme aspect ratio, e.g. 21:9) */
  uw1: Scalars['String'];
  /** Ultra-wide image 2(extreme aspect ratio, e.g. 21:9) */
  uw2: Scalars['String'];
  /** Ultra-wide image 3(extreme aspect ratio, e.g. 21:9) */
  uw3: Scalars['String'];
  /** Wide image 1(aspect ratio >= 4:3, < 580K pixels.) Example: 640x320 */
  w1: Scalars['String'];
  /** Wide image 2(aspect ratio >= 4:3, between 580K and 3M pixels.) Example: 1920x1080 */
  w2: Scalars['String'];
  /** Wide image 3(aspect ratio >= 4:3, between 3M and 16M pixels.) Example: 3840x2160 */
  w3: Scalars['String'];
};

export type Title = {
  __typename?: 'Title';
  attractions?: Maybe<Scalars['String']>;
  bookmarkStatus?: Maybe<Scalars['Boolean']>;
  boughtEpisodeNumberTextList?: Maybe<Array<Scalars['String']>>;
  cast: Array<Credit>;
  castSummary?: Maybe<Scalars['String']>;
  catchphrase?: Maybe<Scalars['String']>;
  check?: Maybe<Scalars['String']>;
  closestExpiryDateText?: Maybe<Scalars['String']>;
  comingSoonMainEpisodeCount?: Maybe<Scalars['Int']>;
  copyright?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  credits?: Maybe<TitleCreditResponse>;
  currentEpisode?: Maybe<Episode>;
  endDateText?: Maybe<Scalars['String']>;
  /** Returns the (user's) current episode if no ID is provided. */
  episode: Episode;
  /** @deprecated Changed to titleEpisodes */
  episodes: Array<Episode>;
  exclusive: ExclusiveInfo;
  exclusiveBadgeCode?: Maybe<ExclusiveBadgeCode>;
  hasDub?: Maybe<Scalars['Boolean']>;
  hasMultiprice?: Maybe<Scalars['Boolean']>;
  hasSubtitle?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isNew?: Maybe<Scalars['Boolean']>;
  isOriginal: Scalars['Boolean'];
  lastEpisode?: Maybe<Scalars['String']>;
  mainCast: Array<Credit>;
  mainGenreCode?: Maybe<MainGenreCodeType>;
  mainGenreName?: Maybe<Scalars['String']>;
  mediaGroupTypeCode?: Maybe<Scalars['String']>;
  mediaTypeCode?: Maybe<Scalars['String']>;
  minimumPrice?: Maybe<Scalars['Int']>;
  missingAlertText?: Maybe<Scalars['String']>;
  nextUpdateDate?: Maybe<Scalars['String']>;
  nextUpdateDateTime?: Maybe<Scalars['DateTime']>;
  nfreeBadge?: Maybe<Scalars['String']>;
  nodCatchupFlag?: Maybe<Scalars['Boolean']>;
  nodSpecialFlag?: Maybe<Scalars['Boolean']>;
  onairFlag?: Maybe<Scalars['Boolean']>;
  paymentBadgeList: Array<PaymentBadge>;
  productLineupCodeList: Array<Scalars['String']>;
  productionYear?: Maybe<Scalars['String']>;
  publicEndDate?: Maybe<Scalars['DateTime']>;
  publicMainEpisodeCount?: Maybe<Scalars['Int']>;
  publicStartDate?: Maybe<Scalars['DateTime']>;
  publishStyleCode?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Int']>;
  relatedBooks?: Maybe<RelatedBooksResponse>;
  relatedTitles: Array<TitleRelationGroup>;
  resolutionCode?: Maybe<Scalars['String']>;
  restrictedCode?: Maybe<Scalars['String']>;
  sakuhinNotices?: Maybe<Array<Scalars['String']>>;
  saleText?: Maybe<Scalars['String']>;
  seriesCode?: Maybe<Scalars['String']>;
  seriesInOrder?: Maybe<Scalars['Int']>;
  seriesName?: Maybe<Scalars['String']>;
  sinceYear?: Maybe<Scalars['String']>;
  story?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Thumbnail>;
  /** @deprecated use titleComments in Feature or RecommendBlock */
  titleComment?: Maybe<Scalars['String']>;
  titleEpisodes?: Maybe<TitleEpisodes>;
  /** @deprecated not used */
  titleHeading?: Maybe<Scalars['String']>;
  titleName?: Maybe<Scalars['String']>;
  totalEpisodes?: Maybe<Scalars['Int']>;
  updateOfWeek?: Maybe<Scalars['Int']>;
  userRate?: Maybe<Scalars['Int']>;
};

export type TitleCreditsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type TitleEpisodeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TitleEpisodesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type TitleRelatedBooksArgs = {
  includeChapter?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type TitleTitleEpisodesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type TitleCreditResponse = {
  __typename?: 'TitleCreditResponse';
  hasEpisodeCredits?: Maybe<Scalars['Boolean']>;
  pageInfo: PortalPageInfo;
  titleCredits: Array<Credit>;
};

export type TitleEpisodes = {
  __typename?: 'TitleEpisodes';
  episodes: Array<Episode>;
  pageInfo: PortalPageInfo;
};

export type TitleFeature = {
  __typename?: 'TitleFeature';
  featureName: Scalars['String'];
  id: Scalars['ID'];
  titleComment: Scalars['String'];
};

export enum TitleLifetimeClass {
  New = 'NEW',
  Old = 'OLD',
  SemiNew = 'SEMI_NEW',
  Unspecified = 'UNSPECIFIED',
}

export type TitleRelationGroup = {
  __typename?: 'TitleRelationGroup';
  groupName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  titles: Array<Title>;
};

export type TitleStage = {
  __typename?: 'TitleStage';
  attractions?: Maybe<Scalars['String']>;
  bookmarkStatus?: Maybe<Scalars['Boolean']>;
  cast: Array<Credit>;
  castSummary?: Maybe<Scalars['String']>;
  catchphrase?: Maybe<Scalars['String']>;
  check?: Maybe<Scalars['String']>;
  comingSoonMainEpisodeCount?: Maybe<Scalars['Int']>;
  copyright?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  currentEpisode?: Maybe<Episode>;
  displayPublicEndDate?: Maybe<Scalars['String']>;
  endDateText?: Maybe<Scalars['String']>;
  /** Returns the (user's) current episode if no ID is provided. */
  episode: Episode;
  exclusive: ExclusiveInfo;
  exclusiveBadgeCode?: Maybe<ExclusiveBadgeCode>;
  /** Returns feature information if featureCode is provided */
  feature?: Maybe<TitleFeature>;
  hasDub?: Maybe<Scalars['Boolean']>;
  hasMultiprice?: Maybe<Scalars['Boolean']>;
  hasSubtitle?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isNew?: Maybe<Scalars['Boolean']>;
  isOriginal: Scalars['Boolean'];
  lastEpisode?: Maybe<Scalars['String']>;
  mainCast: Array<Credit>;
  mainGenreCode?: Maybe<MainGenreCodeType>;
  mainGenreName?: Maybe<Scalars['String']>;
  mediaGroupTypeCode?: Maybe<Scalars['String']>;
  mediaTypeCode?: Maybe<Scalars['String']>;
  minimumPrice?: Maybe<Scalars['Int']>;
  missingAlertText?: Maybe<Scalars['String']>;
  nextUpdateDate?: Maybe<Scalars['String']>;
  nextUpdateDateTime?: Maybe<Scalars['DateTime']>;
  nfreeBadge?: Maybe<Scalars['String']>;
  nodCatchupFlag?: Maybe<Scalars['Boolean']>;
  nodSpecialFlag?: Maybe<Scalars['Boolean']>;
  onairFlag?: Maybe<Scalars['Boolean']>;
  paymentBadgeList: Array<PaymentBadge>;
  productLineupCodeList: Array<Scalars['String']>;
  productionYear?: Maybe<Scalars['String']>;
  publicEndDate?: Maybe<Scalars['DateTime']>;
  publicMainEpisodeCount?: Maybe<Scalars['Int']>;
  publicStartDate?: Maybe<Scalars['DateTime']>;
  publishStyleCode?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Int']>;
  resolutionCode?: Maybe<Scalars['String']>;
  restrictedCode?: Maybe<Scalars['String']>;
  sakuhinNotices?: Maybe<Array<Scalars['String']>>;
  saleText?: Maybe<Scalars['String']>;
  seriesCode?: Maybe<Scalars['String']>;
  seriesName?: Maybe<Scalars['String']>;
  sinceYear?: Maybe<Scalars['String']>;
  story?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Thumbnail>;
  /** @deprecated not used */
  titleComment?: Maybe<Scalars['String']>;
  /** @deprecated not used */
  titleHeading?: Maybe<Scalars['String']>;
  titleName?: Maybe<Scalars['String']>;
  /** @deprecated Use publicMainEpisodeCount */
  totalEpisodes?: Maybe<Scalars['Int']>;
  updateOfWeek?: Maybe<Scalars['Int']>;
  userRate?: Maybe<Scalars['Int']>;
};

export type TitleStageEpisodeArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type TitleStageFeatureArgs = {
  featureCode?: InputMaybe<Scalars['ID']>;
};

export type TopSakuhin = {
  __typename?: 'TopSakuhin';
  sakuhinName: Scalars['String'];
  thumbnail: Thumbnail;
};

export type Trailer = {
  __typename?: 'Trailer';
  cmsXid: Scalars['String'];
  id: Scalars['ID'];
  introduction: Scalars['String'];
  nameJa: Scalars['String'];
  /**
   * Gets an array of ThumbnailSet, the first ThumbnailSet in results
   * is the main thumbnail(cover image) for Trailer.
   */
  thumbnails: Array<ThumbnailSet>;
  trailerNoDisplayName?: Maybe<Scalars['String']>;
};

export type UBook = {
  __typename?: 'UBook';
  content?: Maybe<Scalars['String']>;
  coverImage?: Maybe<Scalars['String']>;
  isEncrypted?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  scale?: Maybe<Scalars['String']>;
};

export type UnextPublishingDetail = {
  __typename?: 'UnextPublishingDetail';
  introduction?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<BookPublishingDetailThumbnail>;
};

export type UnknownUser = {
  __typename?: 'UnknownUser';
  multiAccId: Scalars['String'];
  platformCode: Scalars['String'];
  platformId: Scalars['String'];
};

export type UpdateLibuFavoriteListInput = {
  favoriteList: LibuFavoriteListInput;
  id: Scalars['ID'];
};

export type UpdateLibuPlaylistInput = {
  id: Scalars['ID'];
  playlist: LibuPlaylistInput;
};

/** update Item (multiple) to playlist's input. Update only the sort order. */
export type UpdateLibuPlaylistItemsInput = {
  items: Array<Scalars['ID']>;
  /**
   * Move the specified items to this position
   *
   * If it is 1, it moves to the beginning, and if it is 0, it moves to the end.
   */
  moveToPosition?: InputMaybe<Scalars['Int']>;
  playlistId: Scalars['ID'];
};

export type UpdateLibuRatingInput = {
  domain: LibuDomain;
  rating: LibuRatingInput;
};

/** update viewing history's input. */
export type UpdateLibuViewHistoryInput = {
  domain: LibuDomain;
  item: LibuViewHistoryInput;
};

export type Upsell = {
  __typename?: 'Upsell';
  /** In case of portal-error, errorCode can be found under gql-error with message 'UPSELL_ERROR' */
  canDisplay: Scalars['Boolean'];
  /** In case of portal-error, errorCode can be found under gql-error with message 'UPSELL_GUIDE_ERROR' */
  guide: UpsellGuide;
};

export type UpsellDisplayState = {
  __typename?: 'UpsellDisplayState';
  canDisplay: Scalars['Boolean'];
};

export type UpsellGuide = {
  __typename?: 'UpsellGuide';
  destinationUrl: Scalars['String'];
  hasRemainingPpd: Scalars['Boolean'];
  needSubscribeAppStore: Scalars['Boolean'];
  subscriptionDisabled?: Maybe<UpsellGuideSubscriptionDisabled>;
  userPlatformCode: Scalars['String'];
};

export type UpsellGuideSubscriptionDisabled = {
  __typename?: 'UpsellGuideSubscriptionDisabled';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type UrlInfo = {
  __typename?: 'UrlInfo';
  captionFlg?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  commodityCode?: Maybe<Scalars['String']>;
  dubFlg?: Maybe<Scalars['Boolean']>;
  endPoint?: Maybe<Scalars['Int']>;
  endrollStartPosition?: Maybe<Scalars['Int']>;
  holderId?: Maybe<Scalars['String']>;
  movieAudioList?: Maybe<Array<AudioType>>;
  movieProfile?: Maybe<Array<PlaylistMovieProfile>>;
  movieSecurityLevelCode?: Maybe<Scalars['String']>;
  resumePoint?: Maybe<Scalars['Int']>;
  saleTypeCode?: Maybe<Scalars['String']>;
  sceneSearchList?: Maybe<SceneSearchList>;
  startPoint?: Maybe<Scalars['Int']>;
  umcContentId?: Maybe<Scalars['String']>;
};

export type UserCard = {
  __typename?: 'UserCard';
  settlementName: Scalars['String'];
  settlementType: Scalars['String'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  accountTypeCode?: Maybe<AccountTypeCode>;
  adultPreventionCode: Scalars['String'];
  adultPreventionText: Scalars['String'];
  /** @deprecated Not needed. */
  birthday?: Maybe<Scalars['DateTime']>;
  blockInfo?: Maybe<BlockInfo>;
  filmRatingCode?: Maybe<Scalars['String']>;
  forceLowBitRateFlgs?: Maybe<ForceLowBitRateFlgs>;
  hasRegisteredEmail?: Maybe<Scalars['Boolean']>;
  hasVideoSubscription?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  /** @deprecated Use blockInfo instead */
  isBlocked?: Maybe<Scalars['Boolean']>;
  linkedAccountIssuer?: Maybe<Scalars['String']>;
  multiAccountId?: Maybe<Scalars['String']>;
  otherFunctionId?: Maybe<Scalars['String']>;
  pauseFlag?: Maybe<Scalars['Boolean']>;
  pointLatestExpire?: Maybe<Scalars['Int']>;
  pointLatestExpireDateTime?: Maybe<Scalars['DateTime']>;
  points?: Maybe<Scalars['Int']>;
  ppdVideo?: Maybe<UserInfoPpdVideo>;
  siteCode?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  userPlatformCode?: Maybe<Scalars['String']>;
  userPlatformId?: Maybe<Scalars['String']>;
};

export type UserInfoPpdVideo = {
  __typename?: 'UserInfoPpdVideo';
  expireLimitDateTime?: Maybe<Scalars['DateTime']>;
  hasRight?: Maybe<Scalars['Boolean']>;
  planCode?: Maybe<Scalars['String']>;
};

export type UserProfileResponse = {
  __typename?: 'UserProfileResponse';
  profile?: Maybe<LibuUserProfile>;
};

export type UserSecurityCodeCheckResponse = {
  __typename?: 'UserSecurityCodeCheckResponse';
  valid: Scalars['Boolean'];
};

export type UserSecurityCodeUpdateResponse = {
  __typename?: 'UserSecurityCodeUpdateResponse';
  updated: Scalars['Boolean'];
};

export type VideoAssociatedProductItem = {
  __typename?: 'VideoAssociatedProductItem';
  code: Scalars['ID'];
  displayButtonText: Scalars['String'];
  displaySaleType: Scalars['String'];
  displayValidityDurationText: Scalars['String'];
  displayValidityEndText: Scalars['String'];
  name: Scalars['String'];
  originalPrice: Scalars['Int'];
  planCode: Scalars['String'];
  price: Scalars['Int'];
  publicEndDate: Scalars['DateTime'];
  publicStartDate: Scalars['DateTime'];
  ruleImageUrl: Scalars['String'];
  ruleNote: Scalars['String'];
  ruleTextHeading: Scalars['String'];
  ruleTextHtml: Scalars['String'];
  ruleTitle: Scalars['String'];
  typeCode: Scalars['String'];
};

export enum VideoCodec {
  H264 = 'H264',
  H265 = 'H265',
}

/**
 * VideoConsumable includes the following information:
 * consumable id: for getting a playback token.
 * type: is a main video or a short sample video.
 * videoProfilse: available formats for playing video(codec, resolution, etc),
 *                check vr formats in geometry of a video profile.
 * durationSeconds: the length of the video in seconds.
 */
export type VideoConsumable = {
  __typename?: 'VideoConsumable';
  /** audioProfiles lists all available audio formats of the video */
  audioProfiles: Array<AudioProfile>;
  /** total video length in seconds */
  durationSeconds: Scalars['Float'];
  /** consumable id */
  id: Scalars['ID'];
  type: VideoConsumableType;
  /** videoProfiles lists all available video formats for different devices' choosing */
  videoProfiles: Array<VideoProfile>;
};

export enum VideoConsumableType {
  Dubs = 'DUBS',
  Main = 'MAIN',
  Sample = 'SAMPLE',
  Subs = 'SUBS',
  Unspecified = 'UNSPECIFIED',
}

export enum VideoDynamicRangeType {
  DolbyVision = 'DOLBY_VISION',
  Hdr10 = 'HDR10',
  Sdr = 'SDR',
}

export type VideoFreewordSearchResponse = {
  __typename?: 'VideoFreewordSearchResponse';
  pageInfo: PortalPageInfo;
  titles: Array<Title>;
};

export type VideoHistoryRemovalResponse = {
  __typename?: 'VideoHistoryRemovalResponse';
  removed: Scalars['Boolean'];
};

export type VideoProductItem = {
  __typename?: 'VideoProductItem';
  code: Scalars['ID'];
  discountRate?: Maybe<Scalars['String']>;
  displayButtonText: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  displaySaleType: Scalars['String'];
  /** from display_valid_span */
  displayValidityDurationText: Scalars['String'];
  displayValidityEndText: Scalars['String'];
  invoice: Scalars['Int'];
  isAdult: Scalars['Boolean'];
  isSale: Scalars['Boolean'];
  name: Scalars['String'];
  originalPrice: Scalars['Int'];
  pointBalance: Scalars['Int'];
  points: Scalars['Int'];
  price: Scalars['Int'];
  publicEndDate: Scalars['DateTime'];
  publicStartDate: Scalars['DateTime'];
  purchaseDescription?: Maybe<Scalars['String']>;
  saleTypeCode: Scalars['String'];
  segment: Scalars['String'];
  svodAvailableFromText: Scalars['String'];
  /** from valid_span */
  validityDurationMinutes: Scalars['Int'];
};

export type VideoProductList = {
  __typename?: 'VideoProductList';
  contractProducts: Array<VideoAssociatedProductItem>;
  ppvProducts: Array<VideoProductItem>;
};

export type VideoProductListContractProductsArgs = {
  purchasingAgent?: InputMaybe<PurchasingAgent>;
};

export type VideoProductPurchaseResponse = {
  __typename?: 'VideoProductPurchaseResponse';
  product?: Maybe<VideoPurchasedProductItem>;
};

/** VideoProfile is a video format set for playing video */
export type VideoProfile = {
  __typename?: 'VideoProfile';
  codec: CapyVideoCodec;
  dynamicRange: DynamicRange;
  /** geometry includes vr formats of a vr video. */
  geometry?: Maybe<Geometry>;
  resolution: Resolution;
};

export type VideoPurchasedProductItem = {
  __typename?: 'VideoPurchasedProductItem';
  code: Scalars['ID'];
  discountRate?: Maybe<Scalars['String']>;
  isAdult: Scalars['Boolean'];
  isSale: Scalars['Boolean'];
  name: Scalars['String'];
  originalPrice: Scalars['Int'];
  price: Scalars['Int'];
  publicEndDate: Scalars['DateTime'];
  publicStartDate: Scalars['DateTime'];
  purchaseDescription?: Maybe<Scalars['String']>;
  segment: Scalars['String'];
  /** from valid_span */
  validityDurationMinutes: Scalars['Int'];
};

export type VideoRankingResponse = {
  __typename?: 'VideoRankingResponse';
  titles: Array<VideoRankingTitle>;
};

export type VideoRankingTitle = {
  __typename?: 'VideoRankingTitle';
  id: Scalars['ID'];
  name: Scalars['String'];
  thumbnailUrl: Scalars['String'];
};

export enum VideoRankingType {
  All = 'ALL',
  Anime = 'ANIME',
  Movie = 'MOVIE',
  TvSeries = 'TV_SERIES',
  Variety = 'VARIETY',
}

export type VideoSearchResponse = {
  __typename?: 'VideoSearchResponse';
  pageInfo: PortalPageInfo;
  /** Returns the the person's name if personCode and personNameCode, which personNameCode and penNameCode can be set, are provided. */
  personName?: Maybe<Scalars['String']>;
  titles: Array<Title>;
};

export type GetMediaChapterInfoQueryVariables = Exact<{
  mediaChapterId: Scalars['ID'];
  recommendationPagination?: InputMaybe<Pagination>;
  allChaptersPagination?: InputMaybe<Pagination>;
  isLoggedIn?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetMediaChapterInfoQuery = {
  __typename?: 'Query';
  mediaChapter?: {
    __typename?: 'MediaChapter';
    id: string;
    metadata: {
      __typename?: 'MediaChapterMeta';
      chapterNo: number;
      nameJa: string;
      startTimeSeconds: number;
      endTimeSeconds: number;
      parent: {
        __typename?: 'MediaLight';
        id: string;
        metadata: {
          __typename?: 'MediaLightMeta';
          id: string;
          nameJa: string;
          mainDurationSeconds?: number | null;
          thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
        };
      };
      thumbnails: Array<{
        __typename?: 'ThumbnailSet';
        t1: string;
        w2: string;
      }>;
      paginatedAllChapters: {
        __typename?: 'PaginatedMediaChapterLight';
        records: Array<{
          __typename?: 'MediaChapterLight';
          metadata: {
            __typename?: 'MediaChapterLightMeta';
            id: string;
            chapterNo: number;
            startTimeSeconds: number;
            endTimeSeconds: number;
            thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
          };
        }>;
        pageInfo: {
          __typename?: 'PageInfo';
          totalRecordCount: number;
          pageSize: number;
          currentOffset: number;
          hasNext: boolean;
        };
      };
      contentTags: Array<{
        __typename?: 'ContentTag';
        nameJa: string;
        id: string;
        contentType: ContentType;
      }>;
      credits: Array<{
        __typename?: 'MediaCredit';
        alias: { __typename?: 'AliasLight'; id: string; nameJa: string };
      }>;
      performances: Array<{
        __typename?: 'MediaPerformance';
        alias: { __typename?: 'AliasLight'; id: string; nameJa: string };
      }>;
      brandGroups: Array<{
        __typename?: 'BrandGroupLight';
        id: string;
        nameJa: string;
      }>;
      videoConsumables: Array<{
        __typename?: 'VideoConsumable';
        id: string;
        type: VideoConsumableType;
        videoProfiles: Array<{
          __typename?: 'VideoProfile';
          codec: CapyVideoCodec;
          resolution: Resolution;
          geometry?: {
            __typename?: 'Geometry';
            geometryType: GeometryType;
            horizontalAngleDegree: number;
            projectionType: ProjectionType;
            stereoVideo: boolean;
            stereoLayout: StereoLayout;
          } | null;
        }>;
      }>;
    };
    productInfo?: {
      __typename?: 'MediaChapterLightProductSummary';
      platformLifetimeClass: TitleLifetimeClass;
      minPrice?: number | null;
    } | null;
    paginatedRecommendations: {
      __typename?: 'PaginatedMediaChapterLight';
      pageInfo: {
        __typename?: 'PageInfo';
        totalRecordCount: number;
        currentOffset: number;
        pageSize: number;
        hasNext: boolean;
        hasPrevious: boolean;
      };
      records: Array<{
        __typename?: 'MediaChapterLight';
        id: string;
        metadata: {
          __typename?: 'MediaChapterLightMeta';
          id: string;
          nameJa: string;
          chapterNo: number;
          favoriteCount: number;
          durationSeconds: number;
          featureBadges: Array<MediaFeatureBadge>;
          thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
          contentTags: Array<{
            __typename?: 'ContentTag';
            id: string;
            nameJa: string;
            contentType: ContentType;
          }>;
        };
        mediaProductInfo?: {
          __typename?: 'MediaLightProductSummary';
          platformLifetimeClass: TitleLifetimeClass;
        } | null;
      }>;
    };
    rating?: { __typename?: 'LibuRating'; score: number } | null;
    favorite?: { __typename?: 'LibuFavoriteItem'; id: string } | null;
  } | null;
};

export type GetMinimumMediaChapterInfoQueryVariables = Exact<{
  mediaChapterId: Scalars['ID'];
}>;

export type GetMinimumMediaChapterInfoQuery = {
  __typename?: 'Query';
  mediaChapter?: {
    __typename?: 'MediaChapter';
    id: string;
    metadata: {
      __typename?: 'MediaChapterMeta';
      nameJa: string;
      thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
    };
  } | null;
};

export type GetMediaChapterIdQueryVariables = Exact<{
  mediaChapterId: Scalars['ID'];
}>;

export type GetMediaChapterIdQuery = {
  __typename?: 'Query';
  mediaChapter?: { __typename?: 'MediaChapter'; id: string } | null;
};

export type AddViewHistoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AddViewHistoryMutation = {
  __typename?: 'Mutation';
  addViewHistory?: { __typename?: 'LibuViewHistoryItem'; id: string } | null;
};

export type AddRatingMutationVariables = Exact<{
  input: AddLibuRatingInput;
}>;

export type AddRatingMutation = {
  __typename?: 'Mutation';
  addRating?: { __typename?: 'LibuRating'; id: string } | null;
};

export type GetViewHistoryItemsQueryVariables = Exact<{
  pagination?: InputMaybe<LibuPagination>;
}>;

export type GetViewHistoryItemsQuery = {
  __typename?: 'Query';
  viewHistoryItems?: {
    __typename?: 'LibuViewHistoryItemHits';
    items?: Array<{
      __typename?: 'LibuViewHistoryItem';
      resource:
        | { __typename?: 'LibuPlaylistLight' }
        | {
            __typename?: 'MediaChapterLight';
            id: string;
            metadata: {
              __typename?: 'MediaChapterLightMeta';
              id: string;
              nameJa: string;
              chapterNo: number;
              favoriteCount: number;
              durationSeconds: number;
              featureBadges: Array<MediaFeatureBadge>;
              thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
              contentTags: Array<{
                __typename?: 'ContentTag';
                id: string;
                nameJa: string;
                contentType: ContentType;
              }>;
            };
            mediaProductInfo?: {
              __typename?: 'MediaLightProductSummary';
              platformLifetimeClass: TitleLifetimeClass;
            } | null;
          }
        | { __typename?: 'MediaLight' };
    }> | null;
    pageResult: {
      __typename?: 'LibuPageResult';
      totalCount: number;
      hasNextPage: boolean;
      limit: number;
      offset: number;
    };
  } | null;
};

export type DeleteViewHistoryMutationVariables = Exact<{
  resources: Array<LibuResourceInput> | LibuResourceInput;
  inverse?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeleteViewHistoryMutation = {
  __typename?: 'Mutation';
  deleteViewHistory?: Array<{
    __typename?: 'LibuViewHistoryItem';
    id: string;
  }> | null;
};

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserProfileQuery = {
  __typename?: 'Query';
  userProfile: {
    __typename?: 'UserProfileResponse';
    profile?: {
      __typename?: 'BeemiUserProfile';
      showViewHistory?: boolean | null;
    } | null;
  };
};

export type SetUserProfileMutationVariables = Exact<{
  input: SetUserProfileInput;
}>;

export type SetUserProfileMutation = {
  __typename?: 'Mutation';
  setUserProfile: {
    __typename?: 'SetUserProfileResponse';
    profile: {
      __typename?: 'BeemiUserProfile';
      showViewHistory?: boolean | null;
    };
  };
};

export type GetFavoriteListQueryVariables = Exact<{
  favoriteList: LibuFavoriteListKey;
  pagination?: InputMaybe<LibuPagination>;
  orderBy?: InputMaybe<
    Array<LibuFavoriteItemSortCondition> | LibuFavoriteItemSortCondition
  >;
}>;

export type GetFavoriteListQuery = {
  __typename?: 'Query';
  favoriteList?: {
    __typename?: 'LibuFavoriteList';
    id: string;
    title: string;
    itemList?: {
      __typename?: 'LibuFavoriteItemHits';
      pageResult: {
        __typename?: 'LibuPageResult';
        totalCount: number;
        hasNextPage: boolean;
        limit: number;
        offset: number;
      };
      items?: Array<{
        __typename?: 'LibuFavoriteItem';
        id: string;
        resource?:
          | { __typename?: 'LibuPlaylistLight' }
          | {
              __typename?: 'MediaChapterLight';
              id: string;
              metadata: {
                __typename?: 'MediaChapterLightMeta';
                id: string;
                nameJa: string;
                chapterNo: number;
                favoriteCount: number;
                durationSeconds: number;
                featureBadges: Array<MediaFeatureBadge>;
                thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
                contentTags: Array<{
                  __typename?: 'ContentTag';
                  id: string;
                  nameJa: string;
                  contentType: ContentType;
                }>;
              };
              mediaProductInfo?: {
                __typename?: 'MediaLightProductSummary';
                platformLifetimeClass: TitleLifetimeClass;
              } | null;
            }
          | {
              __typename?: 'MediaLight';
              id: string;
              metadata: {
                __typename?: 'MediaLightMeta';
                id: string;
                nameJa: string;
                favoriteCount: number;
                featureBadges: Array<MediaFeatureBadge>;
                thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
                contentTags: Array<{
                  __typename?: 'ContentTag';
                  id: string;
                  nameJa: string;
                }>;
              };
              productInfo?: {
                __typename?: 'MediaLightProductSummary';
                paymentBadges?: Array<CapyPaymentBadgeCode> | null;
                saleBadges?: Array<CapySaleBadgeCode> | null;
                minPrice?: number | null;
                hasMultiplePrices: boolean;
                platformLifetimeClass: TitleLifetimeClass;
              } | null;
            }
          | null;
      }> | null;
    } | null;
  } | null;
};

export type PurchaseHistoryItemsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  sorter?: InputMaybe<PurchasedMediaSorter>;
}>;

export type PurchaseHistoryItemsQuery = {
  __typename?: 'Query';
  purchasedMedia: {
    __typename?: 'PaginatedMediaLight';
    pageInfo: { __typename?: 'PageInfo'; hasNext: boolean };
    records: Array<{
      __typename?: 'MediaLight';
      id: string;
      favorite?: { __typename?: 'LibuFavoriteItem'; id: string } | null;
      metadata: {
        __typename?: 'MediaLightMeta';
        id: string;
        nameJa: string;
        favoriteCount: number;
        featureBadges: Array<MediaFeatureBadge>;
        thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
        contentTags: Array<{
          __typename?: 'ContentTag';
          id: string;
          nameJa: string;
        }>;
      };
      productInfo?: {
        __typename?: 'MediaLightProductSummary';
        paymentBadges?: Array<CapyPaymentBadgeCode> | null;
        saleBadges?: Array<CapySaleBadgeCode> | null;
        minPrice?: number | null;
        hasMultiplePrices: boolean;
        platformLifetimeClass: TitleLifetimeClass;
      } | null;
    }>;
  };
};

export type DeletePurchasedMediaMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeletePurchasedMediaMutation = {
  __typename?: 'Mutation';
  deletePurchasedMedia: boolean;
};

export type GetPlatformInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlatformInfoQuery = {
  __typename?: 'Query';
  platformInfo: {
    __typename?: 'PlatformInfo';
    deviceName: string;
    deviceType: number;
    screenType?: ScreenType | null;
    userAgent: string;
  };
};

export type GetMediaQueryVariables = Exact<{
  mediaId: Scalars['ID'];
  deviceId: Scalars['String'];
  isLoggedIn?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetMediaQuery = {
  __typename?: 'Query';
  media?: {
    __typename?: 'Media';
    id: string;
    metadata: {
      __typename?: 'MediaMeta';
      id: string;
      nameJa: string;
      releaseLifetimeClass: TitleLifetimeClass;
      favoriteCount: number;
      synopsis?: string | null;
      adultProductCode?: string | null;
      copyright?: string | null;
      originalReleaseDate?: Date | null;
      featureBadges: Array<MediaFeatureBadge>;
      thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
      screenshots: Array<{
        __typename?: 'ThumbnailSet';
        w1: string;
        w2: string;
      }>;
      paginatedChapters: {
        __typename?: 'PaginatedMediaChapterLight';
        records: Array<{
          __typename?: 'MediaChapterLight';
          id: string;
          metadata: {
            __typename?: 'MediaChapterLightMeta';
            id: string;
            nameJa: string;
            chapterNo: number;
            durationSeconds: number;
            thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
          };
        }>;
      };
      videoConsumables: Array<{
        __typename?: 'VideoConsumable';
        id: string;
        type: VideoConsumableType;
        durationSeconds: number;
        videoProfiles: Array<{
          __typename?: 'VideoProfile';
          codec: CapyVideoCodec;
          resolution: Resolution;
          geometry?: {
            __typename?: 'Geometry';
            geometryType: GeometryType;
            horizontalAngleDegree: number;
            projectionType: ProjectionType;
            stereoVideo: boolean;
            stereoLayout: StereoLayout;
          } | null;
        }>;
      }>;
      performances: Array<{
        __typename?: 'MediaPerformance';
        characterName?: string | null;
        alias: { __typename?: 'AliasLight'; id: string; nameJa: string };
      }>;
      credits: Array<{
        __typename?: 'MediaCredit';
        creditType?: string | null;
        alias: { __typename?: 'AliasLight'; id: string; nameJa: string };
      }>;
      brandGroups: Array<{
        __typename?: 'BrandGroupLight';
        id: string;
        nameJa: string;
      }>;
      contentTags: Array<{
        __typename?: 'ContentTag';
        id: string;
        nameJa: string;
        contentType: ContentType;
      }>;
    };
    productInfo?: {
      __typename?: 'ContractProductSummary';
      publicEndDateTime: Date;
      copyright?: string | null;
      minPrice?: number | null;
      hasMultiplePrices: boolean;
      platformLifetimeClass: TitleLifetimeClass;
      saleBadges?: Array<CapySaleBadgeCode> | null;
      paymentBadges?: Array<CapyPaymentBadgeCode> | null;
      products?: Array<{
        __typename?: 'ContractProduct';
        productCode: string;
        productName: string;
        saleTypeCode: CapySaleTypeCode;
        priceInTax?: number | null;
        expiredDateTime?: Date | null;
        onSale: boolean;
        saleStartDateTime: Date;
        saleEndDateTime: Date;
        originalPrice?: number | null;
        originalPriceInTax?: number | null;
        alreadyPurchased: boolean;
      }> | null;
    } | null;
    paginatedRecommendations: {
      __typename?: 'PaginatedMediaLight';
      records: Array<{
        __typename?: 'MediaLight';
        id: string;
        metadata: {
          __typename?: 'MediaLightMeta';
          id: string;
          nameJa: string;
          favoriteCount: number;
          featureBadges: Array<MediaFeatureBadge>;
          thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
          contentTags: Array<{
            __typename?: 'ContentTag';
            id: string;
            nameJa: string;
          }>;
        };
        productInfo?: {
          __typename?: 'MediaLightProductSummary';
          paymentBadges?: Array<CapyPaymentBadgeCode> | null;
          saleBadges?: Array<CapySaleBadgeCode> | null;
          minPrice?: number | null;
          hasMultiplePrices: boolean;
          platformLifetimeClass: TitleLifetimeClass;
        } | null;
      }>;
    };
    rating?: { __typename?: 'LibuRating'; score: number } | null;
    favorite?: { __typename?: 'LibuFavoriteItem'; id: string } | null;
    playbackRight?: {
      __typename?: 'PlaybackRight';
      expiredDateTime?: Date | null;
    } | null;
  } | null;
};

export type GetMediaPlayInfoQueryVariables = Exact<{
  mediaId: Scalars['ID'];
  deviceId: Scalars['String'];
  chapterPagination: Pagination;
}>;

export type GetMediaPlayInfoQuery = {
  __typename?: 'Query';
  media?: {
    __typename?: 'Media';
    id: string;
    metadata: {
      __typename?: 'MediaMeta';
      id: string;
      nameJa: string;
      thumbnails: Array<{ __typename?: 'ThumbnailSet'; w2: string }>;
      paginatedChapters: {
        __typename?: 'PaginatedMediaChapterLight';
        records: Array<{
          __typename?: 'MediaChapterLight';
          id: string;
          metadata: {
            __typename?: 'MediaChapterLightMeta';
            id: string;
            nameJa: string;
            chapterNo: number;
            durationSeconds: number;
            startTimeSeconds: number;
            endTimeSeconds: number;
            thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
          };
        }>;
        pageInfo: { __typename?: 'PageInfo'; hasNext: boolean };
      };
      videoConsumables: Array<{
        __typename?: 'VideoConsumable';
        id: string;
        type: VideoConsumableType;
        durationSeconds: number;
        videoProfiles: Array<{
          __typename?: 'VideoProfile';
          codec: CapyVideoCodec;
          resolution: Resolution;
          geometry?: {
            __typename?: 'Geometry';
            geometryType: GeometryType;
            horizontalAngleDegree: number;
            projectionType: ProjectionType;
            stereoVideo: boolean;
            stereoLayout: StereoLayout;
          } | null;
        }>;
      }>;
    };
    productInfo?: {
      __typename?: 'ContractProductSummary';
      copyright?: string | null;
      minPrice?: number | null;
      hasMultiplePrices: boolean;
      platformLifetimeClass: TitleLifetimeClass;
      saleBadges?: Array<CapySaleBadgeCode> | null;
      paymentBadges?: Array<CapyPaymentBadgeCode> | null;
      products?: Array<{
        __typename?: 'ContractProduct';
        productCode: string;
        productName: string;
        saleTypeCode: CapySaleTypeCode;
        priceInTax?: number | null;
        expiredDateTime?: Date | null;
        onSale: boolean;
        saleStartDateTime: Date;
        saleEndDateTime: Date;
        originalPrice?: number | null;
        originalPriceInTax?: number | null;
        alreadyPurchased: boolean;
      }> | null;
    } | null;
  } | null;
};

export type GetMediaIdQueryVariables = Exact<{
  mediaId: Scalars['ID'];
}>;

export type GetMediaIdQuery = {
  __typename?: 'Query';
  media?: { __typename?: 'Media'; id: string } | null;
};

export type GetPlayBackTokensQueryVariables = Exact<{
  contentId: Scalars['ID'];
  consumableId: Scalars['ID'];
  deviceId: Scalars['String'];
  resolutionUpperLimit?: InputMaybe<Scalars['String']>;
  bitRateLowerLimit?: InputMaybe<Scalars['Int']>;
  bitRateUpperLimit?: InputMaybe<Scalars['Int']>;
}>;

export type GetPlayBackTokensQuery = {
  __typename?: 'Query';
  playBackTokens?: {
    __typename?: 'PlaybackTokenResponse';
    playbackToken: string;
    isemToken: string;
    productCode?: string | null;
    saleTypeCode?: CapySaleTypeCode | null;
  } | null;
};

export type GetOneTimeTokenQueryVariables = Exact<{ [key: string]: never }>;

export type GetOneTimeTokenQuery = {
  __typename?: 'Query';
  webfront_oneTimeToken?: {
    __typename?: 'OneTimeTokenResponse';
    token: string;
  } | null;
};

export type GetPointBackQueryVariables = Exact<{ [key: string]: never }>;

export type GetPointBackQuery = {
  __typename?: 'Query';
  webfront_pointBack: {
    __typename?: 'PointBackResponse';
    hasVideoSubscription: boolean;
    point: number;
    setting?: {
      __typename?: 'PointBackSetting';
      canIncreasePercentage: boolean;
      maxPercentage: number;
      percentage: number;
      productList: Array<PointBackProduct>;
      scheduleDate: string;
    } | null;
  };
};

export type GetMediaProductInfoQueryVariables = Exact<{
  mediaId: Scalars['ID'];
  deviceId: Scalars['String'];
}>;

export type GetMediaProductInfoQuery = {
  __typename?: 'Query';
  media?: {
    __typename?: 'Media';
    id: string;
    metadata: {
      __typename?: 'MediaMeta';
      id: string;
      nameJa: string;
      nameKana: string;
      featureBadges: Array<MediaFeatureBadge>;
      videoConsumables: Array<{
        __typename?: 'VideoConsumable';
        id: string;
        type: VideoConsumableType;
        videoProfiles: Array<{
          __typename?: 'VideoProfile';
          codec: CapyVideoCodec;
          resolution: Resolution;
          geometry?: {
            __typename?: 'Geometry';
            geometryType: GeometryType;
            horizontalAngleDegree: number;
            projectionType: ProjectionType;
            stereoVideo: boolean;
            stereoLayout: StereoLayout;
          } | null;
        }>;
      }>;
    };
    productInfo?: {
      __typename?: 'ContractProductSummary';
      copyright?: string | null;
      minPrice?: number | null;
      hasMultiplePrices: boolean;
      platformLifetimeClass: TitleLifetimeClass;
      saleBadges?: Array<CapySaleBadgeCode> | null;
      paymentBadges?: Array<CapyPaymentBadgeCode> | null;
      products?: Array<{
        __typename?: 'ContractProduct';
        alreadyPurchased: boolean;
        productCode: string;
        productName: string;
        saleTypeCode: CapySaleTypeCode;
        priceInTax?: number | null;
        expiredDateTime?: Date | null;
        onSale: boolean;
        saleStartDateTime: Date;
        saleEndDateTime: Date;
        originalPrice?: number | null;
        originalPriceInTax?: number | null;
      }> | null;
    } | null;
  } | null;
};

export type PurchaseBeemiVideoMutationVariables = Exact<{
  deviceCode: Scalars['String'];
  capyId: Scalars['String'];
  productCode: Scalars['String'];
}>;

export type PurchaseBeemiVideoMutation = {
  __typename?: 'Mutation';
  purchaseBeemiVideo?: boolean | null;
};

export type GetSearchResultsQueryVariables = Exact<{
  query: Scalars['String'];
  pagination?: InputMaybe<Pagination>;
  nodeFilter?: InputMaybe<Array<NodeFilter> | NodeFilter>;
}>;

export type GetSearchResultsQuery = {
  __typename?: 'Query';
  beemiSearch?: {
    __typename?: 'TextSearchResponse';
    media: {
      __typename?: 'MediaSearchHits';
      hits: Array<{
        __typename?: 'MediaLight';
        id: string;
        metadata: {
          __typename?: 'MediaLightMeta';
          id: string;
          nameJa: string;
          favoriteCount: number;
          featureBadges: Array<MediaFeatureBadge>;
          thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
          contentTags: Array<{
            __typename?: 'ContentTag';
            id: string;
            nameJa: string;
          }>;
        };
        productInfo?: {
          __typename?: 'MediaLightProductSummary';
          paymentBadges?: Array<CapyPaymentBadgeCode> | null;
          saleBadges?: Array<CapySaleBadgeCode> | null;
          minPrice?: number | null;
          hasMultiplePrices: boolean;
          platformLifetimeClass: TitleLifetimeClass;
        } | null;
      }>;
    };
    mediaChapters: {
      __typename?: 'MediaChaptersSearchHits';
      hits: Array<{
        __typename?: 'MediaChapterLight';
        id: string;
        metadata: {
          __typename?: 'MediaChapterLightMeta';
          id: string;
          nameJa: string;
          chapterNo: number;
          favoriteCount: number;
          durationSeconds: number;
          featureBadges: Array<MediaFeatureBadge>;
          thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
          contentTags: Array<{
            __typename?: 'ContentTag';
            id: string;
            nameJa: string;
            contentType: ContentType;
          }>;
        };
        mediaProductInfo?: {
          __typename?: 'MediaLightProductSummary';
          platformLifetimeClass: TitleLifetimeClass;
        } | null;
      }>;
    };
    suggestions: Array<{
      __typename?: 'Suggestion';
      id: string;
      incidence: number;
      nameJa: string;
      contentType: ContentType;
      description: string;
    }>;
    aliases: {
      __typename?: 'AliasSearchHits';
      hits: Array<{ __typename?: 'AliasLight'; id: string; nameJa: string }>;
    };
  } | null;
};

export type GetSearchResultsStoreQueryVariables = Exact<{
  query: Scalars['String'];
  pagination?: InputMaybe<Pagination>;
  searchFilters?: InputMaybe<SearchFilter>;
  searchSorters?: InputMaybe<Array<SearchSorter> | SearchSorter>;
  nodeFilter?: InputMaybe<Array<NodeFilter> | NodeFilter>;
}>;

export type GetSearchResultsStoreQuery = {
  __typename?: 'Query';
  beemiSearch?: {
    __typename?: 'TextSearchResponse';
    media: {
      __typename?: 'MediaSearchHits';
      hasMore: boolean;
      total: number;
      hits: Array<{
        __typename?: 'MediaLight';
        id: string;
        metadata: {
          __typename?: 'MediaLightMeta';
          id: string;
          nameJa: string;
          favoriteCount: number;
          featureBadges: Array<MediaFeatureBadge>;
          thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
          contentTags: Array<{
            __typename?: 'ContentTag';
            id: string;
            nameJa: string;
          }>;
        };
        productInfo?: {
          __typename?: 'MediaLightProductSummary';
          paymentBadges?: Array<CapyPaymentBadgeCode> | null;
          saleBadges?: Array<CapySaleBadgeCode> | null;
          minPrice?: number | null;
          hasMultiplePrices: boolean;
          platformLifetimeClass: TitleLifetimeClass;
        } | null;
      }>;
    };
    suggestions: Array<{
      __typename?: 'Suggestion';
      id: string;
      incidence: number;
      nameJa: string;
      contentType: ContentType;
      description: string;
    }>;
  } | null;
};

export type GetSearchResultsSvodQueryVariables = Exact<{
  query: Scalars['String'];
  pagination?: InputMaybe<Pagination>;
  searchFilters?: InputMaybe<SearchFilter>;
  searchSorters?: InputMaybe<Array<SearchSorter> | SearchSorter>;
  nodeFilter?: InputMaybe<Array<NodeFilter> | NodeFilter>;
}>;

export type GetSearchResultsSvodQuery = {
  __typename?: 'Query';
  beemiSearch?: {
    __typename?: 'TextSearchResponse';
    mediaChapters: {
      __typename?: 'MediaChaptersSearchHits';
      hasMore: boolean;
      total: number;
      hits: Array<{
        __typename?: 'MediaChapterLight';
        id: string;
        metadata: {
          __typename?: 'MediaChapterLightMeta';
          id: string;
          nameJa: string;
          chapterNo: number;
          favoriteCount: number;
          durationSeconds: number;
          featureBadges: Array<MediaFeatureBadge>;
          thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
          contentTags: Array<{
            __typename?: 'ContentTag';
            id: string;
            nameJa: string;
            contentType: ContentType;
          }>;
        };
        mediaProductInfo?: {
          __typename?: 'MediaLightProductSummary';
          platformLifetimeClass: TitleLifetimeClass;
        } | null;
      }>;
    };
    suggestions: Array<{
      __typename?: 'Suggestion';
      id: string;
      incidence: number;
      nameJa: string;
      contentType: ContentType;
      description: string;
    }>;
  } | null;
};

export type SimpleNodeLookupQueryVariables = Exact<{
  nodes?: InputMaybe<SimpleNodeLookupFilter>;
}>;

export type SimpleNodeLookupQuery = {
  __typename?: 'Query';
  simpleNodeLookup?: {
    __typename?: 'SimpleNodeLookupResponse';
    nodes?: Array<{
      __typename?: 'Node';
      id: string;
      nameJa: string;
      contentType: ContentType;
    }> | null;
  } | null;
};

export type GetAutoCompleteSearchQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type GetAutoCompleteSearchQuery = {
  __typename?: 'Query';
  beemiAutoCompleteSearch?: {
    __typename?: 'AutoCompleteResponse';
    nodes?: Array<{
      __typename?: 'SearchSuggestionNode';
      id: string;
      contentType: ContentType;
      text: string;
      score: number;
      description: string;
    }> | null;
  } | null;
};

export type GetPlanetsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlanetsQuery = {
  __typename?: 'Query';
  planets: Array<{
    __typename?: 'Planet';
    id: string;
    description?: string | null;
    displayName?: string | null;
  }>;
};

export type GetAllContentTagSuggestionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllContentTagSuggestionsQuery = {
  __typename?: 'Query';
  allContentTagSuggestions: Array<{
    __typename?: 'Suggestion';
    id: string;
    nameJa: string;
    contentType: ContentType;
    description: string;
  }>;
};

export type GetSpotlightedSuggestionQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSpotlightedSuggestionQuery = {
  __typename?: 'Query';
  spotlightedSuggestions: Array<{
    __typename?: 'SpotlightedSuggestion';
    id: string;
    text: string;
    description: string;
    backgroundImage: { __typename?: 'ThumbnailSet'; w1: string };
  }>;
};

export type GetAltDetailsBlockQueryVariables = Exact<{
  blockId: Scalars['ID'];
  planetId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<Pagination>;
}>;

export type GetAltDetailsBlockQuery = {
  __typename?: 'Query';
  contentBlockFull?:
    | {
        __typename?: 'CapyRecommendationBlock';
        id: string;
        name?: string | null;
        description?: string | null;
        paginatedMedia: {
          __typename?: 'PaginatedMediaLight';
          records: Array<{
            __typename?: 'MediaLight';
            id: string;
            metadata: {
              __typename?: 'MediaLightMeta';
              id: string;
              nameJa: string;
              favoriteCount: number;
              featureBadges: Array<MediaFeatureBadge>;
              thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
              contentTags: Array<{
                __typename?: 'ContentTag';
                id: string;
                nameJa: string;
              }>;
            };
            productInfo?: {
              __typename?: 'MediaLightProductSummary';
              paymentBadges?: Array<CapyPaymentBadgeCode> | null;
              saleBadges?: Array<CapySaleBadgeCode> | null;
              minPrice?: number | null;
              hasMultiplePrices: boolean;
              platformLifetimeClass: TitleLifetimeClass;
            } | null;
          }>;
          pageInfo: { __typename?: 'PageInfo'; hasNext: boolean };
        };
      }
    | { __typename?: 'MediaSpotlightBlock' }
    | { __typename?: 'NewMediaRankingBlock' }
    | { __typename?: 'PopularityRankingBlock' }
    | { __typename?: 'RevenueRankingBlock' }
    | { __typename?: 'SalesBlock' }
    | null;
};

export type GetNewBlockQueryVariables = Exact<{
  planetId: Scalars['ID'];
  pagination?: InputMaybe<Pagination>;
}>;

export type GetNewBlockQuery = {
  __typename?: 'Query';
  newMediaRankingBlock?: {
    __typename?: 'NewMediaRankingBlock';
    id: string;
    name?: string | null;
    description?: string | null;
    paginatedMedia: {
      __typename?: 'PaginatedMediaLight';
      records: Array<{
        __typename?: 'MediaLight';
        id: string;
        metadata: {
          __typename?: 'MediaLightMeta';
          id: string;
          nameJa: string;
          favoriteCount: number;
          featureBadges: Array<MediaFeatureBadge>;
          thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
          contentTags: Array<{
            __typename?: 'ContentTag';
            id: string;
            nameJa: string;
          }>;
        };
        productInfo?: {
          __typename?: 'MediaLightProductSummary';
          paymentBadges?: Array<CapyPaymentBadgeCode> | null;
          saleBadges?: Array<CapySaleBadgeCode> | null;
          minPrice?: number | null;
          hasMultiplePrices: boolean;
          platformLifetimeClass: TitleLifetimeClass;
        } | null;
      }>;
      pageInfo: { __typename?: 'PageInfo'; hasNext: boolean };
    };
  } | null;
};

export type GetRankingBlockQueryVariables = Exact<{
  planetId: Scalars['ID'];
  pagination?: InputMaybe<Pagination>;
}>;

export type GetRankingBlockQuery = {
  __typename?: 'Query';
  revenueRankingBlock?: {
    __typename?: 'RevenueRankingBlock';
    id: string;
    name?: string | null;
    paginatedMedia: {
      __typename?: 'PaginatedMediaLight';
      records: Array<{
        __typename?: 'MediaLight';
        id: string;
        metadata: {
          __typename?: 'MediaLightMeta';
          id: string;
          nameJa: string;
          favoriteCount: number;
          featureBadges: Array<MediaFeatureBadge>;
          thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
          contentTags: Array<{
            __typename?: 'ContentTag';
            id: string;
            nameJa: string;
          }>;
        };
        productInfo?: {
          __typename?: 'MediaLightProductSummary';
          paymentBadges?: Array<CapyPaymentBadgeCode> | null;
          saleBadges?: Array<CapySaleBadgeCode> | null;
          minPrice?: number | null;
          hasMultiplePrices: boolean;
          platformLifetimeClass: TitleLifetimeClass;
        } | null;
      }>;
    };
  } | null;
};

export type GetSaleDetailBlockQueryVariables = Exact<{
  blockId: Scalars['ID'];
  planetId?: InputMaybe<Scalars['ID']>;
  pagination?: InputMaybe<Pagination>;
}>;

export type GetSaleDetailBlockQuery = {
  __typename?: 'Query';
  contentBlockFull?:
    | { __typename?: 'CapyRecommendationBlock' }
    | { __typename?: 'MediaSpotlightBlock' }
    | { __typename?: 'NewMediaRankingBlock' }
    | { __typename?: 'PopularityRankingBlock' }
    | { __typename?: 'RevenueRankingBlock' }
    | {
        __typename?: 'SalesBlock';
        id: string;
        name?: string | null;
        description?: string | null;
        displayStartDate: Date;
        displayEndDate: Date;
        paginatedMedia: {
          __typename?: 'PaginatedMediaLight';
          records: Array<{
            __typename?: 'MediaLight';
            id: string;
            metadata: {
              __typename?: 'MediaLightMeta';
              id: string;
              nameJa: string;
              favoriteCount: number;
              featureBadges: Array<MediaFeatureBadge>;
              thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
              contentTags: Array<{
                __typename?: 'ContentTag';
                id: string;
                nameJa: string;
              }>;
            };
            productInfo?: {
              __typename?: 'MediaLightProductSummary';
              paymentBadges?: Array<CapyPaymentBadgeCode> | null;
              saleBadges?: Array<CapySaleBadgeCode> | null;
              minPrice?: number | null;
              hasMultiplePrices: boolean;
              platformLifetimeClass: TitleLifetimeClass;
            } | null;
          }>;
          pageInfo: { __typename?: 'PageInfo'; hasNext: boolean };
        };
      }
    | null;
};

export type GetSalesBlockQueryVariables = Exact<{
  planetId: Scalars['ID'];
}>;

export type GetSalesBlockQuery = {
  __typename?: 'Query';
  activeSaleBlocksV2: {
    __typename?: 'PaginatedSalesBlocks';
    records: Array<{
      __typename: 'SalesBlock';
      id: string;
      name?: string | null;
      description?: string | null;
      displayStartDate: Date;
      displayEndDate: Date;
      paginatedMedia: {
        __typename?: 'PaginatedMediaLight';
        pageInfo: { __typename?: 'PageInfo'; totalRecordCount: number };
      };
      thumbnails: Array<{
        __typename?: 'ThumbnailSet';
        w1: string;
        w2: string;
      }>;
    }>;
  };
};

export type GetTopBlocksQueryVariables = Exact<{
  planetId: Scalars['ID'];
  listLimit: Scalars['Int'];
}>;

export type GetTopBlocksQuery = {
  __typename?: 'Query';
  beemiInfoList: Array<{
    __typename?: 'BeemiInfoListItem';
    title: string;
    ttl: string;
    type?: BeemiInfoListItemType | null;
    url: string;
  }>;
  activeSaleBlocksV2: {
    __typename?: 'PaginatedSalesBlocks';
    records: Array<{
      __typename: 'SalesBlock';
      id: string;
      name?: string | null;
      paginatedMedia: {
        __typename?: 'PaginatedMediaLight';
        pageInfo: { __typename?: 'PageInfo'; totalRecordCount: number };
      };
      thumbnails: Array<{
        __typename?: 'ThumbnailSet';
        w1: string;
        w2: string;
      }>;
    }>;
  };
  revenueRankingBlock?: {
    __typename?: 'RevenueRankingBlock';
    id: string;
    name?: string | null;
    paginatedMedia: {
      __typename?: 'PaginatedMediaLight';
      records: Array<{
        __typename?: 'MediaLight';
        id: string;
        metadata: {
          __typename?: 'MediaLightMeta';
          id: string;
          nameJa: string;
          favoriteCount: number;
          featureBadges: Array<MediaFeatureBadge>;
          thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
          contentTags: Array<{
            __typename?: 'ContentTag';
            id: string;
            nameJa: string;
          }>;
        };
        productInfo?: {
          __typename?: 'MediaLightProductSummary';
          paymentBadges?: Array<CapyPaymentBadgeCode> | null;
          saleBadges?: Array<CapySaleBadgeCode> | null;
          minPrice?: number | null;
          hasMultiplePrices: boolean;
          platformLifetimeClass: TitleLifetimeClass;
        } | null;
      }>;
    };
  } | null;
  newMediaRankingBlock?: {
    __typename?: 'NewMediaRankingBlock';
    id: string;
    name?: string | null;
    paginatedMedia: {
      __typename?: 'PaginatedMediaLight';
      records: Array<{
        __typename?: 'MediaLight';
        id: string;
        metadata: {
          __typename?: 'MediaLightMeta';
          id: string;
          nameJa: string;
          favoriteCount: number;
          featureBadges: Array<MediaFeatureBadge>;
          thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
          contentTags: Array<{
            __typename?: 'ContentTag';
            id: string;
            nameJa: string;
          }>;
        };
        productInfo?: {
          __typename?: 'MediaLightProductSummary';
          paymentBadges?: Array<CapyPaymentBadgeCode> | null;
          saleBadges?: Array<CapySaleBadgeCode> | null;
          minPrice?: number | null;
          hasMultiplePrices: boolean;
          platformLifetimeClass: TitleLifetimeClass;
        } | null;
      }>;
    };
  } | null;
  pickupBlockV2: {
    __typename?: 'PaginatedContentBlocks';
    records: Array<
      | {
          __typename: 'CapyRecommendationBlock';
          id: string;
          name?: string | null;
          paginatedMedia: {
            __typename?: 'PaginatedMediaLight';
            records: Array<{
              __typename?: 'MediaLight';
              id: string;
              metadata: {
                __typename?: 'MediaLightMeta';
                id: string;
                nameJa: string;
                favoriteCount: number;
                featureBadges: Array<MediaFeatureBadge>;
                thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
                contentTags: Array<{
                  __typename?: 'ContentTag';
                  id: string;
                  nameJa: string;
                }>;
              };
              productInfo?: {
                __typename?: 'MediaLightProductSummary';
                paymentBadges?: Array<CapyPaymentBadgeCode> | null;
                saleBadges?: Array<CapySaleBadgeCode> | null;
                minPrice?: number | null;
                hasMultiplePrices: boolean;
                platformLifetimeClass: TitleLifetimeClass;
              } | null;
            }>;
          };
        }
      | {
          __typename: 'MediaSpotlightBlock';
          id: string;
          name?: string | null;
          spotlight: {
            __typename?: 'MediaLight';
            metadata: {
              __typename?: 'MediaLightMeta';
              id: string;
              thumbnails: Array<{
                __typename?: 'ThumbnailSet';
                w2: string;
                uw2: string;
              }>;
            };
          };
        }
      | { __typename: 'NewMediaRankingBlock' }
      | { __typename: 'PopularityRankingBlock' }
      | { __typename: 'RevenueRankingBlock' }
      | {
          __typename: 'SalesBlock';
          id: string;
          name?: string | null;
          thumbnails: Array<{
            __typename?: 'ThumbnailSet';
            w2: string;
            uw2: string;
          }>;
        }
    >;
  };
  popularContentTagsV2: {
    __typename?: 'PaginatedContentTags';
    records: Array<{
      __typename?: 'ContentTag';
      id: string;
      nameJa: string;
      contentType: ContentType;
    }>;
  };
  recommendedBlocksV2: {
    __typename?: 'PaginatedContentBlocks';
    records: Array<
      | {
          __typename: 'CapyRecommendationBlock';
          id: string;
          name?: string | null;
          paginatedMedia: {
            __typename?: 'PaginatedMediaLight';
            records: Array<{
              __typename?: 'MediaLight';
              id: string;
              metadata: {
                __typename?: 'MediaLightMeta';
                id: string;
                nameJa: string;
                favoriteCount: number;
                featureBadges: Array<MediaFeatureBadge>;
                thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
                contentTags: Array<{
                  __typename?: 'ContentTag';
                  id: string;
                  nameJa: string;
                }>;
              };
              productInfo?: {
                __typename?: 'MediaLightProductSummary';
                paymentBadges?: Array<CapyPaymentBadgeCode> | null;
                saleBadges?: Array<CapySaleBadgeCode> | null;
                minPrice?: number | null;
                hasMultiplePrices: boolean;
                platformLifetimeClass: TitleLifetimeClass;
              } | null;
            }>;
          };
        }
      | {
          __typename: 'MediaSpotlightBlock';
          id: string;
          name?: string | null;
          spotlight: {
            __typename?: 'MediaLight';
            metadata: {
              __typename?: 'MediaLightMeta';
              id: string;
              thumbnails: Array<{
                __typename?: 'ThumbnailSet';
                w1: string;
                w2: string;
                uw2: string;
              }>;
            };
          };
        }
      | { __typename: 'NewMediaRankingBlock' }
      | { __typename: 'PopularityRankingBlock' }
      | { __typename: 'RevenueRankingBlock' }
      | { __typename: 'SalesBlock' }
    >;
  };
};

export type GetUserChaptersQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserChaptersQuery = {
  __typename?: 'Query';
  recommendedChaptersV2: {
    __typename?: 'PaginatedMediaChapterLight';
    pageInfo: {
      __typename?: 'PageInfo';
      totalRecordCount: number;
      currentOffset: number;
      pageSize: number;
      hasNext: boolean;
      hasPrevious: boolean;
    };
    records: Array<{
      __typename?: 'MediaChapterLight';
      id: string;
      metadata: {
        __typename?: 'MediaChapterLightMeta';
        id: string;
        nameJa: string;
        chapterNo: number;
        favoriteCount: number;
        durationSeconds: number;
        featureBadges: Array<MediaFeatureBadge>;
        thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
        contentTags: Array<{
          __typename?: 'ContentTag';
          id: string;
          nameJa: string;
          contentType: ContentType;
        }>;
      };
      mediaProductInfo?: {
        __typename?: 'MediaLightProductSummary';
        platformLifetimeClass: TitleLifetimeClass;
      } | null;
    }>;
  };
  popularContentTagsV2: {
    __typename?: 'PaginatedContentTags';
    records: Array<{
      __typename?: 'ContentTag';
      id: string;
      nameJa: string;
      contentType: ContentType;
    }>;
  };
};

export type GetNewChaptersQueryVariables = Exact<{ [key: string]: never }>;

export type GetNewChaptersQuery = {
  __typename?: 'Query';
  newChaptersV2: {
    __typename?: 'PaginatedMediaChapterLight';
    pageInfo: {
      __typename?: 'PageInfo';
      totalRecordCount: number;
      currentOffset: number;
      pageSize: number;
      hasNext: boolean;
      hasPrevious: boolean;
    };
    records: Array<{
      __typename?: 'MediaChapterLight';
      id: string;
      metadata: {
        __typename?: 'MediaChapterLightMeta';
        id: string;
        nameJa: string;
        chapterNo: number;
        favoriteCount: number;
        durationSeconds: number;
        featureBadges: Array<MediaFeatureBadge>;
        thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
        contentTags: Array<{
          __typename?: 'ContentTag';
          id: string;
          nameJa: string;
          contentType: ContentType;
        }>;
      };
      mediaProductInfo?: {
        __typename?: 'MediaLightProductSummary';
        platformLifetimeClass: TitleLifetimeClass;
      } | null;
    }>;
  };
};

export type GetPopularChaptersQueryVariables = Exact<{ [key: string]: never }>;

export type GetPopularChaptersQuery = {
  __typename?: 'Query';
  popularChaptersV2: {
    __typename?: 'PaginatedMediaChapterLight';
    pageInfo: {
      __typename?: 'PageInfo';
      totalRecordCount: number;
      currentOffset: number;
      pageSize: number;
      hasNext: boolean;
      hasPrevious: boolean;
    };
    records: Array<{
      __typename?: 'MediaChapterLight';
      id: string;
      metadata: {
        __typename?: 'MediaChapterLightMeta';
        id: string;
        nameJa: string;
        chapterNo: number;
        favoriteCount: number;
        durationSeconds: number;
        featureBadges: Array<MediaFeatureBadge>;
        thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
        contentTags: Array<{
          __typename?: 'ContentTag';
          id: string;
          nameJa: string;
          contentType: ContentType;
        }>;
      };
      mediaProductInfo?: {
        __typename?: 'MediaLightProductSummary';
        platformLifetimeClass: TitleLifetimeClass;
      } | null;
    }>;
  };
};

export type AddFavoriteItemMutationVariables = Exact<{
  input: LibuFavoriteItemInput;
}>;

export type AddFavoriteItemMutation = {
  __typename?: 'Mutation';
  addFavoriteItem?: {
    __typename?: 'LibuFavoriteItem';
    id: string;
    status?: LibuMutationStatus | null;
  } | null;
};

export type DeleteFavoriteItemsMutationVariables = Exact<{
  input: DeleteLibuFavoriteItemsInput;
}>;

export type DeleteFavoriteItemsMutation = {
  __typename?: 'Mutation';
  deleteFavoriteItems?: Array<{
    __typename?: 'LibuFavoriteItem';
    id: string;
  }> | null;
};

export type ChapterCardFragment = {
  __typename?: 'MediaChapterLight';
  id: string;
  metadata: {
    __typename?: 'MediaChapterLightMeta';
    id: string;
    nameJa: string;
    chapterNo: number;
    favoriteCount: number;
    durationSeconds: number;
    featureBadges: Array<MediaFeatureBadge>;
    thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
    contentTags: Array<{
      __typename?: 'ContentTag';
      id: string;
      nameJa: string;
      contentType: ContentType;
    }>;
  };
  mediaProductInfo?: {
    __typename?: 'MediaLightProductSummary';
    platformLifetimeClass: TitleLifetimeClass;
  } | null;
};

export type PackageCardFragment = {
  __typename?: 'MediaLight';
  id: string;
  metadata: {
    __typename?: 'MediaLightMeta';
    id: string;
    nameJa: string;
    favoriteCount: number;
    featureBadges: Array<MediaFeatureBadge>;
    thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
    contentTags: Array<{
      __typename?: 'ContentTag';
      id: string;
      nameJa: string;
    }>;
  };
  productInfo?: {
    __typename?: 'MediaLightProductSummary';
    paymentBadges?: Array<CapyPaymentBadgeCode> | null;
    saleBadges?: Array<CapySaleBadgeCode> | null;
    minPrice?: number | null;
    hasMultiplePrices: boolean;
    platformLifetimeClass: TitleLifetimeClass;
  } | null;
};

export type PackageCardLoggedInFragment = {
  __typename?: 'MediaLight';
  id: string;
  favorite?: { __typename?: 'LibuFavoriteItem'; id: string } | null;
  metadata: {
    __typename?: 'MediaLightMeta';
    id: string;
    nameJa: string;
    favoriteCount: number;
    featureBadges: Array<MediaFeatureBadge>;
    thumbnails: Array<{ __typename?: 'ThumbnailSet'; t1: string }>;
    contentTags: Array<{
      __typename?: 'ContentTag';
      id: string;
      nameJa: string;
    }>;
  };
  productInfo?: {
    __typename?: 'MediaLightProductSummary';
    paymentBadges?: Array<CapyPaymentBadgeCode> | null;
    saleBadges?: Array<CapySaleBadgeCode> | null;
    minPrice?: number | null;
    hasMultiplePrices: boolean;
    platformLifetimeClass: TitleLifetimeClass;
  } | null;
};

export type PageInfoFieldsFragment = {
  __typename?: 'PageInfo';
  totalRecordCount: number;
  currentOffset: number;
  pageSize: number;
  hasNext: boolean;
  hasPrevious: boolean;
};

export type PaginatedChapterCardFragment = {
  __typename?: 'PaginatedMediaChapterLight';
  pageInfo: {
    __typename?: 'PageInfo';
    totalRecordCount: number;
    currentOffset: number;
    pageSize: number;
    hasNext: boolean;
    hasPrevious: boolean;
  };
  records: Array<{
    __typename?: 'MediaChapterLight';
    id: string;
    metadata: {
      __typename?: 'MediaChapterLightMeta';
      id: string;
      nameJa: string;
      chapterNo: number;
      favoriteCount: number;
      durationSeconds: number;
      featureBadges: Array<MediaFeatureBadge>;
      thumbnails: Array<{ __typename?: 'ThumbnailSet'; w1: string }>;
      contentTags: Array<{
        __typename?: 'ContentTag';
        id: string;
        nameJa: string;
        contentType: ContentType;
      }>;
    };
    mediaProductInfo?: {
      __typename?: 'MediaLightProductSummary';
      platformLifetimeClass: TitleLifetimeClass;
    } | null;
  }>;
};

export type ProductFieldsFragment = {
  __typename?: 'ContractProduct';
  productCode: string;
  productName: string;
  saleTypeCode: CapySaleTypeCode;
  priceInTax?: number | null;
  expiredDateTime?: Date | null;
  onSale: boolean;
  saleStartDateTime: Date;
  saleEndDateTime: Date;
  originalPrice?: number | null;
  originalPriceInTax?: number | null;
  alreadyPurchased: boolean;
};

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserInfoQuery = {
  __typename?: 'Query';
  userInfo?: {
    __typename?: 'UserInfo';
    id?: string | null;
    points?: number | null;
    hasVideoSubscription?: boolean | null;
    linkedAccountIssuer?: string | null;
    multiAccountId?: string | null;
    userPlatformId?: string | null;
    userPlatformCode?: string | null;
    superUser?: boolean | null;
    ppdVideo?: {
      __typename?: 'UserInfoPpdVideo';
      expireLimitDateTime?: Date | null;
      hasRight?: boolean | null;
    } | null;
  } | null;
  webfront_upsell: { __typename?: 'Upsell'; canDisplay: boolean };
};

export const PackageCardFragmentDoc = gql`
  fragment PackageCard on MediaLight {
    id
    metadata {
      id
      nameJa
      favoriteCount
      thumbnails {
        t1
      }
      contentTags {
        id
        nameJa
      }
      featureBadges
    }
    productInfo {
      paymentBadges
      saleBadges
      minPrice
      hasMultiplePrices
      platformLifetimeClass
    }
  }
`;
export const PackageCardLoggedInFragmentDoc = gql`
  fragment PackageCardLoggedIn on MediaLight {
    ...PackageCard
    favorite(
      favoriteList: {
        kind: TITLE
        domainTitle: { domain: BEEMI, title: "beemi-mylist-package" }
      }
    ) {
      id
    }
  }
  ${PackageCardFragmentDoc}
`;
export const PageInfoFieldsFragmentDoc = gql`
  fragment PageInfoFields on PageInfo {
    totalRecordCount
    currentOffset
    pageSize
    hasNext
    hasPrevious
  }
`;
export const ChapterCardFragmentDoc = gql`
  fragment ChapterCard on MediaChapterLight {
    id
    metadata {
      id
      nameJa
      chapterNo
      favoriteCount
      thumbnails {
        w1
      }
      contentTags {
        id
        nameJa
        contentType
      }
      durationSeconds
      featureBadges
    }
    mediaProductInfo {
      platformLifetimeClass
    }
  }
`;
export const PaginatedChapterCardFragmentDoc = gql`
  fragment PaginatedChapterCard on PaginatedMediaChapterLight {
    pageInfo {
      ...PageInfoFields
    }
    records {
      ...ChapterCard
    }
  }
  ${PageInfoFieldsFragmentDoc}
  ${ChapterCardFragmentDoc}
`;
export const ProductFieldsFragmentDoc = gql`
  fragment ProductFields on ContractProduct {
    productCode
    productName
    saleTypeCode
    priceInTax
    expiredDateTime
    onSale
    saleStartDateTime
    saleEndDateTime
    originalPrice
    originalPriceInTax
    alreadyPurchased
  }
`;
export const GetMediaChapterInfoDocument = gql`
  query getMediaChapterInfo(
    $mediaChapterId: ID!
    $recommendationPagination: Pagination
    $allChaptersPagination: Pagination
    $isLoggedIn: Boolean = false
  ) {
    mediaChapter(mediaChapterId: $mediaChapterId) {
      id
      metadata {
        chapterNo
        parent {
          id
        }
        thumbnails {
          t1
          w2
        }
        nameJa
        startTimeSeconds
        endTimeSeconds
        paginatedAllChapters(pagination: $allChaptersPagination) {
          records {
            metadata {
              id
              chapterNo
              thumbnails {
                w1
              }
              startTimeSeconds
              endTimeSeconds
            }
          }
          pageInfo {
            totalRecordCount
            pageSize
            currentOffset
            hasNext
          }
        }
        contentTags {
          nameJa
          id
          contentType
        }
        credits {
          alias {
            id
            nameJa
          }
        }
        performances {
          alias {
            id
            nameJa
          }
        }
        brandGroups {
          id
          nameJa
        }
        videoConsumables {
          id
          type
          videoProfiles {
            codec
            resolution
            geometry {
              geometryType
              horizontalAngleDegree
              projectionType
              stereoVideo
              stereoLayout
            }
          }
        }
        parent {
          id
          metadata {
            id
            nameJa
            mainDurationSeconds
            thumbnails {
              w1
            }
          }
        }
      }
      productInfo {
        platformLifetimeClass
        minPrice
      }
      paginatedRecommendations(pagination: $recommendationPagination) {
        ...PaginatedChapterCard
      }
      rating(domain: BEEMI) @include(if: $isLoggedIn) {
        score
      }
      favorite(
        favoriteList: {
          kind: TITLE
          domainTitle: { domain: BEEMI, title: "beemi-mylist-chapter" }
        }
      ) @include(if: $isLoggedIn) {
        id
      }
    }
  }
  ${PaginatedChapterCardFragmentDoc}
`;

/**
 * __useGetMediaChapterInfoQuery__
 *
 * To run a query within a React component, call `useGetMediaChapterInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaChapterInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaChapterInfoQuery({
 *   variables: {
 *      mediaChapterId: // value for 'mediaChapterId'
 *      recommendationPagination: // value for 'recommendationPagination'
 *      allChaptersPagination: // value for 'allChaptersPagination'
 *      isLoggedIn: // value for 'isLoggedIn'
 *   },
 * });
 */
export function useGetMediaChapterInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaChapterInfoQuery,
    GetMediaChapterInfoQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetMediaChapterInfoQuery,
    GetMediaChapterInfoQueryVariables
  >(GetMediaChapterInfoDocument, options);
}
export function useGetMediaChapterInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaChapterInfoQuery,
    GetMediaChapterInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMediaChapterInfoQuery,
    GetMediaChapterInfoQueryVariables
  >(GetMediaChapterInfoDocument, options);
}
export type GetMediaChapterInfoQueryHookResult = ReturnType<
  typeof useGetMediaChapterInfoQuery
>;
export type GetMediaChapterInfoLazyQueryHookResult = ReturnType<
  typeof useGetMediaChapterInfoLazyQuery
>;
export type GetMediaChapterInfoQueryResult = Apollo.QueryResult<
  GetMediaChapterInfoQuery,
  GetMediaChapterInfoQueryVariables
>;
export const GetMinimumMediaChapterInfoDocument = gql`
  query getMinimumMediaChapterInfo($mediaChapterId: ID!) {
    mediaChapter(mediaChapterId: $mediaChapterId) {
      id
      metadata {
        thumbnails {
          w1
        }
        nameJa
      }
    }
  }
`;

/**
 * __useGetMinimumMediaChapterInfoQuery__
 *
 * To run a query within a React component, call `useGetMinimumMediaChapterInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMinimumMediaChapterInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMinimumMediaChapterInfoQuery({
 *   variables: {
 *      mediaChapterId: // value for 'mediaChapterId'
 *   },
 * });
 */
export function useGetMinimumMediaChapterInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMinimumMediaChapterInfoQuery,
    GetMinimumMediaChapterInfoQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetMinimumMediaChapterInfoQuery,
    GetMinimumMediaChapterInfoQueryVariables
  >(GetMinimumMediaChapterInfoDocument, options);
}
export function useGetMinimumMediaChapterInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMinimumMediaChapterInfoQuery,
    GetMinimumMediaChapterInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMinimumMediaChapterInfoQuery,
    GetMinimumMediaChapterInfoQueryVariables
  >(GetMinimumMediaChapterInfoDocument, options);
}
export type GetMinimumMediaChapterInfoQueryHookResult = ReturnType<
  typeof useGetMinimumMediaChapterInfoQuery
>;
export type GetMinimumMediaChapterInfoLazyQueryHookResult = ReturnType<
  typeof useGetMinimumMediaChapterInfoLazyQuery
>;
export type GetMinimumMediaChapterInfoQueryResult = Apollo.QueryResult<
  GetMinimumMediaChapterInfoQuery,
  GetMinimumMediaChapterInfoQueryVariables
>;
export const GetMediaChapterIdDocument = gql`
  query getMediaChapterId($mediaChapterId: ID!) {
    mediaChapter(mediaChapterId: $mediaChapterId) {
      id
    }
  }
`;

/**
 * __useGetMediaChapterIdQuery__
 *
 * To run a query within a React component, call `useGetMediaChapterIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaChapterIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaChapterIdQuery({
 *   variables: {
 *      mediaChapterId: // value for 'mediaChapterId'
 *   },
 * });
 */
export function useGetMediaChapterIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaChapterIdQuery,
    GetMediaChapterIdQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetMediaChapterIdQuery,
    GetMediaChapterIdQueryVariables
  >(GetMediaChapterIdDocument, options);
}
export function useGetMediaChapterIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaChapterIdQuery,
    GetMediaChapterIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMediaChapterIdQuery,
    GetMediaChapterIdQueryVariables
  >(GetMediaChapterIdDocument, options);
}
export type GetMediaChapterIdQueryHookResult = ReturnType<
  typeof useGetMediaChapterIdQuery
>;
export type GetMediaChapterIdLazyQueryHookResult = ReturnType<
  typeof useGetMediaChapterIdLazyQuery
>;
export type GetMediaChapterIdQueryResult = Apollo.QueryResult<
  GetMediaChapterIdQuery,
  GetMediaChapterIdQueryVariables
>;
export const AddViewHistoryDocument = gql`
  mutation addViewHistory($id: ID!) {
    addViewHistory(
      input: {
        domain: BEEMI
        item: { resource: { type: MEDIA_CHAPTER_LIGHT, id: $id } }
      }
    ) {
      id
    }
  }
`;
export type AddViewHistoryMutationFn = Apollo.MutationFunction<
  AddViewHistoryMutation,
  AddViewHistoryMutationVariables
>;

/**
 * __useAddViewHistoryMutation__
 *
 * To run a mutation, you first call `useAddViewHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddViewHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addViewHistoryMutation, { data, loading, error }] = useAddViewHistoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddViewHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddViewHistoryMutation,
    AddViewHistoryMutationVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useMutation<
    AddViewHistoryMutation,
    AddViewHistoryMutationVariables
  >(AddViewHistoryDocument, options);
}
export type AddViewHistoryMutationHookResult = ReturnType<
  typeof useAddViewHistoryMutation
>;
export type AddViewHistoryMutationResult =
  Apollo.MutationResult<AddViewHistoryMutation>;
export type AddViewHistoryMutationOptions = Apollo.BaseMutationOptions<
  AddViewHistoryMutation,
  AddViewHistoryMutationVariables
>;
export const AddRatingDocument = gql`
  mutation addRating($input: AddLibuRatingInput!) {
    addRating(input: $input) {
      id
    }
  }
`;
export type AddRatingMutationFn = Apollo.MutationFunction<
  AddRatingMutation,
  AddRatingMutationVariables
>;

/**
 * __useAddRatingMutation__
 *
 * To run a mutation, you first call `useAddRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRatingMutation, { data, loading, error }] = useAddRatingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRatingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRatingMutation,
    AddRatingMutationVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useMutation<AddRatingMutation, AddRatingMutationVariables>(
    AddRatingDocument,
    options
  );
}
export type AddRatingMutationHookResult = ReturnType<
  typeof useAddRatingMutation
>;
export type AddRatingMutationResult = Apollo.MutationResult<AddRatingMutation>;
export type AddRatingMutationOptions = Apollo.BaseMutationOptions<
  AddRatingMutation,
  AddRatingMutationVariables
>;
export const GetViewHistoryItemsDocument = gql`
  query getViewHistoryItems($pagination: LibuPagination) {
    viewHistoryItems(domain: BEEMI, pagination: $pagination) {
      items {
        resource {
          ...ChapterCard
        }
      }
      pageResult {
        totalCount
        hasNextPage
        limit
        offset
      }
    }
  }
  ${ChapterCardFragmentDoc}
`;

/**
 * __useGetViewHistoryItemsQuery__
 *
 * To run a query within a React component, call `useGetViewHistoryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewHistoryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewHistoryItemsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetViewHistoryItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetViewHistoryItemsQuery,
    GetViewHistoryItemsQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetViewHistoryItemsQuery,
    GetViewHistoryItemsQueryVariables
  >(GetViewHistoryItemsDocument, options);
}
export function useGetViewHistoryItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetViewHistoryItemsQuery,
    GetViewHistoryItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetViewHistoryItemsQuery,
    GetViewHistoryItemsQueryVariables
  >(GetViewHistoryItemsDocument, options);
}
export type GetViewHistoryItemsQueryHookResult = ReturnType<
  typeof useGetViewHistoryItemsQuery
>;
export type GetViewHistoryItemsLazyQueryHookResult = ReturnType<
  typeof useGetViewHistoryItemsLazyQuery
>;
export type GetViewHistoryItemsQueryResult = Apollo.QueryResult<
  GetViewHistoryItemsQuery,
  GetViewHistoryItemsQueryVariables
>;
export const DeleteViewHistoryDocument = gql`
  mutation deleteViewHistory(
    $resources: [LibuResourceInput!]!
    $inverse: Boolean = false
  ) {
    deleteViewHistory(
      input: { resources: $resources, inverse: $inverse, domain: BEEMI }
    ) {
      id
    }
  }
`;
export type DeleteViewHistoryMutationFn = Apollo.MutationFunction<
  DeleteViewHistoryMutation,
  DeleteViewHistoryMutationVariables
>;

/**
 * __useDeleteViewHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteViewHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteViewHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteViewHistoryMutation, { data, loading, error }] = useDeleteViewHistoryMutation({
 *   variables: {
 *      resources: // value for 'resources'
 *      inverse: // value for 'inverse'
 *   },
 * });
 */
export function useDeleteViewHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteViewHistoryMutation,
    DeleteViewHistoryMutationVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useMutation<
    DeleteViewHistoryMutation,
    DeleteViewHistoryMutationVariables
  >(DeleteViewHistoryDocument, options);
}
export type DeleteViewHistoryMutationHookResult = ReturnType<
  typeof useDeleteViewHistoryMutation
>;
export type DeleteViewHistoryMutationResult =
  Apollo.MutationResult<DeleteViewHistoryMutation>;
export type DeleteViewHistoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteViewHistoryMutation,
  DeleteViewHistoryMutationVariables
>;
export const GetUserProfileDocument = gql`
  query getUserProfile {
    userProfile(domain: BEEMI) {
      profile {
        ... on BeemiUserProfile {
          showViewHistory
        }
      }
    }
  }
`;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options
  );
}
export function useGetUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserProfileQuery,
    GetUserProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(
    GetUserProfileDocument,
    options
  );
}
export type GetUserProfileQueryHookResult = ReturnType<
  typeof useGetUserProfileQuery
>;
export type GetUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetUserProfileLazyQuery
>;
export type GetUserProfileQueryResult = Apollo.QueryResult<
  GetUserProfileQuery,
  GetUserProfileQueryVariables
>;
export const SetUserProfileDocument = gql`
  mutation setUserProfile($input: SetUserProfileInput!) {
    setUserProfile(input: $input) {
      profile {
        ... on BeemiUserProfile {
          showViewHistory
        }
      }
    }
  }
`;
export type SetUserProfileMutationFn = Apollo.MutationFunction<
  SetUserProfileMutation,
  SetUserProfileMutationVariables
>;

/**
 * __useSetUserProfileMutation__
 *
 * To run a mutation, you first call `useSetUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserProfileMutation, { data, loading, error }] = useSetUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserProfileMutation,
    SetUserProfileMutationVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useMutation<
    SetUserProfileMutation,
    SetUserProfileMutationVariables
  >(SetUserProfileDocument, options);
}
export type SetUserProfileMutationHookResult = ReturnType<
  typeof useSetUserProfileMutation
>;
export type SetUserProfileMutationResult =
  Apollo.MutationResult<SetUserProfileMutation>;
export type SetUserProfileMutationOptions = Apollo.BaseMutationOptions<
  SetUserProfileMutation,
  SetUserProfileMutationVariables
>;
export const GetFavoriteListDocument = gql`
  query getFavoriteList(
    $favoriteList: LibuFavoriteListKey!
    $pagination: LibuPagination
    $orderBy: [LibuFavoriteItemSortCondition!]
  ) {
    favoriteList(favoriteList: $favoriteList) {
      id
      title
      itemList(pagination: $pagination, orderBy: $orderBy) {
        pageResult {
          totalCount
          hasNextPage
          limit
          offset
        }
        items {
          id
          resource {
            ...PackageCard
            ...ChapterCard
          }
        }
      }
    }
  }
  ${PackageCardFragmentDoc}
  ${ChapterCardFragmentDoc}
`;

/**
 * __useGetFavoriteListQuery__
 *
 * To run a query within a React component, call `useGetFavoriteListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFavoriteListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFavoriteListQuery({
 *   variables: {
 *      favoriteList: // value for 'favoriteList'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetFavoriteListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFavoriteListQuery,
    GetFavoriteListQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetFavoriteListQuery, GetFavoriteListQueryVariables>(
    GetFavoriteListDocument,
    options
  );
}
export function useGetFavoriteListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFavoriteListQuery,
    GetFavoriteListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFavoriteListQuery,
    GetFavoriteListQueryVariables
  >(GetFavoriteListDocument, options);
}
export type GetFavoriteListQueryHookResult = ReturnType<
  typeof useGetFavoriteListQuery
>;
export type GetFavoriteListLazyQueryHookResult = ReturnType<
  typeof useGetFavoriteListLazyQuery
>;
export type GetFavoriteListQueryResult = Apollo.QueryResult<
  GetFavoriteListQuery,
  GetFavoriteListQueryVariables
>;
export const PurchaseHistoryItemsDocument = gql`
  query PurchaseHistoryItems(
    $pagination: Pagination
    $sorter: PurchasedMediaSorter
  ) {
    purchasedMedia(pagination: $pagination, sorter: $sorter) {
      pageInfo {
        hasNext
      }
      records {
        ...PackageCardLoggedIn
      }
    }
  }
  ${PackageCardLoggedInFragmentDoc}
`;

/**
 * __usePurchaseHistoryItemsQuery__
 *
 * To run a query within a React component, call `usePurchaseHistoryItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseHistoryItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseHistoryItemsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      sorter: // value for 'sorter'
 *   },
 * });
 */
export function usePurchaseHistoryItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PurchaseHistoryItemsQuery,
    PurchaseHistoryItemsQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    PurchaseHistoryItemsQuery,
    PurchaseHistoryItemsQueryVariables
  >(PurchaseHistoryItemsDocument, options);
}
export function usePurchaseHistoryItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PurchaseHistoryItemsQuery,
    PurchaseHistoryItemsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PurchaseHistoryItemsQuery,
    PurchaseHistoryItemsQueryVariables
  >(PurchaseHistoryItemsDocument, options);
}
export type PurchaseHistoryItemsQueryHookResult = ReturnType<
  typeof usePurchaseHistoryItemsQuery
>;
export type PurchaseHistoryItemsLazyQueryHookResult = ReturnType<
  typeof usePurchaseHistoryItemsLazyQuery
>;
export type PurchaseHistoryItemsQueryResult = Apollo.QueryResult<
  PurchaseHistoryItemsQuery,
  PurchaseHistoryItemsQueryVariables
>;
export const DeletePurchasedMediaDocument = gql`
  mutation deletePurchasedMedia($ids: [String!]!) {
    deletePurchasedMedia(ids: $ids)
  }
`;
export type DeletePurchasedMediaMutationFn = Apollo.MutationFunction<
  DeletePurchasedMediaMutation,
  DeletePurchasedMediaMutationVariables
>;

/**
 * __useDeletePurchasedMediaMutation__
 *
 * To run a mutation, you first call `useDeletePurchasedMediaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurchasedMediaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePurchasedMediaMutation, { data, loading, error }] = useDeletePurchasedMediaMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeletePurchasedMediaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePurchasedMediaMutation,
    DeletePurchasedMediaMutationVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useMutation<
    DeletePurchasedMediaMutation,
    DeletePurchasedMediaMutationVariables
  >(DeletePurchasedMediaDocument, options);
}
export type DeletePurchasedMediaMutationHookResult = ReturnType<
  typeof useDeletePurchasedMediaMutation
>;
export type DeletePurchasedMediaMutationResult =
  Apollo.MutationResult<DeletePurchasedMediaMutation>;
export type DeletePurchasedMediaMutationOptions = Apollo.BaseMutationOptions<
  DeletePurchasedMediaMutation,
  DeletePurchasedMediaMutationVariables
>;
export const GetPlatformInfoDocument = gql`
  query GetPlatformInfo {
    platformInfo {
      deviceName
      deviceType
      screenType
      userAgent
    }
  }
`;

/**
 * __useGetPlatformInfoQuery__
 *
 * To run a query within a React component, call `useGetPlatformInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlatformInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlatformInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlatformInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlatformInfoQuery,
    GetPlatformInfoQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetPlatformInfoQuery, GetPlatformInfoQueryVariables>(
    GetPlatformInfoDocument,
    options
  );
}
export function useGetPlatformInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlatformInfoQuery,
    GetPlatformInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlatformInfoQuery,
    GetPlatformInfoQueryVariables
  >(GetPlatformInfoDocument, options);
}
export type GetPlatformInfoQueryHookResult = ReturnType<
  typeof useGetPlatformInfoQuery
>;
export type GetPlatformInfoLazyQueryHookResult = ReturnType<
  typeof useGetPlatformInfoLazyQuery
>;
export type GetPlatformInfoQueryResult = Apollo.QueryResult<
  GetPlatformInfoQuery,
  GetPlatformInfoQueryVariables
>;
export const GetMediaDocument = gql`
  query getMedia(
    $mediaId: ID!
    $deviceId: String!
    $isLoggedIn: Boolean = false
  ) {
    media(mediaId: $mediaId) {
      id
      metadata {
        id
        nameJa
        releaseLifetimeClass
        favoriteCount
        thumbnails {
          t1
        }
        screenshots {
          w1
          w2
        }
        paginatedChapters {
          records {
            id
            metadata {
              id
              nameJa
              chapterNo
              thumbnails {
                t1
              }
              durationSeconds
            }
          }
        }
        videoConsumables {
          id
          type
          videoProfiles {
            codec
            resolution
            geometry {
              geometryType
              horizontalAngleDegree
              projectionType
              stereoVideo
              stereoLayout
            }
          }
          durationSeconds
        }
        performances {
          characterName
          alias {
            id
            nameJa
          }
        }
        credits {
          creditType
          alias {
            id
            nameJa
          }
        }
        brandGroups {
          id
          nameJa
        }
        synopsis
        contentTags {
          id
          nameJa
          contentType
        }
        adultProductCode
        copyright
        originalReleaseDate
        featureBadges
      }
      productInfo(deviceId: $deviceId) {
        products {
          ...ProductFields
        }
        publicEndDateTime
        copyright
        minPrice
        hasMultiplePrices
        platformLifetimeClass
        saleBadges
        paymentBadges
      }
      paginatedRecommendations {
        records {
          ...PackageCard
        }
      }
      rating(domain: BEEMI) @include(if: $isLoggedIn) {
        score
      }
      favorite(
        favoriteList: {
          kind: TITLE
          domainTitle: { domain: BEEMI, title: "beemi-mylist-package" }
        }
      ) @include(if: $isLoggedIn) {
        id
      }
      playbackRight {
        expiredDateTime
      }
    }
  }
  ${ProductFieldsFragmentDoc}
  ${PackageCardFragmentDoc}
`;

/**
 * __useGetMediaQuery__
 *
 * To run a query within a React component, call `useGetMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      deviceId: // value for 'deviceId'
 *      isLoggedIn: // value for 'isLoggedIn'
 *   },
 * });
 */
export function useGetMediaQuery(
  baseOptions: Apollo.QueryHookOptions<GetMediaQuery, GetMediaQueryVariables>
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetMediaQuery, GetMediaQueryVariables>(
    GetMediaDocument,
    options
  );
}
export function useGetMediaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaQuery,
    GetMediaQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMediaQuery, GetMediaQueryVariables>(
    GetMediaDocument,
    options
  );
}
export type GetMediaQueryHookResult = ReturnType<typeof useGetMediaQuery>;
export type GetMediaLazyQueryHookResult = ReturnType<
  typeof useGetMediaLazyQuery
>;
export type GetMediaQueryResult = Apollo.QueryResult<
  GetMediaQuery,
  GetMediaQueryVariables
>;
export const GetMediaPlayInfoDocument = gql`
  query getMediaPlayInfo(
    $mediaId: ID!
    $deviceId: String!
    $chapterPagination: Pagination!
  ) {
    media(mediaId: $mediaId) {
      id
      metadata {
        id
        nameJa
        thumbnails {
          w2
        }
        paginatedChapters(pagination: $chapterPagination) {
          records {
            id
            metadata {
              id
              nameJa
              chapterNo
              thumbnails {
                t1
              }
              durationSeconds
              startTimeSeconds
              endTimeSeconds
            }
          }
          pageInfo {
            hasNext
          }
        }
        videoConsumables {
          id
          type
          videoProfiles {
            codec
            resolution
            geometry {
              geometryType
              horizontalAngleDegree
              projectionType
              stereoVideo
              stereoLayout
            }
          }
          durationSeconds
        }
      }
      productInfo(deviceId: $deviceId) {
        products {
          ...ProductFields
        }
        copyright
        minPrice
        hasMultiplePrices
        platformLifetimeClass
        saleBadges
        paymentBadges
      }
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useGetMediaPlayInfoQuery__
 *
 * To run a query within a React component, call `useGetMediaPlayInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaPlayInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaPlayInfoQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      deviceId: // value for 'deviceId'
 *      chapterPagination: // value for 'chapterPagination'
 *   },
 * });
 */
export function useGetMediaPlayInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaPlayInfoQuery,
    GetMediaPlayInfoQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetMediaPlayInfoQuery, GetMediaPlayInfoQueryVariables>(
    GetMediaPlayInfoDocument,
    options
  );
}
export function useGetMediaPlayInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaPlayInfoQuery,
    GetMediaPlayInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMediaPlayInfoQuery,
    GetMediaPlayInfoQueryVariables
  >(GetMediaPlayInfoDocument, options);
}
export type GetMediaPlayInfoQueryHookResult = ReturnType<
  typeof useGetMediaPlayInfoQuery
>;
export type GetMediaPlayInfoLazyQueryHookResult = ReturnType<
  typeof useGetMediaPlayInfoLazyQuery
>;
export type GetMediaPlayInfoQueryResult = Apollo.QueryResult<
  GetMediaPlayInfoQuery,
  GetMediaPlayInfoQueryVariables
>;
export const GetMediaIdDocument = gql`
  query getMediaId($mediaId: ID!) {
    media(mediaId: $mediaId) {
      id
    }
  }
`;

/**
 * __useGetMediaIdQuery__
 *
 * To run a query within a React component, call `useGetMediaIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaIdQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *   },
 * });
 */
export function useGetMediaIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaIdQuery,
    GetMediaIdQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetMediaIdQuery, GetMediaIdQueryVariables>(
    GetMediaIdDocument,
    options
  );
}
export function useGetMediaIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaIdQuery,
    GetMediaIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMediaIdQuery, GetMediaIdQueryVariables>(
    GetMediaIdDocument,
    options
  );
}
export type GetMediaIdQueryHookResult = ReturnType<typeof useGetMediaIdQuery>;
export type GetMediaIdLazyQueryHookResult = ReturnType<
  typeof useGetMediaIdLazyQuery
>;
export type GetMediaIdQueryResult = Apollo.QueryResult<
  GetMediaIdQuery,
  GetMediaIdQueryVariables
>;
export const GetPlayBackTokensDocument = gql`
  query getPlayBackTokens(
    $contentId: ID!
    $consumableId: ID!
    $deviceId: String!
    $resolutionUpperLimit: String
    $bitRateLowerLimit: Int
    $bitRateUpperLimit: Int
  ) {
    playBackTokens(
      contentId: $contentId
      consumableId: $consumableId
      deviceId: $deviceId
      resolutionUpperLimit: $resolutionUpperLimit
      bitRateLowerLimit: $bitRateLowerLimit
      bitRateUpperLimit: $bitRateUpperLimit
    ) {
      playbackToken
      isemToken
      productCode
      saleTypeCode
    }
  }
`;

/**
 * __useGetPlayBackTokensQuery__
 *
 * To run a query within a React component, call `useGetPlayBackTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayBackTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayBackTokensQuery({
 *   variables: {
 *      contentId: // value for 'contentId'
 *      consumableId: // value for 'consumableId'
 *      deviceId: // value for 'deviceId'
 *      resolutionUpperLimit: // value for 'resolutionUpperLimit'
 *      bitRateLowerLimit: // value for 'bitRateLowerLimit'
 *      bitRateUpperLimit: // value for 'bitRateUpperLimit'
 *   },
 * });
 */
export function useGetPlayBackTokensQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPlayBackTokensQuery,
    GetPlayBackTokensQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetPlayBackTokensQuery,
    GetPlayBackTokensQueryVariables
  >(GetPlayBackTokensDocument, options);
}
export function useGetPlayBackTokensLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlayBackTokensQuery,
    GetPlayBackTokensQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPlayBackTokensQuery,
    GetPlayBackTokensQueryVariables
  >(GetPlayBackTokensDocument, options);
}
export type GetPlayBackTokensQueryHookResult = ReturnType<
  typeof useGetPlayBackTokensQuery
>;
export type GetPlayBackTokensLazyQueryHookResult = ReturnType<
  typeof useGetPlayBackTokensLazyQuery
>;
export type GetPlayBackTokensQueryResult = Apollo.QueryResult<
  GetPlayBackTokensQuery,
  GetPlayBackTokensQueryVariables
>;
export const GetOneTimeTokenDocument = gql`
  query getOneTimeToken {
    webfront_oneTimeToken {
      token
    }
  }
`;

/**
 * __useGetOneTimeTokenQuery__
 *
 * To run a query within a React component, call `useGetOneTimeTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneTimeTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneTimeTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOneTimeTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOneTimeTokenQuery,
    GetOneTimeTokenQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetOneTimeTokenQuery, GetOneTimeTokenQueryVariables>(
    GetOneTimeTokenDocument,
    options
  );
}
export function useGetOneTimeTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOneTimeTokenQuery,
    GetOneTimeTokenQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOneTimeTokenQuery,
    GetOneTimeTokenQueryVariables
  >(GetOneTimeTokenDocument, options);
}
export type GetOneTimeTokenQueryHookResult = ReturnType<
  typeof useGetOneTimeTokenQuery
>;
export type GetOneTimeTokenLazyQueryHookResult = ReturnType<
  typeof useGetOneTimeTokenLazyQuery
>;
export type GetOneTimeTokenQueryResult = Apollo.QueryResult<
  GetOneTimeTokenQuery,
  GetOneTimeTokenQueryVariables
>;
export const GetPointBackDocument = gql`
  query getPointBack {
    webfront_pointBack {
      hasVideoSubscription
      point
      setting {
        canIncreasePercentage
        maxPercentage
        percentage
        productList
        scheduleDate
      }
    }
  }
`;

/**
 * __useGetPointBackQuery__
 *
 * To run a query within a React component, call `useGetPointBackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPointBackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPointBackQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPointBackQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPointBackQuery,
    GetPointBackQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetPointBackQuery, GetPointBackQueryVariables>(
    GetPointBackDocument,
    options
  );
}
export function useGetPointBackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPointBackQuery,
    GetPointBackQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPointBackQuery, GetPointBackQueryVariables>(
    GetPointBackDocument,
    options
  );
}
export type GetPointBackQueryHookResult = ReturnType<
  typeof useGetPointBackQuery
>;
export type GetPointBackLazyQueryHookResult = ReturnType<
  typeof useGetPointBackLazyQuery
>;
export type GetPointBackQueryResult = Apollo.QueryResult<
  GetPointBackQuery,
  GetPointBackQueryVariables
>;
export const GetMediaProductInfoDocument = gql`
  query getMediaProductInfo($mediaId: ID!, $deviceId: String!) {
    media(mediaId: $mediaId) {
      id
      metadata {
        id
        nameJa
        nameKana
        videoConsumables {
          id
          type
          videoProfiles {
            codec
            resolution
            geometry {
              geometryType
              horizontalAngleDegree
              projectionType
              stereoVideo
              stereoLayout
            }
          }
        }
        featureBadges
      }
      productInfo(deviceId: $deviceId) {
        products {
          ...ProductFields
          alreadyPurchased
        }
        copyright
        minPrice
        hasMultiplePrices
        platformLifetimeClass
        saleBadges
        paymentBadges
      }
    }
  }
  ${ProductFieldsFragmentDoc}
`;

/**
 * __useGetMediaProductInfoQuery__
 *
 * To run a query within a React component, call `useGetMediaProductInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMediaProductInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMediaProductInfoQuery({
 *   variables: {
 *      mediaId: // value for 'mediaId'
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGetMediaProductInfoQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMediaProductInfoQuery,
    GetMediaProductInfoQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetMediaProductInfoQuery,
    GetMediaProductInfoQueryVariables
  >(GetMediaProductInfoDocument, options);
}
export function useGetMediaProductInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMediaProductInfoQuery,
    GetMediaProductInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMediaProductInfoQuery,
    GetMediaProductInfoQueryVariables
  >(GetMediaProductInfoDocument, options);
}
export type GetMediaProductInfoQueryHookResult = ReturnType<
  typeof useGetMediaProductInfoQuery
>;
export type GetMediaProductInfoLazyQueryHookResult = ReturnType<
  typeof useGetMediaProductInfoLazyQuery
>;
export type GetMediaProductInfoQueryResult = Apollo.QueryResult<
  GetMediaProductInfoQuery,
  GetMediaProductInfoQueryVariables
>;
export const PurchaseBeemiVideoDocument = gql`
  mutation purchaseBeemiVideo(
    $deviceCode: String!
    $capyId: String!
    $productCode: String!
  ) {
    purchaseBeemiVideo(
      deviceCode: $deviceCode
      capyId: $capyId
      productCode: $productCode
    )
  }
`;
export type PurchaseBeemiVideoMutationFn = Apollo.MutationFunction<
  PurchaseBeemiVideoMutation,
  PurchaseBeemiVideoMutationVariables
>;

/**
 * __usePurchaseBeemiVideoMutation__
 *
 * To run a mutation, you first call `usePurchaseBeemiVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseBeemiVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseBeemiVideoMutation, { data, loading, error }] = usePurchaseBeemiVideoMutation({
 *   variables: {
 *      deviceCode: // value for 'deviceCode'
 *      capyId: // value for 'capyId'
 *      productCode: // value for 'productCode'
 *   },
 * });
 */
export function usePurchaseBeemiVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PurchaseBeemiVideoMutation,
    PurchaseBeemiVideoMutationVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useMutation<
    PurchaseBeemiVideoMutation,
    PurchaseBeemiVideoMutationVariables
  >(PurchaseBeemiVideoDocument, options);
}
export type PurchaseBeemiVideoMutationHookResult = ReturnType<
  typeof usePurchaseBeemiVideoMutation
>;
export type PurchaseBeemiVideoMutationResult =
  Apollo.MutationResult<PurchaseBeemiVideoMutation>;
export type PurchaseBeemiVideoMutationOptions = Apollo.BaseMutationOptions<
  PurchaseBeemiVideoMutation,
  PurchaseBeemiVideoMutationVariables
>;
export const GetSearchResultsDocument = gql`
  query getSearchResults(
    $query: String!
    $pagination: Pagination
    $nodeFilter: [NodeFilter!]
  ) {
    beemiSearch(
      query: $query
      ratingCode: R18
      searchType: ALL
      pagination: $pagination
      nodeFilter: $nodeFilter
    ) {
      media {
        hits {
          ...PackageCard
        }
      }
      mediaChapters {
        hits {
          ...ChapterCard
        }
      }
      suggestions {
        id
        incidence
        nameJa
        contentType
        description
      }
      aliases {
        hits {
          id
          nameJa
        }
      }
    }
  }
  ${PackageCardFragmentDoc}
  ${ChapterCardFragmentDoc}
`;

/**
 * __useGetSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultsQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *      nodeFilter: // value for 'nodeFilter'
 *   },
 * });
 */
export function useGetSearchResultsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchResultsQuery,
    GetSearchResultsQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(
    GetSearchResultsDocument,
    options
  );
}
export function useGetSearchResultsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchResultsQuery,
    GetSearchResultsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchResultsQuery,
    GetSearchResultsQueryVariables
  >(GetSearchResultsDocument, options);
}
export type GetSearchResultsQueryHookResult = ReturnType<
  typeof useGetSearchResultsQuery
>;
export type GetSearchResultsLazyQueryHookResult = ReturnType<
  typeof useGetSearchResultsLazyQuery
>;
export type GetSearchResultsQueryResult = Apollo.QueryResult<
  GetSearchResultsQuery,
  GetSearchResultsQueryVariables
>;
export const GetSearchResultsStoreDocument = gql`
  query getSearchResultsStore(
    $query: String!
    $pagination: Pagination
    $searchFilters: SearchFilter
    $searchSorters: [SearchSorter!]
    $nodeFilter: [NodeFilter!]
  ) {
    beemiSearch(
      query: $query
      ratingCode: R18
      searchType: VIDEO_MEDIA
      pagination: $pagination
      searchFilters: $searchFilters
      searchSorters: $searchSorters
      nodeFilter: $nodeFilter
    ) {
      media {
        hasMore
        total
        hits {
          ...PackageCard
        }
      }
      suggestions {
        id
        incidence
        nameJa
        contentType
        description
      }
    }
  }
  ${PackageCardFragmentDoc}
`;

/**
 * __useGetSearchResultsStoreQuery__
 *
 * To run a query within a React component, call `useGetSearchResultsStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultsStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultsStoreQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *      searchFilters: // value for 'searchFilters'
 *      searchSorters: // value for 'searchSorters'
 *      nodeFilter: // value for 'nodeFilter'
 *   },
 * });
 */
export function useGetSearchResultsStoreQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchResultsStoreQuery,
    GetSearchResultsStoreQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetSearchResultsStoreQuery,
    GetSearchResultsStoreQueryVariables
  >(GetSearchResultsStoreDocument, options);
}
export function useGetSearchResultsStoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchResultsStoreQuery,
    GetSearchResultsStoreQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchResultsStoreQuery,
    GetSearchResultsStoreQueryVariables
  >(GetSearchResultsStoreDocument, options);
}
export type GetSearchResultsStoreQueryHookResult = ReturnType<
  typeof useGetSearchResultsStoreQuery
>;
export type GetSearchResultsStoreLazyQueryHookResult = ReturnType<
  typeof useGetSearchResultsStoreLazyQuery
>;
export type GetSearchResultsStoreQueryResult = Apollo.QueryResult<
  GetSearchResultsStoreQuery,
  GetSearchResultsStoreQueryVariables
>;
export const GetSearchResultsSvodDocument = gql`
  query getSearchResultsSvod(
    $query: String!
    $pagination: Pagination
    $searchFilters: SearchFilter
    $searchSorters: [SearchSorter!]
    $nodeFilter: [NodeFilter!]
  ) {
    beemiSearch(
      query: $query
      ratingCode: R18
      searchType: MEDIA_CHAPTER
      pagination: $pagination
      searchFilters: $searchFilters
      searchSorters: $searchSorters
      nodeFilter: $nodeFilter
    ) {
      mediaChapters {
        hasMore
        total
        hits {
          ...ChapterCard
        }
      }
      suggestions {
        id
        incidence
        nameJa
        contentType
        description
      }
    }
  }
  ${ChapterCardFragmentDoc}
`;

/**
 * __useGetSearchResultsSvodQuery__
 *
 * To run a query within a React component, call `useGetSearchResultsSvodQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultsSvodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultsSvodQuery({
 *   variables: {
 *      query: // value for 'query'
 *      pagination: // value for 'pagination'
 *      searchFilters: // value for 'searchFilters'
 *      searchSorters: // value for 'searchSorters'
 *      nodeFilter: // value for 'nodeFilter'
 *   },
 * });
 */
export function useGetSearchResultsSvodQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSearchResultsSvodQuery,
    GetSearchResultsSvodQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetSearchResultsSvodQuery,
    GetSearchResultsSvodQueryVariables
  >(GetSearchResultsSvodDocument, options);
}
export function useGetSearchResultsSvodLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSearchResultsSvodQuery,
    GetSearchResultsSvodQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSearchResultsSvodQuery,
    GetSearchResultsSvodQueryVariables
  >(GetSearchResultsSvodDocument, options);
}
export type GetSearchResultsSvodQueryHookResult = ReturnType<
  typeof useGetSearchResultsSvodQuery
>;
export type GetSearchResultsSvodLazyQueryHookResult = ReturnType<
  typeof useGetSearchResultsSvodLazyQuery
>;
export type GetSearchResultsSvodQueryResult = Apollo.QueryResult<
  GetSearchResultsSvodQuery,
  GetSearchResultsSvodQueryVariables
>;
export const SimpleNodeLookupDocument = gql`
  query simpleNodeLookup($nodes: SimpleNodeLookupFilter) {
    simpleNodeLookup(nodes: $nodes) {
      nodes {
        id
        nameJa
        contentType
      }
    }
  }
`;

/**
 * __useSimpleNodeLookupQuery__
 *
 * To run a query within a React component, call `useSimpleNodeLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleNodeLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleNodeLookupQuery({
 *   variables: {
 *      nodes: // value for 'nodes'
 *   },
 * });
 */
export function useSimpleNodeLookupQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SimpleNodeLookupQuery,
    SimpleNodeLookupQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<SimpleNodeLookupQuery, SimpleNodeLookupQueryVariables>(
    SimpleNodeLookupDocument,
    options
  );
}
export function useSimpleNodeLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SimpleNodeLookupQuery,
    SimpleNodeLookupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SimpleNodeLookupQuery,
    SimpleNodeLookupQueryVariables
  >(SimpleNodeLookupDocument, options);
}
export type SimpleNodeLookupQueryHookResult = ReturnType<
  typeof useSimpleNodeLookupQuery
>;
export type SimpleNodeLookupLazyQueryHookResult = ReturnType<
  typeof useSimpleNodeLookupLazyQuery
>;
export type SimpleNodeLookupQueryResult = Apollo.QueryResult<
  SimpleNodeLookupQuery,
  SimpleNodeLookupQueryVariables
>;
export const GetAutoCompleteSearchDocument = gql`
  query getAutoCompleteSearch($query: String!) {
    beemiAutoCompleteSearch(query: $query, pagination: { limit: 20 }) {
      nodes {
        id
        contentType
        text
        score
        description
      }
    }
  }
`;

/**
 * __useGetAutoCompleteSearchQuery__
 *
 * To run a query within a React component, call `useGetAutoCompleteSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoCompleteSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoCompleteSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetAutoCompleteSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAutoCompleteSearchQuery,
    GetAutoCompleteSearchQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetAutoCompleteSearchQuery,
    GetAutoCompleteSearchQueryVariables
  >(GetAutoCompleteSearchDocument, options);
}
export function useGetAutoCompleteSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAutoCompleteSearchQuery,
    GetAutoCompleteSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAutoCompleteSearchQuery,
    GetAutoCompleteSearchQueryVariables
  >(GetAutoCompleteSearchDocument, options);
}
export type GetAutoCompleteSearchQueryHookResult = ReturnType<
  typeof useGetAutoCompleteSearchQuery
>;
export type GetAutoCompleteSearchLazyQueryHookResult = ReturnType<
  typeof useGetAutoCompleteSearchLazyQuery
>;
export type GetAutoCompleteSearchQueryResult = Apollo.QueryResult<
  GetAutoCompleteSearchQuery,
  GetAutoCompleteSearchQueryVariables
>;
export const GetPlanetsDocument = gql`
  query getPlanets {
    planets {
      id
      description
      displayName
    }
  }
`;

/**
 * __useGetPlanetsQuery__
 *
 * To run a query within a React component, call `useGetPlanetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlanetsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPlanetsQuery,
    GetPlanetsQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetPlanetsQuery, GetPlanetsQueryVariables>(
    GetPlanetsDocument,
    options
  );
}
export function useGetPlanetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPlanetsQuery,
    GetPlanetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlanetsQuery, GetPlanetsQueryVariables>(
    GetPlanetsDocument,
    options
  );
}
export type GetPlanetsQueryHookResult = ReturnType<typeof useGetPlanetsQuery>;
export type GetPlanetsLazyQueryHookResult = ReturnType<
  typeof useGetPlanetsLazyQuery
>;
export type GetPlanetsQueryResult = Apollo.QueryResult<
  GetPlanetsQuery,
  GetPlanetsQueryVariables
>;
export const GetAllContentTagSuggestionsDocument = gql`
  query getAllContentTagSuggestions {
    allContentTagSuggestions {
      id
      nameJa
      contentType
      description
    }
  }
`;

/**
 * __useGetAllContentTagSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetAllContentTagSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllContentTagSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllContentTagSuggestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllContentTagSuggestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllContentTagSuggestionsQuery,
    GetAllContentTagSuggestionsQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetAllContentTagSuggestionsQuery,
    GetAllContentTagSuggestionsQueryVariables
  >(GetAllContentTagSuggestionsDocument, options);
}
export function useGetAllContentTagSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllContentTagSuggestionsQuery,
    GetAllContentTagSuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllContentTagSuggestionsQuery,
    GetAllContentTagSuggestionsQueryVariables
  >(GetAllContentTagSuggestionsDocument, options);
}
export type GetAllContentTagSuggestionsQueryHookResult = ReturnType<
  typeof useGetAllContentTagSuggestionsQuery
>;
export type GetAllContentTagSuggestionsLazyQueryHookResult = ReturnType<
  typeof useGetAllContentTagSuggestionsLazyQuery
>;
export type GetAllContentTagSuggestionsQueryResult = Apollo.QueryResult<
  GetAllContentTagSuggestionsQuery,
  GetAllContentTagSuggestionsQueryVariables
>;
export const GetSpotlightedSuggestionDocument = gql`
  query getSpotlightedSuggestion {
    spotlightedSuggestions {
      id
      text
      description
      backgroundImage {
        w1
      }
    }
  }
`;

/**
 * __useGetSpotlightedSuggestionQuery__
 *
 * To run a query within a React component, call `useGetSpotlightedSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpotlightedSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpotlightedSuggestionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpotlightedSuggestionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSpotlightedSuggestionQuery,
    GetSpotlightedSuggestionQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetSpotlightedSuggestionQuery,
    GetSpotlightedSuggestionQueryVariables
  >(GetSpotlightedSuggestionDocument, options);
}
export function useGetSpotlightedSuggestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSpotlightedSuggestionQuery,
    GetSpotlightedSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSpotlightedSuggestionQuery,
    GetSpotlightedSuggestionQueryVariables
  >(GetSpotlightedSuggestionDocument, options);
}
export type GetSpotlightedSuggestionQueryHookResult = ReturnType<
  typeof useGetSpotlightedSuggestionQuery
>;
export type GetSpotlightedSuggestionLazyQueryHookResult = ReturnType<
  typeof useGetSpotlightedSuggestionLazyQuery
>;
export type GetSpotlightedSuggestionQueryResult = Apollo.QueryResult<
  GetSpotlightedSuggestionQuery,
  GetSpotlightedSuggestionQueryVariables
>;
export const GetAltDetailsBlockDocument = gql`
  query getAltDetailsBlock(
    $blockId: ID!
    $planetId: ID
    $pagination: Pagination
  ) {
    contentBlockFull(
      blockId: $blockId
      planetId: $planetId
      pagination: $pagination
    ) {
      ... on CapyRecommendationBlock {
        id
        name
        description
        paginatedMedia {
          records {
            ...PackageCard
          }
          pageInfo {
            hasNext
          }
        }
      }
    }
  }
  ${PackageCardFragmentDoc}
`;

/**
 * __useGetAltDetailsBlockQuery__
 *
 * To run a query within a React component, call `useGetAltDetailsBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAltDetailsBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAltDetailsBlockQuery({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      planetId: // value for 'planetId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetAltDetailsBlockQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAltDetailsBlockQuery,
    GetAltDetailsBlockQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetAltDetailsBlockQuery,
    GetAltDetailsBlockQueryVariables
  >(GetAltDetailsBlockDocument, options);
}
export function useGetAltDetailsBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAltDetailsBlockQuery,
    GetAltDetailsBlockQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAltDetailsBlockQuery,
    GetAltDetailsBlockQueryVariables
  >(GetAltDetailsBlockDocument, options);
}
export type GetAltDetailsBlockQueryHookResult = ReturnType<
  typeof useGetAltDetailsBlockQuery
>;
export type GetAltDetailsBlockLazyQueryHookResult = ReturnType<
  typeof useGetAltDetailsBlockLazyQuery
>;
export type GetAltDetailsBlockQueryResult = Apollo.QueryResult<
  GetAltDetailsBlockQuery,
  GetAltDetailsBlockQueryVariables
>;
export const GetNewBlockDocument = gql`
  query getNewBlock($planetId: ID!, $pagination: Pagination) {
    newMediaRankingBlock(planetId: $planetId, pagination: $pagination) {
      id
      name
      description
      paginatedMedia {
        records {
          ...PackageCard
        }
        pageInfo {
          hasNext
        }
      }
    }
  }
  ${PackageCardFragmentDoc}
`;

/**
 * __useGetNewBlockQuery__
 *
 * To run a query within a React component, call `useGetNewBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewBlockQuery({
 *   variables: {
 *      planetId: // value for 'planetId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetNewBlockQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNewBlockQuery,
    GetNewBlockQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetNewBlockQuery, GetNewBlockQueryVariables>(
    GetNewBlockDocument,
    options
  );
}
export function useGetNewBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNewBlockQuery,
    GetNewBlockQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNewBlockQuery, GetNewBlockQueryVariables>(
    GetNewBlockDocument,
    options
  );
}
export type GetNewBlockQueryHookResult = ReturnType<typeof useGetNewBlockQuery>;
export type GetNewBlockLazyQueryHookResult = ReturnType<
  typeof useGetNewBlockLazyQuery
>;
export type GetNewBlockQueryResult = Apollo.QueryResult<
  GetNewBlockQuery,
  GetNewBlockQueryVariables
>;
export const GetRankingBlockDocument = gql`
  query getRankingBlock($planetId: ID!, $pagination: Pagination) {
    revenueRankingBlock(planetId: $planetId, pagination: $pagination) {
      id
      name
      paginatedMedia {
        records {
          ...PackageCard
        }
      }
    }
  }
  ${PackageCardFragmentDoc}
`;

/**
 * __useGetRankingBlockQuery__
 *
 * To run a query within a React component, call `useGetRankingBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRankingBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRankingBlockQuery({
 *   variables: {
 *      planetId: // value for 'planetId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetRankingBlockQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRankingBlockQuery,
    GetRankingBlockQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetRankingBlockQuery, GetRankingBlockQueryVariables>(
    GetRankingBlockDocument,
    options
  );
}
export function useGetRankingBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRankingBlockQuery,
    GetRankingBlockQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRankingBlockQuery,
    GetRankingBlockQueryVariables
  >(GetRankingBlockDocument, options);
}
export type GetRankingBlockQueryHookResult = ReturnType<
  typeof useGetRankingBlockQuery
>;
export type GetRankingBlockLazyQueryHookResult = ReturnType<
  typeof useGetRankingBlockLazyQuery
>;
export type GetRankingBlockQueryResult = Apollo.QueryResult<
  GetRankingBlockQuery,
  GetRankingBlockQueryVariables
>;
export const GetSaleDetailBlockDocument = gql`
  query getSaleDetailBlock(
    $blockId: ID!
    $planetId: ID
    $pagination: Pagination
  ) {
    contentBlockFull(
      blockId: $blockId
      planetId: $planetId
      pagination: $pagination
    ) {
      ... on SalesBlock {
        id
        name
        description
        displayStartDate
        displayEndDate
        paginatedMedia {
          records {
            ...PackageCard
          }
          pageInfo {
            hasNext
          }
        }
      }
    }
  }
  ${PackageCardFragmentDoc}
`;

/**
 * __useGetSaleDetailBlockQuery__
 *
 * To run a query within a React component, call `useGetSaleDetailBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaleDetailBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaleDetailBlockQuery({
 *   variables: {
 *      blockId: // value for 'blockId'
 *      planetId: // value for 'planetId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetSaleDetailBlockQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSaleDetailBlockQuery,
    GetSaleDetailBlockQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetSaleDetailBlockQuery,
    GetSaleDetailBlockQueryVariables
  >(GetSaleDetailBlockDocument, options);
}
export function useGetSaleDetailBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSaleDetailBlockQuery,
    GetSaleDetailBlockQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSaleDetailBlockQuery,
    GetSaleDetailBlockQueryVariables
  >(GetSaleDetailBlockDocument, options);
}
export type GetSaleDetailBlockQueryHookResult = ReturnType<
  typeof useGetSaleDetailBlockQuery
>;
export type GetSaleDetailBlockLazyQueryHookResult = ReturnType<
  typeof useGetSaleDetailBlockLazyQuery
>;
export type GetSaleDetailBlockQueryResult = Apollo.QueryResult<
  GetSaleDetailBlockQuery,
  GetSaleDetailBlockQueryVariables
>;
export const GetSalesBlockDocument = gql`
  query getSalesBlock($planetId: ID!) {
    activeSaleBlocksV2(planetId: $planetId) {
      records {
        __typename
        ... on SalesBlock {
          id
          name
          description
          displayStartDate
          displayEndDate
          paginatedMedia {
            pageInfo {
              totalRecordCount
            }
          }
          thumbnails {
            w1
            w2
          }
        }
      }
    }
  }
`;

/**
 * __useGetSalesBlockQuery__
 *
 * To run a query within a React component, call `useGetSalesBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesBlockQuery({
 *   variables: {
 *      planetId: // value for 'planetId'
 *   },
 * });
 */
export function useGetSalesBlockQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSalesBlockQuery,
    GetSalesBlockQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetSalesBlockQuery, GetSalesBlockQueryVariables>(
    GetSalesBlockDocument,
    options
  );
}
export function useGetSalesBlockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSalesBlockQuery,
    GetSalesBlockQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSalesBlockQuery, GetSalesBlockQueryVariables>(
    GetSalesBlockDocument,
    options
  );
}
export type GetSalesBlockQueryHookResult = ReturnType<
  typeof useGetSalesBlockQuery
>;
export type GetSalesBlockLazyQueryHookResult = ReturnType<
  typeof useGetSalesBlockLazyQuery
>;
export type GetSalesBlockQueryResult = Apollo.QueryResult<
  GetSalesBlockQuery,
  GetSalesBlockQueryVariables
>;
export const GetTopBlocksDocument = gql`
  query getTopBlocks($planetId: ID!, $listLimit: Int!) {
    beemiInfoList {
      title
      ttl
      type
      url
    }
    activeSaleBlocksV2(planetId: $planetId, pagination: { limit: $listLimit }) {
      records {
        __typename
        ... on SalesBlock {
          id
          name
          paginatedMedia {
            pageInfo {
              totalRecordCount
            }
          }
          thumbnails {
            w1
            w2
          }
        }
      }
    }
    revenueRankingBlock(
      planetId: $planetId
      pagination: { limit: $listLimit }
    ) {
      id
      name
      paginatedMedia {
        records {
          ...PackageCard
        }
      }
    }
    newMediaRankingBlock(
      planetId: $planetId
      pagination: { limit: $listLimit }
    ) {
      id
      name
      paginatedMedia {
        records {
          ...PackageCard
        }
      }
    }
    pickupBlockV2(planetId: $planetId, pagination: { limit: $listLimit }) {
      records {
        __typename
        ... on SalesBlock {
          id
          name
          thumbnails {
            w2
            uw2
          }
        }
        ... on CapyRecommendationBlock {
          id
          name
          paginatedMedia {
            records {
              ...PackageCard
            }
          }
        }
        ... on MediaSpotlightBlock {
          id
          name
          spotlight {
            metadata {
              id
              thumbnails {
                w2
                uw2
              }
            }
          }
        }
      }
    }
    popularContentTagsV2(
      planetId: $planetId
      pagination: { limit: $listLimit }
    ) {
      records {
        id
        nameJa
        contentType
      }
    }
    recommendedBlocksV2(
      planetId: $planetId
      pagination: { limit: $listLimit }
    ) {
      records {
        __typename
        ... on CapyRecommendationBlock {
          id
          name
          paginatedMedia {
            records {
              ...PackageCard
            }
          }
        }
        ... on MediaSpotlightBlock {
          id
          name
          spotlight {
            metadata {
              id
              thumbnails {
                w1
                w2
                uw2
              }
            }
          }
        }
      }
    }
  }
  ${PackageCardFragmentDoc}
`;

/**
 * __useGetTopBlocksQuery__
 *
 * To run a query within a React component, call `useGetTopBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTopBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTopBlocksQuery({
 *   variables: {
 *      planetId: // value for 'planetId'
 *      listLimit: // value for 'listLimit'
 *   },
 * });
 */
export function useGetTopBlocksQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTopBlocksQuery,
    GetTopBlocksQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetTopBlocksQuery, GetTopBlocksQueryVariables>(
    GetTopBlocksDocument,
    options
  );
}
export function useGetTopBlocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTopBlocksQuery,
    GetTopBlocksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTopBlocksQuery, GetTopBlocksQueryVariables>(
    GetTopBlocksDocument,
    options
  );
}
export type GetTopBlocksQueryHookResult = ReturnType<
  typeof useGetTopBlocksQuery
>;
export type GetTopBlocksLazyQueryHookResult = ReturnType<
  typeof useGetTopBlocksLazyQuery
>;
export type GetTopBlocksQueryResult = Apollo.QueryResult<
  GetTopBlocksQuery,
  GetTopBlocksQueryVariables
>;
export const GetUserChaptersDocument = gql`
  query getUserChapters {
    recommendedChaptersV2(pagination: { limit: 100, offset: 0 }) {
      ...PaginatedChapterCard
    }
    popularContentTagsV2(pagination: { limit: 20, offset: 0 }) {
      records {
        id
        nameJa
        contentType
      }
    }
  }
  ${PaginatedChapterCardFragmentDoc}
`;

/**
 * __useGetUserChaptersQuery__
 *
 * To run a query within a React component, call `useGetUserChaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserChaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserChaptersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserChaptersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserChaptersQuery,
    GetUserChaptersQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetUserChaptersQuery, GetUserChaptersQueryVariables>(
    GetUserChaptersDocument,
    options
  );
}
export function useGetUserChaptersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserChaptersQuery,
    GetUserChaptersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserChaptersQuery,
    GetUserChaptersQueryVariables
  >(GetUserChaptersDocument, options);
}
export type GetUserChaptersQueryHookResult = ReturnType<
  typeof useGetUserChaptersQuery
>;
export type GetUserChaptersLazyQueryHookResult = ReturnType<
  typeof useGetUserChaptersLazyQuery
>;
export type GetUserChaptersQueryResult = Apollo.QueryResult<
  GetUserChaptersQuery,
  GetUserChaptersQueryVariables
>;
export const GetNewChaptersDocument = gql`
  query getNewChapters {
    newChaptersV2(pagination: { limit: 100, offset: 0 }) {
      ...PaginatedChapterCard
    }
  }
  ${PaginatedChapterCardFragmentDoc}
`;

/**
 * __useGetNewChaptersQuery__
 *
 * To run a query within a React component, call `useGetNewChaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewChaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewChaptersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNewChaptersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNewChaptersQuery,
    GetNewChaptersQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetNewChaptersQuery, GetNewChaptersQueryVariables>(
    GetNewChaptersDocument,
    options
  );
}
export function useGetNewChaptersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNewChaptersQuery,
    GetNewChaptersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNewChaptersQuery, GetNewChaptersQueryVariables>(
    GetNewChaptersDocument,
    options
  );
}
export type GetNewChaptersQueryHookResult = ReturnType<
  typeof useGetNewChaptersQuery
>;
export type GetNewChaptersLazyQueryHookResult = ReturnType<
  typeof useGetNewChaptersLazyQuery
>;
export type GetNewChaptersQueryResult = Apollo.QueryResult<
  GetNewChaptersQuery,
  GetNewChaptersQueryVariables
>;
export const GetPopularChaptersDocument = gql`
  query getPopularChapters {
    popularChaptersV2(pagination: { limit: 100, offset: 0 }) {
      ...PaginatedChapterCard
    }
  }
  ${PaginatedChapterCardFragmentDoc}
`;

/**
 * __useGetPopularChaptersQuery__
 *
 * To run a query within a React component, call `useGetPopularChaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopularChaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopularChaptersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPopularChaptersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPopularChaptersQuery,
    GetPopularChaptersQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<
    GetPopularChaptersQuery,
    GetPopularChaptersQueryVariables
  >(GetPopularChaptersDocument, options);
}
export function useGetPopularChaptersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPopularChaptersQuery,
    GetPopularChaptersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPopularChaptersQuery,
    GetPopularChaptersQueryVariables
  >(GetPopularChaptersDocument, options);
}
export type GetPopularChaptersQueryHookResult = ReturnType<
  typeof useGetPopularChaptersQuery
>;
export type GetPopularChaptersLazyQueryHookResult = ReturnType<
  typeof useGetPopularChaptersLazyQuery
>;
export type GetPopularChaptersQueryResult = Apollo.QueryResult<
  GetPopularChaptersQuery,
  GetPopularChaptersQueryVariables
>;
export const AddFavoriteItemDocument = gql`
  mutation AddFavoriteItem($input: LibuFavoriteItemInput!) {
    addFavoriteItem(input: $input) {
      id
      status
    }
  }
`;
export type AddFavoriteItemMutationFn = Apollo.MutationFunction<
  AddFavoriteItemMutation,
  AddFavoriteItemMutationVariables
>;

/**
 * __useAddFavoriteItemMutation__
 *
 * To run a mutation, you first call `useAddFavoriteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteItemMutation, { data, loading, error }] = useAddFavoriteItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFavoriteItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFavoriteItemMutation,
    AddFavoriteItemMutationVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useMutation<
    AddFavoriteItemMutation,
    AddFavoriteItemMutationVariables
  >(AddFavoriteItemDocument, options);
}
export type AddFavoriteItemMutationHookResult = ReturnType<
  typeof useAddFavoriteItemMutation
>;
export type AddFavoriteItemMutationResult =
  Apollo.MutationResult<AddFavoriteItemMutation>;
export type AddFavoriteItemMutationOptions = Apollo.BaseMutationOptions<
  AddFavoriteItemMutation,
  AddFavoriteItemMutationVariables
>;
export const DeleteFavoriteItemsDocument = gql`
  mutation DeleteFavoriteItems($input: DeleteLibuFavoriteItemsInput!) {
    deleteFavoriteItems(input: $input) {
      id
    }
  }
`;
export type DeleteFavoriteItemsMutationFn = Apollo.MutationFunction<
  DeleteFavoriteItemsMutation,
  DeleteFavoriteItemsMutationVariables
>;

/**
 * __useDeleteFavoriteItemsMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteItemsMutation, { data, loading, error }] = useDeleteFavoriteItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteFavoriteItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteFavoriteItemsMutation,
    DeleteFavoriteItemsMutationVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useMutation<
    DeleteFavoriteItemsMutation,
    DeleteFavoriteItemsMutationVariables
  >(DeleteFavoriteItemsDocument, options);
}
export type DeleteFavoriteItemsMutationHookResult = ReturnType<
  typeof useDeleteFavoriteItemsMutation
>;
export type DeleteFavoriteItemsMutationResult =
  Apollo.MutationResult<DeleteFavoriteItemsMutation>;
export type DeleteFavoriteItemsMutationOptions = Apollo.BaseMutationOptions<
  DeleteFavoriteItemsMutation,
  DeleteFavoriteItemsMutationVariables
>;
export const GetUserInfoDocument = gql`
  query getUserInfo {
    userInfo {
      id
      points
      hasVideoSubscription
      ppdVideo {
        expireLimitDateTime
        hasRight
      }
      linkedAccountIssuer
      multiAccountId
      userPlatformId
      userPlatformCode
      superUser
    }
    webfront_upsell {
      canDisplay
    }
  }
`;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >
) {
  const { isBot } = useContext(UserAgentContext);
  const options = { ...defaultOptions, ssr: isBot, ...baseOptions };
  return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(
    GetUserInfoDocument,
    options
  );
}
export function useGetUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(
    GetUserInfoDocument,
    options
  );
}
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<
  typeof useGetUserInfoLazyQuery
>;
export type GetUserInfoQueryResult = Apollo.QueryResult<
  GetUserInfoQuery,
  GetUserInfoQueryVariables
>;
