/**
 * Helps group objects by a given key.
 * Kind of like lodash's groupBy but return an array of objects instead of an object
 *
 * @param array Array you want to group
 * @param getKey key of properties you want to group by
 *
 * @example
 * const array = [{ id: 1, type: 'A' }, { id: 2, type: 'B' }, { id: 3, type: 'A' }];
 * const result = groupBy(array, item => item.type);
 *
 * result === [['A' , [{ id: 1, type: 'A' }, { id: 3, type: 'A' }]], ['B', [{ id: 2, type: 'B' }]] ]
 *
 * // Then you can use the result like this:
 *
 * const grouped = result.map(([type, items]) => ({ description: type, itemList: items }));
 */

export const groupBy = <K, V>(
  array: readonly V[],
  getKey: (cur: V, idx: number, src: readonly V[]) => K
): [K, V[]][] =>
  Array.from(
    array.reduce((map, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      const list = map.get(key);
      if (list) list.push(cur);
      else map.set(key, [cur]);
      return map;
    }, new Map<K, V[]>())
  );
