/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';

type OverlayVariants = 'LIGHT' | 'DARK_PURPLE' | 'LIGHT_BLUR' | 'DEEP_BLUR';

const VARIANTS_MAP: Record<OverlayVariants, string> = {
  LIGHT: 'bg-white/30',
  DARK_PURPLE: 'bg-purple/60',
  LIGHT_BLUR: 'backdrop-filter backdrop-blur-xs bg-purple/60',
  DEEP_BLUR: 'backdrop-filter backdrop-blur-xl bg-purple/60 ',
};

export interface OverlayProps {
  variant?: OverlayVariants;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Overlay: React.FC<OverlayProps> = ({
  variant = 'LIGHT_BLUR',
  onClick,
}) => {
  return (
    <div
      className={classNames('z-75 fixed inset-0 w-full', VARIANTS_MAP[variant])}
      onClick={onClick}
    />
  );
};

export default Overlay;
