import { CapySaleTypeCode, PointBackProduct } from '@/shared/generated';
import { ComponentPropsWithoutRef, FC } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import Dialog from '..';
import { globalMessages } from '../../../../constants/messages';
import Button from '../../../Button/Button';
import SelectCell, { SelectCellPriceProps } from '../../../Control/SelectCell';
import Icon from '../../../Icon';
import SimpleDialogTitle from '../SimpleDialog/Title';
import PointBack from './PointBack';

const messages = {
  title: defineMessage({
    id: 'purchase.title',
    defaultMessage: 'レンタル / 購入',
  }),
  rentalForPrice: defineMessage({
    id: 'purchase.rentalForPrice',
    defaultMessage: '{price}円でレンタル',
  }),
  purchaseForPrice: defineMessage({
    id: 'purchase.purchaseForPrice',
    defaultMessage: '{price}円で購入',
  }),
  warning4kTitle: defineMessage({
    id: 'purchase.warning4kTitle',
    defaultMessage: '4K・VR商品をご購入の前に',
  }),
  warning4kDescription: defineMessage({
    id: 'purchase.warning4kDescription',
    defaultMessage:
      'ご利用の端末によっては視聴できない場合があります。テスト動画が問題なく再生できるか事前にご確認ください。',
  }),
};

type PurchaseOption = SelectCellPriceProps & {
  id: string;
  saleTypeCode: CapySaleTypeCode;
};

export type DialogPurchaseProps = {
  description: string;
  isShowing: boolean;
  isPurchaseLoading?: boolean;
  onPurchaseClick?: (id: string) => void;
  onClickOutside?: () => void;
  onCancel?: () => void;
  currentPoints: number;
  options?: PurchaseOption[];
  pointBack?: {
    productList?: PointBackProduct[];
  } & Pick<
    ComponentPropsWithoutRef<typeof PointBack>,
    'percentage' | 'scheduleDate'
  >;
  errorMessage?: string;
  showTestPlayWarning: boolean;
};

const DialogPurchase: FC<DialogPurchaseProps> = ({
  description,
  isShowing,
  isPurchaseLoading,
  onPurchaseClick,
  onClickOutside,
  onCancel,
  currentPoints,
  options,
  pointBack,
  errorMessage,
  showTestPlayWarning,
}) => {
  const intl = useIntl();
  const selectedOptionProps = options?.find((option) => option.checked);
  const pointsToUse = Math.min(currentPoints, selectedOptionProps?.price || 0);

  const isPointbackTarget = pointBack?.productList?.includes(
    selectedOptionProps?.saleTypeCode as unknown as PointBackProduct
  );

  const pointBackTargetTotalPrice = isPointbackTarget
    ? selectedOptionProps?.price
    : undefined;

  const isEst = selectedOptionProps?.saleTypeCode === CapySaleTypeCode.Est;

  return (
    <Dialog
      variant="PURCHASE"
      overlayVariant="DARK_PURPLE"
      isShowing={isShowing}
      onClickOutside={onClickOutside}
    >
      <div className="px-4 pt-6 pb-4 lg:px-0 lg:pb-16">
        <div className="flex items-center justify-center px-4 lg:hidden text-strong text-purple">
          {intl.formatMessage(messages.title)}
        </div>
        <div className="hidden px-6 pb-6 border-b lg:block border-purple/10">
          <SimpleDialogTitle
            title={intl.formatMessage(messages.title)}
            onCancel={onCancel}
          />
        </div>
        <div className="mt-4 lg:px-10 xl:px-20">
          <div className="hidden mt-10 lg:block text-strong text-purple/80">
            {description}
          </div>
          {errorMessage && (
            <div className="flex p-4 border rounded border-error text-error text-caption md:p-3 md:mt-4">
              <div className="w-4.5 h-4.5 mr-1">
                <Icon name="CAUTION_ICON" size="FULL" />
              </div>
              {errorMessage}
            </div>
          )}
          <div className="flex flex-col mt-4 lg:flex-row lg:mt-10">
            <div className="flex flex-col items-stretch space-y-2 lg:flex-1">
              {options?.map(
                ({
                  id,
                  text,
                  subText,
                  price,
                  originalPrice,
                  onSale,
                  iconName,
                  checked,
                  disabled,
                  name,
                  readOnly,
                  onChange,
                }) => (
                  <SelectCell
                    key={id}
                    id={id}
                    text={text}
                    subText={subText}
                    checked={checked}
                    price={price}
                    originalPrice={originalPrice}
                    onSale={onSale}
                    iconName={iconName}
                    disabled={disabled}
                    name={name}
                    readOnly={readOnly}
                    onChange={onChange}
                  />
                )
              )}
            </div>
            <div className="mt-4 lg:mt-0 lg:ml-8 lg:w-80">
              <div className="flex flex-col items-center p-4 pt-6 rounded-lg lg:items-start bg-purple/10 lg:p-8">
                <div className="text-purple/80 lg:w-full">
                  <div className="flex items-center h-5 lg:h-6">
                    <span className="flex-1 text-caption">
                      {intl.formatMessage(globalMessages.pointsAtHand)}
                    </span>
                    <span className="font-mono font-semibold text-xl-number lg:text-2xl lg:justify-self-end">
                      {intl.formatNumber(currentPoints)}
                    </span>
                  </div>
                  <div className="flex items-center h-5 mt-2 lg:h-6 lg:mt-4">
                    <span className="flex-1 text-caption">
                      {intl.formatMessage(globalMessages.pointsToUse)}
                    </span>
                    <span className="ml-2 font-mono font-semibold text-xl-number lg:text-2xl lg:justify-self-end">
                      -{intl.formatNumber(pointsToUse)}
                    </span>
                  </div>
                </div>
                <div className="relative w-full mt-4 lg:mt-6">
                  <Button
                    onClick={() =>
                      onPurchaseClick &&
                      selectedOptionProps &&
                      !isPurchaseLoading &&
                      onPurchaseClick(selectedOptionProps?.id)
                    }
                    text={intl.formatMessage(
                      isEst
                        ? messages.purchaseForPrice
                        : messages.rentalForPrice,
                      {
                        price: selectedOptionProps
                          ? intl.formatNumber(
                              selectedOptionProps.price - pointsToUse
                            )
                          : 0,
                      }
                    )}
                    leftIconName="PURCHASE_ICON"
                  />
                  {isPurchaseLoading && (
                    <div className="absolute w-4 h-4 border-2 border-transparent rounded-full top-3.5 right-3 border-t-white border-l-white animate-spin-fast" />
                  )}
                </div>
                <PointBack
                  pointBackTargetTotalPrice={pointBackTargetTotalPrice}
                  currentPoints={currentPoints}
                  percentage={pointBack?.percentage}
                  scheduleDate={pointBack?.scheduleDate}
                />
              </div>
              {showTestPlayWarning && (
                <div className="p-4 mt-1 border rounded-lg lg:mt-2 border-purple/30 text-purple/60">
                  <div className="text-caption">
                    {intl.formatMessage(messages.warning4kTitle)}
                  </div>
                  <div className="mt-1 text-label">
                    {intl.formatMessage(messages.warning4kDescription)}
                  </div>
                  <a
                    href="/testplay"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="mt-2 text-xs lg:text-sm font-semibold text-purple space-x-0.5 flex items-center"
                  >
                    {intl.formatMessage(globalMessages.playTestVideo)}
                    <Icon name="NEW_TAB_ICON" />
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-1.5 lg:hidden">
          <Button
            variant="TEXT"
            text={intl.formatMessage(globalMessages.cancel)}
            onClick={onCancel}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default DialogPurchase;
