import { ChapterCardProps } from '@/shared/components/Card/ChapterCard';
import { ChapterCardFragment } from '@/shared/generated';

const NEW_ICON_SYMBOL = 'NEW';

export function mapMediaChapterLightToChapterCard(
  mediaItem: ChapterCardFragment
): ChapterCardProps {
  return {
    id: mediaItem.metadata.id,
    description: mediaItem.metadata.nameJa,
    imgSrc: mediaItem.metadata.thumbnails[0]?.w1 || '',
    imgAlt: '',
    duration: mediaItem.metadata.durationSeconds,
    isNew:
      mediaItem.mediaProductInfo?.platformLifetimeClass === NEW_ICON_SYMBOL,
    likes: mediaItem.metadata.favoriteCount,
    tags: mediaItem.metadata.contentTags.map((tag) => tag.nameJa),
    cardSize: 'BLOCK_LARGE',
    featureBadges: mediaItem.metadata.featureBadges,
  };
}
