import tailwindConfig from '@/tailwindConfig';
import resolveConfig from 'tailwindcss/resolveConfig';

/**
 * Note: This type corresponds to tailwind.config.js + default tailwind config
 *
 * Please add/remove breakpoints as needed
 */
type Breakpoints = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

/**
 * Why?
 * https://github.com/tailwindlabs/tailwindcss/issues/8920
 *
 * tl;dr the screen types are broken and it doesn't seems like the maintainers of
 * Tailwind are planning to fix it any time soon
 *
 * TODO/FUTURE: Remove this helper when types are fixed or
 * another way of accessing screens are released
 */
export function getTailwindConfigScreens(): Record<Breakpoints, string> {
  const fullConfig = resolveConfig(tailwindConfig);

  // https://github.com/tailwindlabs/tailwindcss/issues/8920
  return (
    (fullConfig.theme?.screens as unknown as Record<Breakpoints, string>) || {}
  );
}

/**
 * NOTE: This type corresponds to tailwind.config.js + default tailwind config
 *
 * Please add/remove colors as needed
 */
type Colors = 'pink' | 'purple' | 'error';

/**
 * Why?
 * https://github.com/tailwindlabs/tailwindcss/issues/8920
 *
 * tl;dr the types are in general broken and it doesn't seems like the maintainers of
 * Tailwind are planning to fix it any time soon
 *
 * TODO/FUTURE: Remove this helper when types are fixed or
 * another way of accessing colors are released
 */
export function getTailwindConfigColors(): Record<Colors, string> {
  const fullConfig = resolveConfig(tailwindConfig);

  return (fullConfig.theme?.colors as unknown as Record<Colors, string>) || {};
}
