import ChapterPlayer from '@/domain/chapter';
import { KafkaProvider } from '@/domain/log/KafkaContext';
import { TreasureDataClient } from '@/domain/log/TreasureDataClient';
import { TreasureDataContextProvider } from '@/domain/log/TreasureDataContext';
import useTdTransitionLogger from '@/domain/log/useTdTransitionLogger';
import PackageIndex from '@/domain/package';
import SearchDialog from '@/domain/search/components/SearchDialog';
import SearchTopBase from '@/domain/search/SearchTopBase';
import withApollo, { WithApolloProps } from '@/lib/withApollo';
import ErrorBoundary from '@/shared/components/ErrorBoundary';
import Layout from '@/shared/components/Layout';
import GlobalMetaTags from '@/shared/components/MetaTags/global';
import R18Confirmation from '@/shared/components/Modality/Dialog/R18Confirmation';
import URLS from '@/shared/constants/urls';
import { useLoginState } from '@/shared/hooks/useLoginState';
import { IsInitialRouteProvider } from '@/shared/IsInitialRouteContext';
import { PlayerControlContextProvider } from '@/shared/PlayerControlContext';
import SearchDialogContext from '@/shared/SearchDialogContext';
import { SnackBarContextProvider } from '@/shared/SnackBarContext';
import UserAgentContext from '@/shared/UserAgentContext';
import appLocalStorage from '@/shared/utils/appLocalStorage';
import { isBot } from '@/shared/utils/isBot';
import classNames from 'classnames';
import type { AppProps } from 'next/app';
import App, { AppContext, AppInitialProps } from 'next/app';
import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import '../styles/globals.css';

if (typeof window === 'undefined') {
  global.Intl = require('intl');
}

const messages: {
  [key: string]: Record<string, string>;
} = {
  ja: require('../../i18n/locales/ja.json'),
};

const loginNeededPaths = ['/mypage', '/pointback'];

interface CoreAppProps {
  isSsr: boolean;
  url: string;
  userAgent: string;
  isWebpSupported: boolean;
}

const CoreApp = ({
  Component,
  pageProps,
  userAgent,
  isWebpSupported,
  isSsr,
  // Workaround for https://github.com/vercel/next.js/issues/8592
  // See https://github.com/vercel/next.js/issues/8592#issuecomment-648942301
  // @ts-expect-error See above comment
  err,
}: AppProps & CoreAppProps & WithApolloProps) => {
  const router = useRouter();

  const [showSearchDialog, setShowSearchDialog] = useState(false);
  const [showR18Dialog, setShowR18Dialog] = useState(false);

  const [treasureDataClient, setTreasureDataClient] = useState<
    TreasureDataClient | undefined
  >();
  useTdTransitionLogger(treasureDataClient);

  const { data: userInfoData, loading: userInfoLoading } = useLoginState();

  useEffect(() => {
    const storageR18 = appLocalStorage.getBoolean('R18');
    if (!storageR18) {
      setShowR18Dialog(true);
    }
  }, [setShowR18Dialog]);

  const updateSearchDialog = (value: boolean) => {
    setShowSearchDialog(value);
  };

  const hideMobileHeader = router.asPath.includes('mypage');

  const packageId = router.query.pid || router.query.packageId;
  const chapterId = router.query.cid || router.query.chapterId;

  const isShowPackageChapters =
    router.pathname === '/package/[packageId]/chapters';

  return (
    <IntlProvider locale="ja-JP" messages={messages['ja']}>
      <UserAgentContext.Provider
        value={{
          userAgent,
          isWebpSupported,
          isBot: !!userAgent && isBot(userAgent),
        }}
      >
        <PlayerControlContextProvider>
          <TreasureDataContextProvider
            treasureDataClient={treasureDataClient}
            setTreasureDataClient={setTreasureDataClient}
          >
            <KafkaProvider>
              <IsInitialRouteProvider>
                <ErrorBoundary>
                  <GlobalMetaTags />
                  <SearchDialogContext.Provider
                    value={{ showSearchDialog, updateSearchDialog }}
                  >
                    <SnackBarContextProvider>
                      <Layout
                        userInfoData={userInfoData}
                        userInfoLoading={userInfoLoading}
                        hideMobileHeader={hideMobileHeader}
                      >
                        <div
                          className={classNames(
                            'flex-grow flex flex-col',
                            showSearchDialog && 'md:hidden'
                          )}
                        >
                          <Component
                            {...pageProps}
                            err={err}
                            userInfoData={userInfoData}
                          />
                        </div>
                        {packageId &&
                          typeof packageId === 'string' &&
                          !isShowPackageChapters && (
                            <PackageIndex
                              {...pageProps}
                              isSsr={isSsr}
                              packageId={packageId}
                            />
                          )}
                        {chapterId && typeof chapterId === 'string' && (
                          <ChapterPlayer
                            {...pageProps}
                            isSsr={isSsr}
                            chapterId={chapterId}
                          />
                        )}
                        <SearchDialog isShowing={showSearchDialog}>
                          <SearchTopBase />
                        </SearchDialog>
                        <R18Confirmation
                          isShowing={showR18Dialog}
                          onClickYes={() => {
                            appLocalStorage.set('R18', true);
                            setShowR18Dialog(false);
                          }}
                          noButtonHref={URLS.unext}
                        />
                      </Layout>
                    </SnackBarContextProvider>
                  </SearchDialogContext.Provider>
                </ErrorBoundary>
              </IsInitialRouteProvider>
            </KafkaProvider>
          </TreasureDataContextProvider>
        </PlayerControlContextProvider>
      </UserAgentContext.Provider>
    </IntlProvider>
  );
};

CoreApp.getInitialProps = async (appContext: AppContext) => {
  const appInitialProps = await App.getInitialProps(appContext);

  const { ctx, router } = appContext;

  const cookies = ctx?.req ? parseCookies({ req: ctx.req }) : undefined;
  const hasUserCookie = Boolean(cookies?._at || cookies?._st);

  if (!hasUserCookie) {
    if (
      ctx.res &&
      loginNeededPaths.some((path) => router.pathname.match(path))
    ) {
      ctx.res.writeHead(302, {
        Location: '/store',
      });
      ctx.res.end();
    }

    if (ctx.res && router.query.pid) {
      if (router.query.play === 'true') {
        ctx.res.writeHead(301, {
          Location: `/package/${router.query.pid}/play`,
        });
      } else {
        ctx.res.writeHead(301, {
          Location: `/package/${router.query.pid}`,
        });
      }
      ctx.res.end();
    }

    if (ctx.res && router.query.cid) {
      ctx.res.writeHead(301, {
        Location: `/chapter/${router.query.cid}`,
      });
      ctx.res.end();
    }
  }

  const appProps: AppInitialProps & CoreAppProps = {
    ...appInitialProps,
    isSsr: !!ctx.res,
    url: ctx?.req?.url ?? '',
    userAgent:
      ctx.req?.headers['user-agent'] ??
      (typeof window === 'object' ? navigator.userAgent : ''),
    isWebpSupported: ctx.req?.headers.accept?.includes('image/webp') || false,
  };
  return appProps;
};

export default withApollo(CoreApp);
