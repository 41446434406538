// https://docs.sentry.io/platforms/javascript/guides/nextjs/manual-setup/
import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  enabled: !!SENTRY_DSN,
  dsn: SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_APP_ENV || 'local',
  sampleRate: process.env.NEXT_PUBLIC_APP_ENV === 'itstg' ? 1.0 : 0.01,
  tracesSampleRate: 0.0005,
});
