import useChapterSequence, {
  CHAPTER_SEQUENCE_ID_NAME,
} from '@/domain/chapter/useChapterSequence';
import VRGuideArea from '@/domain/chapter/VRGuideArea';
import InfiniteScrollContainer from '@/domain/library/mylist/InfiniteScrollContainer';
import { KafkaContext } from '@/domain/log/KafkaContext';
import { TreasureDataContext } from '@/domain/log/TreasureDataContext';
import { PlayerLog } from '@/domain/log/__types__/player';
import { PlayerControlSkipLog } from '@/domain/log/__types__/player-control-skip';
import useIntentUrl from '@/domain/package/useIntentUrl';
import { TAG_SEPARATOR } from '@/domain/search/hooks/useSearchParams';
import { mapMediaChapterLightToChapterCard } from '@/domain/svod/util';
import Badge from '@/shared/components/Badge';
import BeemiImage from '@/shared/components/BeemiImage';
import Button from '@/shared/components/Button/Button';
import CardGrid from '@/shared/components/Card/CardGrid';
import PlayerChapterCard from '@/shared/components/Card/PlayerChapterCard';
import Icon from '@/shared/components/Icon';
import MetaTags from '@/shared/components/MetaTags';
import DetailModal from '@/shared/components/Modality/DetailModal';
import PlayErrorDialog from '@/shared/components/Modality/Dialog/PlayErrorDialog';
import PlayerMetaLoading from '@/shared/components/Player/PlayerMetaLoading';
import { PlayerKafkaLogger } from '@/shared/components/Player/PlayerUI';
import usePlayerTdLogger from '@/shared/components/Player/usePlayerTdLogger';
import PlayerLoginButtons from '@/shared/components/PlayerLoginButtons';
import SubscribeAction from '@/shared/components/SubscribeAction';
import TagList, { ContentTag, isContentTag } from '@/shared/components/TagList';
import UserButtonsGroup from '@/shared/components/UserButtonsGroup';
import VRAlert from '@/shared/components/VRAlert';
import { INFINITE_FETCH_LIMIT } from '@/shared/constants/limit';
import {
  contentTypeMap,
  errorMessages,
  globalMessages,
} from '@/shared/constants/messages';
import { chapterDetailMetaMessage } from '@/shared/constants/meta/svod/chapterDetail';
import {
  ContentType,
  GetMediaChapterInfoQuery,
  useAddViewHistoryMutation,
  useGetMediaChapterInfoQuery,
  useGetMinimumMediaChapterInfoQuery,
  VideoConsumableType,
} from '@/shared/generated';
import { useLoginState } from '@/shared/hooks/useLoginState';
import usePlayInfo from '@/shared/hooks/usePlayInfo';
import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import IsInitialRouteContext from '@/shared/IsInitialRouteContext';
import { usePlayerControl } from '@/shared/PlayerControlContext';
import { useSnackBar } from '@/shared/SnackBarContext';
import { extractFirstGraphQLError } from '@/shared/utils/extractFirstGraphQLError';
import getLoginUrl from '@/shared/utils/getLoginUrl';
import mergeFetchMore from '@/shared/utils/mergeFetchMore';
import { getTimeString } from '@/shared/utils/timeHelper';
import { ApolloError, NetworkStatus } from '@apollo/client';
import { IsemSessionArgs } from '@u-next/videoplayer';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  ComponentPropsWithoutRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { defineMessage, useIntl } from 'react-intl';
import SignupPrime from '../../shared/components/Modality/Dialog/SignupPrime';
import { useSubscribeActionChapterProps } from './useSubscribeActionChapterProps';

const Player = dynamic(() => import('../../shared/components/Player/Player'), {
  ssr: false,
});

type OnPlayerError = NonNullable<
  ComponentPropsWithoutRef<typeof Player>['onError']
>;

const messages = {
  chapter: defineMessage({
    id: 'player.chapter',
    defaultMessage: 'チャプター{chapter}',
  }),
  related: defineMessage({
    id: 'player.related',
    defaultMessage: '同じ作品のチャプター',
  }),
  recommendation: defineMessage({
    id: 'player.recommendation',
    defaultMessage: '次に再生',
  }),
  continuousPlayback: defineMessage({
    id: 'player.continuousPlayback',
    defaultMessage: '連続再生',
  }),
  trial: defineMessage({
    id: 'player.trial',
    defaultMessage: 'まずは、31日間無料体験',
  }),
  watchTrial: defineMessage({
    id: 'player.watchTrial',
    defaultMessage: '無料トライアルで今すぐ観る',
  }),
  pointsFirst: defineMessage({
    id: 'player.pointsFirst',
    defaultMessage: '無料トライアルで',
  }),
  pointsSecond: defineMessage({
    id: 'player.pointsSecond',
    defaultMessage: '600円分のポイントをGET',
  }),
  watchFree: defineMessage({
    id: 'player.watchFree',
    defaultMessage: '今すぐ無料で観る',
  }),
  nextChapter: defineMessage({
    id: 'player.nextChapter',
    defaultMessage: '次のチャプター',
  }),
  chapterCount: defineMessage({
    id: 'player.chapterCount',
    defaultMessage: '{chapterCount}件',
  }),
  packageDetailLink: defineMessage({
    id: 'player.packageDetailLink',
    defaultMessage: '作品の詳細',
  }),
};

const generateHref = (id: string, contentType: string) => ({
  pathname: '/search/svod',
  query: 'tag=' + id + TAG_SEPARATOR + contentType,
});

const CHAPTER_RECOMMENDATION_LIMIT = 2;

type Props = {
  chapterId: string;
  isLoggedIn?: boolean;
  data?: GetMediaChapterInfoQuery;
  isSsr: boolean;
};

const ChapterPlayer = ({ chapterId, isSsr }: Props) => {
  const intl = useIntl();
  const router = useRouter();
  const { isInitialRoute } = useContext(IsInitialRouteContext);

  const {
    nextChapterSequenceIndex,
    nextChapterId,
    hasForward,
    hasBackward,
    updateChapterSequence,
  } = useChapterSequence({ firstChapterId: chapterId });
  const { playerSeek } = usePlayerControl();

  const [isSignupPrimeDialogShowing, setIsSignupPrimeDialogShowing] =
    useState<boolean>(false);
  const showSignUpDialog = () => {
    setIsSignupPrimeDialogShowing(true);
  };
  const hideSignUpDialog = () => {
    setIsSignupPrimeDialogShowing(false);
  };

  const [showAllChapters, setShowAllChapters] = useState<boolean>(false);
  const [playerErrorCode, setPlayerErrorCode] = useState('');

  const {
    loading: loginStateLoading,
    isLoggedIn,
    isReady,
    canUpsell,
  } = useLoginState();

  const redirectOnClose = useCallback(() => {
    if (router.pathname === '/chapter/[chapterId]') {
      router.push('/store');
    } else {
      const newQuery = { ...router.query };
      delete newQuery['cid'];
      delete newQuery['chapterId'];
      delete newQuery['play'];
      delete newQuery[CHAPTER_SEQUENCE_ID_NAME];

      router.push(
        {
          pathname: router.pathname,
          query: newQuery,
        },
        undefined,
        { scroll: false }
      );
    }
  }, [router]);

  const { showSnackBar } = useSnackBar();
  const onError = useCallback(
    (error: ApolloError) => {
      const notFoundError = extractFirstGraphQLError(error, ['NOT_FOUND']);
      if (notFoundError) {
        showSnackBar({
          message: intl.formatMessage(errorMessages.pageNotFound),
        });

        redirectOnClose();

        return;
      }
      throw new ApolloError(error);
    },
    [intl, redirectOnClose, showSnackBar]
  );

  const {
    data,
    loading: getMediaChapterLoading,
    fetchMore,
    networkStatus,
  } = useGetMediaChapterInfoQuery({
    variables: {
      mediaChapterId: chapterId,
      recommendationPagination: { limit: CHAPTER_RECOMMENDATION_LIMIT },
      allChaptersPagination: { limit: INFINITE_FETCH_LIMIT, offset: 0 },
      isLoggedIn,
    },
    skip: !isReady,
    notifyOnNetworkStatusChange: true,
    onError,
  });
  const getMediaChapterInitialLoading =
    getMediaChapterLoading && networkStatus === NetworkStatus.loading;

  const chaptersHasNextPage =
    !!data?.mediaChapter?.metadata?.paginatedAllChapters?.pageInfo?.hasNext;

  const firstRecommendChapterId =
    data?.mediaChapter?.paginatedRecommendations.records[0]?.id;

  const { data: nextChapterData } = useGetMinimumMediaChapterInfoQuery({
    variables: {
      mediaChapterId: nextChapterId ?? firstRecommendChapterId ?? '',
    },
    skip: !(nextChapterId || firstRecommendChapterId) || getMediaChapterLoading,
  });

  const nextPlayableProps = useMemo(() => {
    return {
      title: nextChapterData?.mediaChapter?.metadata.nameJa ?? '',
      image: nextChapterData?.mediaChapter?.metadata.thumbnails[0].w1 ?? '',
      subTitle: intl.formatMessage(messages.nextChapter),
    };
  }, [intl, nextChapterData?.mediaChapter]);

  const mainConsumable = data?.mediaChapter?.metadata.videoConsumables.find(
    (consumable) => consumable.type === VideoConsumableType.Main
  );

  const handleGetPlayBackTokensError = useCallback((error: ApolloError) => {
    const extractedError = extractFirstGraphQLError(error);
    if (extractedError?.code) setPlayerErrorCode(extractedError.code);
  }, []);

  const handleLimeError = useCallback(
    (e: Error) => setPlayerErrorCode(e.message),
    []
  );

  const { limeToken, isemToken, playables, saleTypeCode, productCode } =
    usePlayInfo({
      consumableId: mainConsumable?.id || '',
      contentId: chapterId,
      skip: !isLoggedIn || canUpsell || !mainConsumable?.id,
      isSample: false,
      onGetPlayBackTokensError: handleGetPlayBackTokensError,
      onLimeError: handleLimeError,
    });

  const loading = loginStateLoading || getMediaChapterInitialLoading;

  const metaData = data?.mediaChapter?.metadata;
  const parentPackageId = metaData?.parent.id || '';
  const poster = metaData?.thumbnails[0]?.w2;
  const isNewPackage =
    data?.mediaChapter?.productInfo?.platformLifetimeClass === 'NEW';

  const { client } = useContext(KafkaContext);
  useEffect(() => {
    if (parentPackageId && chapterId) {
      client?.trackUser<PlayerLog>('player', 'view', 'user_view_page_default', {
        package_id: parentPackageId,
        chapter_id: chapterId,
      });
    }
  }, [chapterId, client, parentPackageId]);

  const handleClickChapterList = useCallback(
    (targetChapterId: string) => {
      if (targetChapterId === chapterId) {
        playerSeek(0);
      } else {
        updateChapterSequence(targetChapterId);

        const newQuery = { ...router.query };
        if (newQuery.cid) {
          newQuery.cid = targetChapterId;
        }
        if (newQuery.chapterId) {
          newQuery.chapterId = targetChapterId;
        }
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...newQuery,
              [CHAPTER_SEQUENCE_ID_NAME]: nextChapterSequenceIndex,
            },
          },
          undefined,
          { scroll: false }
        );
      }
    },
    [
      chapterId,
      nextChapterSequenceIndex,
      playerSeek,
      router,
      updateChapterSequence,
    ]
  );

  const handleClickPrevChapter = useCallback(() => {
    if (hasBackward) {
      history.back();
    }
  }, [hasBackward]);

  const handleClickNextChapter = useCallback(() => {
    if (hasForward) {
      history.go(1);
    } else {
      if (firstRecommendChapterId) {
        updateChapterSequence(firstRecommendChapterId);

        const newQuery = { ...router.query };
        if (newQuery.cid) {
          newQuery.cid = firstRecommendChapterId;
        }
        if (newQuery.chapterId) {
          newQuery.chapterId = firstRecommendChapterId;
        }
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...newQuery,
              [CHAPTER_SEQUENCE_ID_NAME]: nextChapterSequenceIndex,
            },
          },
          undefined,
          { scroll: false }
        );
      }
    }
  }, [
    firstRecommendChapterId,
    hasForward,
    nextChapterSequenceIndex,
    router,
    updateChapterSequence,
  ]);

  const sendSkipLog = useCallback(
    (toggleStatus: boolean) => {
      client?.trackUser<PlayerControlSkipLog>(
        'player-control-skip',
        'click',
        'user_click_dimension_0_default',
        {
          package_id: parentPackageId,
          chapter_id: chapterId,
          toggle_status: toggleStatus, //True for skipping forward. False for skipping backward.
        }
      );
    },
    [chapterId, client, parentPackageId]
  );

  const playerKafkaLogger: PlayerKafkaLogger = {
    skipForward: () => sendSkipLog(true),
    skipBackward: () => sendSkipLog(false),
  };

  const contentTags: ContentTag<ContentType.ContentTag>[] =
    metaData?.contentTags.filter(isContentTag).map((tag, index) => ({
      ...tag,
      kafkaProps: {
        targetPrefix: 'chapterPlayer-contentTags',
        base_schema: 'user_click_dimension_1_default',
        content_type: ContentType.ContentTag,
        tag_id: tag.id,
        chapter_id: chapterId,
        package_id: parentPackageId,
        index,
      },
    })) || [];

  const performanceTags = metaData?.performances.map(({ alias }) => ({
    ...alias,
    contentType: ContentType.Alias,
  }));

  const creditTags = metaData?.credits.map(({ alias }) => ({
    ...alias,
    contentType: ContentType.Alias,
  }));

  const castTags = [...(performanceTags ?? []), ...(creditTags ?? [])];

  const brandGroupTags = metaData?.brandGroups.map((tag) => ({
    ...tag,
    contentType: ContentType.BrandGroup,
  }));

  const isMd = useTailwindBreakpoint('md');

  const handlePlayerError = useCallback<OnPlayerError>((error) => {
    setPlayerErrorCode(String(error.customCode));
  }, []);

  const onRegisterClick = () => {
    location.href =
      process.env.NEXT_PUBLIC_REGISTER_WITH_MONTHLY_PLAN_URL ?? '';
  };
  const onLoginClick = () => {
    location.href = getLoginUrl(`${router.asPath}`);
  };

  const subscribeActionProps = useSubscribeActionChapterProps({
    isLoggedIn,
    canUpsell,
  });

  const [overwrite, setOverwrite] = useState(false);

  // reset overwrite when chapterId changes
  useEffect(() => {
    setOverwrite(false);
  }, [chapterId]);

  const { tdClientId } = useContext(TreasureDataContext);

  const sessionArgs: IsemSessionArgs = useMemo(
    () => ({
      type: 'isem',
      isemToken: isemToken,
      baseUrl: process.env.NEXT_PUBLIC_ISEM_SERVER || '',
      deviceId: tdClientId,
      overwrite,
    }),
    [isemToken, overwrite, tdClientId]
  );

  const playRange = useMemo(
    () =>
      metaData
        ? {
            start: metaData.startTimeSeconds,
            end: metaData.endTimeSeconds,
          }
        : null,
    [metaData]
  );

  const fetchMoreChapters = (offset: number, limit: number) =>
    fetchMore({
      variables: {
        allChaptersPagination: {
          offset,
          limit,
        },
      },
      updateQuery: mergeFetchMore,
    });

  const playerTdLogger = usePlayerTdLogger();

  useEffect(() => {
    if (mainConsumable && data?.mediaChapter) {
      playerTdLogger.setMediaLogInfo({
        media_id: data?.mediaChapter.metadata.parent.id,
        media_name: data?.mediaChapter.metadata.parent.metadata.nameJa,
        media_chapter_id: data.mediaChapter.id,
        media_chapter_start_position:
          data.mediaChapter.metadata.startTimeSeconds,
        media_chapter_end_position: data.mediaChapter.metadata.endTimeSeconds,
        media_chapter_number: data.mediaChapter.metadata.chapterNo,
        consumable_id: mainConsumable.id,
        consumable_type: mainConsumable.type,
        play_time:
          data.mediaChapter.metadata.parent.metadata.mainDurationSeconds ?? 0,
        product_public_code: productCode,
        sale_type_code: saleTypeCode,
      });
    }
  }, [
    data?.mediaChapter,
    mainConsumable,
    playerTdLogger,
    productCode,
    saleTypeCode,
  ]);

  const hasVR = !!mainConsumable?.videoProfiles.some(
    (videoProfile) => videoProfile.geometry
  );

  const currentChapterName = intl.formatMessage(messages.chapter, {
    chapter: metaData?.chapterNo,
  });

  const currentChapterDuration = `${getTimeString(
    Number(metaData?.startTimeSeconds)
  )} ~ ${getTimeString(Number(metaData?.endTimeSeconds))}`;

  const title = metaData?.nameJa || '';
  const subtitle = `${currentChapterName} ${currentChapterDuration}`;

  const [playerSessionId, setPlayerSessionId] = useState('');

  const intentUrl = useIntentUrl({
    type: 'app_beemichapterplayer',
    media_chapter_id: chapterId,
    player_session_id: playerSessionId,
  });

  const packageDetailLink = useMemo(() => {
    const newQuery = { ...router.query };

    if (router.query.chapterId) {
      delete newQuery['chapterId'];
      return {
        pathname: `/package/${parentPackageId}`,
        query: {
          ...newQuery,
        },
      };
    }

    delete newQuery['cid'];
    return {
      pathname: router.pathname,
      query: {
        ...newQuery,
        pid: parentPackageId,
      },
    };
  }, [parentPackageId, router.pathname, router.query]);

  const enterXRSessionRef = useRef<() => void>();

  const viewHistoryReportedChapterId = useRef('');

  const [addViewHistoryMutation] = useAddViewHistoryMutation({
    variables: {
      id: chapterId,
    },
  });

  const onTimeUpdate = useCallback(async () => {
    if (viewHistoryReportedChapterId.current !== chapterId) {
      viewHistoryReportedChapterId.current = chapterId;
      await addViewHistoryMutation();
    }
  }, [chapterId, addViewHistoryMutation]);

  return (
    <>
      <MetaTags
        title={intl.formatMessage(chapterDetailMetaMessage.title, {
          packageName: metaData?.nameJa,
          chapterNo: metaData?.chapterNo,
        })}
        canonicalLink={`/package/${parentPackageId}`}
      />
      <DetailModal onClose={redirectOnClose} isSsr={isSsr} id={chapterId}>
        <article
          className="bg-white md:rounded-2xl pb-[46px] md:pb-[99px]"
          data-testid="ChapterPlayer"
        >
          <div className="w-full mb-7 aspect-w-16 aspect-h-9 lg:mb-12 lg:overflow-hidden lg:rounded-t-2xl bg-purple/20">
            <Player
              playbackAuthorization="bearer"
              sessionArgs={sessionArgs}
              authorizationToken={limeToken}
              resumePointId={mainConsumable?.id}
              title={title}
              subtitle={subtitle}
              poster={poster}
              playables={playables}
              hasPrevChapter={hasBackward}
              handleClickPrevChapter={handleClickPrevChapter}
              handleClickNextChapter={handleClickNextChapter}
              playerKafkaLogger={playerKafkaLogger}
              nextPlayableProps={nextPlayableProps}
              autoplay
              onError={handlePlayerError}
              onTimeUpdate={onTimeUpdate}
              playRange={playRange || undefined}
              chapterId={chapterId}
              playerTdLogger={playerTdLogger}
              hasVR={hasVR}
              playerSessionId={playerSessionId}
              setPlayerSessionId={setPlayerSessionId}
              intentUrl={intentUrl}
              enterXRSessionRef={enterXRSessionRef}
              forceHideLoadingIndicator={canUpsell}
            />

            {!(limeToken && playables) && poster && (
              <BeemiImage
                src={poster}
                alt={metaData?.nameJa}
                className="object-cover w-full h-full"
              />
            )}

            {!loginStateLoading && (!isLoggedIn || canUpsell) && (
              <div className="flex flex-col items-center justify-center w-full h-full gap-2 bg-purple/30">
                {isLoggedIn ? (
                  <Icon
                    name="PLAY_ICON"
                    className="transition text-white/80 w-17 h-17 sm:w-24 sm:h-24 hover:text-white"
                    role="button"
                    onClick={showSignUpDialog}
                    data-testid="chapterPlayer-buttons-play"
                  />
                ) : (
                  <PlayerLoginButtons
                    onRegisterClick={onRegisterClick}
                    onLoginClick={onLoginClick}
                  />
                )}
              </div>
            )}
          </div>

          {hasVR && <VRGuideArea enterXRSessionRef={enterXRSessionRef} />}

          {loading ? (
            <PlayerMetaLoading />
          ) : !showAllChapters ? (
            <div className="flex flex-col justify-center w-full md:flex-row md:px-20">
              <div className="flex flex-col gap-10 md:gap-12 md:flex-grow">
                <section className="flex flex-col w-full gap-10 md:gap-8">
                  <div className="pb-6 border-b border-purple/10 md:border-0 md:pb-0">
                    <div className="align-middle px-7 md:border-b md:flex md:px-0 md:pb-6 ">
                      <div className="flex flex-row items-center gap-3">
                        <div className="relative w-16 h-[90px] overflow-hidden rounded-md shadow-sm shrink-0 md:w-24 md:h-[134px]">
                          <Link href={packageDetailLink} scroll={false}>
                            <BeemiImage
                              src={metaData?.thumbnails[0].t1 ?? ''}
                              alt={metaData?.nameJa ?? ''}
                            />
                          </Link>
                        </div>
                        {!isMd && (
                          <h2 className="text-base font-bold text-purple line-clamp-3">
                            {hasVR && (
                              <span className="mr-1">
                                <Badge badge="VR" />
                              </span>
                            )}
                            {metaData?.nameJa}
                          </h2>
                        )}
                      </div>
                      <div className="md:ml-6">
                        {isMd && (
                          <h2 className="text-lg font-bold text-purple line-clamp-2">
                            {hasVR && (
                              <span className="mr-1 ">
                                <Badge badge="VR" />
                              </span>
                            )}
                            {metaData?.nameJa}
                          </h2>
                        )}
                        <div className="flex flex-row items-center gap-3 mt-2 mb-4">
                          {isNewPackage && <Badge badge="NEW" />}
                          <p className="text-xs font-normal leading-4 md:text-sm ">
                            {intl.formatMessage(messages.chapter, {
                              chapter: metaData?.chapterNo,
                            })}
                          </p>
                          <p className="text-time text-purple/60">
                            {`${getTimeString(
                              Number(metaData?.startTimeSeconds)
                            )} ~ ${getTimeString(
                              Number(metaData?.endTimeSeconds)
                            )}`}
                          </p>
                        </div>
                        <a href={`/package/${parentPackageId}/chapters`}>
                          <Button
                            text={intl.formatMessage(messages.related)}
                            subText={intl.formatMessage(messages.chapterCount, {
                              chapterCount:
                                metaData?.paginatedAllChapters.pageInfo
                                  .totalRecordCount,
                            })}
                            variant="CHAPTER_STAGE"
                            rightIconName="HALF_ARROW_RIGHT"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowAllChapters(true);
                            }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="flex flex-col px-7 md:px-0 gap-y-10">
                  <UserButtonsGroup
                    packageId={parentPackageId}
                    chapterId={chapterId}
                    isLoggedIn={isLoggedIn}
                    isFavorite={Boolean(data?.mediaChapter?.favorite?.id)}
                    rating={data?.mediaChapter?.rating?.score}
                    title={metaData?.nameJa || ''}
                    showSignUpDialog={showSignUpDialog}
                    refetchQueries={['getMediaChapterInfo']}
                  />
                  {hasVR && <VRAlert />}

                  {subscribeActionProps && (
                    <SubscribeAction
                      type="chapter"
                      textLine1={subscribeActionProps.textLine1}
                      buttonText={subscribeActionProps.buttonText}
                      buttonHref={subscribeActionProps.buttonHref || ''}
                    />
                  )}
                </section>
                {metaData?.contentTags &&
                  metaData?.contentTags.length !== 0 && (
                    <section className="px-7 md:px-0">
                      <TagList tags={contentTags} generateHref={generateHref} />
                    </section>
                  )}
                {castTags && castTags.length !== 0 && (
                  <section className="px-7 md:px-0">
                    <TagList
                      tags={castTags}
                      title={intl.formatMessage(globalMessages.cast)}
                      generateHref={generateHref}
                    />
                  </section>
                )}
                {brandGroupTags && brandGroupTags.length !== 0 && (
                  <section className="px-7 md:px-0">
                    <TagList
                      tags={brandGroupTags}
                      title={intl.formatMessage(
                        contentTypeMap(ContentType.BrandGroup)
                      )}
                      generateHref={generateHref}
                    />
                  </section>
                )}
              </div>
              <section className="mt-10 md:mt-0 px-7 md:px-0 md:ml-12">
                <h3 className="text-sub-headline md:text-body text-purple">
                  {intl.formatMessage(messages.recommendation)}
                </h3>
                <div className="mt-5 md:mt-4">
                  <CardGrid
                    appendClassName="justify-start"
                    type="CHAPTER_BLOCK_NORMAL"
                    cards={data?.mediaChapter?.paginatedRecommendations.records.map(
                      (mediaItem, index) => ({
                        ...mapMediaChapterLightToChapterCard(mediaItem),
                        sequenceId: nextChapterSequenceIndex,
                        onClick: () => updateChapterSequence(mediaItem.id),
                        kafkaProps: {
                          targetPrefix: 'chapterPlayer-recommendTitle',
                          base_schema: 'user_click_dimension_1_default',
                          index,
                          chapter_id: mediaItem.id,
                          source_package_id: parentPackageId,
                          source_chapter_id: chapterId,
                        },
                      })
                    )}
                  />
                </div>
              </section>
            </div>
          ) : (
            <div className="md:px-20">
              <section className="pb-6 border-b border-purple/10 px-7 md:px-0">
                <button
                  onClick={() => {
                    setShowAllChapters(false);
                  }}
                >
                  <Icon
                    className="text-purple/60"
                    name="FULL_ARROW_LEFT"
                    size="EXTRA_BIG"
                  />
                </button>
                <h2 className="text-base font-bold md:text-lg text-purple line-clamp-3">
                  {title}
                </h2>
                <div className="flex flex-row items-center gap-3 mt-2">
                  {isNewPackage && <Badge badge="NEW" />}
                  <p className="text-xs font-normal leading-4 md:text-sm ">
                    {currentChapterName}
                  </p>
                  <p className="text-time text-purple/60">
                    {currentChapterDuration}
                  </p>
                </div>
                <div className="mt-2 md:w-70">
                  <Button
                    text={intl.formatMessage(messages.packageDetailLink)}
                    variant="SECONDARY"
                    leftIconName="INFO_ICON"
                    onClick={() => {
                      router.push(packageDetailLink, undefined, {
                        scroll: false,
                      });
                    }}
                  />
                </div>
              </section>
              <section className="mt-10 md:mt-8 px-7 md:px-0">
                <InfiniteScrollContainer
                  fetchMore={fetchMoreChapters}
                  loading={getMediaChapterLoading}
                  hasNextPage={chaptersHasNextPage}
                >
                  <div className="grid justify-center w-full grid-cols-2 gap-4 md:gap-x-3 md:gap-y-5 md:grid-cols-3 lg:grid-cols-4">
                    {metaData?.paginatedAllChapters.records.map((card) => {
                      return (
                        <PlayerChapterCard
                          key={card.metadata.id}
                          description={intl.formatMessage(messages.chapter, {
                            chapter: card.metadata.chapterNo,
                          })}
                          imgSrc={card.metadata.thumbnails[0]?.w1}
                          imgAlt="thumbnail"
                          durationDisplayText={`${getTimeString(
                            Number(card.metadata?.startTimeSeconds),
                            true
                          )} ~`}
                          onClick={() =>
                            handleClickChapterList(card.metadata.id)
                          }
                          selected={chapterId === card.metadata.id}
                        />
                      );
                    })}
                  </div>
                </InfiniteScrollContainer>
              </section>
            </div>
          )}
        </article>
      </DetailModal>
      {isSignupPrimeDialogShowing && (
        <SignupPrime
          resourceId={chapterId}
          type="chapter"
          hideSignUpDialog={hideSignUpDialog}
        />
      )}
      {playerErrorCode && (
        <PlayErrorDialog
          playerErrorCode={playerErrorCode}
          onBackClick={() => {
            setPlayerErrorCode('');
            if (isInitialRoute) {
              router.push('/store');
            } else {
              router.back();
            }
          }}
          onTakeOverSessionClick={() => {
            setPlayerErrorCode('');
            setOverwrite(true);
          }}
        />
      )}
    </>
  );
};

export default ChapterPlayer;
