import _4KIcon from '@/assets/icons/badge/4K.svg';
import BadgeIcon from '@/shared/components/BadgeIcon';
import Icon from '@/shared/components/Icon';
import { globalMessages } from '@/shared/constants/messages';
import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';
import { defineMessage, useIntl } from 'react-intl';

const messages = {
  NEW: defineMessage({
    id: 'badge.new',
    defaultMessage: 'NEW',
  }),
  CURRENT: defineMessage({
    id: 'badge.current',
    defaultMessage: '現在:',
  }),
  POINT: globalMessages.point,
  PRICE: defineMessage({
    id: 'badge.price',
    defaultMessage: '円',
  }),
  MULTI_PRICE: defineMessage({
    id: 'badge.multiPrice',
    defaultMessage: '円〜',
  }),
  PREORDER: defineMessage({
    id: 'badge.preOrder',
    defaultMessage: '予約',
  }),
  NEW_PRODUCT: defineMessage({
    id: 'badge.newProduct',
    defaultMessage: '新作',
  }),
  SEMINEW_PRODUCT: defineMessage({
    id: 'badge.seminewProduct',
    defaultMessage: '準新作',
  }),
  SALE: defineMessage({
    id: 'badge.sale',
    defaultMessage: 'SALE',
  }),
  SVOD: defineMessage({
    id: 'badge.svod',
    defaultMessage: '見放題対象',
  }),
  PURCHASED: defineMessage({
    id: 'badge.purchased',
    defaultMessage: '購入済み',
  }),
  FULLHD: defineMessage({
    id: 'badge.fullhd',
    defaultMessage: 'フルHD',
  }),
  VR: defineMessage({
    id: 'badge.VR',
    defaultMessage: 'VR',
  }),
};

type Badges =
  | 'NEW'
  | 'PREORDER'
  | 'NEW_PRODUCT'
  | 'SEMINEW_PRODUCT'
  | 'PRICE'
  | 'SALE'
  | 'SVOD'
  | 'TIME'
  | 'CURRENT_POINT'
  | 'PURCHASED'
  | 'FULLHD'
  | '4K'
  | 'VR';

const BADGES_MAPS: Record<Badges, string> = {
  NEW: 'h-4 px-1.25 rounded-full bg-pink/80 text-label text-white',
  PREORDER:
    'h-4 px-1 rounded-full border border-purple/60 text-label text-purple/60',
  NEW_PRODUCT:
    'h-4 px-1 rounded-full border border-purple/60 text-label text-purple/60',
  SEMINEW_PRODUCT:
    'h-4 px-1 rounded-full border border-purple/60 text-label text-purple/60',
  PRICE: 'h-4 pr-1.25 pl-0.5 rounded-full bg-purple/10 text-label text-purple',
  SALE: 'h-4 px-1.25 rounded-full bg-purple/10 text-label text-purple',
  SVOD: 'h-4 top-px pl-1.25 pr-0.75 rounded-full bg-purple/80 text-label text-white',
  TIME: 'h-6 left-2 py-1 px-2 rounded bg-purple/60 text-time text-white',
  CURRENT_POINT:
    'h-6 py-1 px-2 rounded-full bg-purple/10 text-caption text-purple space-x-1',
  PURCHASED: 'h-4 px-1.25 rounded-full bg-purple/10 text-label text-purple',
  FULLHD: 'h-4 text-xs text-purple/60',
  '4K': '',
  VR: 'h-5 w-10 bg-purple rounded text-white/80 font-black text-md font-mono justify-center tracking-2',
};

interface BadgeBaseProps extends HTMLAttributes<HTMLDivElement> {
  badge: Badges;
}

interface BadgeSimpleProps extends BadgeBaseProps {
  badge:
    | 'NEW'
    | 'PREORDER'
    | 'NEW_PRODUCT'
    | 'SEMINEW_PRODUCT'
    | 'SALE'
    | 'SVOD'
    | 'PURCHASED'
    | 'FULLHD'
    | '4K'
    | 'VR';
}

interface BadgePriceProps extends BadgeBaseProps {
  badge: 'PRICE';
  price: number;
  multiPrice?: boolean;
}

interface BadgeCurrentPointProps extends BadgeBaseProps {
  badge: 'CURRENT_POINT';
  points: number;
}

interface BadgeTimeProps extends BadgeBaseProps {
  badge: 'TIME';
  time: string;
}

export type BadgeProps =
  | BadgeSimpleProps
  | BadgePriceProps
  | BadgeCurrentPointProps
  | BadgeTimeProps;

const isTimeProps = (props: BadgeProps): props is BadgeTimeProps => {
  if (props.badge === 'TIME') {
    return true;
  }
  return false;
};

const isPriceProps = (props: BadgeProps): props is BadgePriceProps => {
  if (props.badge === 'PRICE') {
    return true;
  }
  return false;
};

const isCurrentPointProps = (
  props: BadgeProps
): props is BadgeCurrentPointProps => {
  if (props.badge === 'CURRENT_POINT') {
    return true;
  }
  return false;
};

const Badge: FC<BadgeProps> = (props) => {
  const intl = useIntl();
  return (
    <div
      className={classNames(
        'inline-flex items-center',
        BADGES_MAPS[props.badge]
      )}
    >
      {isPriceProps(props) ? (
        <>
          <div className="w-3 h-3 fill-current text-pink">
            <BadgeIcon name="POINT" />
          </div>
          <span className="ml-0.5 font-mono text-xs md:text-[13px]">
            {intl.formatNumber(props.price)}
          </span>
          <span className="text-purple/80 text-3xs md:text-2xs">
            {props.multiPrice
              ? intl.formatMessage(messages.MULTI_PRICE)
              : intl.formatMessage(messages.PRICE)}
          </span>
        </>
      ) : isCurrentPointProps(props) ? (
        <>
          <span>{intl.formatMessage(messages.CURRENT)}</span>
          <div className="w-4 h-4 fill-current text-pink">
            <Icon name="POINT_ICON" />
          </div>
          <span className="font-mono text-lg leading-none">
            {intl.formatNumber(props.points)}
          </span>
          <span>{intl.formatMessage(messages.POINT)}</span>
        </>
      ) : props.badge === 'SVOD' ? (
        <>
          <span>{intl.formatMessage(messages[props.badge])}</span>
          <div className="w-4 h-4 text-pink">
            <Icon name="ADDED_ICON" />
          </div>
        </>
      ) : isTimeProps(props) ? (
        <span>{intl.formatTime(props.time)}</span>
      ) : props.badge === '4K' ? (
        <_4KIcon className="h-2.5" />
      ) : (
        <span>{intl.formatMessage(messages[props.badge])}</span>
      )}
    </div>
  );
};

export default Badge;
