import { getTailwindConfigScreens } from '@/shared/utils/getTailwindConfig';
import { useEffect, useState } from 'react';

const screens = getTailwindConfigScreens();

type SupportedBreakPoints = keyof typeof screens;

/**
 * xz: perhaps we can make a useCurrentTailwindScreensize()
 * that returns keyof `fullConfig.theme.screens` in future
 * so we dont have to listen for x screens but just one
 *
 * const screenSize: SupportedBreakPoints = useCurrentTailwindScreensize()
 */
const useTailwindBreakpoint = (breakpoint: SupportedBreakPoints): boolean => {
  const [matches, setMatches] = useState<boolean>(false);
  useEffect(() => {
    const minWidth = screens[breakpoint] || '1200';
    const mediaQueryList = matchMedia(`(min-width: ${minWidth})`);

    // Set the initial value
    setMatches(mediaQueryList.matches);

    // Listen for changes to the query match
    const onChange = (e: MediaQueryListEvent) => setMatches(e.matches);
    mediaQueryList.addEventListener('change', onChange);

    // Cleanup listener on unmount
    return () => mediaQueryList.removeEventListener('change', onChange);
  }, [breakpoint]);
  return matches;
};

export default useTailwindBreakpoint;
