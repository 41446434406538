import { TreasureDataContext } from '@/domain/log/TreasureDataContext';
import { useGetPlayBackTokensQuery } from '@/shared/generated';
import useIsHevcSupportRef from '@/shared/hooks/useIsHevcSupportRef';
import appLocalStorage from '@/shared/utils/appLocalStorage';
import type { ApolloError } from '@apollo/client';
import type { Geometry } from '@u-next/methane-player';
import type { MapOfPlayable } from '@u-next/videoplayer';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

export enum BitRate {
  AUTO = 'bitrateAuto',
  LOW = 'bitrateLow',
  HIGH = 'bitrateHigh',
}

export const BitRateParams = {
  [BitRate.AUTO]: { bitrateLow: 192, bitrateHigh: null },
  [BitRate.LOW]: { bitrateLow: 192, bitrateHigh: 500 },
  [BitRate.HIGH]: { bitrateLow: 1500, bitrateHigh: null },
};

const playableTypesOfInterest: (keyof MapOfPlayable)[] = [
  'dash',
  'dash-cmaf',
  'hls-cmaf',
  'hls-fp',
  'hls-s-aes',
  'dash-cmaf-vr',
];

function mapPlayables(
  playables: ConsumableList['playables'],
  isSample: boolean
): MapOfPlayable {
  const m: MapOfPlayable = {};
  let key: keyof typeof playables;
  for (key in playables) {
    const skip = isSample
      ? key !== 'mp4'
      : !playableTypesOfInterest.includes(key);
    if (skip) {
      continue;
    }

    m[key] = playables[key].map((limePlayable) => ({
      id: limePlayable.cdn_id,
      weight: limePlayable.weight,
      manifestUrl: limePlayable.playlist_url,
      licenseUrls: limePlayable.license_url_list || {},
      geometry: limePlayable.geometry,
    }));
  }
  return m;
}

interface PlayInfo {
  limeToken: string;
  isemToken: string;
  productCode: string;
  saleTypeCode: string;
  playables: MapOfPlayable | null;
  isCalled: boolean;
  refetchPlayBackTokens: () => void;
  bitRate: BitRate;
  onChangeBitRate: (bitRate: BitRate) => void;
}

/**
 * Lime Consumable List API
 * /vod/v1
 *
 * Swagger UI: https://lime-openapi.beemi.unext.dev/specs/consumable-list.html#tag/Consumable-List-API/operation/get-vod-consumable-list
 *
 */
type ConsumableList = {
  /**
   * Scenesearch URLs if exists
   */
  scene_search_list: Record<string, Playable[]>;
  /**
   * Manifest URLs
   */
  playables: Record<keyof MapOfPlayable, Playable[]>;
};

type Playable = {
  /**
   * CDNアクセスの重み付け。
   * 合計が 1.0 となるように内部で計算されている。
   *
   * CDN access weighting.
   * Calculated internally so that the total is 1.0.
   * e.g. 0.9
   */
  weight: number;
  /**
   * CDN name
   *
   * e.g. "SS-default-external-access"
   */
  cdn_id: string;

  /**
   * Scenesearch URL / Manifest CDN endpoint
   *
   * e.g. "https://lime-stream.beemi.unext.dev/scenesearchad01/0001/001/e777ae77-c8fb-4ef4-a70e-2eedbcbd79e6/tears_AD1.ims"
   */
  playlist_url: string;

  /**
   * DRM license server URLs associated with media profile
   */
  license_url_list?: {
    playready?: string;
    widevine?: string;
    fairplay?: string;
  };

  /**
   * Content geometry information basically used for VR.
   */
  geometry?: Geometry;

  extra?: {
    width: number;
    height: number;
  };
};

type LimeStreamManifestIndex = {
  version: number;
  duration: number;
  presentationGeometry: {
    type: 'regular';
  };
  playables: {
    videoCodec: 'h264';
    audioCodec: 'aac';
    dynamicRange: 'sdr';
    width: number;
    height: number;
    frameRate: number;
    gopSize: number;
    bitrateMode: 'abr' | 'crf';
    containerType: 'bmff';
    /**
     * e.g. "bbb_aac_h264_en_sdr_1625k.mp4"
     */
    url: string;
  }[];
};

export default function usePlayInfo({
  consumableId,
  contentId,
  skip,
  isSample,
  onGetPlayBackTokensError,
  onLimeError,
}: {
  consumableId: string;
  contentId: string;
  skip: boolean;
  isSample: boolean;
  onGetPlayBackTokensError?: (error: ApolloError) => void;
  onLimeError?: (error: Error) => void;
}): PlayInfo {
  // checking if a browser supports 4K by looking at if it supports HEVC, because our 4K contents
  // are all using HEVC codec
  const is4KSupportRef = useIsHevcSupportRef();
  const [isCalled, setIsCalled] = useState(false);
  const [playables, setPlayables] = useState<MapOfPlayable | null>(null);
  const { tdClientId } = useContext(TreasureDataContext);

  const [bitRate, setBitRate] = useState(BitRate.AUTO);
  const bitRateInitialized = useRef(false);

  const resolutionUpperLimit = is4KSupportRef.current ? 'UHD4K' : 'FHD';

  useEffect(() => {
    const bitRateInStorage = appLocalStorage.getString('bitRate') as BitRate;
    if (bitRateInStorage) {
      setBitRate(bitRateInStorage);
    }
    bitRateInitialized.current = true;
  }, []);

  const { data, refetch } = useGetPlayBackTokensQuery({
    variables: {
      contentId,
      consumableId,
      deviceId: tdClientId,
      resolutionUpperLimit,
      bitRateLowerLimit: BitRateParams[bitRate].bitrateLow,
      bitRateUpperLimit: BitRateParams[bitRate].bitrateHigh,
    },
    skip: skip || !bitRateInitialized.current,
    onError: onGetPlayBackTokensError,
    fetchPolicy: 'no-cache',
  });

  const handleRefetchPlayBackTokens = useCallback(() => {
    refetch({
      contentId,
      consumableId,
      deviceId: tdClientId,
      resolutionUpperLimit,
      bitRateLowerLimit: BitRateParams[bitRate].bitrateLow,
      bitRateUpperLimit: BitRateParams[bitRate].bitrateHigh,
    });
  }, [
    bitRate,
    contentId,
    consumableId,
    refetch,
    resolutionUpperLimit,
    tdClientId,
  ]);

  const limeToken = data?.playBackTokens?.playbackToken ?? '';
  /**
   * NOTE: isemToken is not present for SAMPLE (saleTypeCode: "UNSPECIFIED")
   */
  const isemToken = data?.playBackTokens?.isemToken ?? '';

  const getPlayables = useCallback(async () => {
    if (skip || !limeToken) return;
    setIsCalled(false);

    try {
      const res = await fetch(`${process.env.NEXT_PUBLIC_LIME_SERVER}/vod/v1`, {
        headers: {
          Authorization: `Bearer ${limeToken}`,
        },
      });

      if (!res.ok) {
        throw new Error(`LI00${res.status}`);
      }

      const json = (await res.json()) as ConsumableList;
      setIsCalled(true);
      const playables = mapPlayables(json.playables, isSample);

      if (isSample) {
        const manifestUrl = playables?.['mp4']?.[0]?.manifestUrl;

        if (!manifestUrl) {
          throw new Error('No manifestUrl for mp4 playable sample');
        }

        const manifestRes = await fetch(manifestUrl);
        if (!manifestRes.ok) {
          throw new Error(
            `Manifest fetch failed: ${res.status} ${res.statusText}`
          );
        }

        const manifest = (await manifestRes.json()) as LimeStreamManifestIndex;

        const samplePlayableUrl = manifest.playables.sort((a, b) => {
          // NOTE: sort by highest quality desc
          return b.width - a.width;
        })[0]?.url;

        const manifestUrlWithoutJsonFile = manifestUrl
          .split('/')
          .filter((v, i, a) => {
            return i !== a.length - 1;
          })
          .join('/');

        const mp4Playable = playables['mp4'];

        playables['mp4'] = [
          {
            licenseUrls: {},
            id: '',
            weight: 1,
            ...mp4Playable,
            manifestUrl: `${manifestUrlWithoutJsonFile}/${samplePlayableUrl}`,
          },
        ];
      }

      setPlayables(playables);
    } catch (error) {
      if (onLimeError) onLimeError(error as Error);
    }
  }, [limeToken, skip, onLimeError, isSample]);

  useEffect(() => {
    getPlayables();
  }, [getPlayables]);

  const handleChangeBitRate = useCallback((bitRate: BitRate) => {
    setBitRate(bitRate);

    appLocalStorage.set('bitRate', bitRate);
  }, []);

  return {
    playables,
    limeToken,
    isemToken,
    productCode: data?.playBackTokens?.productCode ?? '',
    saleTypeCode: data?.playBackTokens?.saleTypeCode ?? '',
    isCalled,
    refetchPlayBackTokens: handleRefetchPlayBackTokens,
    bitRate,
    onChangeBitRate: handleChangeBitRate,
  };
}
