import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';

const icons = require.context(
  '!@svgr/webpack!../../../assets/icons',
  true,
  /\.svg$/
);

type Sizes = 'SMALL' | 'DEFAULT' | 'BIG' | 'BIGGER' | 'EXTRA_BIG' | 'FULL';

export type Icons =
  | 'ADDED_ICON'
  | 'CAUTION_ICON'
  | 'CODE_ICON'
  | 'CLOSE_ICON'
  | 'CONTENT_DELETE_ICON'
  | 'CONTENT_EDIT_ICON'
  | 'COPY_ICON'
  | 'HAMBURGER_ICON'
  | 'HORIZONTAL_ELLIPSIS_ICON'
  | 'FULL_ARROW_LEFT'
  | 'FULL_ARROW_RIGHT'
  | 'FULL_ARROW_UP'
  | 'FULL_ARROW_DOWN'
  | 'FULLSCREEN_ON'
  | 'HALF_ARROW_LEFT'
  | 'HALF_ARROW_DOWN'
  | 'HALF_ARROW_RIGHT'
  | 'INFO_ICON'
  | 'INFO_OUTLINE_ICON'
  | 'INFO_HELP_BUTTON_ICON'
  | 'LIBRARY_ICON'
  | 'LIST_CONTROL_FILTER_ICON'
  | 'LIST_VIEW_ICON'
  | 'MENU_ACCOUNT_ICON'
  | 'NAV_CLOSE_ICON'
  | 'NEW_TAB_ICON'
  | 'POINT_ICON'
  | 'PLAY_ICON'
  | 'PLAYER_SETTINGS_ICON'
  | 'PLUS_ICON'
  | 'SEARCH_ICON'
  | 'SHARE_ICON'
  | 'SHARE_ICON_IOS'
  | 'STORE_ICON'
  | 'SVOD_ICON'
  | 'THUMBS_DOWN'
  | 'THUMBS_UP'
  | 'USER_ICON'
  | 'USER_HISTORY'
  | 'USER_LOGOUT_ICON'
  | 'USER_TICKET_ICON'
  | 'PURCHASE_ICON'
  | 'VR_ICON'
  | 'FACEBOOK'
  | 'TWITTER'
  | 'HATENA';

const SIZE_MAPS: Record<Sizes, string> = {
  SMALL: 'w-3 h-3',
  DEFAULT: 'w-4 h-4',
  BIG: 'w-5 h-5',
  BIGGER: 'w-6 h-6',
  EXTRA_BIG: 'w-8 h-8',
  FULL: 'w-full h-full',
};

export const ICONS_MAPS: Record<Icons, string> = {
  ADDED_ICON: 'added_icon.svg',
  CAUTION_ICON: 'caution_icon.svg',
  CODE_ICON: 'code_icon.svg',
  CLOSE_ICON: 'close_icon.svg',
  CONTENT_DELETE_ICON: 'content_delete_icon.svg',
  CONTENT_EDIT_ICON: 'content_edit_icon.svg',
  COPY_ICON: 'copy_icon.svg',
  HAMBURGER_ICON: 'hamburger_icon.svg',
  HORIZONTAL_ELLIPSIS_ICON: 'horizontal_ellipsis_icon.svg',
  FULL_ARROW_LEFT: 'full_arrow_left.svg',
  FULL_ARROW_RIGHT: 'full_arrow_right.svg',
  FULL_ARROW_UP: 'full_arrow_up.svg',
  FULL_ARROW_DOWN: 'full_arrow_down.svg',
  FULLSCREEN_ON: 'fullscreen_on.svg',
  HALF_ARROW_LEFT: 'half_arrow_left.svg',
  HALF_ARROW_DOWN: 'half_arrow_down.svg',
  HALF_ARROW_RIGHT: 'half_arrow_right.svg',
  INFO_ICON: 'info_icon.svg',
  INFO_OUTLINE_ICON: 'info_outline_icon.svg',
  INFO_HELP_BUTTON_ICON: 'info_help_button_icon.svg',
  LIBRARY_ICON: 'library_icon.svg',
  LIST_CONTROL_FILTER_ICON: 'list_control_filter_icon.svg',
  LIST_VIEW_ICON: 'list_view_icon.svg',
  MENU_ACCOUNT_ICON: 'menu_account_icon.svg',
  NAV_CLOSE_ICON: 'nav_close_icon.svg',
  NEW_TAB_ICON: 'new_tab_icon.svg',
  POINT_ICON: 'point.svg',
  PLAY_ICON: 'play_icon.svg',
  PLAYER_SETTINGS_ICON: 'player_settings_icon.svg',
  PLUS_ICON: 'plus_icon.svg',
  SEARCH_ICON: 'search_icon.svg',
  SHARE_ICON: 'share_icon.svg',
  SHARE_ICON_IOS: 'share_icon_ios.svg',
  STORE_ICON: 'store_icon.svg',
  SVOD_ICON: 'svod_icon.svg',
  THUMBS_DOWN: 'thumbs_down.svg',
  THUMBS_UP: 'thumbs_up.svg',
  USER_ICON: 'user_icon.svg',
  USER_HISTORY: 'user_history.svg',
  USER_LOGOUT_ICON: 'user_logout_icon.svg',
  USER_TICKET_ICON: 'user_ticket_icon.svg',
  PURCHASE_ICON: 'purchase_icon.svg',
  VR_ICON: 'vr_icon.svg',
  FACEBOOK: 'sns/icon_share_facebook.svg',
  TWITTER: 'sns/icon_share_twitter.svg',
  HATENA: 'sns/icon_share_hatena.svg',
};

export interface IconProps extends HTMLAttributes<HTMLDivElement> {
  name: Icons;
  size?: Sizes;
}

const Icon: React.FC<IconProps> = ({
  name,
  size = 'DEFAULT',
  className,
  ...props
}) => {
  const LoadedIcon = icons(`./${ICONS_MAPS[name]}`).default;
  return (
    <div
      className={classNames(
        `inline-flex text-center`,
        SIZE_MAPS[size],
        className
      )}
      {...props}
    >
      <LoadedIcon className={`w-full h-full fill-current`} />
    </div>
  );
};

export default Icon;
