import {
  KafkaContext,
  KafkaPropsWithTargetPrefix,
} from '@/domain/log/KafkaContext';
import { ChapterPlayerContentTagsTagLog } from '@/domain/log/__types__/chapterPlayer-contentTags-tag';
import { PackageDetailContentTagsTagLog } from '@/domain/log/__types__/packageDetail-contentTags-tag';
import { SearchTagSectionTagLog } from '@/domain/log/__types__/search-tagSection-tag';
import { SearchResultTagSuggestTagLog } from '@/domain/log/__types__/searchResult-tagSuggest-tag';
import { SvodTagSuggestTagLog } from '@/domain/log/__types__/svod-tagSuggest-tag';
import classNames from 'classnames';
import { FC, createElement, useContext } from 'react';
import { useIntl } from 'react-intl';
import { globalMessages } from '../../../constants/messages';
import BeemiLink, { BeemiLinkProps } from '../../BeemiLink';
import Icon from '../../Icon';

type Sizes = 'SMALL' | 'DEFAULT';

const SIZE_MAPS: Record<Sizes, string> = {
  SMALL: 'h-7 px-2 text-3xs md:text-xs space-x-px',
  DEFAULT: 'h-8 px-3 text-xs md:text-sm space-x-0.5',
};

type Variants = 'REMOVE' | 'SELECTED' | 'LIST' | 'DEFAULT';

const VARIANTS_MAP: Record<Variants, string> = {
  REMOVE: 'bg-pink/80 text-white',
  SELECTED: 'bg-pink/80 text-white',
  LIST: 'border border-purple/10',
  DEFAULT: 'bg-purple/10 hover:bg-purple/30 transition-colors',
};

type Prefix = '#' | '+';

type TagLog =
  | SvodTagSuggestTagLog
  | SearchTagSectionTagLog
  | PackageDetailContentTagsTagLog
  | SearchResultTagSuggestTagLog
  | ChapterPlayerContentTagsTagLog;

export interface TagButtonProps {
  size?: Sizes;
  variant?: Variants;
  text: string;
  count?: number;
  href?: BeemiLinkProps['href'];
  isBeemiLink?: boolean;
  prefix?: Prefix;
  component?: 'button' | 'div' | 'BeemiLink';
  kafkaProps?: KafkaPropsWithTargetPrefix<TagLog>;
  onClick?: () => void;
  dataTestId?: string;
}

const TagButton: FC<TagButtonProps> = ({
  size = 'DEFAULT',
  variant = 'DEFAULT',
  text,
  count,
  href,
  prefix = '#',
  component = 'button',
  kafkaProps,
  onClick,
  ...props
}) => {
  const intl = useIntl();

  const { client } = useContext(KafkaContext);

  const onClickTag = () => {
    if (kafkaProps) {
      const {
        targetPrefix,
        base_schema = 'user_click_dimension_1_default',
        ...rest
      } = kafkaProps;
      const target = `${targetPrefix}-tag` as TagLog['event']['target'];

      client?.trackUser<TagLog>(target, 'click', base_schema, rest);
    }
    onClick && onClick();
  };

  const inside = (
    <>
      {variant === 'REMOVE' && (
        <span className="flex items-center text-white/60">
          <Icon size="DEFAULT" name="CLOSE_ICON" />
        </span>
      )}
      {variant === 'DEFAULT' && (
        <span className="text-purple/60">{prefix}</span>
      )}
      <div className="flex items-baseline overflow-hidden">
        <span className="truncate">{text}</span>
        {variant === 'DEFAULT' && count && (
          <span className="ml-px font-medium text-purple/60 text-3xs md:text-2xs">
            {intl.formatMessage(globalMessages.numberOfResults, {
              count: intl.formatNumber(count),
            })}
          </span>
        )}
      </div>
    </>
  );

  return component === 'BeemiLink' && href
    ? createElement(
        BeemiLink,
        {
          className: classNames(
            'max-w-full flex-shrink-0 whitespace-nowrap inline-flex items-center rounded-full font-semibold overflow-hidden',
            SIZE_MAPS[size],
            VARIANTS_MAP[variant]
          ),
          href,
          onClick: onClickTag,
          'data-testid': props['dataTestId'],
        },
        inside
      )
    : createElement(
        component,
        {
          className: classNames(
            'max-w-full flex-shrink-0 whitespace-nowrap inline-flex items-center rounded-full font-semibold overflow-hidden',
            SIZE_MAPS[size],
            VARIANTS_MAP[variant]
          ),
          href,
          onClick: onClickTag,
          'data-testid': props['dataTestId'],
        },
        inside
      );
};

export default TagButton;
