import { KafkaContext } from '@/domain/log/KafkaContext';
import { SearchAutoCompleteItemLog } from '@/domain/log/__types__/search-autoComplete-item';
import { mapTagsToKafkaTagList } from '@/domain/search/SearchResult/utils';
import { ContentType, Node } from '@/shared/generated';
import useClickOutside from '@/shared/hooks/useClickOutside';
import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import { FC, ReactNode, useCallback, useContext, useRef } from 'react';
import AutoComplete, { AutoCompleteProps } from './AutoComplete';
import SearchForm, { SearchFormProps } from './SearchForm';

export interface SearchFormContainerProps
  extends Omit<SearchFormProps, 'onEnter'>,
    AutoCompleteProps {
  onSearch?: (value: string | undefined, tags?: Node[]) => void;
  children?: ReactNode;
}

const SearchFormContainer: FC<SearchFormContainerProps> = ({
  suggestions,
  onSearch,
  ...searchFormProps
}) => {
  const { value, tags, onClose } = searchFormProps;
  const isMd = useTailwindBreakpoint('md');

  const dropDownEl = useRef<HTMLDivElement>(null);

  useClickOutside(
    dropDownEl,
    () => {
      //Mobile should not useClickOutside due to the ability to click outside during transition
      if (typeof onClose === 'function' && isMd) {
        handleSearch(value, tags);
      }
    },
    { useCapture: true, useEscKey: true }
  );

  const handleSearch = useCallback(
    (value: string | undefined, tags?: Node[]) => {
      if (value || tags?.length) {
        if (typeof onSearch === 'function') {
          onSearch(value, tags);
        }
      }
      if (typeof onClose === 'function') {
        onClose();
      }
    },
    [onSearch, onClose]
  );

  const { client } = useContext(KafkaContext);

  const onClickAutoCompleteSuggestion = useCallback(
    (id: string, nameJa: string, contentType: ContentType, index: number) => {
      client?.trackUser<SearchAutoCompleteItemLog>(
        'search-autoComplete-item',
        'click',
        'user_click_dimension_1_default',
        {
          autocomplete_tag_list: mapTagsToKafkaTagList(suggestions || []),
          content_type: contentType,
          index,
          search_kw: value,
          search_tag_list: mapTagsToKafkaTagList(tags || []),
          tag_id: id,
        }
      );

      if (tags?.find((tag) => tag.id === id)) {
        handleSearch('', tags);
      } else {
        handleSearch('', [...(tags || []), { id, nameJa, contentType }]);
      }
    },
    [client, handleSearch, suggestions, tags, value]
  );

  return (
    <>
      <div
        className="bg-white z-100 md:relative md:rounded-2xl"
        ref={dropDownEl}
      >
        <AutoComplete
          suggestions={suggestions}
          isTyping={value !== ''}
          onClick={onClickAutoCompleteSuggestion}
        >
          <SearchForm
            {...searchFormProps}
            onEnter={() => handleSearch(value, tags)}
          />
        </AutoComplete>
      </div>
      {value || suggestions?.length ? (
        <div className="fixed top-0 left-0 z-50 hidden w-full h-full md:block bg-black/20 " />
      ) : null}
    </>
  );
};

export default SearchFormContainer;
