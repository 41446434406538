import React, { ButtonHTMLAttributes } from 'react';
import Icon from '../../Icon';

const OptionButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  onClick,
}) => {
  return (
    <button
      data-testid="OptionButton"
      className="w-6 h-6 text-white transition-colors rounded-full bg-purple/30 hover:bg-purple "
      onClick={onClick}
    >
      <span className="flex items-center justify-center">
        <Icon
          name="HORIZONTAL_ELLIPSIS_ICON"
          size="BIG"
          className="rotate-90"
        />
      </span>
    </button>
  );
};

export default OptionButton;
