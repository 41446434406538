import { KafkaClient } from '@/domain/log/kafkaClient';
import { KafkaContext } from '@/domain/log/KafkaContext';
import { LogBody } from '@/domain/log/__types__/logBody';
import { XxxxxxMenuLibraryLog } from '@/domain/log/__types__/xxxxxx-menu-library';
import { XxxxxxMenuStoreLog } from '@/domain/log/__types__/xxxxxx-menu-store';
import { XxxxxxMenuSvodLog } from '@/domain/log/__types__/xxxxxx-menu-svod';
import { withProperties } from '@/shared/utils/typeHelper';
import classNames from 'classnames';
import { FC, ReactNode, useContext } from 'react';
import BeemiLink, { BeemiLinkProps } from '../../BeemiLink';
import Icon, { Icons } from '../../Icon';

export interface NavigationTabBarItemProps extends BeemiLinkProps {
  icon: Icons;
  text: string;
  kafkaSchema: LogBody['event']['target'];
  isActive?: boolean;
}

const sendMenuLog = (
  client: KafkaClient,
  kafkaSchema: LogBody['event']['target']
) => {
  if (kafkaSchema === 'xxxxxx-menu-store') {
    client.trackUser<XxxxxxMenuStoreLog>(
      kafkaSchema,
      'click',
      'user_click_dimension_0_default',
      {}
    );
    return;
  }
  if (kafkaSchema === 'xxxxxx-menu-svod') {
    client.trackUser<XxxxxxMenuSvodLog>(
      kafkaSchema,
      'click',
      'user_click_dimension_0_default',
      {}
    );
    return;
  }
  if (kafkaSchema === 'xxxxxx-menu-library') {
    client.trackUser<XxxxxxMenuLibraryLog>(
      kafkaSchema,
      'click',
      'user_click_dimension_0_default',
      {}
    );
    return;
  }
};

export const NavigationTabBarItem: React.FC<NavigationTabBarItemProps> = ({
  icon,
  text,
  kafkaSchema,
  isActive,
  ...props
}) => {
  const { client } = useContext(KafkaContext);

  return (
    <BeemiLink
      className={classNames(
        'flex flex-col items-center transition-colors transition-font',
        isActive ? 'text-pink/80 font-bold' : 'text-gray-500'
      )}
      {...props}
      onClick={() => {
        client && sendMenuLog(client, kafkaSchema);
      }}
    >
      <div className="w-7 h-7">
        <Icon name={icon} size="FULL" />
      </div>
      <span className="mt-px text-2xs">{text}</span>
    </BeemiLink>
  );
};

const NavigationTabBar: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  return (
    <div
      className={classNames('flex flex-row items-center justify-around h-full')}
    >
      {children}
    </div>
  );
};

export default withProperties(NavigationTabBar, {
  Item: NavigationTabBarItem,
});
