import { INFINITE_FETCH_LIMIT } from '@/shared/constants/limit';
import { ApolloQueryResult } from '@apollo/client';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

interface InfiniteScrollContainerProps {
  fetchMore: (
    offset: number,
    limit: number
  ) => Promise<ApolloQueryResult<unknown>>;
  hasNextPage: boolean;
  loading: boolean;
  children?: ReactNode;
}

const InfiniteScrollContainer: FC<InfiniteScrollContainerProps> = ({
  fetchMore,
  hasNextPage,
  loading,
  children,
}) => {
  const bottomBoundaryRef = useRef(null);
  const [offset, setOffset] = useState<number>(INFINITE_FETCH_LIMIT);
  const [needFetchMore, setNeedFetchMore] = useState<boolean>(false);

  const scrollObserver = useCallback(
    (node: Element) => {
      new IntersectionObserver((entries) => {
        entries.forEach((en) => {
          if (en.isIntersecting) {
            setNeedFetchMore(true);
          }
        });
      }).observe(node);
    },
    [setNeedFetchMore]
  );

  useEffect(() => {
    if (bottomBoundaryRef.current) {
      scrollObserver(bottomBoundaryRef.current);
    }
  }, [scrollObserver]);

  useEffect(() => {
    if (needFetchMore && hasNextPage && !loading) {
      fetchMore(offset, INFINITE_FETCH_LIMIT);
      setOffset(offset + INFINITE_FETCH_LIMIT);
      setNeedFetchMore(false);
    }
  }, [fetchMore, hasNextPage, loading, needFetchMore, offset]);

  return (
    <>
      {children}
      <div ref={bottomBoundaryRef} />
    </>
  );
};

export default InfiniteScrollContainer;
