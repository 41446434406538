import Button from '@/shared/components/Button/Button';
import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import { ComponentProps, FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import Dialog from '..';
import { globalMessages } from '../../../../constants/messages';
import SimpleDialogTitle from './Title';

interface Props {
  isShowing: boolean;
  title: ComponentProps<typeof SimpleDialogTitle>['title'];
  overlay?: ComponentProps<typeof Dialog>['overlayVariant'];
  animationType?: ComponentProps<typeof Dialog>['animationType'];
  renderButtons?: () => JSX.Element;
  onClickOutside?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
}

const SimpleDialog: FC<Props> = ({
  isShowing,
  title,
  children,
  overlay = 'DARK_PURPLE',
  animationType = 'fade',
  renderButtons,
  onClickOutside,
  onCancel,
}) => {
  const intl = useIntl();
  const isMd = useTailwindBreakpoint('md');

  if (isMd) {
    return (
      <Dialog
        overlayVariant={overlay}
        isShowing={isShowing}
        animationType={animationType}
        onClickOutside={onClickOutside}
      >
        <div className="p-6">
          <SimpleDialogTitle title={title} onCancel={onCancel} />
          {children && <div>{children}</div>}
          <div className="flex mt-8 space-x-2">
            {renderButtons && renderButtons()}
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      overlayVariant={overlay}
      isShowing={isShowing}
      animationType={animationType}
      onClickOutside={onClickOutside}
    >
      <div className="px-4 pt-6 pb-4 max-w-86">
        <div className="flex items-center justify-center text-strong text-purple">
          {title}
        </div>
        {children && <div>{children}</div>}
        <div className="mt-6 space-y-1.5">
          {renderButtons && renderButtons()}
          {onCancel && (
            <Button
              variant="TEXT"
              text={intl.formatMessage(globalMessages.cancel)}
              onClick={onCancel}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default SimpleDialog;
