const BOT_USER_AGENT_LIST = [
  'facebookexternalhit',
  'facebot',
  'twitterbot',
  'phantomjs',
  'googlebot',
  'bingbot',
  'msnbot',
  'slurp',
  'duckduckbot',
];

export const isBot = (userAgent: string): boolean => {
  return !!BOT_USER_AGENT_LIST.find((bot) =>
    userAgent.match(new RegExp(bot, 'i'))
  );
};
