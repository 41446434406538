import { KafkaContext } from '@/domain/log/KafkaContext';
import { TreasureDataContext } from '@/domain/log/TreasureDataContext';
import { PurchaseLog } from '@/domain/log/__types__/purchase';
import Button from '@/shared/components/Button/Button';
import PageLoading from '@/shared/components/Loading/PageLoading';
import DialogPurchase from '@/shared/components/Modality/Dialog/Purchase';
import SimpleDialog from '@/shared/components/Modality/Dialog/SimpleDialog';
import { WEB_DEVICE_CODE } from '@/shared/constants';
import {
  CapySaleTypeCode,
  GetUserInfoQuery,
  MediaFeatureBadge,
  useGetMediaProductInfoQuery,
  useGetPointBackQuery,
  usePurchaseBeemiVideoMutation,
} from '@/shared/generated';
import useFormatErrorMessage from '@/shared/hooks/useFormatErrorMessage';
import { extractFirstGraphQLError } from '@/shared/utils/extractFirstGraphQLError';
import hasResolution from '@/shared/utils/hasResolution';
import { ApolloError } from '@apollo/client';
import { differenceInCalendarDays, isToday } from 'date-fns';
import { useRouter } from 'next/router';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import {
  defineMessage,
  FormatDateOptions,
  IntlShape,
  useIntl,
} from 'react-intl';

const DATE_TIME_FORMAT: Record<string, FormatDateOptions> = {
  SAME_DAY: {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  },
  WITHIN_90_DAYS: {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  },
  MORE_THAN_90_DAYS_AGO: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
};

const messages = {
  expiredDateTime: defineMessage({
    id: 'purchase.expiredDateTimeWithin90Days',
    defaultMessage: '{date}まで視聴可',
  }),
  expiredDateTimeSameDay: defineMessage({
    id: 'purchase.expiredDateTimeSameDay',
    defaultMessage: '本日 {date}まで視聴可',
  }),
  purchaseNothing: defineMessage({
    id: 'purchase.purchaseNothing',
    defaultMessage: '購入できる商品がありません',
  }),
  close: defineMessage({
    id: 'purchase.close',
    defaultMessage: '閉じる',
  }),
};

function generateSubText(intl: IntlShape, expiredDateTime: Date) {
  if (isToday(expiredDateTime)) {
    return intl.formatMessage(messages.expiredDateTimeSameDay, {
      date: intl.formatDate(expiredDateTime, DATE_TIME_FORMAT.SAME_DAY),
    });
  }

  if (differenceInCalendarDays(new Date(), expiredDateTime) > 90) {
    return intl.formatMessage(messages.expiredDateTime, {
      date: intl.formatDate(
        expiredDateTime,
        DATE_TIME_FORMAT.MORE_THAN_90_DAYS_AGO
      ),
    });
  }

  return intl.formatMessage(messages.expiredDateTime, {
    date: intl.formatDate(expiredDateTime, DATE_TIME_FORMAT.WITHIN_90_DAYS),
  });
}

interface Props {
  mediaId: string;
  userInfoData: GetUserInfoQuery | undefined;
  onPurchaseComplete: () => void;
}

const PurchaseDialogContainer: FC<Props> = ({
  mediaId,
  userInfoData,
  onPurchaseComplete,
}) => {
  const router = useRouter();
  const intl = useIntl();
  const formatErrorMessage = useFormatErrorMessage();
  const { client } = useContext(KafkaContext);
  const { tdClientId } = useContext(TreasureDataContext);

  const showPurchase = router.query.purchase;
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedProductCode, setSelectedProductCode] = useState('');
  const {
    data,
    loading: mediaProductInfoLoading,
    error: mediaProductInfoError,
  } = useGetMediaProductInfoQuery({
    variables: {
      mediaId,
      deviceId: tdClientId,
    },
  });

  const {
    data: pointBackData,
    loading: pointBackLoading,
    error: pointBackError,
  } = useGetPointBackQuery();

  const [
    purchaseBeemiVideo,
    { loading: purchaseLoading, error: purchaseError },
  ] = usePurchaseBeemiVideoMutation({
    onCompleted: onPurchaseComplete,
  });

  const { code: purchaseErrorCode, message: purchaseErrorMessage } =
    extractFirstGraphQLError(purchaseError) || {};

  useEffect(() => {
    if (purchaseErrorCode || purchaseErrorMessage) {
      const errorMessage = formatErrorMessage(
        purchaseErrorCode,
        purchaseErrorMessage
      );
      setErrorMessage(errorMessage);
    }
  }, [formatErrorMessage, purchaseErrorCode, purchaseErrorMessage]);

  useEffect(() => {
    if (showPurchase) {
      client?.trackUser<PurchaseLog>(
        'purchase',
        'view',
        'user_view_page_default',
        {}
      );
    }
  }, [client, showPurchase]);

  useEffect(() => {
    const firstProduct = data?.media?.productInfo?.products?.filter(
      (product) => !product.alreadyPurchased
    )?.[0];
    if (firstProduct) {
      setSelectedProductCode(firstProduct.productCode);
    }
  }, [data?.media]);

  const handleClose = useCallback(() => {
    setErrorMessage('');
    const newQuery = { ...router.query };
    delete newQuery['purchase'];
    router.push({ ...router, query: newQuery }, undefined, { scroll: false });
  }, [router]);

  if (!data?.media) {
    return null;
  }

  const { id, metadata, productInfo } = data.media;

  const purchaseOptions = productInfo?.products?.flatMap((product) => {
    if (product.priceInTax && product.originalPriceInTax) {
      return [
        {
          id: product.productCode,
          name: `purchase-${id}`,
          text: product.productName,
          subText:
            product.saleTypeCode === CapySaleTypeCode.Tod &&
            product.expiredDateTime
              ? generateSubText(intl, product.expiredDateTime)
              : undefined,
          saleTypeCode: product.saleTypeCode,
          expiredDateTime: product.expiredDateTime,
          price: product.priceInTax,
          originalPrice: product.originalPriceInTax,
          onSale: product.onSale,
          checked: product.productCode === selectedProductCode,
          disabled: product.alreadyPurchased,
          onChange: () => setSelectedProductCode(product.productCode),
        },
      ];
    }
    return [];
  });

  const hasPurchasableItem = productInfo?.products?.some(
    (product) => !product.alreadyPurchased
  );

  const loading = mediaProductInfoLoading || pointBackLoading;

  if (!showPurchase) {
    return null;
  }

  if (loading) {
    return <PageLoading />;
  }

  const error = mediaProductInfoError || pointBackError;

  if (error) {
    throw new ApolloError(error);
  }

  const isVR = metadata?.featureBadges?.includes(MediaFeatureBadge.Vr);
  const is4K = hasResolution(metadata.videoConsumables, 'Uhd4K');
  const showTestPlayWarning = isVR || is4K;

  return (
    <>
      {hasPurchasableItem &&
      typeof userInfoData?.userInfo?.points === 'number' ? (
        <DialogPurchase
          description={metadata.nameJa}
          isShowing={true} // NOTE: Always show since, showPurchase handles rendering
          isPurchaseLoading={purchaseLoading}
          currentPoints={userInfoData.userInfo.points}
          pointBack={{
            productList: pointBackData?.webfront_pointBack.setting?.productList,
            percentage: pointBackData?.webfront_pointBack.setting?.percentage,
            scheduleDate:
              pointBackData?.webfront_pointBack.setting?.scheduleDate,
          }}
          options={purchaseOptions}
          onPurchaseClick={(productCode) => {
            if (productCode) {
              purchaseBeemiVideo({
                variables: {
                  deviceCode: WEB_DEVICE_CODE,
                  capyId: id,
                  productCode: productCode,
                },
              });
            }
          }}
          onCancel={handleClose}
          onClickOutside={handleClose}
          errorMessage={errorMessage}
          showTestPlayWarning={showTestPlayWarning}
        />
      ) : (
        <SimpleDialog
          isShowing={true}
          title={intl.formatMessage(messages.purchaseNothing)}
          renderButtons={() => (
            <Button
              text={intl.formatMessage(messages.close)}
              onClick={handleClose}
            />
          )}
          onClickOutside={handleClose}
        />
      )}
    </>
  );
};

export default PurchaseDialogContainer;
