import { defineMessage } from 'react-intl';

export const packageDetailMetaMessage = {
  title: defineMessage({
    id: 'package.meta.detail.title',
    defaultMessage:
      '{packageName} - アダルト動画が31日間無料で見放題 | BEEMI（ビーミ）',
  }),
  description: defineMessage({
    id: 'package.meta.detail.description',
    defaultMessage:
      '<31日間無料トライアル実施中>人気AV女優・ガチ素人・エロアニメなどアダルト動画を観るならBEEMI。プレステージ・MGS動画・FALENOなど人気メーカー作品も多数配信中。BEEMIなら安心安全に、今すぐスマホ・PCで高画質に楽しめる！',
  }),
};
