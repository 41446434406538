import Button from '@/shared/components/Button/Button';
import SimpleDialog from '@/shared/components/Modality/Dialog/SimpleDialog';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  isShowing: boolean;
  onClose: () => void;
}

const OtherPurchaseOptionDialog: FC<Props> = ({ isShowing, onClose }) => {
  const router = useRouter();
  const intl = useIntl();
  return (
    <SimpleDialog
      isShowing={isShowing}
      title={intl.formatMessage({
        id: 'package.otherPurchaseOption',
        defaultMessage: 'その他の購入オプション',
      })}
      onClickOutside={onClose}
      onCancel={onClose}
      renderButtons={() => {
        return (
          <Link
            href={{
              pathname: router.pathname,
              query: {
                ...router.query,
                purchase: 'true',
              },
            }}
            scroll={false}
            legacyBehavior
          >
            <Button
              component="a"
              text={intl.formatMessage({
                id: 'package.otherPurchaseOption.goPurchase',
                defaultMessage: '購入画面へ',
              })}
              onClick={onClose}
              appendClassName="mb-1.5 cursor-pointer"
            />
          </Link>
        );
      }}
    >
      <p className="mt-6 mb-4 text-center text-caption text-error">
        {intl.formatMessage(
          {
            id: 'package.otherPurchaseOption.warning',
            defaultMessage:
              '月額プランの見放題対象作品なので、<br></br>購入しなくてもご視聴いただけます。',
          },
          { br: () => <br /> }
        )}
      </p>
      <p className="mb-6 text-center text-caption">
        {intl.formatMessage(
          {
            id: 'package.otherPurchaseOption.description',
            defaultMessage:
              '購入しておけば、月額プランの解約後や、<br></br>作品が配信停止になった場合も視聴できます。',
          },
          { br: () => <br /> }
        )}
      </p>
    </SimpleDialog>
  );
};

export default OtherPurchaseOptionDialog;
