import BeemiLink, { BeemiLinkProps } from '@/shared/components/BeemiLink';
import classNames from 'classnames';
import Link from 'next/link';
import React, { FC } from 'react';
import BeemiLogo from '../../../../assets/icons/logo/Logo_BEEMI.svg';
import { SearchButtonProps } from '../../Button/SearchButton';
import Icon from '../../Icon';

export interface HeaderProps {
  searchButtonProps: SearchButtonProps;
  isActive?: boolean;
  onHamburgerIconClick: (e: React.SyntheticEvent) => void;
  onSearchIconClick: () => void;
  userIconHref?: BeemiLinkProps['href'];
  hideMobileHeader?: boolean;
  isLoggedIn?: boolean;
}

const Header: FC<HeaderProps> = ({
  searchButtonProps,
  isActive = false,
  onHamburgerIconClick,
  onSearchIconClick,
  userIconHref,
  hideMobileHeader,
  isLoggedIn,
}) => {
  return (
    <header
      className={classNames(
        'px-5 pt-8 md:pt-0 bg-white/80',
        hideMobileHeader && 'hidden md:block'
      )}
    >
      <div className="flex items-center justify-between w-full h-8 md:h-12 md:px-4">
        {/* Tablet/Mobile Start */}
        <button
          className="hidden w-10 h-10 transition md:block lg:hidden text-purple/60 hover:text-purple"
          onClick={onHamburgerIconClick}
        >
          {!isActive ? (
            <Icon name="HAMBURGER_ICON" size="FULL" />
          ) : (
            <Icon name="CLOSE_ICON" size="FULL" />
          )}
        </button>

        <Link
          className="flex w-32 h-8 text-purple md:w-24 md:h-6 md:block lg:hidden"
          href="/store"
        >
          <BeemiLogo className="w-full h-full" />
        </Link>
        <div className="flex items-center space-x-4 text-purple/60 md:text-purple lg:hidden">
          <button
            className="w-8 h-8 md:w-10 md:h-10 lg:hidden"
            onClick={onSearchIconClick}
          >
            <Icon
              name={searchButtonProps?.iconName || 'SEARCH_ICON'}
              size="FULL"
            />
          </button>
          {isLoggedIn && (
            <BeemiLink href={userIconHref || ''} className="w-8 h-8 md:hidden">
              <Icon name="USER_ICON" size="FULL" />
            </BeemiLink>
          )}
        </div>
        {/* Tablet/Mobile End */}
      </div>
    </header>
  );
};

export default Header;
