import ScrollContainer from '@/shared/components/ScrollContainer';
import { contentTypeMap } from '@/shared/constants/messages';
import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { ContentType, SearchSuggestionNode } from '../../../generated';

export interface AutoCompleteProps {
  suggestions?: SearchSuggestionNode[];
  isTyping?: boolean;
  onClick?: (
    id: string,
    nameJa: string,
    contentType: ContentType,
    index: number
  ) => void;
  children?: ReactNode;
}

const AutoComplete: FC<AutoCompleteProps> = ({
  suggestions = [],
  isTyping,
  onClick,
  children,
}) => {
  const intl = useIntl();

  return (
    <div
      className={classNames(
        'bg-white md:h-auto md:p-2 md:pb-4 md:rounded-2xl ',
        isTyping && suggestions.length !== 0 ? 'h-[90vh] pb-[10vh] ' : 'h-auto'
      )}
    >
      {children}
      {suggestions.length > 0 && (
        <ScrollContainer
          as="div"
          className="w-full h-full px-5 pt-4 md:px-0.5 md:pt-1 overflow-auto"
        >
          {suggestions.map(({ id, text, contentType, description }, index) => (
            <button
              key={id}
              className="box-border flex items-center justify-between w-full h-12 pl-3 pr-2 border-b border-purple/10 md:pr-3"
              data-testid="AutoCompleteItem"
              onClick={() => {
                if (typeof onClick === 'function') {
                  onClick(id, text, contentType, index);
                }
              }}
            >
              <span className="w-full text-sm text-left truncate text-purple md:text-md">
                {text}
              </span>
              <span className="flex-shrink-0 ml-6 text-right text-3xs text-purple/60 md:text-2xs">
                {contentType === ContentType['ContentTag']
                  ? description
                  : intl.formatMessage(contentTypeMap(contentType))}
              </span>
            </button>
          ))}
        </ScrollContainer>
      )}
    </div>
  );
};

export default AutoComplete;
