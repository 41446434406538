import { createContext, FC, ReactNode, useContext, useRef } from 'react';

type PlayerSeek = (value: number) => void;

interface ContextProps {
  playerSeek: PlayerSeek;
  registerPlayerSeek: (cb: PlayerSeek) => void;
}
const PlayerControlContext = createContext({} as ContextProps);

export const PlayerControlContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const playerSeekRef = useRef<PlayerSeek>();
  const registerPlayerSeek = (cb: PlayerSeek) => (playerSeekRef.current = cb);
  const playerSeek = (value: number) => playerSeekRef.current?.(value);

  return (
    <PlayerControlContext.Provider value={{ playerSeek, registerPlayerSeek }}>
      <>{children}</>
    </PlayerControlContext.Provider>
  );
};

export const usePlayerControl = (): ContextProps => {
  const context = useContext(PlayerControlContext);
  if (!context) {
    throw new Error(
      'usePlayerControl must be rendered within the PlayerControl.Provider'
    );
  }
  return context;
};

export default PlayerControlContext;
