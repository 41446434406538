import classNames from 'classnames';
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  HTMLAttributes,
  MouseEventHandler,
} from 'react';
import { twMerge } from 'tailwind-merge';
import Icon, { Icons } from '../../Icon';

type Variants =
  | 'PRIMARY'
  | 'SECONDARY'
  | 'THIN_SECONDARY'
  | 'BOTTOM_SHEET'
  | 'TEXT'
  | 'TRANSPARENT'
  | 'CHAPTER_STAGE'
  | 'VR';

const VARIANTS_MAP: Record<Variants, string> = {
  PRIMARY: 'bg-pink hover:bg-pink/90 text-white',
  SECONDARY: 'border border-pink/80 hover:bg-pink/20 text-pink',
  THIN_SECONDARY: 'border border-pink/80 hover:bg-pink/20 text-pink h-8',
  BOTTOM_SHEET: 'bg-purple/10 text-purple hover:bg-purple/5',
  TEXT: 'hover:bg-purple/10 text-purple/80',
  TRANSPARENT:
    'border border-white text-white bg-transparent hover:bg-white/60 active:bg-white/90 active:text-purple',
  CHAPTER_STAGE:
    'bg-purple/5 text-purple text-xs font-normal hover:bg-purple/5 rounded-xl h-10',
  VR: 'bg-purple text-white',
};

const SUBTEXT_MAP: Record<
  Extract<Variants, 'PRIMARY' | 'CHAPTER_STAGE'>,
  string
> = {
  PRIMARY: 'ml-2 font-medium text-3xs md:text-2xs text-white/80',
  CHAPTER_STAGE: 'ml-1 font-normal text-3xs md:text-2xs text-purple/60',
};

type HTMLType = ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement> &
  HTMLAttributes<HTMLDivElement>;

export interface ButtonProps extends HTMLType {
  variant?: Variants;
  text: string;
  subText?: string;
  leftIconName?: Icons;
  rightIconName?: Icons;
  component?: 'button' | 'a' | 'div';
  color?: string;
  appendClassName?: string;
  onClick?: MouseEventHandler;
}

const Button = React.forwardRef<null, ButtonProps>(
  (
    {
      variant = 'PRIMARY',
      text,
      subText,
      leftIconName,
      rightIconName,
      onClick,
      component = 'button',
      color,
      appendClassName,
      ...props
    },
    ref
  ) => {
    const Component = component;
    return (
      <Component
        className={twMerge(
          'flex items-center w-full px-2.5 h-11 text-sm transition-colors rounded-md md:text-md font-semibold whitespace-nowrap cursor-pointer',
          VARIANTS_MAP[variant],
          appendClassName
        )}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        {leftIconName && (
          <Icon
            name={leftIconName}
            size={variant === 'THIN_SECONDARY' ? 'DEFAULT' : 'BIGGER'}
          />
        )}
        <span
          className={classNames(
            `flex items-center justify-center flex-1 leading-5`,
            color
          )}
        >
          {text}
          {(variant === 'PRIMARY' || variant === 'CHAPTER_STAGE') && subText ? (
            <span className={SUBTEXT_MAP[variant]}>{subText}</span>
          ) : null}
        </span>

        {rightIconName && <Icon name={rightIconName} size="BIG" />}
      </Component>
    );
  }
);

Button.displayName = 'Button';
export default Button;
