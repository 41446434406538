import BeemiLogo from '@/assets/icons/logo/Logo_BEEMI.svg';
import { FC } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import Dialog from '..';
import { globalMessages } from '../../../../constants/messages';
import Button, { ButtonProps } from '../../../Button/Button';

const messages = {
  title: defineMessage({
    id: 'r18.title',
    defaultMessage: 'あなたは18歳以上ですか？',
  }),
  description: defineMessage({
    id: 'r18.description',
    defaultMessage: '成人向けコンテンツを{br}含んでおりますのでご注意ください',
  }),
  footnoteTitle: defineMessage({
    id: 'r18.footnoteTitle',
    defaultMessage: '月額プランで2万本の動画が見放題 ！！',
  }),
  footnoteDescription: defineMessage({
    id: 'r18.footnoteDescription',
    defaultMessage:
      'BEEMIは、東証一部上場企業のグループ会社U-NEXTが{br}運営する日本最大級の動画配信サービスです。',
  }),
};

export interface R18ConfirmationProps {
  isShowing: boolean;
  onClickYes?: ButtonProps['onClick'];
  noButtonHref: string;
}

const R18Confirmation: FC<R18ConfirmationProps> = ({
  isShowing,
  onClickYes,
  noButtonHref,
}) => {
  const intl = useIntl();
  return (
    <Dialog
      enableLockBodyScroll
      data-cy="dialog-r18confirmation"
      isShowing={isShowing}
      variant="R18"
      overlayVariant="DEEP_BLUR"
      animationType={{
        enter: 'none',
        leave: 'fade',
      }}
    >
      <div className="text-center ">
        <div className="flex flex-col items-center justify-center px-4 pt-10 md:pt-12 md:px-12">
          <BeemiLogo className="h-12" />
          <div className="mt-4 md:mt-6 text-strong whitespace-nowrap">
            {intl.formatMessage(messages.description, {
              br: <br className="md:hidden" />,
            })}
          </div>
          <div className="mt-2 md:mt-4 text-sub-headline">
            {intl.formatMessage(messages.title)}
          </div>
          <div className="md:flex md:space-x-4 w-full mt-6 md:mt-8 space-y-1.5 md:space-y-0">
            <Button
              text={intl.formatMessage(globalMessages.yes)}
              onClick={onClickYes}
            />
            <Button
              component="a"
              text={intl.formatMessage(globalMessages.no)}
              variant="SECONDARY"
              href={noButtonHref}
            />
          </div>
        </div>
        <div className="px-4 py-6 mt-6 md:mt-8 md:py-8 md:px-8 bg-purple/5 text-purple/80">
          <div className="text-strong">
            {intl.formatMessage(messages.footnoteTitle)}
          </div>
          <div className="mt-2 text-label">
            {intl.formatMessage(messages.footnoteDescription, { br: <br /> })}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default R18Confirmation;
