import Icon from '@/shared/components/Icon';
import classNames from 'classnames';

type COLOR = 'LIGHT_PURPLE' | 'DEEP_PURPLE';

export interface LikeProps {
  likes: number;
  color?: COLOR;
}

const COLOR_MAP: Record<COLOR, string> = {
  LIGHT_PURPLE: 'text-purple/60',
  DEEP_PURPLE: 'text-purple/80',
};

const Like: React.FC<LikeProps> = ({ likes, color = 'LIGHT_PURPLE' }) => {
  return (
    <div
      className={classNames(
        'inline-flex items-center text-label space-x-0.5',
        COLOR_MAP[color]
      )}
    >
      <Icon name="THUMBS_UP" size="SMALL" className="mr-0.5" />
      {likes}
    </div>
  );
};

export default Like;
