import Button from '@/shared/components/Button/Button';
import { globalMessages } from '@/shared/constants/messages';
import Link from 'next/link';
import { FC } from 'react';
import { useIntl } from 'react-intl';

const Buttons: FC = () => {
  const intl = useIntl();
  return (
    <div className="flex flex-col space-y-2 w-60">
      <Link href="/mypage" passHref legacyBehavior>
        <Button text={intl.formatMessage(globalMessages.myPage)} />
      </Link>
      <Button
        component="a"
        href={process.env.NEXT_PUBLIC_HELP_URL}
        text={intl.formatMessage(globalMessages.helpCenter)}
      />
      <Link href="/store" passHref legacyBehavior>
        <Button
          variant="SECONDARY"
          text={intl.formatMessage(globalMessages.backToTop)}
        />
      </Link>
    </div>
  );
};

export default Buttons;
