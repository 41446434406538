import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';

interface SynopsisProps {
  synopsis: string;
  saleStartDate: Date | undefined | null;
  adultProductCode: string | undefined | null;
}
const messages = {
  adultProductCode: defineMessage({
    id: 'package.adultProductCode',
    defaultMessage: '品番: {adultProductCode}',
  }),
  saleStart: defineMessage({
    id: 'package.saleStart',
    defaultMessage: '発売日: {saleStartDate}',
  }),
};

const Synopsis: FC<SynopsisProps> = ({
  synopsis,
  saleStartDate,
  adultProductCode,
}) => {
  const intl = useIntl();
  const [showAll, setShowAll] = useState(false);
  const [needShowAllbutton, setNeedShowAllbutton] = useState(false);
  const synopsisRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const synopsisEl = synopsisRef.current;
    if (!synopsisEl) return;
    setNeedShowAllbutton(synopsisEl?.scrollHeight > synopsisEl?.clientHeight);
  }, []);

  return (
    <section className="relative">
      <h2 className="text-sub-headline">
        {intl.formatMessage({
          id: 'package.synopsis',
          defaultMessage: '概要',
        })}
      </h2>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <p
        onClick={() => needShowAllbutton && setShowAll((showAll) => !showAll)}
        ref={synopsisRef}
        className={classNames(
          'text-caption mt-2 text-purple/80',
          showAll ? 'line-clamp-none' : 'line-clamp-5'
        )}
      >
        {synopsis}
      </p>
      {adultProductCode && (showAll || !needShowAllbutton) && (
        <p className="block mt-2 text-caption text-purple/80">
          {intl.formatMessage(messages.adultProductCode, {
            adultProductCode,
          })}
        </p>
      )}
      {needShowAllbutton && (
        <button
          className={classNames(
            'absolute font-semibold text-xs md:text-sm bg-white right-0',
            showAll ? 'mt-2' : '-mt-4 shadow-showAll'
          )}
          onClick={() => setShowAll((showAll) => !showAll)}
        >
          {showAll
            ? intl.formatMessage({
                id: 'package.showLess',
                defaultMessage: '閉じる',
              })
            : intl.formatMessage({
                id: 'package.showAll',
                defaultMessage: 'すべて表示',
              })}
        </button>
      )}
      {saleStartDate && (
        <div
          className={classNames(
            'text-caption text-purple/80',
            showAll ? 'mt-8' : 'mt-2'
          )}
        >
          {intl.formatMessage(messages.saleStart, {
            saleStartDate: intl.formatDate(saleStartDate, {
              year: 'numeric',
              month: 'long',
            }),
          })}
        </div>
      )}
    </section>
  );
};

export default Synopsis;
