import SearchButton from '@/shared/components/Button/SearchButton';
import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import { useSearchDialog } from '@/shared/SearchDialogContext';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

interface DesktopHeaderProps {
  title?: ReactNode;
}

const DesktopHeader: React.FC<DesktopHeaderProps> = ({ title }) => {
  const isLg = useTailwindBreakpoint('lg');
  const intl = useIntl();
  const { showSearchDialog, updateSearchDialog } = useSearchDialog();
  if (!isLg) return null;
  return (
    <header className="fixed top-0 left-0 z-50 w-screen pl-60">
      <div className="flex items-center w-full h-12 px-4 py-2 border-b bg-white/80 backdrop-blur-sm">
        {!!title && <h1 className="text-purple/80 text-strong">{title}</h1>}
        <div className="ml-auto">
          <SearchButton
            text={
              showSearchDialog
                ? intl.formatMessage({
                    id: 'header.close',
                    defaultMessage: '閉じる',
                  })
                : intl.formatMessage({
                    id: 'header.search',
                    defaultMessage: '探す',
                  })
            }
            iconName={showSearchDialog ? 'CLOSE_ICON' : 'SEARCH_ICON'}
            onClick={() => updateSearchDialog(!showSearchDialog)}
          />
        </div>
      </div>
    </header>
  );
};

export default DesktopHeader;
