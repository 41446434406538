import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import { FC, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import Button from '../../Button/Button';
import ActionSheet from '../ActionSheet';
import BottomSheet from '../BottomSheet/index';
import DialogIframe from '../Dialog/Iframe';

const messages = {
  title: defineMessage({
    id: 'share.bottomsheet.title',
    defaultMessage: '共有',
  }),
  twitter: defineMessage({
    id: 'share.buttons.twitter',
    defaultMessage: 'Twitter',
  }),
  facebook: defineMessage({
    id: 'share.buttons.facebook',
    defaultMessage: 'Facebook',
  }),
  hatena: defineMessage({
    id: 'share.buttons.hatena',
    defaultMessage: 'はてブ',
  }),
  embed: defineMessage({
    id: 'share.buttons.embed',
    defaultMessage: '埋め込む',
  }),
  webShare: defineMessage({
    id: 'share.buttons.webShare',
    defaultMessage: 'その他',
  }),
  shareText: defineMessage({
    id: 'share.text',
    defaultMessage: '{titleName}・BEEMI',
  }),
  shareTwitterText: defineMessage({
    id: 'share.twitter.text',
    defaultMessage: '「{titleName}」をBEEMIで視聴',
  }),
  shareTwitterHashtag: defineMessage({
    id: 'share.hashtag',
    defaultMessage: 'BEEMI',
  }),
};
const TWITTER_VIA = 'BEEMI_official'; // twitter accout name

const getTwitterShareUrl = ({
  shareUrl,
  shareText,
  hashTag,
}: {
  shareUrl: string;
  shareText: string;
  hashTag: string;
}) => {
  const encodeTwitterText = encodeURIComponent(shareText);
  return `https://twitter.com/share?url=${shareUrl}&text=${encodeTwitterText}&count=horizontal&via=${TWITTER_VIA}&hashtags=${hashTag}`;
};

const getFacebookShareUrl = ({
  shareUrl,
  shareText,
}: {
  shareUrl: string;
  shareText: string;
}) => {
  const encodeFacebookText = encodeURIComponent(shareText);
  return `https://www.facebook.com/sharer.php?u=${shareUrl}&t=${encodeFacebookText}`;
};

const getHatenaShareUrl = ({
  shareUrl,
  shareText,
}: {
  shareUrl: string;
  shareText: string;
}) => {
  const encodeHatenaText = encodeURIComponent(shareText);
  return `https://b.hatena.ne.jp/add?mode=confirm&title=${encodeHatenaText}&url=${shareUrl}`;
};

const onClickWebShare = ({
  shareUrl,
  shareTitle,
  shareText,
  onSuccess,
  onError,
}: {
  shareUrl: string;
  shareTitle: string;
  shareText: string;
  onSuccess: () => void;
  onError: () => void;
}) => {
  navigator
    ?.share({
      url: shareUrl,
      title: shareTitle,
      text: shareText,
    })
    .then(() => {
      onSuccess();
    })
    .catch(() => {
      onError();
    });
};

export interface DialogShareProps {
  isShowing: boolean;
  embedCode: string;
  shareUrl: string;
  shareTitle: string;
  onCancel: () => void;
}

const DialogShare: FC<DialogShareProps> = (props) => {
  const intl = useIntl();
  const [isShowingIframe, setIsShowingIframe] = useState(false);
  const isMd = useTailwindBreakpoint('md');

  const buttonGroup = (
    <>
      <Button
        variant="BOTTOM_SHEET"
        text={intl.formatMessage(messages.twitter)}
        leftIconName="TWITTER"
        component="a"
        target="_blank"
        rel="noopener noreferrer"
        href={getTwitterShareUrl({
          shareUrl: props.shareUrl,
          shareText: intl.formatMessage(messages.shareTwitterText, {
            titleName: props.shareTitle,
          }),
          hashTag: intl.formatMessage(messages.shareTwitterHashtag),
        })}
        onClick={props.onCancel}
      />
      <Button
        variant="BOTTOM_SHEET"
        text={intl.formatMessage(messages.facebook)}
        leftIconName="FACEBOOK"
        component="a"
        target="_blank"
        rel="noopener noreferrer"
        href={getFacebookShareUrl({
          shareUrl: props.shareUrl,
          shareText: intl.formatMessage(messages.shareText, {
            titleName: props.shareTitle,
          }),
        })}
        onClick={props.onCancel}
      />
      <Button
        variant="BOTTOM_SHEET"
        text={intl.formatMessage(messages.hatena)}
        leftIconName="HATENA"
        component="a"
        target="_blank"
        rel="noopener noreferrer"
        href={getHatenaShareUrl({
          shareUrl: props.shareUrl,
          shareText: intl.formatMessage(messages.shareText, {
            titleName: props.shareTitle,
          }),
        })}
        onClick={props.onCancel}
      />
      <Button
        variant="BOTTOM_SHEET"
        text={intl.formatMessage(messages.embed)}
        leftIconName="CODE_ICON"
        onClick={() => {
          setIsShowingIframe(true);
          props.onCancel();
        }}
      />

      {typeof navigator !== 'undefined' && Boolean(navigator?.share) && (
        <Button
          variant="BOTTOM_SHEET"
          text={intl.formatMessage(messages.webShare)}
          leftIconName="HORIZONTAL_ELLIPSIS_ICON"
          onClick={() => {
            onClickWebShare({
              shareUrl: props.shareUrl,
              shareTitle: intl.formatMessage(messages.shareText, {
                titleName: props.shareTitle,
              }),
              shareText: intl.formatMessage(messages.shareText, {
                titleName: props.shareTitle,
              }),
              onSuccess: props.onCancel,
              onError: props.onCancel,
            });
          }}
        />
      )}
    </>
  );

  return (
    <>
      {isMd ? (
        <>
          {props.isShowing && (
            <ActionSheet onClickOutside={props.onCancel}>
              {buttonGroup}
            </ActionSheet>
          )}
        </>
      ) : (
        <BottomSheet
          title={intl.formatMessage(messages.title)}
          isShowing={props.isShowing && !isShowingIframe}
          onCancel={props.onCancel}
          onClickOutside={props.onCancel}
        >
          {buttonGroup}
        </BottomSheet>
      )}

      <DialogIframe
        isShowing={isShowingIframe}
        embedCode={props.embedCode}
        onCancel={() => {
          setIsShowingIframe(false);
        }}
        onClickOutside={() => {
          setIsShowingIframe(false);
        }}
      />
    </>
  );
};

export default DialogShare;
