import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';

const PackageLoading: React.FC = () => {
  const isMd = useTailwindBreakpoint('md');
  return isMd ? (
    <div className="relative">
      <div
        className="absolute w-full h-60"
        style={{
          background:
            'linear-gradient(180deg, rgba(43, 38, 71, 0.1) 0%, rgba(43, 38, 71, 0) 100%)',
        }}
      />
      <div className="flex px-20 pt-16">
        <div className="relative z-10 mr-12 bg-white border rounded-md shadow-sm w-50 h-70 border-purple/10" />
        <div className="flex-grow pt-2">
          <div className="w-auto h-5 rounded bg-purple/10 animate-pulse" />
          <div className="w-9/12 h-5 mt-3 rounded bg-purple/10 animate-pulse" />
          <div className="flex w-4/5 mt-20">
            <div className="w-full mr-2 rounded h-11 bg-purple/10 animate-pulse" />
            <div className="w-full rounded h-11 bg-purple/10 animate-pulse" />
          </div>
          <div className="flex mt-6 ml-4 gap-x-8">
            <div className="w-12 rounded h-14 bg-purple/10 animate-pulse" />
            <div className="w-12 rounded h-14 bg-purple/10 animate-pulse" />
            <div className="w-12 rounded h-14 bg-purple/10 animate-pulse" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="relative">
      <div className="absolute z-10 w-40 h-56 -translate-x-1/2 bg-white border rounded-md shadow-sm border-purple/10 top-12 left-1/2" />
      <div className="w-auto h-50 bg-purple/10 animate-pulse" />
      <div className="pt-25 px-7">
        <div className="w-auto h-4 rounded bg-purple/10 animate-pulse" />
        <div className="w-9/12 h-4 mt-3 rounded bg-purple/10 animate-pulse" />
        <div className="flex mt-10">
          <div className="w-full mr-2 rounded h-11 bg-purple/10 animate-pulse" />
          <div className="w-full rounded h-11 bg-purple/10 animate-pulse" />
        </div>
        <div className="flex justify-center mt-12 gap-x-12">
          <div className="w-10 h-12 rounded bg-purple/10 animate-pulse" />
          <div className="w-10 h-12 rounded bg-purple/10 animate-pulse" />
          <div className="w-10 h-12 rounded bg-purple/10 animate-pulse" />
        </div>
      </div>
    </div>
  );
};

export default PackageLoading;
