import Overlay from '@/shared/components/Modality/Overlay';
import ScrollContainer from '@/shared/components/ScrollContainer';
import { animated, useTransition, UseTransitionProps } from '@react-spring/web';
import classNames from 'classnames';
import React, {
  ComponentProps,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { MODAL_ROOT } from '../constants';

type Variants = 'SIMPLE_DIALOG' | 'R18' | 'PURCHASE' | 'SEARCH_FILTER';

const ANIMATED_DIV_MAP: Record<Variants, string> = {
  SIMPLE_DIALOG:
    'w-full px-4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
  R18: 'w-full px-4 md:w-auto top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
  PURCHASE:
    'w-full px-4 lg:py-8 2xl:py-12 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
  SEARCH_FILTER:
    'h-screen md:h-auto w-full top-0 left-0 md:top-1/2 md:left-1/2 md:-translate-x-1/2 md:-translate-y-1/2',
};

const CONTAINER_DIV_MAP: Record<Variants, string> = {
  SIMPLE_DIALOG: 'bg-white my-10 rounded-2xl md:max-w-xl',
  R18: 'bg-white my-10 rounded-2xl',
  PURCHASE: 'bg-white my-16 rounded-2xl lg:max-w-[832px] 2xl:max-w-[1056px]',
  SEARCH_FILTER: 'min-h-full bg-white md:my-10 md:rounded-2xl md:max-w-144',
};

type AnimationType = 'slide' | 'fade' | 'none';
type AnimationTypes = {
  enter: AnimationType;
  leave: AnimationType;
};

export interface DialogProps {
  isShowing: boolean;
  overlayVariant: ComponentProps<typeof Overlay>['variant'];
  variant?: Variants;
  appendClassName?: string;
  enableLockBodyScroll?: boolean;
  animationType?: AnimationType | AnimationTypes;
  'data-cy'?: string;
  onClickOutside?: () => void;
  children?: ReactNode;
}

const slideTransition: UseTransitionProps = {
  from: { y: '120%', immediate: false },
  enter: { y: '0%', immediate: false },
  leave: { y: '300%', immediate: false },
};
const fadeTransition: UseTransitionProps = {
  from: { opacity: 0, immediate: false },
  enter: { opacity: 1, immediate: false },
  leave: { opacity: 0, immediate: false },
};

function getAnimationProps(animationType: AnimationType): UseTransitionProps {
  switch (animationType) {
    case 'none':
      return {
        from: { immediate: true },
        enter: { immediate: true },
        leave: { immediate: true },
      };
    case 'fade':
      return fadeTransition;

    case 'slide':
    default:
      return slideTransition;
  }
}

function getAnimationsProps(
  animationTypes: AnimationTypes
): UseTransitionProps {
  const enter = getAnimationProps(animationTypes.enter);
  const leave = getAnimationProps(animationTypes.leave);
  return {
    from: {
      ...leave.from,
      ...enter.from,
    },
    enter: {
      ...leave.enter,
      ...enter.enter,
    },
    leave: leave.leave,
  };
}

const Dialog: React.FC<DialogProps> = ({
  isShowing,
  overlayVariant,
  children,
  variant = 'SIMPLE_DIALOG',
  appendClassName,
  animationType = 'slide',
  'data-cy': dataCy,
  onClickOutside,
}) => {
  const animationProps =
    typeof animationType === 'string'
      ? getAnimationProps(animationType)
      : getAnimationsProps(animationType);

  const transitions = useTransition(isShowing, animationProps);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback<MouseEventHandler<HTMLDivElement>>(
    (event) => {
      if (event.target === containerRef.current) {
        onClickOutside?.();
      }
    },
    [onClickOutside]
  );

  const isBrowser = typeof window !== 'undefined';

  return isBrowser
    ? createPortal(
        <>
          {isShowing && <Overlay variant={overlayVariant} />}
          {transitions(
            (styles, item) =>
              item && (
                <ScrollContainer
                  as="div"
                  data-cy={dataCy}
                  className={classNames(
                    `fixed z-75 max-h-screen overflow-y-auto`,
                    ANIMATED_DIV_MAP[variant]
                  )}
                  onClick={handleClickOutside}
                  ref={containerRef}
                >
                  <animated.div
                    style={styles}
                    className={classNames(
                      'shadow-mobile md:shadow-desktop mx-auto',
                      CONTAINER_DIV_MAP[variant],
                      appendClassName
                    )}
                  >
                    {children}
                  </animated.div>
                </ScrollContainer>
              )
          )}
        </>,
        document.getElementById(MODAL_ROOT) as Element
      )
    : null;
};

export default Dialog;
