import useIntentUrl from '@/domain/package/useIntentUrl';
import Button from '@/shared/components/Button/Button';
import {
  ANDROID_OS_NAME,
  NEXT_BROWSER_2D_USER_AGENT,
  NEXT_BROWSER_USER_AGENT,
} from '@/shared/constants';
import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import UserAgentContext from '@/shared/UserAgentContext';
import { useSupportXRWithLayers } from '@u-next/methane-player-react';
import Bowser from 'bowser';
import { MutableRefObject, useContext } from 'react';
import { defineMessage, useIntl } from 'react-intl';

const messages = {
  VrPlay: defineMessage({
    id: 'VrPlay',
    defaultMessage: 'VRで再生',
  }),
};

interface Props {
  enterXRSessionRef: MutableRefObject<(() => void) | undefined>;
}

const VRGuideArea: React.FC<Props> = ({ enterXRSessionRef }) => {
  const intl = useIntl();

  const { userAgent } = useContext(UserAgentContext);
  const bowser = Bowser.parse(userAgent);

  const { supportXR } = useSupportXRWithLayers();
  const isNextBrowser = userAgent.includes(NEXT_BROWSER_USER_AGENT);
  const isNextBrowser2D = userAgent.includes(NEXT_BROWSER_2D_USER_AGENT);
  const isAndroid =
    bowser.os.name?.toLowerCase() === ANDROID_OS_NAME || isNextBrowser;

  const intentUrl = useIntentUrl({ type: 'nextbrowservr' });

  const isMd = useTailwindBreakpoint('md');

  if (isAndroid) {
    if (isNextBrowser) {
      if (isNextBrowser2D) {
        // TODO WF-11127 confirm if intent url is correct
        return !isMd ? (
          <a className="block mb-8 px-7 md:px-20" href={intentUrl}>
            <Button
              variant="VR"
              text={intl.formatMessage(messages.VrPlay)}
              leftIconName="VR_ICON"
            />
          </a>
        ) : null;
      } else {
        // already in NextBrowser VR mode
        return null;
      }
    } else {
      return (
        // TODO WF-10980 add link
        <a className="block mb-8 px-7 md:px-20">
          <img
            className="w-full rounded-md"
            src="/img/next_browser_app_vr_banner.png"
            alt={intl.formatMessage({
              id: 'watchVrOnUnextBrowserApp',
              defaultMessage:
                'スマホでBEEMIの動画を視聴しよう。U-NEXT BROWSER アプリ',
            })}
          />
        </a>
      );
    }
  } else if (supportXR) {
    return !isMd ? (
      <div className="mb-8 px-7 md:px-20">
        <Button
          variant="VR"
          text={intl.formatMessage(messages.VrPlay)}
          leftIconName="VR_ICON"
          onClick={() => {
            enterXRSessionRef.current?.();
          }}
        />
      </div>
    ) : null;
  }

  return null;
};

export default VRGuideArea;
