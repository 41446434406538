import Overlay from '@/shared/components/Modality/Overlay';
import { animated, useTransition } from '@react-spring/web';
import React, {
  MouseEventHandler,
  ReactNode,
  useCallback,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';
import { defineMessage, useIntl } from 'react-intl';
import Button from '../../Button/Button';
import { MODAL_ROOT } from '../constants';

const messages = {
  cancel: defineMessage({
    id: 'card.cancel',
    defaultMessage: 'キャンセル',
  }),
};

export interface BottomSheetProps {
  title: string;
  isShowing: boolean;
  onCancel: () => void;
  onClickOutside: () => void;
  children?: ReactNode;
}

/* BottomSheet is only for Mobile */
const BottomSheet: React.FC<BottomSheetProps> = ({
  title,
  isShowing,
  children,
  onClickOutside,
  onCancel,
}) => {
  const intl = useIntl();
  const transitions = useTransition(isShowing, {
    from: { y: '120%' },
    enter: { y: '0%' },
    leave: { y: '120%' },
  });

  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (onClickOutside) {
        /**
         * Why `e.stopPropagation()`?
         *
         * Given PackageDetail Dialog is open, when clicking outside the BottomSheet,
         * the onClick event listener in Dialog (parent of PackageDetail) will be executed first
         * which will close the PackageDialog instead of just closing the BottomSheet.
         *
         * Previous implementation were using `useClickOutside`.
         * However, useClickOutside works by adding the eventListener on the body tag, which of course will get the event propagated last.
         * Therefor adding an event lister directly to the div the user will click on and then stop the event propagation,
         * will stop the event from reaching to any other event listener.
         *
         * PR source: https://github.com/u-next/beemi-web/pull/237#discussion_r916519682
         */
        e.stopPropagation();
        onClickOutside();
      }
    },
    [onClickOutside]
  );

  return isShowing
    ? createPortal(
        <>
          <Overlay variant="DARK_PURPLE" onClick={handleClickOutside} />
          {transitions(
            (styles, item) =>
              item && (
                <animated.div
                  style={styles}
                  className="fixed bottom-0 left-0 w-full z-100"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div
                    className="relative flex flex-col items-center py-2 bg-white shadow-mobile rounded-t-2xl"
                    ref={containerRef}
                  >
                    <span className="w-full px-6 py-4 text-center break-words text-caption text-purple/80">
                      {title}
                    </span>
                    <div className="w-full px-4 space-y-1.5">
                      {children}
                      <Button
                        variant="TEXT"
                        text={intl.formatMessage(messages.cancel)}
                        onClick={onCancel}
                      />
                    </div>
                  </div>
                </animated.div>
              )
          )}
        </>,
        document.getElementById(MODAL_ROOT) as Element
      )
    : null;
};

export default BottomSheet;
