import Icon from '@/shared/components/Icon';
import { globalMessages } from '@/shared/constants/messages';
import { defineMessage, useIntl } from 'react-intl';

const messages = {
  title: defineMessage({
    id: 'vrAlert.title',
    defaultMessage: 'こちらはVR作品です',
  }),
  description: defineMessage({
    id: 'vrAlert.description',
    defaultMessage: 'VR対応デバイスでお楽しみいただけます。',
  }),
  deviceListLink: defineMessage({
    id: 'vrAlert.deviceListLink',
    defaultMessage: 'デバイス一覧はこちら',
  }),
};

const VRAlert = (): JSX.Element => {
  const intl = useIntl();
  return (
    <div className="flex flex-col items-center border border-solid rounded md:justify-between md:flex-row">
      <div className="flex flex-col items-center mt-5 md:items-start md:ml-5 md:my-3 ">
        <p className="text-body">{intl.formatMessage(messages.title)}</p>
        <p className="flex flex-col items-center text-label text-purple/60 md:items-start">
          <span>{intl.formatMessage(messages.description)}</span>
          {/* TODO WF-11123 replace URL of device list */}
          <a className="underline " href="/hogehoge">
            {intl.formatMessage(messages.deviceListLink)}
          </a>
        </p>
      </div>
      <a
        className="flex items-center px-2 py-1 mt-3 mb-4 rounded-md bg-purple/10 md:my-0 md:mr-5 shrink-0 "
        href="/testplay"
      >
        <span className="text-caption">
          {intl.formatMessage(globalMessages.playTestVideo)}
        </span>
        <Icon name="NEW_TAB_ICON" size="DEFAULT" />
      </a>
    </div>
  );
};

export default VRAlert;
