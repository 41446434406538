import Button from '@/shared/components/Button/Button';
import type { ComponentProps, FC } from 'react';
import { defineMessage, useIntl } from 'react-intl';

const messages = {
  register: defineMessage({
    id: 'player.register',
    defaultMessage: 'アカウント登録',
  }),
  login: defineMessage({
    id: 'player.login',
    defaultMessage: 'ログイン',
  }),
};

interface Props {
  onRegisterClick: ComponentProps<typeof Button>['onClick'];
  onLoginClick: ComponentProps<typeof Button>['onClick'];
}

const PlayerLoginButtons: FC<Props> = ({ onRegisterClick, onLoginClick }) => {
  const intl = useIntl();
  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <Button
        text={intl.formatMessage(messages.register)}
        variant="TRANSPARENT"
        appendClassName="w-40 md:w-60"
        onClick={onRegisterClick}
      />
      <Button
        text={intl.formatMessage(messages.login)}
        variant="TRANSPARENT"
        appendClassName="w-40 md:w-60"
        onClick={onLoginClick}
      />
    </div>
  );
};

export default PlayerLoginButtons;
