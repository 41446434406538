import { useLockBodyScroll } from '@/shared/hooks/useLockBodyScroll';
import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import { animated, useTransition } from '@react-spring/web';
import React, { ReactNode, useRef } from 'react';

export interface SearchDialogProps {
  isShowing: boolean;
  children?: ReactNode;
}

const SearchDialog: React.FC<SearchDialogProps> = ({ isShowing, children }) => {
  const isMd = useTailwindBreakpoint('md');
  const transitions = useTransition(isShowing, {
    from: { y: '120%' },
    enter: { y: '0%' },
    leave: { y: '300%' },
    immediate: isMd,
  });
  const containerRef = useRef<HTMLDivElement>(null);
  useLockBodyScroll(!!isShowing && !isMd, undefined, containerRef.current);

  return transitions(
    (styles, item) =>
      item && (
        <>
          <animated.div
            ref={containerRef}
            style={styles}
            className="fixed inset-0 z-40 flex flex-col h-screen overflow-y-auto bg-white md:z-20 md:h-auto lg:ml-60 pb-96"
          >
            {children}
          </animated.div>
        </>
      )
  );
};

export default SearchDialog;
