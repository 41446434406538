import Icon from '@/shared/components/Icon';
import { useLockBodyScroll } from '@/shared/hooks/useLockBodyScroll';
import {
  animated,
  useChain,
  useSpringRef,
  useTransition,
} from '@react-spring/web';
import classNames from 'classnames';
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  'data-cy'?: string;
  id: string;
  onClose: () => void;
  isSsr: boolean;
  isCloseButtonDark?: boolean;
  children?: ReactNode;
}

const DetailModal: FC<Props> = ({
  children,
  id,
  'data-cy': dataCy,
  onClose,
  isSsr,
  isCloseButtonDark = false,
}) => {
  const [isShowing, setIsShowing] = useState(true);
  const fadeRef = useSpringRef();
  const fadeTransition = useTransition(isShowing, {
    ref: fadeRef,
    onRest: isShowing ? undefined : onClose,
    config: {
      duration: isShowing ? 200 : 150,
    },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    immediate: isShowing && isSsr,
  });
  const slideRef = useSpringRef();
  const slideTransition = useTransition(isShowing, {
    ref: slideRef,
    config: {
      duration: isShowing ? 200 : 150,
    },
    from: { transform: 'translate3d(0, 100%, 0)' },
    enter: { transform: 'translate3d(0, 0%, 0)' },
    leave: { transform: 'translate3d(0, 100%, 0)' },
    immediate: isShowing && isSsr,
  });
  useChain([fadeRef, slideRef], isShowing ? [0, 0.2] : [0.1, 0]);
  const containerRef = useRef<HTMLDivElement>(null);
  /**
   * we need to use state instead of ref here because,
   * we want to make sure useLockBodyScroll is called again after scrollContainer is rendered
   */
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(
    null
  );

  useLockBodyScroll(isShowing, undefined, scrollContainer);

  useEffect(() => {
    // reset modal scroll position if id (packageId/chapterId) changes
    scrollContainer?.scrollTo(0, 0);
  }, [id, scrollContainer]);

  const closeModal = useCallback(() => {
    setIsShowing(false);
  }, []);

  const handleClickOutside: React.MouseEventHandler<HTMLDivElement> =
    useCallback(
      (event) => {
        if (event.target === scrollContainer) {
          closeModal();
        }
      },
      [closeModal, scrollContainer]
    );

  return fadeTransition(
    (fadeProps, fadeItem) =>
      fadeItem && (
        <animated.div
          className="fixed inset-0 w-full z-75 bg-purple/60 backdrop-blur-xs"
          style={fadeProps}
        >
          {slideTransition(
            (slideProps, slideItem) =>
              slideItem && (
                <animated.div
                  data-cy={dataCy}
                  ref={setScrollContainer}
                  style={slideProps}
                  className="absolute w-full max-h-screen overflow-y-scroll z-75 lg:py-8 2xl:py-12"
                  onClick={handleClickOutside}
                >
                  <div
                    ref={containerRef}
                    className="relative min-h-screen mx-auto bg-white shadow-mobile md:shadow-desktop lg:rounded-2xl lg:max-w-3xl 2xl:max-w-5xl"
                  >
                    {children}
                    <button
                      className={classNames(
                        'absolute z-10 transition-colors w-11 h-11 top-4 left-4',
                        isCloseButtonDark
                          ? 'text-purple/80 hover:text-purple'
                          : 'text-white/80 hover:text-white'
                      )}
                      onClick={closeModal}
                      data-testid="detailModal-button-close"
                    >
                      <Icon name="CLOSE_ICON" size="FULL" />
                    </button>
                  </div>
                </animated.div>
              )
          )}
        </animated.div>
      )
  );
};

export default DetailModal;
