import {
  KafkaContext,
  KafkaPropsWithBaseSchema,
} from '@/domain/log/KafkaContext';
import type { LogBody } from '@/domain/log/__types__/logBody';
import type { PackageDetailRecommendTitleDetailLog } from '@/domain/log/__types__/packageDetail-recommendTitle-detail';
import type { PackageDetailRecommendTitleMyListLog } from '@/domain/log/__types__/packageDetail-recommendTitle-myList';
import type { PackageDetailRecommendTitleSamplePlayLog } from '@/domain/log/__types__/packageDetail-recommendTitle-samplePlay';
import type { SearchResultChapterBlockMyListLog } from '@/domain/log/__types__/searchResult-chapterBlock-myList';
import type { SearchResultChapterBlockPlayLog } from '@/domain/log/__types__/searchResult-chapterBlock-play';
import type { SearchResultPackageBlockDetailLog } from '@/domain/log/__types__/searchResult-packageBlock-detail';
import type { SearchResultPackageBlockMyListLog } from '@/domain/log/__types__/searchResult-packageBlock-myList';
import type { SearchResultPackageBlockSamplePlayLog } from '@/domain/log/__types__/searchResult-packageBlock-samplePlay';
import type { SearchResultChapterTitleListMyListLog } from '@/domain/log/__types__/searchResultChapter-titleList-myList';
import type { SearchResultChapterTitleListPlayLog } from '@/domain/log/__types__/searchResultChapter-titleList-play';
import type { SearchResultPackageTitleListDetailLog } from '@/domain/log/__types__/searchResultPackage-titleList-detail';
import type { SearchResultPackageTitleListMyListLog } from '@/domain/log/__types__/searchResultPackage-titleList-myList';
import type { SearchResultPackageTitleListSamplePlayLog } from '@/domain/log/__types__/searchResultPackage-titleList-samplePlay';
import type { StoreTitleListDetailLog } from '@/domain/log/__types__/store-titleList-detail';
import type { StoreTitleListMyListLog } from '@/domain/log/__types__/store-titleList-myList';
import type { StoreTitleListSamplePlayLog } from '@/domain/log/__types__/store-titleList-samplePlay';
import type { StoreTitleListDetailTitleListDetailLog } from '@/domain/log/__types__/storeTitleListDetail-titleList-detail';
import type { StoreTitleListDetailTitleListMyListLog } from '@/domain/log/__types__/storeTitleListDetail-titleList-myList';
import type { StoreTitleListDetailTitleListSamplePlayLog } from '@/domain/log/__types__/storeTitleListDetail-titleList-samplePlay';
import type { SvodTitleListMyListLog } from '@/domain/log/__types__/svod-titleList-myList';
import Button from '@/shared/components/Button/Button';
import ActionSheet from '@/shared/components/Modality/ActionSheet';
import BottomSheet from '@/shared/components/Modality/BottomSheet';
import PopupHelper from '@/shared/components/PopupHelper';
import { VideoConsumableType } from '@/shared/generated';
import { useLoginState } from '@/shared/hooks/useLoginState';
import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import { BeemiResourceType } from '@/shared/types';
import { mergeQueryString } from '@/shared/utils/routeHelper';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Dispatch,
  FC,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useContext,
} from 'react';
import { defineMessage, useIntl } from 'react-intl';

const messages = {
  details: defineMessage({
    id: 'card.optionsSheet.details',
    defaultMessage: '詳細',
  }),
  play: defineMessage({
    id: 'card.optionsSheet.play',
    defaultMessage: '再生',
  }),
  samplePlay: defineMessage({
    id: 'card.optionsSheet.samplePlay',
    defaultMessage: 'サンプルを再生',
  }),
  addToMyList: defineMessage({
    id: 'card.optionsSheet.addToMyList',
    defaultMessage: 'マイリストに追加',
  }),
  removeFromMyList: defineMessage({
    id: 'card.optionsSheet.removeFromMyList',
    defaultMessage: 'マイリストから削除',
  }),
};

type DetailLog =
  | StoreTitleListDetailLog
  | SearchResultPackageBlockDetailLog
  | SearchResultPackageTitleListDetailLog
  | StoreTitleListDetailTitleListDetailLog
  | PackageDetailRecommendTitleDetailLog;
type PlayLog =
  | StoreTitleListSamplePlayLog
  | SearchResultPackageBlockSamplePlayLog
  | SearchResultPackageTitleListSamplePlayLog
  | SearchResultChapterTitleListPlayLog
  | StoreTitleListDetailTitleListSamplePlayLog
  | SearchResultChapterBlockPlayLog
  | PackageDetailRecommendTitleSamplePlayLog;
type MyListLog =
  | StoreTitleListMyListLog
  | SvodTitleListMyListLog
  | SearchResultPackageBlockMyListLog
  | SearchResultChapterBlockMyListLog
  | SearchResultPackageTitleListMyListLog
  | SearchResultChapterTitleListMyListLog
  | StoreTitleListDetailTitleListMyListLog
  | PackageDetailRecommendTitleMyListLog;

type OptionsSheetLog = DetailLog | PlayLog | MyListLog;

interface Props {
  id: string;
  title: string;
  isShowing: boolean;
  isFavorite?: boolean;
  isSample?: boolean;
  showDetails?: boolean;
  type: BeemiResourceType;
  onClose: () => void;
  onCancel: () => void;
  onClickMyList?: MouseEventHandler;
  anchor: HTMLElement | null;
  kafkaProps?: KafkaPropsWithBaseSchema<OptionsSheetLog>;
  isAddToMyListClicked: boolean;
  setIsAddToMyListAdded: Dispatch<SetStateAction<boolean>>;
}

const OptionsSheet: FC<Props> = ({
  id,
  title,
  isShowing,
  isFavorite,
  isSample,
  showDetails = true,
  type,
  onClose,
  onCancel,
  onClickMyList,
  anchor,
  kafkaProps,
  isAddToMyListClicked,
  setIsAddToMyListAdded,
}) => {
  const intl = useIntl();
  const isMd = useTailwindBreakpoint('md');
  const router = useRouter();

  const { client } = useContext(KafkaContext);

  /**
   * basically cid for chapter and pid for package
   */
  const queryKey = `${type.charAt(0)}id`;
  const { path: typeDetailPath } = mergeQueryString(router, {
    [queryKey]: id,
  });
  const { path: packageSamplePlayPath } = mergeQueryString(router, {
    [queryKey]: id,
    play: VideoConsumableType.Sample,
  });

  const { isLoggedIn } = useLoginState();

  const handleDetailButtonClick = useCallback(() => {
    if (kafkaProps) {
      const { targetPrefix, base_schema, ...rest } = kafkaProps;
      const target = `${targetPrefix}-detail`;
      client?.trackUser(
        target as LogBody['event']['target'],
        'click',
        base_schema,
        rest
      );
    }

    onClose();
  }, [client, kafkaProps, onClose]);

  const handleOnClickPlay = useCallback(() => {
    if (kafkaProps) {
      const { targetPrefix, base_schema, ...rest } = kafkaProps;
      const target = `${targetPrefix}-${
        type === 'package' ? 'samplePlay' : 'play'
      }`;

      client?.trackUser(
        target as LogBody['event']['target'],
        'click',
        base_schema,
        rest
      );
    }

    router.push(
      type === 'chapter' ? typeDetailPath : packageSamplePlayPath,
      undefined,
      { scroll: false }
    );

    onClose();
  }, [
    client,
    kafkaProps,
    onClose,
    packageSamplePlayPath,
    router,
    type,
    typeDetailPath,
  ]);

  const handleMyListButtonClick = useCallback<MouseEventHandler>(
    (e) => {
      if (kafkaProps) {
        const { targetPrefix, base_schema, ...rest } = kafkaProps;
        const target = `${targetPrefix}-myList` as MyListLog['event']['target'];

        client?.trackUser<MyListLog>(target, 'click', base_schema, rest);
      }
      onClickMyList?.(e);
      setIsAddToMyListAdded(true);
    },
    [client, kafkaProps, onClickMyList]
  );

  const Buttons = (
    <>
      {showDetails && (
        <Link href={typeDetailPath} passHref scroll={false} legacyBehavior>
          <Button
            text={intl.formatMessage(messages.details)}
            variant="BOTTOM_SHEET"
            leftIconName="INFO_OUTLINE_ICON"
            component="a"
            /**
             * We need to dismiss the PopupHelper when user click this button
             */
            onClick={handleDetailButtonClick}
          />
        </Link>
      )}

      <Button
        text={intl.formatMessage(
          isSample ? messages.samplePlay : messages.play
        )}
        variant="BOTTOM_SHEET"
        leftIconName="PLAY_ICON"
        component="a"
        onClick={handleOnClickPlay}
        appendClassName="cursor-pointer"
      />
      {isLoggedIn && (
        <Button
          text={intl.formatMessage(
            isFavorite ? messages.removeFromMyList : messages.addToMyList
          )}
          variant="BOTTOM_SHEET"
          leftIconName={
            isFavorite
              ? 'CONTENT_DELETE_ICON'
              : isAddToMyListClicked
              ? 'ADDED_ICON'
              : 'PLUS_ICON'
          }
          onClick={handleMyListButtonClick}
          appendClassName={
            isAddToMyListClicked ? 'pointer-events-none opacity-50' : ''
          }
        />
      )}
    </>
  );

  if (isMd) {
    return isShowing ? (
      <PopupHelper anchor={anchor}>
        <ActionSheet onClickOutside={onClose}>{Buttons}</ActionSheet>
      </PopupHelper>
    ) : null;
  }

  return (
    <BottomSheet
      isShowing={isShowing}
      onCancel={onCancel}
      onClickOutside={onClose}
      title={title}
    >
      {Buttons}
    </BottomSheet>
  );
};

export default OptionsSheet;
