type Key = 'svodHintDismiss' | 'R18' | 'bitRate';

type Value = string | boolean | Record<string, unknown>;

const BOOLEAN_TRUE = '1';
const BOOLEAN_FALSE = '0';

const set = (key: Key, value: Value): void => {
  let serializedValue = '';

  if (typeof value === 'boolean') {
    serializedValue = value ? BOOLEAN_TRUE : BOOLEAN_FALSE;
  } else if (typeof value === 'string') {
    serializedValue = value;
  } else {
    serializedValue = JSON.stringify(value);
  }

  window.localStorage.setItem(key, serializedValue);
};

const getBoolean = (key: Key): boolean | null => {
  const value = window.localStorage.getItem(key);
  if (value === null) {
    return null;
  }

  return value === BOOLEAN_TRUE;
};

const getString = (key: Key): string | null => {
  const value = window.localStorage.getItem(key);
  if (value === null) {
    return null;
  }

  return value;
};

const getObject = <T>(key: Key): T | null => {
  const value = window.localStorage.getItem(key);
  if (!value) {
    return null;
  }

  return JSON.parse(value) as T;
};

const remove = (key: Key): void => {
  window.localStorage.removeItem(key);
};

const appLocalStorage = {
  set,
  getBoolean,
  getString,
  getObject,
  remove,
};

export default appLocalStorage;
