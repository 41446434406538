import React, { createContext } from 'react';

interface ContextProps {
  showSearchDialog: boolean;
  updateSearchDialog: (value: boolean) => void;
}
const SearchDialogContext = createContext<ContextProps>({
  showSearchDialog: false,
  updateSearchDialog: () => {
    return null;
  },
});

export const useSearchDialog = (): ContextProps => {
  const context = React.useContext(SearchDialogContext);
  if (!context) {
    throw new Error(
      'useSearchDialog must be rendered within the SearchDialogContext.Provider'
    );
  }
  return context;
};

export default SearchDialogContext;
