import { differenceInCalendarDays } from 'date-fns';

/**
 * Returns the number of days between the current date and the given date.
 *
 * @param scheduleDate The date to compare against the current date.
 * @returns The number of days between the given date and the current date.
 */
export const getCalendarDayDiffIncludingToday = (
  scheduleDate: Date
): number => {
  // Calculate the difference in days between the given date and the current date.
  const diffInDays = differenceInCalendarDays(scheduleDate, new Date());

  // Add one day to the diff and return the result.
  return diffInDays + 1;
};
