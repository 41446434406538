import { FC, MouseEventHandler } from 'react';
import Icon, { Icons } from '../../Icon';

export interface TitleActionButtonProps {
  icon: Icons;
  text: string;
  checked?: boolean;
  styleOverride?: string;
  onClick?: MouseEventHandler;
}

const TitleActionButton: FC<TitleActionButtonProps> = ({
  icon,
  text,
  checked,
  styleOverride,
  ...props
}) => {
  return (
    <button
      className={`flex flex-col items-center w-20 transition-colors ${
        checked ? 'text-purple' : 'text-purple/60'
      } hover:bg-purple/10 ${styleOverride}`}
      {...props}
    >
      <Icon name={icon} size="EXTRA_BIG" />
      <span className="mt-1 text-3xs md:text-2xs">{text}</span>
    </button>
  );
};

export default TitleActionButton;
