import { animated } from '@react-spring/web';
import React, {
  ComponentProps,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { lock, unlock } from 'tua-body-scroll-lock';

interface HtmlDivProps extends HTMLAttributes<HTMLDivElement> {
  as: 'div';
  children: ReactNode;
}

interface AnimatedDivProps extends ComponentProps<typeof animated.div> {
  as: typeof animated.div;
  children: ReactNode;
}

type Props = HtmlDivProps | AnimatedDivProps;

const ScrollContainer = React.forwardRef<HTMLDivElement | null, Props>(
  ({ children, as, ...rest }, ref) => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
      ref,
      () => scrollContainerRef.current
    );

    useEffect(() => {
      const element = scrollContainerRef.current;

      lock(element);
      return () => {
        unlock(element);
      };
    }, []);

    return React.createElement(
      as,
      {
        ref: scrollContainerRef,
        ...rest,
      },
      children
    );
  }
);

ScrollContainer.displayName = 'ScrollContainer';
export default ScrollContainer;
