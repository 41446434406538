import { useGetOneTimeTokenQuery } from '@/shared/generated';
import { useLoginState } from '@/shared/hooks/useLoginState';
import { useEffect, useState } from 'react';

interface Params {
  type: 'app_beemipackageplayer' | 'app_beemichapterplayer' | 'nextbrowservr';
  media_id?: string;
  media_chapter_id?: string;
  player_session_id?: string;
}

export default function useIntentUrl({
  type,
  media_id,
  media_chapter_id,
  player_session_id,
}: Params): string {
  const { data: oneTimeTokenData } = useGetOneTimeTokenQuery();
  const { data: userInfoData } = useLoginState();

  const [intentUrl, setIntentUrl] = useState('');

  useEffect(() => {
    const intentParams = {
      back_url: encodeURIComponent(location.href),
      session_key: oneTimeTokenData?.webfront_oneTimeToken?.token,
      platform_code: userInfoData?.userInfo?.userPlatformCode,
    };

    if (oneTimeTokenData && userInfoData) {
      if (type === 'nextbrowservr') {
        setIntentUrl(
          `jp.unext.vr://page=nextbrowservr?` +
            Object.entries(intentParams)
              .map(([key, value]) => key + '=' + value)
              .join('&')
        );
      }

      if (type === 'app_beemipackageplayer' && media_id && player_session_id) {
        setIntentUrl(
          `jp.unext://page=beemipackageplayer?` +
            Object.entries({
              ...intentParams,
              adult_type: 1,
              launch_type: 1,
              media_id,
              player_session_id,
            })
              .map(([key, value]) => key + '=' + value)
              .join('&')
        );
      }
      if (
        type === 'app_beemichapterplayer' &&
        media_chapter_id &&
        player_session_id
      ) {
        setIntentUrl(
          `jp.unext://page=beemipackageplayer?` +
            Object.entries({
              ...intentParams,
              adult_type: 1,
              launch_type: 1,
              media_chapter_id,
              player_session_id,
            })
              .map(([key, value]) => key + '=' + value)
              .join('&')
        );
      }
    }
  }, [
    media_chapter_id,
    media_id,
    oneTimeTokenData,
    player_session_id,
    type,
    userInfoData,
  ]);

  return intentUrl;
}
