import Head from 'next/head';
import { FC } from 'react';

interface MetaTagsProps {
  title?: string;
  description?: string;
  canonicalLink?: string;
  noIndex?: boolean;
}

const MetaTags: FC<MetaTagsProps> = ({
  title,
  description,
  canonicalLink,
  noIndex,
}) => {
  const hostname = process.env.NEXT_PUBLIC_PUBLIC_URL;

  return (
    <Head>
      {/* Next.js only handles <title> and <meta name /> duplicates */}
      {/* to handle duplicates, key is required for tags other than <title> and <meta name /> */}
      {title && [
        <title key="title">{title}</title>,
        <meta key="og:title" property="og:title" content={title} />,
      ]}
      {/* description can be blank */}
      {typeof description === 'string' && [
        <meta key="description" name="description" content={description} />,
        <meta
          property="og:description"
          content={description}
          key="og:description"
        />,
      ]}
      {canonicalLink && (
        <link
          rel="canonical"
          href={`${hostname}${canonicalLink}`}
          key="canonical"
        />
      )}
      {noIndex && <meta name="robots" content="noindex" />}
    </Head>
  );
};

export default MetaTags;
