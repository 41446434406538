import { useGetUserInfoQuery } from '@/shared/generated';

export enum LOGIN_STATE {
  UNINITIALISED = 'UNINITIALISED',
  LOADING = 'LOADING',
  LOGGED_IN = 'LOGGED_IN',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

function deriveLoginState(res: ReturnType<typeof useGetUserInfoQuery>) {
  if (!res) {
    return LOGIN_STATE.UNINITIALISED;
  }

  const { data, loading } = res;

  if (loading) {
    return LOGIN_STATE.LOADING;
  }

  if (data?.userInfo?.id) {
    return LOGIN_STATE.LOGGED_IN;
  }

  return LOGIN_STATE.UNAUTHENTICATED;
}

export const useLoginState = () => {
  const res = useGetUserInfoQuery();
  const state = deriveLoginState(res);
  return {
    ...res,
    state,
    isReady: ![LOGIN_STATE.UNINITIALISED, LOGIN_STATE.LOADING].includes(state),
    isLoggedIn: state === LOGIN_STATE.LOGGED_IN,
    /**
     * there is already logic applied on the server.
     * this will return false for users that are already subscribed
     * https://u-next.slack.com/archives/C3HTNHMJ6/p1657074665955999?thread_ts=1657073235.198329&cid=C3HTNHMJ6
     */
    canUpsell: res.data?.webfront_upsell?.canDisplay ?? false,
  };
};
