import { PackageCardProps } from '@/shared/components/Card/PackageCard';
import {
  CapyPaymentBadgeCode,
  PackageCardLoggedInFragment,
  TitleLifetimeClass,
} from '@/shared/generated';

export function mapMediaLightToPackageCard(
  mediaLight: PackageCardLoggedInFragment
): PackageCardProps {
  const { id, metadata, productInfo, favorite } = mediaLight;

  return {
    id: id,
    description: metadata.nameJa,
    imgSrc: metadata.thumbnails[0]?.t1 || '',
    imgAlt: metadata.nameJa,
    isSvod: productInfo?.paymentBadges?.includes(CapyPaymentBadgeCode.Svod),
    price: productInfo?.minPrice || 0,
    multiPrice: productInfo?.hasMultiplePrices,
    tags: metadata.contentTags.map((tag) => tag.nameJa),
    isNew: productInfo?.platformLifetimeClass === TitleLifetimeClass.New,
    likes: metadata.favoriteCount,
    isFavorite: !!favorite,
    featureBadges: metadata.featureBadges,
  };
}
