import PackageDetail from '@/domain/package/PackageDetail';
import PackagePlayer from '@/domain/package/PackagePlayer';
import DetailModal from '@/shared/components/Modality/DetailModal';
import { useRouter } from 'next/router';
import { FC, useCallback } from 'react';

interface Props {
  packageId: string;
  isSsr: boolean;
}

const PackageIndex: FC<Props> = ({ packageId, isSsr }) => {
  const router = useRouter();

  const handleClose = useCallback(() => {
    if (router.pathname.startsWith('/package/[packageId]')) {
      router.push('/store');
    } else {
      const newQuery = { ...router.query };
      delete newQuery['pid'];
      delete newQuery['packageId'];
      delete newQuery['play'];

      router.push(
        {
          pathname: router.pathname,
          query: newQuery,
        },
        undefined,
        { scroll: false }
      );
    }
  }, [router]);

  const isPackagePlayer =
    Boolean(router.query.play) || router.pathname.endsWith('/play');

  return (
    <>
      <DetailModal
        id={packageId}
        onClose={handleClose}
        isSsr={isSsr}
        isCloseButtonDark={!isPackagePlayer}
      >
        {isPackagePlayer ? (
          <PackagePlayer packageId={packageId} />
        ) : (
          <PackageDetail packageId={packageId} />
        )}
      </DetailModal>
    </>
  );
};

export default PackageIndex;
