import { Node } from '@/shared/generated';
import classNames from 'classnames';
import { FC } from 'react';
import TagButton from '../../Button/TagButton';
import Icon from '../../Icon';

export interface SearchFormButtonProps {
  placeholder?: string;
  tags?: Node[];
  value: string;
  onClick?: () => void;
}

const SearchFormButton: FC<SearchFormButtonProps> = ({
  placeholder,
  value,
  tags = [],
  onClick,
}) => {
  return (
    <div className="px-5 md:p-2 md:pb-4">
      <button
        className={classNames(
          'flex items-center w-full p-2 rounded cursor-pointer md:border-2 md:border-transparent md:pr-3 md:rounded-lg bg-purple/10 md:bg-purple/5 md:justify-between',
          tags.length > 0 ? 'md:pl-4' : 'md:pl-6'
        )}
        onClick={onClick}
      >
        <div className="flex-shrink-0 mr-2 w-7 h-7 md:mr-0 md:ml-2 md:order-2 md:w-10 md:h-10 md:text-purple/10">
          <Icon name="SEARCH_ICON" size="FULL" />
        </div>
        <div className="flex flex-wrap items-center gap-1 overflow-hidden md:gap-2">
          {tags.length > 0 &&
            tags.map((tag) => (
              <TagButton
                key={tag.id}
                size="SMALL"
                variant="SELECTED"
                text={tag.nameJa}
                component="div"
                dataTestId="SearchTag"
              />
            ))}
          {value
            ? value
            : tags.length === 0 &&
              !value &&
              !!placeholder && (
                <div className="text-purple/40">
                  {value ? value : placeholder}
                </div>
              )}
        </div>
      </button>
    </div>
  );
};

export default SearchFormButton;
