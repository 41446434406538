import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import { useSnackBar } from '@/shared/SnackBarContext';
import classNames from 'classnames';
import React, { RefObject, useRef } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import Button from '../../../Button/Button';
import SimpleDialog from '../SimpleDialog';

const messages = {
  iframeTitle: defineMessage({
    id: 'share.iframe.title',
    defaultMessage: '動画の埋め込み',
  }),
  iframeButton: defineMessage({
    id: 'iframe.copy',
    defaultMessage: 'コピー',
  }),
  iframeMessage: defineMessage({
    id: 'share.iframe.message',
    defaultMessage: 'クリップボードにコピーしました。',
  }),
};

export interface DialogIframeProps {
  isShowing: boolean;
  embedCode: string;
  onClickOutside: () => void;
  onCancel: () => void;
}

const DialogIframe: React.FC<DialogIframeProps> = (props) => {
  const intl = useIntl();
  const formRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const isMd = useTailwindBreakpoint('md');

  const { showSnackBar } = useSnackBar();

  const handleFormClick = () => {
    formRef.current?.select();
  };
  const handleCopyButtonClick = () => {
    handleFormClick();
    document.execCommand('copy');
    showSnackBar({ message: intl.formatMessage(messages.iframeMessage) });
  };

  const renderButtons = () => {
    return (
      <>
        <Button
          variant="PRIMARY"
          text={intl.formatMessage(messages.iframeButton)}
          leftIconName="COPY_ICON"
          onClick={handleCopyButtonClick}
        />
      </>
    );
  };

  const commonTextboxStyles =
    'w-full border-2 rounded text-sm text-purple bg-purple/10 border-purple/10 focus:border-purple/10 focus:ring-0 placeholder-purple/40 selection:bg-pink/20 md:text-md mt-6';
  return (
    <>
      <SimpleDialog
        title={intl.formatMessage(messages.iframeTitle)}
        isShowing={props.isShowing}
        onCancel={props.onCancel}
        onClickOutside={props.onClickOutside}
        renderButtons={renderButtons}
        animationType="fade"
      >
        {isMd ? (
          <textarea
            className={classNames(
              commonTextboxStyles,
              'h-44 resize-none px-3.5 py-3.5'
            )}
            value={props.embedCode}
            ref={formRef as RefObject<HTMLTextAreaElement>}
            onClick={handleFormClick}
          />
        ) : (
          <input
            type="text"
            className={classNames(commonTextboxStyles, 'h-14 px-5')}
            value={props.embedCode}
            ref={formRef as RefObject<HTMLInputElement>}
            onClick={handleFormClick}
          />
        )}
      </SimpleDialog>
    </>
  );
};

export default DialogIframe;
