import VRGuideArea from '@/domain/chapter/VRGuideArea';
import InfiniteScrollContainer from '@/domain/library/mylist/InfiniteScrollContainer';
import { KafkaContext } from '@/domain/log/KafkaContext';
import { TreasureDataContext } from '@/domain/log/TreasureDataContext';
import { PlayerControlSkipLog } from '@/domain/log/__types__/player-control-skip';
import useIntentUrl from '@/domain/package/useIntentUrl';
import PurchaseDialog from '@/domain/purchase';
import BeemiImage from '@/shared/components/BeemiImage';
import PlayerChapterCard from '@/shared/components/Card/PlayerChapterCard';
import Footer from '@/shared/components/Layout/Footer';
import MetaTags from '@/shared/components/MetaTags';
import PlayErrorDialog from '@/shared/components/Modality/Dialog/PlayErrorDialog';
import SignupMonthly from '@/shared/components/Modality/Dialog/SignupMonthly';
import PlayerMetaLoading from '@/shared/components/Player/PlayerMetaLoading';
import { PlayerKafkaLogger } from '@/shared/components/Player/PlayerUI';
import usePlayerTdLogger from '@/shared/components/Player/usePlayerTdLogger';
import PlayerLoginButtons from '@/shared/components/PlayerLoginButtons';
import { INFINITE_FETCH_LIMIT } from '@/shared/constants/limit';
import { errorMessages } from '@/shared/constants/messages';
import { packagePlayerMetaMessage } from '@/shared/constants/meta/package/packagePlayer';
import {
  CapyPaymentBadgeCode,
  useGetMediaPlayInfoQuery,
  VideoConsumableType,
} from '@/shared/generated';
import { useLoginState } from '@/shared/hooks/useLoginState';
import usePlayInfo from '@/shared/hooks/usePlayInfo';
import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import IsInitialRouteContext from '@/shared/IsInitialRouteContext';
import { usePlayerControl } from '@/shared/PlayerControlContext';
import { useSnackBar } from '@/shared/SnackBarContext';
import { extractFirstGraphQLError } from '@/shared/utils/extractFirstGraphQLError';
import getLoginUrl from '@/shared/utils/getLoginUrl';
import mergeFetchMore from '@/shared/utils/mergeFetchMore';
import { getTimeString } from '@/shared/utils/timeHelper';
import { ApolloError, NetworkStatus } from '@apollo/client';
import { IsemSessionArgs } from '@u-next/videoplayer';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import {
  ComponentProps,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { defineMessage, useIntl } from 'react-intl';

const Player = dynamic(() => import('../../shared/components/Player/Player'), {
  ssr: false,
});

type PlayerProps = ComponentProps<typeof Player>;

const messages = {
  chapter: defineMessage({
    id: 'player.chapter',
    defaultMessage: 'チャプター{chapter}',
  }),
};

interface Props {
  packageId: string;
}

const PackagePlayer: FC<Props> = ({ packageId }) => {
  const intl = useIntl();
  const { isInitialRoute } = useContext(IsInitialRouteContext);
  const isMd = useTailwindBreakpoint('md');
  const router = useRouter();
  const { tdClientId } = useContext(TreasureDataContext);

  const { showSnackBar } = useSnackBar();
  const onError = useCallback(
    (error: ApolloError) => {
      const notFoundError = extractFirstGraphQLError(error, ['NOT_FOUND']);
      if (notFoundError) {
        showSnackBar({
          message: intl.formatMessage(errorMessages.pageNotFound),
        });

        router.push('/store');

        return;
      }
      throw new ApolloError(error);
    },
    [intl, router, showSnackBar]
  );

  const { data, loading, fetchMore, networkStatus } = useGetMediaPlayInfoQuery({
    variables: {
      mediaId: packageId,
      deviceId: tdClientId,
      chapterPagination: {
        offset: 0,
        limit: INFINITE_FETCH_LIMIT,
      },
    },
    notifyOnNetworkStatusChange: true,
    onError,
  });

  const [isShowingSignupMonthly, setIsShowingSignupMonthly] = useState(false);
  const [playerErrorCode, setPlayerErrorCode] = useState('');

  const {
    isLoggedIn,
    data: userInfoData,
    loading: loginStateLoading,
  } = useLoginState();
  const initialLoading = loading && networkStatus === NetworkStatus.loading;
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0);
  const { playerSeek } = usePlayerControl();

  const handlePlayerError = useCallback<NonNullable<PlayerProps['onError']>>(
    (error) => {
      setPlayerErrorCode(error.customCode || '');
    },
    []
  );

  const requestedConsumableType = router.query.play
    ? (router.query.play as VideoConsumableType)
    : VideoConsumableType.Main;

  const requestedConsumable = data?.media?.metadata.videoConsumables.find(
    (consumable) => consumable.type === requestedConsumableType
  );

  const handleGetPlayBackTokensError = useCallback(
    (error: ApolloError) => {
      const forbiddenError = extractFirstGraphQLError(error, ['FORBIDDEN']);
      if (forbiddenError) {
        const newQuery = { ...router.query, purchase: 'true' };
        router.push({ ...router, query: newQuery }, undefined, {
          scroll: false,
        });
      } else {
        const extractedError = extractFirstGraphQLError(error);
        if (extractedError?.code) setPlayerErrorCode(extractedError.code);
      }
    },
    [router]
  );

  const handleLimeError = useCallback(
    (e: Error) => setPlayerErrorCode(e.message),
    []
  );

  const isSample = requestedConsumableType === VideoConsumableType.Sample;

  const {
    limeToken,
    isemToken,
    productCode,
    saleTypeCode,
    playables,
    refetchPlayBackTokens,
    bitRate,
    onChangeBitRate,
  } = usePlayInfo({
    consumableId: requestedConsumable?.id || '',
    contentId: packageId,
    skip: !requestedConsumable?.id || (!isLoggedIn && !isSample),
    isSample,
    onGetPlayBackTokensError: handleGetPlayBackTokensError,
    onLimeError: handleLimeError,
  });

  const handlePurchaseComplete = useCallback(() => {
    refetchPlayBackTokens();

    const newQuery = { ...router.query };
    delete newQuery['purchase'];
    router.push({ ...router, query: newQuery }, undefined, { scroll: false });
  }, [refetchPlayBackTokens, router]);

  const isSvod = data?.media?.productInfo?.paymentBadges?.includes(
    CapyPaymentBadgeCode.Svod
  );
  const mediaMeta = data?.media?.metadata;
  const poster = mediaMeta?.thumbnails[0]?.w2;
  const currentChapterStartTime =
    mediaMeta?.paginatedChapters?.records?.[currentChapterIndex].metadata
      .startTimeSeconds;
  const currentChapterEndTime =
    mediaMeta?.paginatedChapters?.records?.[currentChapterIndex].metadata
      .endTimeSeconds;
  const chapterHasNextPage =
    !!data?.media?.metadata?.paginatedChapters?.pageInfo?.hasNext;
  const currentChapterName = intl.formatMessage(messages.chapter, {
    chapter: currentChapterIndex + 1,
  });
  const currentChapterDuration =
    typeof currentChapterStartTime === 'number' &&
    typeof currentChapterEndTime === 'number'
      ? `${getTimeString(currentChapterStartTime, true)} ~ ${getTimeString(
          currentChapterEndTime,
          true
        )}`
      : '';

  const title = data?.media?.metadata.nameJa ?? '';
  const subtitle = `${currentChapterName} ${currentChapterDuration}`;

  const chaptersInfo: ComponentProps<typeof Player>['chaptersInfo'] =
    useMemo(() => {
      return mediaMeta?.paginatedChapters?.records?.map(({ metadata }) => ({
        startTime: metadata.startTimeSeconds,
        endTime: metadata.endTimeSeconds,
      }));
    }, [mediaMeta]);

  const playerChapterList: ComponentProps<typeof Player>['chapterList'] =
    mediaMeta?.paginatedChapters?.records?.map(({ metadata }, index) => ({
      isPlaying: currentChapterIndex === index,
      displayName: intl.formatMessage(messages.chapter, {
        chapter: metadata.chapterNo,
      }),
      subText: `${getTimeString(
        metadata.startTimeSeconds,
        true
      )} ~ ${getTimeString(metadata.endTimeSeconds, true)}`,
      image: metadata.thumbnails[0].t1,
      handlePlayableChange: () => playerSeek(metadata.startTimeSeconds),
    }));

  /**
   * NOTE: because of how videoPlayer.load in Player.tsx works,
   * even if this callback gets a new version, only the first version
   * will be used by the Player. Therefor we only want to render Player.tsx
   * once we have `chaptersInfo`
   * */
  const onTimeUpdate: NonNullable<PlayerProps['onTimeUpdate']> | undefined =
    chaptersInfo
      ? (e) => {
          const currentTime = Math.floor(
            (e.target as HTMLVideoElement).currentTime
          );

          const foundChapterIndex = chaptersInfo.findIndex(
            ({ startTime, endTime }) =>
              startTime <= currentTime && currentTime < endTime
          );

          if (foundChapterIndex !== -1) {
            setCurrentChapterIndex(foundChapterIndex);
          }
        }
      : undefined;

  const { client } = useContext(KafkaContext);

  const sendSkipLog = useCallback(
    (toggleStatus: boolean) => {
      client?.trackUser<PlayerControlSkipLog>(
        'player-control-skip',
        'click',
        'user_click_dimension_0_default',
        {
          package_id: packageId,
          toggle_status: toggleStatus, //True for skipping forward. False for skipping backward.
        }
      );
    },
    [client, packageId]
  );

  const playerKafkaLogger: PlayerKafkaLogger = {
    skipForward: () => sendSkipLog(true),
    skipBackward: () => sendSkipLog(false),
  };

  const onLoginClick = () => {
    location.href = getLoginUrl(`/package/${packageId}/play`);
  };

  const fetchMoreChapters = (offset: number, limit: number) =>
    fetchMore({
      variables: {
        chapterPagination: {
          offset,
          limit,
        },
      },
      updateQuery: mergeFetchMore,
    });

  const [overwrite, setOverwrite] = useState(false);

  const sessionArgs: IsemSessionArgs = useMemo(
    () => ({
      type: 'isem',
      isemToken: isemToken,
      baseUrl: process.env.NEXT_PUBLIC_ISEM_SERVER || '',
      deviceId: tdClientId,
      overwrite,
    }),
    [isemToken, overwrite, tdClientId]
  );

  const playerTdLogger = usePlayerTdLogger();

  useEffect(() => {
    if (requestedConsumable && data?.media) {
      playerTdLogger.setMediaLogInfo({
        media_id: packageId,
        media_name: data?.media?.metadata.nameJa ?? '',
        consumable_id: requestedConsumable?.id ?? '',
        consumable_type: requestedConsumable?.type ?? '',
        play_time: requestedConsumable.durationSeconds,
        product_public_code: productCode,
        sale_type_code: saleTypeCode,
      });
    }
  }, [
    data?.media,
    packageId,
    playerTdLogger,
    productCode,
    requestedConsumable,
    saleTypeCode,
  ]);

  const hasVR = !!requestedConsumable?.videoProfiles.some(
    (videoProfile) => videoProfile.geometry
  );

  const [playerSessionId, setPlayerSessionId] = useState('');

  const intentUrl = useIntentUrl({
    type: 'app_beemipackageplayer',
    media_id: packageId,
    player_session_id: playerSessionId,
  });

  const enterXRSessionRef = useRef<() => void>();

  return (
    <>
      <MetaTags
        title={intl.formatMessage(packagePlayerMetaMessage.title, {
          packageName: mediaMeta?.nameJa,
        })}
        description={intl.formatMessage(packagePlayerMetaMessage.description)}
        canonicalLink={`/package/${packageId}`}
      />
      <div
        data-testid="PackagePlayerContainer"
        className="w-full mb-6 aspect-w-16 aspect-h-9 lg:mb-12 lg:overflow-hidden lg:rounded-t-2xl bg-purple/20"
      >
        <Player
          playbackAuthorization="bearer"
          sessionArgs={isSample ? undefined : sessionArgs}
          authorizationToken={limeToken}
          resumePointId={requestedConsumable?.id}
          title={title}
          subtitle={subtitle}
          poster={poster}
          playables={playables}
          chapterList={playerChapterList}
          autoplay
          onError={handlePlayerError}
          onTimeUpdate={onTimeUpdate}
          chaptersInfo={chaptersInfo}
          playerKafkaLogger={playerKafkaLogger}
          playerTdLogger={playerTdLogger}
          packageId={packageId}
          bitRate={bitRate}
          onChangeBitRate={onChangeBitRate}
          hasVR={hasVR}
          playerSessionId={playerSessionId}
          setPlayerSessionId={setPlayerSessionId}
          intentUrl={intentUrl}
          enterXRSessionRef={enterXRSessionRef}
        />

        {!(limeToken && playables) && (
          <>
            {poster && (
              <BeemiImage
                src={poster}
                alt={mediaMeta?.nameJa}
                className="object-cover w-full h-full"
              />
            )}
            {!isLoggedIn && !loginStateLoading && (
              <PlayerLoginButtons
                onRegisterClick={() => {
                  if (isSvod) {
                    setIsShowingSignupMonthly(true);
                  } else {
                    location.href =
                      process.env.NEXT_PUBLIC_REGISTER_PF_URL ?? '';
                  }
                }}
                onLoginClick={onLoginClick}
              />
            )}
          </>
        )}
      </div>

      {hasVR && <VRGuideArea enterXRSessionRef={enterXRSessionRef} />}

      {initialLoading || !mediaMeta ? (
        <PlayerMetaLoading />
      ) : (
        <>
          <div className="border-b px-7 border-purple/10 md:px-20 md:border-0">
            <h1 className="font-bold text-md text-purple">{title}</h1>
            <div className="pb-6 mt-2 text-xs md:text-sm md:border-b">
              <span className="mr-3 text-purple">{currentChapterName}</span>
              {typeof currentChapterStartTime === 'number' &&
                typeof currentChapterEndTime === 'number' && (
                  <span className="text-time text-purple/60">
                    {currentChapterDuration}
                  </span>
                )}
            </div>
          </div>
          <InfiniteScrollContainer
            loading={loading}
            fetchMore={fetchMoreChapters}
            hasNextPage={chapterHasNextPage}
          >
            <div className="grid justify-center w-full grid-cols-2 gap-4 px-8 pt-10 gap-x-4 gap-y-4 md:gap-x-3 md:gap-y-6 md:px-20 md:pt-8 md:pb-25 md:grid-cols-3 lg:grid-cols-4">
              {mediaMeta.paginatedChapters?.records?.map(
                ({ id, metadata }, i) => (
                  <PlayerChapterCard
                    key={id}
                    selected={i === currentChapterIndex}
                    description={intl.formatMessage(messages.chapter, {
                      chapter: metadata.chapterNo,
                    })}
                    imgSrc={metadata.thumbnails[0].t1}
                    imgAlt={metadata.nameJa}
                    durationDisplayText={`${getTimeString(
                      metadata.startTimeSeconds,
                      true
                    )} ~`}
                    onClick={() => {
                      setCurrentChapterIndex(i);
                      playerSeek(metadata.startTimeSeconds);
                    }}
                  />
                )
              )}
            </div>
          </InfiniteScrollContainer>
        </>
      )}
      {!isMd && <Footer />}
      <SignupMonthly
        isShowing={isShowingSignupMonthly}
        onClickOutside={() => {
          setIsShowingSignupMonthly(false);
        }}
        onCancel={() => {
          setIsShowingSignupMonthly(false);
        }}
      />
      <PurchaseDialog
        mediaId={packageId}
        userInfoData={userInfoData}
        onPurchaseComplete={handlePurchaseComplete}
      />
      {playerErrorCode && (
        <PlayErrorDialog
          playerErrorCode={playerErrorCode}
          onBackClick={() => {
            setPlayerErrorCode('');
            if (isInitialRoute) {
              router.push('/store');
            } else {
              router.back();
            }
          }}
          onTakeOverSessionClick={() => {
            setPlayerErrorCode('');
            setOverwrite(true);
          }}
        />
      )}
    </>
  );
};

export default PackagePlayer;
