import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';

export const CHAPTER_SEQUENCE_ID_NAME = 'csid';
export const CHAPTER_SEQUENCE_NAME = 'cs';

type Params = {
  // firstChapterId will ONLY be used to intialize the chapterSequence when first time useChapterSequence
  // is called. Afterwards, firstChapterId will be ignored even its value is changed
  firstChapterId: string;
};

/**
 * chapterSequenceIndex ("csid" in query) will be incremented, and chapterSequence will be updated only if user:
 *   1. clicks a new chapter link on chapter play page
 *   2. clicks the next chapter button
 *
 * By checking chapterSequenceIndex and chapterSequence, we can know that if
 * a user
 *   1. has a previous chapter playback session (chapterSequenceIndex > 0)
 *   2. has come to this chapter playback session by history back (nextChapterSequenceIndex < chapterSequence.length)
 */
function useChapterSequence({ firstChapterId }: Params): {
  nextChapterSequenceIndex: number;
  nextChapterId: string | null;
  hasForward: boolean;
  hasBackward: boolean;
  updateChapterSequence: (chapterId: string) => void;
} {
  const router = useRouter();
  const chapterSequenceIndex =
    typeof router.query[CHAPTER_SEQUENCE_ID_NAME] === 'string'
      ? Number(router.query[CHAPTER_SEQUENCE_ID_NAME])
      : 0;
  const chapterSequence = useRef<string[]>([]);

  // updateChapterSequence should always be called BEFORE the router gets updated, so that we could
  // differentiate the case that user clicks a new chapter link, or just does a history back/foward
  const updateChapterSequence = useCallback(
    (chapterId: string) => {
      chapterSequence.current = chapterSequence.current
        .slice(0, chapterSequenceIndex + 1)
        .concat([chapterId]);

      sessionStorage.setItem(
        CHAPTER_SEQUENCE_NAME,
        chapterSequence.current.join(',')
      );
    },
    [chapterSequenceIndex]
  );

  useEffect(() => {
    chapterSequence.current =
      sessionStorage.getItem(CHAPTER_SEQUENCE_NAME)?.split(',') ?? [];

    // initialize chapterSequence with first chapter id if there's nothing restored from session storage,
    // we need to do this because we don't call updateChapterSequence for the first one
    if (chapterSequence.current.length === 0) {
      chapterSequence.current = [firstChapterId];
      sessionStorage.setItem(
        CHAPTER_SEQUENCE_NAME,
        chapterSequence.current.join(',')
      );
    }

    return () => {
      // reset the value in session storage if this hook gets unmounted
      // notice it won't be triggered if user refreshes the page
      sessionStorage.removeItem(CHAPTER_SEQUENCE_NAME);
    };
    // make sure this effect will only be exectued on mounting/unmounting useChapterSequence
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // The effect order could not be reversed. This effect must be put after the above restore/initialization effect
  useEffect(() => {
    if (
      // wait for chapterSequence intialization
      chapterSequence.current.length > 0 &&
      // impossible condition unless user manipulates the query/session storage, reset everything
      chapterSequenceIndex >= chapterSequence.current.length
    ) {
      sessionStorage.removeItem(CHAPTER_SEQUENCE_NAME);
      const newQuery = { ...router.query };
      delete newQuery[CHAPTER_SEQUENCE_ID_NAME];
      router.replace({ ...router, query: { ...newQuery } });
    }
  }, [chapterSequenceIndex, router]);

  const nextChapterSequenceIndex =
    chapterSequence.current.length === 0 ? 1 : chapterSequenceIndex + 1;
  return {
    nextChapterSequenceIndex,
    nextChapterId: chapterSequence.current[nextChapterSequenceIndex] ?? null,
    hasForward: nextChapterSequenceIndex < chapterSequence.current.length,
    hasBackward: chapterSequenceIndex > 0,
    updateChapterSequence,
  };
}

export default useChapterSequence;
