import BeemiImage from '@/shared/components/BeemiImage';
import classNames from 'classnames';
import { FC } from 'react';
import { useIntl } from 'react-intl';

interface Props {
  selected?: boolean;
  className?: string;
  description: string;
  imgSrc: string;
  imgAlt: string;
  onClick?: () => void;
  durationDisplayText: string;
}

const PlayerChapterCard: FC<Props> = ({
  selected,
  className,
  description,
  imgAlt,
  imgSrc,
  durationDisplayText,
  onClick,
}) => {
  const intl = useIntl();
  return (
    <div className={classNames('w-full flex flex-col', className)}>
      <button
        className="overflow-hidden rounded-md shadow-sm aspect-w-16 aspect-h-9 group"
        onClick={onClick}
      >
        {selected && (
          <div className="z-10 flex items-center justify-center w-full h-full font-bold text-white bg-purple/60 md:text-lg">
            {intl.formatMessage({
              id: 'relatedChapter.playing',
              defaultMessage: '再生中',
            })}
          </div>
        )}
        <BeemiImage
          className="object-cover w-full h-full"
          src={imgSrc}
          alt={imgAlt}
        />
      </button>
      <div className="flex flex-col justify-center mt-0.5">
        <p className="text-xs md:text-sm text-purple">{description}</p>
        <p className="w-20 overflow-hidden font-mono text-xs md:text-sm whitespace-nowrap text-purple/60 md:text-purple/80 md:w-fit">
          {durationDisplayText}
        </p>
      </div>
    </div>
  );
};

export default PlayerChapterCard;
