import Head from 'next/head';
import { FC } from 'react';

const GlobalMetaTags: FC = () => {
  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* These favicon images are served under /public folder */}
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
      <link
        rel="icon alternate"
        sizes="64x64"
        type="image/png"
        href="/favicon.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/img/apple-touch-icon.png"
      />
      <link rel="icon" sizes="192x192" href="/img/android-icon.png" />
      <meta property="og:image" content="/img/ogp.png" />
    </Head>
  );
};

export default GlobalMetaTags;
