import { FC } from 'react';
import BeemiLogoSymbol from '../../../../assets/icons/logo/Logo_BEEMI_sym_anime.svg';

const PageLoading: FC = () => {
  return (
    <div
      className="flex items-center justify-center flex-grow"
      data-testid="PageLoading"
    >
      <div className="w-24 md:w-40">
        <BeemiLogoSymbol />
      </div>
    </div>
  );
};

export default PageLoading;
