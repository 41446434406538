import { FC } from 'react';
import UNextLogoHorizontal from '../../../../assets/icons/logo/Logo_U-NEXT_Horizontal.svg';
import URLS from '../../../constants/urls';
import Icon from '../../Icon';

const Footer: FC = () => {
  return (
    <footer className="flex items-center justify-center h-32 mt-24 text-md text-purple bg-purple/10 md:flex-col md:opacity-60 md:bg-transparent">
      <a
        className="flex items-center space-x-1.5 mr-7 md:mr-0 md:mb-6"
        href={URLS.unext}
        target="_blank"
        rel="noreferrer noopener"
      >
        <UNextLogoHorizontal className="w-[85px]" />
        <Icon name="NEW_TAB_ICON" />
      </a>
      <a
        className="flex items-center space-x-1.5"
        href="https://www.unext.co.jp/ja"
        target="_blank"
        rel="noreferrer noopener"
      >
        <span>運営会社</span>
        <Icon name="NEW_TAB_ICON" />
      </a>
    </footer>
  );
};

export default Footer;
