import { Node } from '@/shared/generated';
import classNames from 'classnames';
import React, {
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import TagButton from '../../Button/TagButton';
import Icon from '../../Icon';

export interface SearchFormProps {
  placeholder?: string;
  tags?: Node[];
  value: string;
  onChange?: (value: string) => void;
  onRemoveTag?: (tagId: string) => void;
  onEnter?: () => void;
  onClose?: () => void;
}

const SearchForm: React.FC<SearchFormProps> = ({
  placeholder,
  tags,
  value,
  onChange,
  onRemoveTag,
  onEnter,
  onClose,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      setFocused(true);
    }

    return () => setFocused(false);
  }, []);

  const handleChange = useCallback(
    (newValue: string) => {
      if (typeof onChange === 'function') onChange(newValue);
    },
    [onChange]
  );

  const handleEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      // event.keyCode is *deprecated* but still supported by all browsers according to MDN
      // event.key === 'Enter' is the new suggestion, but this does not help for Japanese when the keyboard suggests kanji and user clicks Enter
      // To solve for Japanese text, we will use keyCode until a better solution exists or is no longer supported
      if (event.keyCode === 13) {
        if (typeof onEnter === 'function') onEnter();
      }
    },
    [onEnter]
  );

  return (
    <div>
      <div
        className={classNames(
          'flex w-full h-14 items-center relative bg-purple/5 pl-6 pr-3 py-3 box-border border-2 border-transparent md:pl-6 md:rounded-lg md:border-purple/10',
          tags && tags.length > 0 ? '' : 'shadow-xs md:shadow-none'
        )}
        data-testid="search--searchForm"
      >
        <button
          className="flex items-center mr-4 cursor-pointer md:hidden"
          onClick={onClose}
        >
          <Icon name="FULL_ARROW_LEFT" size="EXTRA_BIG" />
        </button>
        <input
          ref={inputRef}
          className={classNames(
            'flex-grow h-8 bg-transparent border-none outline-none caret-pink accent-pink text-purple placeholder-purple/40',
            // workaround for making iOS safari not scrolling on focus
            focused ? 'opacity-1' : 'opacity-0'
          )}
          placeholder={placeholder}
          value={value}
          onChange={(event) => handleChange(event.target.value)}
          onKeyDown={handleEnter}
        />
        {value ? (
          <button
            className="flex items-center justify-center w-8 h-8"
            onClick={() => {
              handleChange('');
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
          >
            <div className="flex items-center w-6 h-6">
              <Icon
                className="text-white rounded-full cursor-pointer bg-purple/30"
                name="NAV_CLOSE_ICON"
                size="FULL"
              />
            </div>
          </button>
        ) : (
          <div className="w-8 h-8 md:w-10 md:h-10">
            <Icon className="text-purple/10" name="SEARCH_ICON" size="FULL" />
          </div>
        )}
      </div>
      {tags && tags.length > 0 && (
        <div className="flex flex-wrap px-5 py-2 bg-purple/10 gap-y-1 gap-x-1.5 md:gap-x-2 md:bg-transparent md:px-0.5">
          {tags.map((tag) => (
            <TagButton
              key={tag.id}
              size="SMALL"
              variant="REMOVE"
              text={tag.nameJa}
              onClick={() => {
                if (typeof onRemoveTag === 'function') {
                  onRemoveTag(tag.id);
                  if (inputRef.current) {
                    inputRef.current.focus();
                  }
                }
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchForm;
